import { useFormik } from 'formik';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import TopNavBar from '../../layouts/TopNavBar';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addCategoryStyle.module.css';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import JoditEditor from 'jodit-react';
import { toast } from 'react-toastify';
import { showErrorToast } from '../../../../Toster';

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	slug: Yup.string()
		.max(120, 'Slug must not exceed 120 characters')
		.required('Slug is required'),
	description: Yup.string()
		.required('Description is required'),
	image: Yup.mixed()
		.required("Image is required"),
	alt_title: Yup.string()
		.required("Image Alt is required"),
	meta_title: Yup.string()
		.required("Meta Title is required"),
	meta_keywords: Yup.string()
		.required("IMeta Keywords is required"),
	meta_description: Yup.string()
		.required("Meta Description is required"),
	structured_data: Yup.string()
		.required("Schema is required"),
};

const AddCategory = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			name: '',
			slug: '',
			description: '',
			file: '',
			alt_title: '',
			meta_title: '',
			meta_keywords: '',
			meta_description: '',
			structured_data: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			let formData = new FormData();
			formData.append("name", values.name);
			formData.append("slug", values.slug);
			formData.append("description", values.description);
			formData.append("image", values.image);
			formData.append("alt_title", values.alt_title);
			formData.append("meta_title", values.meta_title);
			formData.append("meta_keywords", values.meta_keywords);
			formData.append("meta_description", values.meta_description);
			formData.append("structured_data", values.structured_data);
			setLoaderCheck(true);
		
			axiosPrivate.post('categories/create', formData)
			.then((response) => {
				setLoaderCheck(false);
				setAlertMessage({ message: 'New category created successfully' });
				navigate('/admin/categories');
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					if (error.response.status === 422) {
						console.log('Error response:', error.response);
						for (let i = 0; i < error.response.data.errors.length; i++) {
							const { param, msg} = error.response.data.errors[i];
							setFieldError(param, msg);
							
		
							if (param === 'name' && msg.includes('Category name already in use')) {
								showErrorToast('Category name already in use');
							}
						}
					}
				} else {
					
					console.error('Unexpected error:', error);
				}
			});
		},
	});

	return (
		<div>
			<TopNavBar links={{ list: '/admin/categories', add: '/admin/categories/add' }} />
			<div className="section-body pt-1">
				<div className="container-fluid">
					<div className="tab-content pt-2">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="category-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Category</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="slug"
														name="slug"
														type="text"
														className="form-control"
														placeholder="Slug *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.slug}
													/>
													{formik.touched.slug && formik.errors.slug ? (
														<div className={classes.invalidDataError}>
															{formik.errors.slug}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<JoditEditor
														id="description"
														name="description"
														type="text"
														className="form-control"
														placeholder="Description *"
														onChange={(newContent) =>
															formik.setFieldValue(
															  `description`,
															  newContent
															)
														  }
														onBlur={formik.handleBlur}
														value={formik.values.description}
													/>
													{formik.touched.description && formik.errors.description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="imageCategory"
														name="image"
														type="file"
														accept='image/*'
														className="form-control"
														placeholder="Image *"
														onChange={(e) =>
															formik.setFieldValue('image', e.currentTarget.files[0])
														}
														onBlur={formik.handleBlur}
													/>
													{formik.touched.image && formik.errors.image ? (
														<div className={classes.invalidDataError}>
															{formik.errors.image}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="alt_title"
														name="alt_title"
														type="text"
														className="form-control"
														placeholder="Image Alt *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.alt_title}
													/>
													{formik.touched.alt_title && formik.errors.alt_title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.alt_title}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="meta_title"
														name="meta_title"
														type="text"
														className="form-control"
														placeholder="Meta Title *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_title}
													/>
													{formik.touched.meta_title && formik.errors.meta_title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_title}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="meta_keywords"
														name="meta_keywords"
														type="text"
														className="form-control"
														placeholder="Meta Keywords *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_keywords}
													/>
													{formik.touched.meta_keywords && formik.errors.meta_keywords ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_keywords}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<textarea
														id="meta_description"
														name="meta_description"
														type="text"
														className="form-control"
														placeholder="Meta Description *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_description}
													/>
													{formik.touched.meta_description && formik.errors.meta_description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<textarea
														id="structured_data"
														name="structured_data"
														type="text"
														className="form-control"
														placeholder="Schema *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.structured_data}
													/>
													{formik.touched.structured_data && formik.errors.structured_data ? (
														<div className={classes.invalidDataError}>
															{formik.errors.structured_data}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12  text-right">
												<hr className="mt-4" />
												<button
													type="button"
													id='button_1'
													className="btn btn-secondary mx-1"
													data-dismiss="modal"
													onClick={() => navigate('/admin/categories')}
												>
													CLOSE
												</button>
												{authPermissions.includes('Category-create') && (
													<button type="submit" id='button_2' className="btn btn-primary ">
														SUBMIT
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCategory;
