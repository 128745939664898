import { Formik, useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import axiosPrivate from "../../../hooks/axiosPrivate";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";
import TopNavBar from "../../backend/layouts/TopNavBar";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toster";

const Menus = () => {
  const navigate = useNavigate();
  const id = useParams();
  const { authPermissions } = useContext(AuthContext);
  const [pageName, setPagename] = useState('Add Menu');

	const [parentPageCheck, setParentPageCheck] = useState(false);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedPage, setSelectedPage] = useState(null);
	const { setLoaderCheck } = useContext(LoaderContext);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [city, setCity] = useState(null);
	const [pages, setPages] = useState([]);
	const [menus, setMenus] = useState([]);
	const [status, setStatus] = useState([]);
	const [submitButton, setSubmitButton] = useState("Submit");
	const [seqyenceNo, setSequenceNo] = useState(0);
	const [sequenceData, setSequenceData] = useState([]);

  const validationRules = {
    sequence: Yup.number().required("Sequence is required"),
	name: Yup.string().required("Name is Required"), 
	slug: Yup.string().required("Slug is Required"),
	is_active: Yup.string().required("Status is Required"),
  };

  const initialValues = {
    name: "",
    slug: "",
    parent_id: "",
    sequence: "",
    is_active: "",
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        title: values.name,
        slug: values.slug,
        sequence: values.sequence,
        is_active: values.is_active == "Active" ? 0 : 1,
        parent_id: values.parent_id ? values.parent_id : null,
      };

      if (!id.id) {
        setLoaderCheck(true);
        axiosPrivate
          .post("menus/create", formData)
          .then((res) => {
			showSuccessToast("Menu Created")
            setLoaderCheck(false);
            navigate("/admin/menus");
          })
          .catch((error) => {
            setLoaderCheck(false);
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .put(`menus/update/${id.id}`, formData)
          .then((res) => {
			showSuccessToast("Menu Updated")
            setLoaderCheck(false);
            navigate("/admin/menus");
          })
          .catch((error) => {
			showErrorToast(error?.response?.data?.message ||error?.response?.data?.errors[0]?.msg)
            setLoaderCheck(false);
          });
      }
    },
  });

  const onSelectedCity = (event) => {
    setFieldValue("slug", event);
    setSelectedCity(event);
  };
  const onSelectedStatus = (event) => {
    setFieldValue("is_active", event);
    setSelectedStatus(event);
  };

  const onSelectedPage = (event) => {
    setFieldValue("parent_id", event);
    setSelectedPage(event);
  };

  const onSetMenu = (event) => {
    // setFieldValue('parent_id', event);
    setCity(event);
    if (event === "no") {
      setParentPageCheck(true);
    } else {
      setParentPageCheck(false);
    }
  };

	useEffect(() => {
		const getAllPages = () => {
			setLoaderCheck(true);
			axiosPrivate
				.get("pages")
				.then((res) => {
					setLoaderCheck(false);
					let array = [
            {
							name: "Blogs",
							slug: "blog-listing",
						},
            {
							name: "Testimonial",
							slug: "testimonial",
						},
            {
							name: "Brochure",
							slug: "brochure",
						},
						{
							name: "Product Listing",
							slug: "product-list",
						},
						{
							name: "Product Detail",
							slug: "product-detail",
						},
						{
							name: "Contact",
							slug: "contact-us",
						},

            {
              name: "Careers",
              slug: "career-page",
            },
            {
              name: "Products",
              slug: "product-list",
            },
            {
              name: "Urban Gardens",
              slug: "urban-gardens",
            },

						{
							name: "Under Construction",
							slug: "under-construction",
						},
						{
							name: "Gallery",
							slug: "gallery",
						},
            {
              name: "Farmers Training",
              slug: "farmers-training"
            },
						{
							name: "Crops",
							slug: "crops",
						},
						{
							name: "products/crop-protection",
							slug: "products/crop-protection",
						},
						{
							name: "products/enhance-plant-growth",
							slug: "products/enhance-plant-growth",
						},
						{
							name: "products/soil-conditioner",
							slug: "products/soil-conditioner",
						}
					];
					let status = [{ name: "Active" }, { name: "Inactive" }];
					// let finalStatus=[...res?.data?.data,...status]
					setStatus(status);
					let finalPages = [...res?.data?.data, ...array];
					setPages(finalPages);
				})
				.catch((error) => {
					setLoaderCheck(false);
				});
		};

		const getAllMenus = () => {
			setLoaderCheck(true);
			axiosPrivate
				.get("menus")
				.then((res) => {
					setLoaderCheck(false);
					setMenus(res?.data?.data);
				})
				.catch((error) => {
					setLoaderCheck(false);
				});
		};

    // if(id.id){
    const getPageById = () => {
      setLoaderCheck(true);
      axiosPrivate
        .get("menus/" + id.id)
        .then((res) => {
          setLoaderCheck(false);
          setFieldValue("name", res?.data?.data.title);
          setFieldValue("slug", res?.data?.data.slug);
          setFieldValue(
            "is_active",
            res?.data?.data.is_active == 0 ? "Active" : "Inactive"
          );
          setFieldValue("parent_id", res?.data?.data.parent_id);
          setFieldValue("sequence", res?.data?.data.sequence);
          setSelectedPage(res?.data?.data.parent_id);
          setSelectedCity(res?.data?.data.slug);
          setSelectedStatus(res?.data?.data.status);
          setCity(res?.data?.data.parent_id == null ? "yes" : "no");
          setParentPageCheck(res?.data?.data.parent_id == null ? false : true);
          setSubmitButton("Update");
          setPagename('Edit Menu');
          setLoaderCheck(false);
        })
        .catch((error) => {
          setLoaderCheck(true);
        });
    };

    getAllPages();
    getAllMenus();
    if (id.id) {
      getPageById();
    }
  }, []);
  const addLink =
    authPermissions.includes("Menu-create") === true ? "/admin/menus/add" : "";

  const handleSequence = (e) => {
    axiosPrivate.get("menus/get").then((res) => {
      setSequenceData(res?.data?.data);
    });
    const filtered = sequenceData.filter(
      (data) => data.sequence == e.target.value
    );
    // if(!parentPageCheck){
    if (filtered.length == 0) {
      handleBlur(e);
    } else {
      showErrorToast("Enter Unique Sequence Number");
      setFieldValue("sequence", "");
    }
    // }
  };

  return (
    <>
      <TopNavBar links={{ list: "/admin/menus", add: addLink }} />
      <div className="section-body pt-2 website-template">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="home-template"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                     {pageName}
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row bordered-row">
                      {/*  Tax Name */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Menu Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Menu Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="invalidDataError">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <Dropdown
                            value={selectedCity}
                            name="slug"
                            onBlur={handleBlur}
                            onChange={(e) => onSelectedCity(e.value)}
                            options={pages}
                            optionLabel="name"
                            optionValue="slug"
                            placeholder="Select Page"
                            className="w-full md:w-14rem"
                          />

                          {touched.slug && errors.slug ? (
                            <div className="invalidDataError">
                              {errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-4 col-sm-12"
                        style={{ marginBottom: "15px" }}
                      >
                        <label>
                          Main Menu:<span className="required">*</span>
                        </label>
                        <div className="field-radiobutton d-flex align-items-center">
                          <RadioButton
                            inputId="main_yes"
                            name="menucheck"
                            value="yes"
                            onChange={(e) => onSetMenu(e.value)}
                            checked={city === "yes"}
                          />
                          <label htmlFor="main_yes" className="m-0 mx-2">
                            Yes
                          </label>
                        </div>
                        <div className="field-radiobutton d-flex align-items-center">
                          <RadioButton
                            inputId="main_no"
                            name="menucheck"
                            value="no"
                            onChange={(e) => onSetMenu(e.value)}
                            checked={city === "no"}
                          />
                          <label htmlFor="main_no" className="m-0 mx-2">
                            No
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Status:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <Dropdown
                            value={values.is_active}
                            name="is_active"
                            onBlur={handleBlur}
                            onChange={(e) => onSelectedStatus(e.value)}
                            options={status}
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Select Status"
                            className="w-full md:w-14rem"
                          />

                          {touched.is_active && errors.is_active ? (
                            <div className="invalidDataError">
                              {errors.is_active}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {parentPageCheck ? (
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <label>
                            Parent Page:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <Dropdown
                              value={selectedPage}
                              name="parent_id"
                              onBlur={handleBlur}
                              onChange={(e) => onSelectedPage(e.value)}
                              options={menus}
                              optionLabel="title"
                              optionValue="id"
                              placeholder="Select Page"
                              className="w-full md:w-14rem"
                              style={{ height: "35px" }}
                            />
                            {touched.slug && errors.slug ? (
                              <div className="invalidDataError">
                                {errors.slug}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Menu sequence */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Sequence No:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="sequence"
                            name="sequence"
                            type="number"
                            className="form-control"
                            placeholder="Sequence No"
                            onChange={handleChange}
                            onBlur={(e) => handleSequence(e)}
                            value={values.sequence}
                          />
                          {touched.sequence && errors.sequence ? (
                            <div className="invalidDataError">
                              {errors.sequence}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 submit-btn text-right">
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary mx-1"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/menus")}
                        >
                          Close
                        </button>
                        {authPermissions.includes("Home Template-create") && (
                          <button
                            type="submit"
                            id="button_2"
                            className="btn btn-primary"
                          >
                            {submitButton}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menus;
