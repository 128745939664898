import { useFormik } from "formik";
import React, { useContext } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Gallery.module.css";
import AuthContext from "../../../../context/Auth/AuthContext";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import TopNavBar from "../../layouts/TopNavBar";
import { showErrorToast } from "../../../../Toster";
import { useState } from "react";
import { useEffect } from "react";

const validationRules = {
  title: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Title is required"),
  alt: Yup.string().required("Alt is required"),
  //media_for: Yup.string().required("'Media For' is required"),
  //media: Yup.string().required('media is required'),
};

const AddGallery = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(`crop-category`)
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {
        showErrorToast(error.message);
      });
  }, []);
  // formik(add)
  const formik = useFormik({
    initialValues: {
      title: "",
      alt: "",
      media: "",
      media_for: "",
      media_type: "",
      crop_category:0,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var formData = new FormData();
      formData.append("title", values.title);
      formData.append("media_type", values.media_type);
      formData.append("alt", values.alt);
      formData.append("media", values.media);
      formData.append("media_for", values.media_for);
      formData.append("crop_category", values.crop_category);

      setLoaderCheck(true);
      setSubmitting(true)
      setLoaderCheck(true);
      
      axiosPrivate
        .post("product-gallery/create", formData)
        .then((response) => {
          setSubmitting(false); 
          setLoaderCheck(false);
          setAlertMessage({ message: "New Media Created Successfully" });
          navigate("/admin/gallery");
        })
        .catch((error) => {
          setLoaderCheck(false);
          // if (typeof error.response.data.errors == 'array') {
          // 	showErrorToast(error.response.data.errors[0].msg);
          // } else {
          // 	showErrorToast(error.response.data.errors.msg);
          // }
        });
    },
  });

  return (
    <>
      <TopNavBar
        links={{ list: "/admin/gallery", add: "/admin/gallery/add" }}
      />
      <div className="section-body pt-1 ">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="gallery-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* title */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Title "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* alt */}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Alt :<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="alt"
                            name="alt"
                            type="text"
                            className="form-control"
                            placeholder="Alt "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alt}
                          />
                          {formik.touched.alt && formik.errors.alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*medias*/}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Media:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="media"
                            name="media"
                            type="file"
                            className="form-control"
                            placeholder="media *"
                            onChange={(e) =>
                              formik.setFieldValue(
                                "media",
                                e.currentTarget.files[0]
                              )
                            }
                            onBlur={formik.handleBlur}
                            required
                          />
                          {formik.touched.media && formik.errors.media ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.media}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Media Type<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="media_type"
                            name="media_type"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.media_type}
                          >
                            <option>Select Media</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                          </select>
                          {formik.touched.media_type &&
                          formik.errors.media_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.media_type}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Media For<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            id="media_for"
                            name="media_for"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.media_for}
                          >
                            <option value="">Select Media</option>
                            <option value="Gallery">Gallery</option>
                            <option value="Crop">Crop</option>
                            <option value="Farmers Training">Farmers Training</option>
                          </select>
                         
                        </div>
                      </div>
                      {formik.values.media_for === "Crop" && (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Category:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <select
                              id="crop_category"
                              name="crop_category"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.crop_category}
                            >
                              <option value="">Select Category</option>
                              {category.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {/* button */}
                      <div className="col-12 submit-btn text-right">
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary mx-1"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/gallery")}
                        >
                          Close
                        </button>
                        {authPermissions.includes("Gallery-create") && (
                          <button
                            type="submit"
                            id="button_2"
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGallery;
