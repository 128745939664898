import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import slider1 from '../../assets/images/slider1.svg';
import slider2 from '../../assets/images/slider2.svg';
import slider3 from '../../assets/images/slider3.svg';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/Auth/AuthContext';
import axiosPrivate from '../../hooks/axiosPrivate';
import AlertContext from '../../context/Alert/AlertContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Auth.css';
import { showErrorToast, showSuccessToast } from '../../Toster';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [otpRecieved, setOtpRecieved] = useState(false);
  const [email, setEmail] = useState('');

  const loginSchma = Yup.object({
    otp: Yup.string().required('OTP is Required'),
    password: Yup.string()
      .max(20, 'Password must not exceed 20 characters')
      .min(8, 'Password must exceed 8 characters')
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const loginForm = {
    password: '',
    confirm_password: '',
    otp: '',
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: loginForm,
      validationSchema: loginSchma,
      onSubmit: (values) => {
        const data = {
          email,
          ...values,
        };
        axiosPrivate
          .post('change-password', data)
          .then((res) => {
            showSuccessToast('Password Changed Successfully');
            navigate('/auth/signin');
          })

          .catch((err) => {
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          });
      },
    });

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    axiosPrivate
      .post('forgot-password', data)
      .then((res) => {
        showSuccessToast(
          'OTP has been sent successfully | Check your entered Email'
        );
        setOtpRecieved(true);
      })
      .catch((err) => {
        showErrorToast(
          err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
        );
      });
  };

  return (
    <Fragment>
      <div className='auth_left'>
        <div className='card'>
          <div className='text-center mb-2'>
            <Link className='header-brand' to='/'>
              {/* <i className="fe fe-command brand-logo" /> */}
              <img
                src='./../assets/images/home/logo.webp'
                alt='forgot-password'
                loading='lazy'
              />
            </Link>
          </div>
          <div className='card-body'>
            {!otpRecieved && (
              <form>
                <div className='card-title ' style={{ fontWeight: 'bold' }}>
                  Enter Details
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    name='email'
                    id='id'
                    className='form-control'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => submitHandler(e)}
                    type='submit'
                    className='btn  btn-block submit-button'
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            )}
            {otpRecieved && (
              <form onSubmit={handleSubmit}>
                <div className='card-title ' style={{ fontWeight: 'bold' }}>
                  Enter Details
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    className='form-control'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Password'
                  />
                  {touched.password && errors.password ? (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.password}
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    name='confirm_password'
                    id='confirm_password'
                    className='form-control'
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Confirm Password'
                  />
                  {touched.confirm_password && errors.confirm_password ? (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.confirm_password}
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <input
                    type='number'
                    name='otp'
                    id='otp'
                    className='form-control'
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Enter OTP'
                  />
                  {touched.otp && errors.otp ? (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.otp}
                    </div>
                  ) : null}
                </div>

                <div className='form-footer'>
                  <input
                    type='submit'
                    className='btn  btn-block submit-button'
                    value='Submit'
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
