import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Blog.module.css";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import AuthContext from "../../../../context/Auth/AuthContext";
import TopNavBar from "../../layouts/TopNavBar";
import JoditEditor from "jodit-react";
import { WithContext as ReactTags } from "react-tag-input";

const validationRules = {
  title: Yup.string().required("Title is required"),
  slug: Yup.string().required("Slug is required"),
  description: Yup.string().required("Description is required"),
  short_description: Yup.string().required("Short Description is required"),
  featured_image: Yup.string().required("Featured Image is required"),
  alt: Yup.string().required("Alt is required"),
  blog_type: Yup.string().required("Blog Type is required"),
  publish_date: Yup.string().required("Publish Date is required"),
  status: Yup.string().required("Status is required"),
};

const AddBlog = () => {
  const { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [blogTypeList, setBlogTypeList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [ogImage, seOgImage] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);

  //get blog by id

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`blogs/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        if (response?.data?.code === 200) {
          formik.setValues({ ...response?.data?.data });
          setKeywords(JSON.parse(response.data.data?.keywords));
          setTags(JSON.parse(response.data.data?.tags));
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, []);

  const config = {
    controls: {
      font: {
        list: {
          "var(--alice)": "Alice",
        },
      },
    },
  };

  // get all  Blog type
  useEffect(() => {
    axiosPrivate
      .get("blog-type")
      .then((blogtype) => {
        setBlogTypeList(blogtype?.data?.data);
        // showSuccessToast("Blog Category Fetched");
      })

      .catch((error) => {
        showErrorToast("Internal server Error");
      });
  }, []);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const handleDeleteKeywords = (i) => {
    setKeywords(keywords.filter((tag, index) => index !== i));
  };

  const handleAdditionKeywords = (keyword) => {
    setKeywords([...keywords, keyword]);
  };

  const handleDragKeywords = (keyword, currPos, newPos) => {
    const newkeywords = keywords.slice();

    newkeywords.splice(currPos, 1);
    newkeywords.splice(newPos, 0, keyword);

    setKeywords(newkeywords);
  };

  const handleDeleteTags = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAdditionTags = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDragTags = (tags, currPos, newPos) => {
    const newtags = tags.slice();

    newtags.splice(currPos, 1);
    newtags.splice(newPos, 0, tags);

    setTags(newtags);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleOgImageChange = (e) => {
    // When a new file is selected, set the imagePreview state and update Formik field value
    const file = e.currentTarget.files[0];

    seOgImage(URL.createObjectURL(file));

    formik.setFieldValue("og_image", file);
  };

  const handleImageChange = (e) => {
    // When a new file is selected, set the imagePreview state and update Formik field value
    const file = e.currentTarget.files[0];
    setFeaturedImage(URL.createObjectURL(file));

    formik.setFieldValue("featured_image", file);
  };
  // formik(add)
  const formik = useFormik({
    initialValues: {
      title: "",
      slug: "",
      description: "",
      short_description: "",
      featured_image: [],
      alt: "",

      meta_title: "",
      meta_description: "",
      keywords: [],
      tags: [],
      og_title: "",
      og_description: "",
      og_image: "",
      og_alt: "",
      canonical_tags: '',

      blog_type: "",
      publish_date: "",
      status: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var formData = new FormData();

      if (values.status === "Draft") {
        var status = 0;
      } else if (values.status === "Pending") {
        var status = 1;
      } else if (values.status === "Published") {
        var status = 2;
      } else {
        var status = 0;
      }

      formData.append("title", values.title);
      formData.append("slug", values.slug);
      formData.append("description", values.description);
      formData.append("short_description", values.short_description);
      formData.append("featured_image", values.featured_image);
      formData.append("alt", values.alt);
      formData.append("meta_title", values.meta_title);
      formData.append("keywords", JSON.stringify(keywords));
      formData.append("tags", JSON.stringify(tags));
      formData.append("meta_description", values.meta_description);
      formData.append("og_title", values.og_title);
      formData.append("og_image", values.og_image);
      formData.append("og_alt", values.og_alt);
      formData.append("og_description", values.og_description);
      formData.append("canonical_tags", values.canonical_tags);
      formData.append("blog_type", values.blog_type);
      formData.append("publish_date", values.publish_date);
      formData.append("status", status);

      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put(`blogs/update/${id}`, formData)
          .then((response) => {
            setLoaderCheck(false);
            setAlertMessage({ message: "Blog  Update Successfully" });
            navigate("/admin/blog");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error?.response?.data?.errors === "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post("blogs/create", formData)
          .then((response) => {
            setLoaderCheck(false);
            setAlertMessage({ message: "New  Blog Successfully" });
            navigate("/admin/blog");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error?.response?.data?.errors === "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      }
    },
  });

  return (
    <div>
      <TopNavBar links={{ list: "/admin/blog", add: "/admin/blog/add" }} />
      <div className="section-body pt-1 ">
        <div className="container-fluid">
          <div className="tab-content mt-3 ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/*   blog   Title */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*   blog Slug */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="slug"
                            name="slug"
                            type="text"
                            className="form-control"
                            placeholder="Slug Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  short Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Short Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <JoditEditor
                            config={config}
                            id="short_description"
                            name="short_description"
                            type="text"
                            className="form-control"
                            placeholder=" Short Description "
                            onChange={(newContent) =>
                              formik.setFieldValue(
                                `short_description`,
                                newContent
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.short_description}
                          />
                          {formik.touched.short_description &&
                          formik.errors.short_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.short_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Detail  Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Detail Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <JoditEditor
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Description "
                            onChange={(newContent) =>
                              formik.setFieldValue(`description`, newContent)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured Image*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="featured_image"
                            name="featured_image"
                            type="file"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={handleImageChange}
                            onBlur={formik.handleBlur}
                          />
                          {featuredImage && (
                            <img
                              src={featuredImage}
                              alt="Preview"
                              style={{ maxWidth: "150px" }}
                              loading="lazy"
                            />
                          )}
                          {formik.values.featured_image && (
                            <img
                              src={`${process.env.REACT_APP_COMMON_FILE_URL}blogs/${formik.values.featured_image}`}
                              alt={formik.values.alt}
                              style={{ maxWidth: "150px" }}
                              loading="lazy"
                            />
                          )}
                          {formik.touched.featured_image &&
                          formik.errors.featured_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.featured_image}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured   images alt*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image Alt:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="alt"
                            name="alt"
                            type="text"
                            className="form-control"
                            placeholder="Featured Image Alt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alt}
                          />
                          {formik.touched.alt && formik.errors.alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Meta Title heading*/}
                      <div className='col-lg-12 col-md-12 col-sm-12 '>
                        <p
                          className='my-3'
                          style={{ fontWeight: '700', fontSize: '20px' }}
                        >
                          Meta Fields:
                        </p>
                      </div>

                      {/*  meta Description */}
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <label>
                          Meta Description:
                          <span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <textarea
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='Meta Description '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_description}
                          />
                          {formik.touched.meta_description &&
                          formik.errors.meta_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Meta Title*/}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Meta Title:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='Meta Title'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_title}
                          />
                          {formik.touched.meta_title &&
                          formik.errors.meta_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      
                      {/* Og Title*/}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Og Title:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='og_title'
                            name='og_title'
                            type='text'
                            className='form-control'
                            placeholder='Og Title'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_title}
                          />
                          {formik.touched.og_title && formik.errors.og_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Og  Image*/}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Og Image:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='og_image'
                            name='og_image'
                            type='file'
                            className='form-control'
                            placeholder='Og  Image'
                            onBlur={formik.handleBlur}
                            onChange={handleOgImageChange}
                          />
                          {ogImage && (
                            <img
                              src={ogImage}
                              alt='Preview'
                              style={{ maxWidth: '150px' }}
                              loading='lazy'
                            />
                          )}
                          {formik.values.og_image && (
                            <img
                              src={`${process.env.REACT_APP_COMMON_FILE_URL}blogs/${formik.values.og_image}`}
                              alt={formik.values.og_alt}
                              style={{ maxWidth: '150px' }}
                              loading='lazy'
                            />
                          )}
                          {formik.touched.og_image && formik.errors.og_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_image}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Og  Alt*/}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Og Alt:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='og_alt'
                            name='og_alt'
                            type='text'
                            className='form-control'
                            placeholder='Og   Alt'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_alt}
                          />
                          {formik.touched.og_alt && formik.errors.og_alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  Og Description */}
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <label>
                          Og Description:
                          <span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <textarea
                            id='og_description'
                            name='og_description'
                            type='text'
                            className='form-control'
                            placeholder='Og Description '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_description}
                          />
                          {formik.touched.og_description &&
                          formik.errors.og_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Canonical Tags:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='canonical_tags'
                            name='canonical_tags'
                            type='text'
                            className='form-control'
                            placeholder='Canonical'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.canonical_tags}
                          />
                          {formik.touched.canonical_tags && formik.errors.canonical_tags ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.canonical_tags}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Keywords*/}
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <label>
                          Keywords:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          {/* <input
                            id='keywords'
                            name='keywords'
                            type='text'
                            className='form-control'
                            placeholder='Keywords'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.keywords}
                          /> */}
                          <ReactTags
                          tags={keywords}
                          id='keywords'
                          name='keywords'
                          delimiters={delimiters}
                          handleDelete={handleDeleteKeywords}
                          handleAddition={handleAdditionKeywords}
                          handleDrag={handleDragKeywords}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={formik?.handleChange}
                          handleInputBlur={formik?.handleBlur}
                          value={formik?.values?.keywords}
                          placeholder='Enter keywords'
                        />
                          {formik.touched.keywords && formik.errors.keywords ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.keywords}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Tagss*/}
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <label>
                          Tags:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                        <ReactTags
                          tags={tags}
                          id='tags'
                          name='tags'
                          delimiters={delimiters}
                          handleDelete={handleDeleteTags}
                          handleAddition={handleAdditionTags}
                          handleDrag={handleDragTags}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={formik?.handleChange}
                          handleInputBlur={formik?.handleBlur}
                          value={formik?.values?.tags}
                          placeholder='Enter tags'
                        />
                          {formik?.touched?.tags && formik?.errors?.tags ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.tags}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <h5>Meta Fields:</h5>
          <div className={styles.mainFormContainer} style={{paddingRight:'3%'}}>
            <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='meta title *'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='meta description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div
                        className='col-md-12 col-sm-12'
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Keywords:</h5>
                        <ReactTags
                          tags={keywords}
                          id='keywords'
                          name='keywords'
                          delimiters={delimiters}
                          handleDelete={handleDeleteKeywords}
                          handleAddition={handleAdditionKeywords}
                          handleDrag={handleDragKeywords}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.keywords}
                          placeholder='Enter keywords'
                        />
                      </div>
                      <div
                        className='col-md-12 col-sm-12 '
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Tags:</h5>
                        <ReactTags
                          tags={tags}
                          id='tags'
                          name='tags'
                          delimiters={delimiters}
                          handleDelete={handleDeleteTags}
                          handleAddition={handleAdditionTags}
                          handleDrag={handleDragTags}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.tags}
                          placeholder='Enter tags'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_title'
                            name='og_title'
                            type='text'
                            className='form-control'
                            placeholder='Og title'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_description'
                            name='og_description'
                            type='text'
                            className='form-control'
                            placeholder='Og description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_image'
                            name='og_image'
                            type='file'
                            className='form-control'
                            placeholder='Og Image'
                            onChange={onFileUpload}
                            accept='image/*'
                            onBlur={handleBlur}
                            style={{ margin: '2%' }}
                          />
                          {typeof values?.og_image === 'string' && values?.og_image !== '' ? (
                            <img
                            className={styles.mainImage}
                            src={values?.og_image}
                            alt="Og Image Alt"
                            loading='lazy'
                          />
                          ) : (
                            ''
                          )}
                          
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_alt'
                            name='og_alt'
                            type='text'
                            className='form-control'
                            placeholder='Og alt'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.og_alt}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

            
           
          </div> */}

                      {/*  Blog  Category */}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Category:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="role"
                            name="blog_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.blog_type}
                          >
                            <option>Select Blog Type</option>
                            {blogTypeList.map((role, key) => (
                              <option key={key + role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.blog_type &&
                          formik.errors.blog_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.blog_type}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  publish date*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Publish Date:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="publish_date"
                            name="publish_date"
                            type="date"
                            className="form-control"
                            placeholder="Publish Data"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.publish_date}
                            min={new Date().toISOString().split("T")[0]}
                          />
                          {formik.touched.publish_date &&
                          formik.errors.publish_date ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.publish_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Tags*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Tags:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="tags"
                            name="tags"
                            type="text"
                            className="form-control"
                            placeholder="Tags"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tags}
                          />
                          {formik.touched.tags && formik.errors.tags ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.tags}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*   status */}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Status:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option>Select Status</option>
                            <option>Draft</option>
                            <option>Pending</option>
                            <option>Published</option>
                          </select>
                        </div>
                      </div>

                      {/* button close and submitF */}
                      <div className="col-12 submit-btn  text-right">
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/blog")}
                        >
                          Close
                        </button>
                        {authPermissions.includes("Blog-create") && (
                          <button
                            type="submit"
                            id="button-2"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
