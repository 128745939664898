import React, { useEffect, useState, useContext } from 'react';
import styles from './PersonalDetails.module.css';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast, showSuccessToast } from '../../../../Toster';
import AlertContext from '../../../../context/Alert/AlertContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import Changepassword from '../../../backend/masters/userProfile/Changepassword';



const user = JSON.parse(localStorage.getItem('userData'));

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid phone number', function(value) {
    // Check if the mobile number does not start from 0 to 5
    if (!value) return true; // If no value, let Yup.string().required handle it
    return !/^[0-5]/.test(value);
  }).required("Phone number is required"),
  email: Yup.string()
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid Email!"
    )
    .required("Email is required"),
  city: Yup.string().required("Village/City is required"),
  pincode: Yup.string().required("Pincode is required"),
  state: Yup.string().required("State is required"),
  address: Yup.string().required("Address is required"),
});


const initialValues = {
  name: '',
  email: '',
  mobile: '',
  pincode: '',
  city: '',
  state: '',
  landmark: '',
  address: '',
  role_id: user?.role_id
};

function PersonalDetails() {
  
  const { userId } = user;
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const getUserPersonalDetails = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`users/get`)
      .then((response) => {
        setLoaderCheck(false);
        if (response?.data?.code === 200) {
          // setValues(response.data.data);
          const data = response?.data?.data;

          setFieldValue('pincode', data?.address[0]?.pincode);
          setFieldValue('city', data?.address[0]?.city);
          setFieldValue('state', data?.address[0]?.state);
          // setFieldValue('landmark', data?.address[0]?.landmark);
          setFieldValue('name', data.email === null ? data.address[0]?.name : data.name);
          setFieldValue('email', data.email === null ? data.address[0]?.email : data.email);
          setFieldValue('mobile', data.mobile);
          setFieldValue('address', data?.address[0]?.address);
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (typeof error?.response?.data?.errors == 'array') {
          showErrorToast(error?.response?.data?.errors?.msg);
        } else {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };
  useEffect(() => {
    getUserPersonalDetails();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('mobile', values.mobile);
      formData.append('pincode', values.pincode);
      formData.append('city', values.city);
      formData.append('state', values.state);
      // formData.append('landmark', values.landmark);
      formData.append('address', values.address);
      formData.append('role_id', values.role_id);
      setLoaderCheck(true);
      axiosPrivate
        .put(`users/${userId}`, formData)
        .then((response) => {
          setLoaderCheck(false);
         showSuccessToast(response?.data?.message);
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            showErrorToast('Internal server error');
          }
        });
    },
  });


  const OpenpasswordModel = () => {
    setIsPasswordChange(true);
  };

  const closeModelPassword = () => {
    setIsPasswordChange(false);
  };


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <>
      <div className={styles.userProfileContainer}>
        <form action='' onSubmit={handleSubmit}>
          <div className={styles.form}>
            <div className={styles.inputField}>
              <label htmlFor='name'>Your Name</label>
              <input
                type='text'
                name='name'
                id='name'
                placeholder='Your name'
                onChange={(event) => {
                  nameFilter(event);
                }}
                onBlur={handleBlur}
                value={values.name}
              />
              {touched.name && errors.name ? (
              <div style={{ color: "red" }}>{errors.name}</div>
            ) : null}
            </div>
            <div className={styles.inputField}>
              <label htmlFor='email'>Email Address</label>
              <input
                type='email'
                name='email'
                id='email'
                placeholder='Your email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email ? (
              <div style={{ color: "red" }}>{errors.email}</div>
            ) : null}
            </div>

            <div className={styles.inputField}>
              <label htmlFor='number'>Phone Number</label>
              <input
                type='text'
                name='mobile'
                id='mobile'
                placeholder='Phone number'
                onChange={(event) => {
                  numberFilter(event);
                }}
                onBlur={handleBlur}
                value={values.mobile}
                maxLength={10}
              />
              {touched.mobile && errors.mobile ? (
              <div style={{ color: "red" }}>{errors.mobile}</div>
            ) : null}
            </div>
            <div className={styles.inputField}>
              <label htmlFor='address'>Address</label>
              <input
                type='text'
                name='address'
                id='address'
                placeholder='Enter Address'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
              />
              {touched.address && errors.address ? (
              <div style={{ color: "red" }}>{errors.address}</div>
            ) : null}
            </div>
            <div className={styles.inputField}>
              <label htmlFor='pincode'>Pincode</label>
              <input
                type='text'
                name='pincode'
                id='pincode'
                placeholder='Enter Pincode'
                onChange={(event) => {
                  numberFilter(event);
                }}
                onBlur={handleBlur}
                value={values.pincode}
                maxLength={6}
              />
              {touched.pincode && errors.pincode ? (
              <div style={{ color: "red" }}>{errors.pincode}</div>
            ) : null}
            </div>

            <div className={styles.inputField}>
              <label htmlFor='city'>City</label>
              <input
                type='test'
                name='city'
                id='city'
                placeholder='Enter your City'
                onChange={(event) => {
                  nameFilter(event);
                }}
                onBlur={handleBlur}
                value={values.city}
              />
              {touched.city && errors.city ? (
              <div style={{ color: "red" }}>{errors.city}</div>
            ) : null}
            </div>
            <div className={styles.inputField}>
              <label htmlFor='state'>State</label>
              <input
                type='text'
                name='state'
                id='state'
                placeholder='Enter your State'
                onChange={(event) => {
                  nameFilter(event);
                }}
                onBlur={handleBlur}
                value={values.state}
              />
              {touched.state && errors.state ? (
              <div style={{ color: "red" }}>{errors.state}</div>
            ) : null}
            </div>
            
          </div>
          {/* <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='form-group'>
              <a
                href='# '
                onClick={() => OpenpasswordModel()}
                style={{
                  fontSize: '15px',
                  textDecoration: 'underline',
                }}
              >
                Change Password
              </a>
            </div>
          </div>
          <div className='card-body' style={{ padding: '0 20px 20px' }}>
            {isPasswordChange ? (
              <Changepassword changePasswordClose={closeModelPassword} />
            ) : (
              ''
            )}
          </div> */}

          <div className={styles.updateContainer}>
            
            <button className={styles.update}>Update</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default React.memo(PersonalDetails);
