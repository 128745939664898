import React, { useState, useEffect } from 'react';
import styles from './ProductListing.module.css';
import { Link, useParams } from 'react-router-dom';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from 'react';
import LoaderContext from '../../../context/Loader/LoaderContext';
import amazonImg from '../../../assets/images/amazon_new.webp';
import flipkartImg from '../../../assets/images/flipkart.png';
import amazonMobileImg from '../../../assets/images/AmazonMobilePNG.png';
import flipkartMobileImg from '../../../assets/images/flipkartMobile.png';

function ProductDinamicPage({ initialValues }) {
  const { setLoaderCheck } = useContext(LoaderContext);

  function productslug(slug) {
    return slug
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  return (
    <div>
      <div>
        <div className='text-center'>
          <h2 className={styles.secondHeading}>
            {initialValues?.categorySection[0]?.heading}
          </h2>
        </div>
        <div className={styles.imgDiv}>
          <img className={styles.categoryImg} src={initialValues?.mainImage} />

          <div className={styles.categoryContent}>
            <div dangerouslySetInnerHTML={{ __html: initialValues?.categorySection[0]?.content }}/>

            
            
          </div>
        </div>
      </div>
      <div className={styles.productListContainer}>
        {initialValues?.ProductSection.length > 0 ? (
          <>
            {initialValues?.ProductSection.map((product, index) => (
              <>
                <div className={styles.productList}>
                  <div className={styles.productImg}>
                    <Link to={`/product-detail/` + productslug(product?.slug)}>
                      <img
                        src={product?.productImage}
                        alt={product.name}
                        loading='lazy'
                      />
                    </Link>
                  </div>
                  <div className={`${styles.productDetail} m-4`}>
                    <Link to={`/product-detail/` + productslug(product.slug)}>
                      <h1 className={styles.productName}>{product.name}</h1>
                    </Link>
                    <Link to={`/${product.category_slug}`}>
                      <h4 style={{ fontSize: '1.1rem' }}>
                        {product?.category_name}
                      </h4>
                    </Link>
                    <p>
                        {product.forms}
                        </p>
                        <div
                          style={{ marginTop: '20px' , fontSize: '14px'}}
                          dangerouslySetInnerHTML={{
                            __html: product?.short_description,
                          }}
                        />
                    <div className={`${styles.desktopLinks}`}>
                      <div className={`${styles.desktopBtn}`}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <button
                            className='btn btn-primary'
                            style={{ background: '#7fb401' }}
                            //onClick={() => addToCart(product, "buynow")}
                          >
                            Shop Now
                          </button>
                        </Link>
                        <a href={product?.product_pdf}>
                          <button
                            className='btn btn-primary ml-2'
                            style={{ background: '#7fb401' }}
                          >
                            Know More
                          </button>
                        </a>
                      </div>
                      <div className={`${styles.desktopBtn}`}>
                        {product?.amazon_link !== null &&
                          product?.amazon_link !== '' &&
                          product?.amazon_link !== 'null' && (
                            <Link
                              className={`${styles.desktopLogo}`}
                              to={product?.amazon_link}
                            >
                              <img
                                className={`${styles.desktopLogoImage1}`}
                                src={amazonImg}
                                alt='amazon logo'
                              />
                            </Link>
                          )}

                        {product?.flipkart_link !== null &&
                          product?.flipkart_link !== '' &&
                          product?.flipkart_link !== 'null' && (
                            <Link
                              to={product?.flipkart_link}
                              className={`${styles.desktopLogo}`}
                            >
                              <img
                                className={`${styles.desktopLogoImage2}`}
                                src={flipkartImg}
                                alt='flipkart logo'
                                srcset=''
                              />
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.mobileBtn}>
                    <Link to={`/product-detail/` + productslug(product.slug)}>
                      <button
                        className='btn btn-primary ml-2'
                        style={{ background: '#7fb401 shopNowBtn' }}
                      >
                        Shop Now
                      </button>
                    </Link>
                    <a href={product?.product_pdf} target='_blank'>
                      <button
                        className='btn btn-primary ml-2'
                        style={{ background: '#7fb401 knowMoreBtn' }}
                      >
                        Know More
                      </button>
                    </a>
                    {/* <div className={styles.productPriceMobile}>
                          <BsCurrencyRupee />
                          <p>{product.price}</p>
                        </div> */}
                    {product?.amazon_link !== null &&
                      product?.amazon_link !== '' &&
                      product?.amazon_link !== 'null' && (
                        <Link
                          to={product?.amazon_link}
                          className={`${styles.desktopLogo}`}
                        >
                          <img
                            className={`${styles.desktopLogoImage1}`}
                            src={amazonMobileImg}
                            alt='amazonMobileImg'
                            srcset=''
                          />
                        </Link>
                      )}
                    {product?.flipkart_link !== null &&
                      product?.flipkart_link !== '' &&
                      product?.flipkart_link !== 'null' && (
                        <Link
                          to={product?.flipkart_link}
                          className={`${styles.desktopLogo}`}
                        >
                          <img
                            className={`${styles.desktopLogoImage1}`}
                            src={amazonMobileImg}
                            alt='amazonMobileImg'
                            srcset=''
                          />
                        </Link>
                      )}
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          'NO Product found'
        )}
      </div>
    </div>
  );
}

export default React.memo(ProductDinamicPage); // Memoize the ProductDinamicPage;
