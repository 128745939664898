import React, { useState, useEffect } from 'react';
import styles from './Products.module.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { Carousel } from 'primereact/carousel';
import { ProductService } from './ProductServices';
import { Link } from 'react-router-dom';
import axiosPrivate from '../../../hooks/axiosPrivate';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { useContext } from 'react';
import MinfertLogo from '../../../assets/images/logo.webp';

export default function Products() {
  const [products, setProducts] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  // const responsiveOptions = [
  //   {
  //     breakpoint: "1199px",
  //     numVisible: 2,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: "991px",
  //     numVisible: 2,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: "767px",
  //     numVisible: 1,
  //     numScroll: 1,
  //   },
  // ];

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  const colors = ['#99D052', '#ED3237', '#F9C500'];

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('products')
      .then((products) => {
        setLoaderCheck(false);

        var products_list = [];
        let count = 0;
        count = count == 3 ? 0 : count;
        products.data?.data?.forEach((element) => {
          count = count + 1;
          let object = {
            id: element.id,
            brand: 'Minfert',
            name: element?.name,
            types: element?.types, // Access "Type" directly from the API
            forms: element?.forms,
            name: element?.name,
            description: element?.short_description,
            slug: element?.slug,
            variant: element?.product_variants[0]?.variant,
            image: element?.product_images[0]?.image,
            link: 'product-detail/' + element.slug,
            background: colors[count % colors.length],
          };
          products_list.push(object);
        });

        setProducts(products_list);
      })
      .catch((err) => {
        setLoaderCheck(false);
        console.log(err);
      });
  }, []);

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (product) => {
    return (
      <Link to={product.link}>
        <div
          className={styles.productContainer}
          style={{ backgroundColor: product.background }}
        >
          <div className={styles.logoDiv}>
            {/* <img src={MinfertLogo} alt="Minfert Logo" className={styles.logoImg} style={{  backgroundColor: 'white', */}
            {/* width: '356px',  */}
            {/* marginTop: '9px',  */}
            {/* }} */}
          </div>
          <div className={styles.content}>
            <div className={styles.imgContainer}>
              <img
                src={product.image}
                alt={product.name}
                className={styles.productImg}
                loading='lazy'
              />
            </div>
          </div>
          <div
            className={styles.productDetails}
            style={{ width: '100%', lineHeight: '34px' }}
          >
            <div className={styles.productName}>
              <h6 style={{ fontSize: '1.3rem' }}>{product.name}</h6>
            </div>
            <div className={styles.productDescription}>
              {/* <div className={styles.descriptionLine}>
                <span>Types:</span> {product.types}
              </div>
              <div className={styles.descriptionLine}>
                <span>About Product:</span> {product.forms}
              </div>
              <div className={styles.descriptionLine}>
                <span>Packs:</span> {product.variant}
              </div> */}
               <p>
                        {product.forms}
                        </p>
                        <div
                          style={{ marginTop: '20px' , fontSize: '14px'}}
                          dangerouslySetInnerHTML={{
                            __html: product?.short_description,
                          }}
                        />
            </div>
            <div className={styles.productLink}>
              {/* <Link style={{color:"white"}} to={product.link}>Shop Now</Link> */}
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={`${styles.container} custom-carousel`}>
      <div className={styles.headingContainer}>
        <h2 className={styles.mainHeading}>
          Our Products{' '}
          <img
            src='./assets/images/home/leaf-1-1 1.webp'
            alt='Leaf'
            loading='lazy'
            className={styles.leaf}
          />
        </h2>
        <p className={styles.mainContent}>
          Natural Products To Enhance Plant Growth.
        </p>
        <Link to='/products/crop-protection' className={styles.viewProducts}>
          View all
        </Link>
      </div>
      <div className={`${styles.card} ${styles.customCarousel}`}>
        <Carousel
          value={products}
          numVisible={3}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
          autoplayInterval={3000}
          circular // Enable circular wrapping
          autoplay // Enable autoplay
        />
      </div>
    </div>
  );
}
