import React from 'react';
// import mainImg from "../../../assets/images/Coming soon.svg"
import mainImg from '../../../assets/images/Banner image.png';
import Navbar from './Navbar';
import Topbar from './Topbar';
import Logo from './Logo';

const CommigSoon = () => {
  return (
    <>
      <Topbar />
      <Logo />
      <Navbar />
      <div>
        <img src={mainImg} alt='comming soon' loading='lazy' />
      </div>
    </>
  );
};

export default React.memo(CommigSoon); // Memoize the CommigSoon;
