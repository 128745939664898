import React from 'react';
import BlogPost from '../home/BlogPost';

function Blog() {
  const blogValues = {
    mainHeading: 'Blog Post',
    currentPage: 'home',
    mainContent:
      'Delve into the realm of organic farming . Learn about sustainable practices, healthier harvests, and the eco-friendly advantages that define this agricultural revolution',
    viewMoreBlogBtn: 'View More Blogs',
    posts: [
      {
        heading: 'Lorem Ipsum is simply dummy text',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        readMore: 'Read more',
        image: './assets/images/home/Rectangle 29.webp',
        writerName: 'Writer name',
        writerImage: './assets/images/home/Ellipse 28.webp',
      },

      {
        heading: 'Lorem Ipsum is simply dummy text',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        readMore: 'Read more',
        image: './assets/images/home/Rectangle 33.webp',
        writerName: 'Writer name',
        writerImage: './assets/images/home/Ellipse 28.webp',
      },

      {
        heading: 'Lorem Ipsum is simply dummy text',
        content:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        readMore: 'Read more',
        image: './assets/images/home/Rectangle 34.webp',
        writerName: 'Writer name',
        writerImage: './assets/images/home/Ellipse 28.webp',
      },
    ],
  };

  return (
    <>
      <BlogPost initialValues={blogValues} />
    </>
  );
}
export default React.memo(Blog); // Memoize the Blog;
