import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import DataTable2 from "../../../DataTable2";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import TopNavBar from "../../layouts/TopNavBar";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import AuthContext from '../../../../context/Auth/AuthContext';

const Tax = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [taxState, setTaxState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);
	const { authPermissions } = useContext(AuthContext);
	// header
	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{
			name: "Name",
			field: "name",
			sortable: true,
			classKey: "",
		},
		{ name: "TAX AMOUNT", field: "tax_amount", sortable: true, classKey: "" },

		{ name: "ACTION", field: "action", classKey: "" },
	];

	// search item
	const searchItems = ["name"];

	//  tax list
	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate.get("tax", options)
			.then((tax) => {
				setLoaderCheck(false);
				const taxData = tax.data.data.map((value, index) => {
					let buttons = [];
					if (authPermissions.includes('Tax-update'))
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/tax/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: "green" }} size={18} />
							</Link>
						);
					if (authPermissions.includes('Tax-delete'))
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => taxDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: "red" }} size={17} />
							</button>
						);
						value["sr-no"]=index+1
					value["action"] = buttons.length > 0 ? buttons : "-";
					return value;
				})

				if (authPermissions.includes('Tax-read')) {
					setTaxState(taxData);
				} else {
					setTaxState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				console.log(error);
			})
	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function

	const taxDeleteHandler = async (id) => {
		setLoaderCheck(true);
		axiosPrivate
			.delete(`tax/${id}`)
			.then(async (response) => {
				setLoaderCheck(false);
				if (response.data.code == 200) {
					setTaxState([]);
					setAlertMessage({ message: "tax Deleted Successfully!" });
					await refreshTable();
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (typeof error.response.data.errors == "array") {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};
	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { "content-type": "application/json" },
			};
			setLoaderCheck(true);
			axiosPrivate.get("tax", options)
				.then((tax) => {
					setLoaderCheck(false);
					const taxData = tax.data.data.map((value, key) => {
						let buttons = [];
						if (authPermissions.includes('Tax-update'))
							buttons.push(
								<Link
									key="editButton##1"
									type="button"
									to={`/admin/tax/edit/${value.id}`}
									className="btn btn-icon"
									title="Edit"
								>
									<BiEdit style={{ color: "green" }} size={18} />
								</Link>
							);
						if (authPermissions.includes('Tax-delete'))
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => taxDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<RiDeleteBinLine style={{ color: "red" }} size={17} />
								</button>
							);
						value["action"] = buttons.length > 0 ? buttons : "-";
						return value;
					})

					if (authPermissions.includes('Tax-read')) {
						setTaxState(taxData);
					} else {
						setTaxState([]);
					}
					resolve(true);
				})
				.catch((error) => {
					setLoaderCheck(false);
				});
		});
	};

	const addLink = authPermissions.includes('Tax-create') === true ? '/admin/tax/add' : '';

	return (
		<>
			<TopNavBar links={{ list: '/admin/tax', add: addLink }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={taxState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tax;
