import React, { useState, useEffect, useContext } from "react";
import TopNavBar from "../../backend/layouts/TopNavBar";
import DataTable2 from "../../DataTable2";
import { toast } from "react-toastify";

import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link } from "react-router-dom";
import AlertContext from "../../../context//Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";



function AboutPageListingTemplate() {
  const [aboutstate, setAboutState] = useState([]);
  const [refreshState, setRefreshState] = useState(false);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);

  const headers = [
    { name: "Page Name", field: "name", classKey: "" },
    { name: "Slug", field: "slug", classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];
  const searchItems = ["name"];

  const getAboutPageData = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/pages`, options)
      .then((res) => {
        setLoaderCheck(false);
        const aboutpagedata = res.data.data.filter(
          (res) => res.template_type === "about"
        );

        const aboutdata = aboutpagedata.map((value, index) => {
          let buttons = [];
          if (authPermissions.includes('About Template-update'))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/about-template/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (authPermissions.includes('About Template-delete'))
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => categoryDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          value["sr-no"] = index + 1;
          return value;
        });

        // Sort the array based on the 'created_at' property in descending order
        const sortedAboutData = aboutdata.sort((a, b) =>
          new Date(b.created_at) - new Date(a.created_at)
        );

        if (authPermissions.includes('About Template-read')) {
          setAboutState(sortedAboutData);
        } else {
          setAboutState([]);
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.message);
        }
      });
  };

  useEffect(() => {
    getAboutPageData();
  }, [refreshState, authPermissions]);

  const categoryDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`/pages/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setRefreshState(!refreshState);
		  showSuccessToast("Category Deleted successfully")
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.message);
        }
      });
  };

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const addLink = authPermissions.includes('About Template-create') === true ? "/admin/about-template/add" : '';

	return (
		<>
			<TopNavBar
				links={{ list: "/admin/about-template", add: addLink }}
			/>
			<div className="section-body pt-1">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="category-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h3 className="card-title">About LIST</h3> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={aboutstate}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AboutPageListingTemplate;
