import React, { useContext, useEffect, useState } from 'react';
import styles from './Logo.module.css';
import { AiOutlineUser } from 'react-icons/ai';
import { BsCart3, BsSearch } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link, useNavigate } from 'react-router-dom';
import { ImFacebook } from 'react-icons/im';
import { AiFillInstagram } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { IoLogoLinkedin } from 'react-icons/io';
import { TiSocialYoutube } from 'react-icons/ti';
import navbarStyles from './Navbar.module.css';
import { RxCross2 } from 'react-icons/rx';
import CartCountContext from '../../../context/CartCount/CartCountContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { showErrorToast } from '../../../Toster';
import { AutoComplete } from 'primereact/autocomplete';
import MinfertLogo from '../../../assets/images/logo.webp';

function Logo() {
  const [menus, setMenus] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [showSubMenu, setShowSubMenu] = useState({});
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const [hideSearchIcon, setHideSearchIcon] = useState(false);
  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const navigate = useNavigate();
  const [subMenu, setSubMenu] = useState({
    flag: false,
    menu: '',
  });

  const { cartCount } = useContext(CartCountContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);

  const [name, setName] = useState("")

  useEffect(() => {
    if (isShowNavbar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShowNavbar]);

  function routeToProfile(e) {
    let userRole = localStorage.getItem('userData');
    if (userRole) {
      if (JSON.parse(userRole).role === 'client') {
        navigate('/user-dashboard');
      } else {
        navigate('/admin');
      }
    } else {
      navigate('/auth/signin');
    }
  }

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('menus')
      .then((res) => {
        setLoaderCheck(false);
        setMenus(res.data.data);
      })
      .catch((err) => {
        setLoaderCheck(false);
        setMenus([]);
      });
  }, []);

  function showSubMenus(e, res) {
    const isSubMenuOpen = showSubMenu[res.id] || false;
    setShowSubMenu({ ...showSubMenu, [res.id]: !isSubMenuOpen });
  }

  function hideShow(e, res) {
    e.preventDefault();
    if (res?.is_active === 1) {
      navigate('/coming-soon');
    } 
    else if (res?.submenus?.is_active == 1) {
      navigate('/comming-soon');
    }else {
      navigate('/' + res?.slug);
    }
    setIsShowNavbar(false);
  }

  // Function to make the API call
  const fetchResults = async () => {
    try {
      const response = await axiosPrivate.get(`search/${searchTerm}`);
      const data = await response?.data?.data;
      const products=data?.filter((product)=>product.result_type === 'product')
      setResults(products);
    } catch (err) {
      showErrorToast(
        err?.response?.data?.message ||
          err?.response?.data?.errors[0]?.msg ||
          err?.response?.data?.errors?.msg
      );
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchResults();
    } else {
      setResults([]); // Clear the results if the search term is empty
    }
  }, [searchTerm]);

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setSearchTerm(e.target.value);
    setHideSearchIcon(true);
    if (e.target.value.length === 0) {
      setHideSearchIcon(true);
    }
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
    setHideSearchIcon(true);
    if (e.target.value.length === 0) {
      setHideSearchIcon(true);
    }
  };

  const searchSuggestions = (event) => {
    const query = event?.query;
    // Filter and return the suggestions based on the query
    const filteredResults = results.map((result) =>
      result.product_name?.toLowerCase().includes(query?.toLowerCase())
    );

    return filteredResults;
  };

  const itemTemplate = (item) => {
    if (item.result_type === 'product') {
      return (
        <a
          href={`/product-detail/${item.product_slug}`}
          className='dropdown-item'
        >
          {item.product_name}
        </a>
      );
    }
    return <></>;
  };

  const getUserPersonalDetails = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`users/get`)
      .then((response) => {
        if (response?.data?.code === 200) {
          const data = response?.data?.data;
          if(data?.email !== null){
            setName(data?.name)
          }else{
            setName(data?.address[0]?.name)
          }
        }
      })
      .catch((error) => {
        
      });
  };
  useEffect(() => {
    getUserPersonalDetails();
  }, []);

  return (
    <>
      <div className={`${styles.container} searchBar`}>
      <AutoComplete
            value={searchTerm}
            suggestions={results}
            completeMethod={searchSuggestions} // Change this line
            itemTemplate={itemTemplate}
            field='product_name'
            onChange={(e) => handleInputChange(e)}
            placeholder='Search...'
            color='black'
            className='search'
            
          />
        {/* Logo */}
        <section>
          <div className={styles.logoDiv}>
            <Link to='/'>
              <img src={MinfertLogo} alt='Minfert' loading='lazy' />
            </Link>
          </div>
        </section>

        {/* Profile */}
        <section className={styles.profileContainer}>
          <div
            className={styles.accountProfile}
            onClick={(e) => routeToProfile(e)}
          >
            <div
              className={`${styles.profileImg} ${
                localStorage.getItem('userData') ? styles.green : ''
              }`}
            >
              <AiOutlineUser
               style={{ cursor: 'pointer' }}
                className={`${styles.profile} ${
                  localStorage.getItem('userData') ? styles.green : ''
                }`}
              />
            </div>
            <div className={styles.signInDetail}>
              <p className={styles.welcome}>Welcome!</p>
             
              {JSON.parse(localStorage.getItem('userData')) ? (
                <p className={styles.signIn}>
                  {name}
                </p>
              ) : (
                <p className={styles.signIn}>Sign In</p>
              )}
            </div>
          </div>

          <div className={`${styles.accountProfile} ${styles.cart}`}>
            <div className={`${styles.profileImg}  ${styles.cartImg}`}>
              <Link to='/cart'>
                <BsCart3  style={{ cursor: 'pointer' }} className={styles.cartIcon} />
              </Link>
            </div>
            <div className={styles.signInDetail}>
              <p className={styles.welcome}>My Cart</p>
              <p className={styles.cartQuantity}>
                {cartCount > 0 ? cartCount : 0}
              </p>
            </div>
          </div>
          <div className={`${styles.accountProfile}`}>
            <GiHamburgerMenu
              className={styles.burgerMenu}
              onClick={() => setIsShowNavbar(true)}
            />
            {/* {!isShowNavbar ? (
              <GiHamburgerMenu
                className={styles.burgerMenu}
                onClick={() => setIsShowNavbar(true)}
              />
            ) : (
              <RxCross2
                className={styles.burgerMenu}
                onClick={() => setIsShowNavbar(false)}
              />
            )} */}
          </div>
        </section>
      </div>

      {/* navbar */}
      <div className={`${isShowNavbar ? 'd-block' : 'd-none'}`}>
        <div className={`${styles.navbar} `}>
          <RxCross2
            className={`${styles.burgerMenu} ${styles.sidebarCross}`}
            onClick={() => setIsShowNavbar(false)}
          />
          {/* <div
            className={`${navbarStyles.searchContainer} ${navbarStyles.searchSection}`}
          >
            <input
              type='search'
              name='search'
              id='search'
              placeholder='Search...'
              className={`${navbarStyles.search}`}
              value={searchTerm}
              suggestions={results}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            <BsSearch
              className={`${navbarStyles.searchIcon} ${
                navbarStyles.responsiveSearch
              } ${hideSearchIcon ? navbarStyles.hide : navbarStyles.show}`}
            />
            {/* <div className='custom-dropdown'>
              <div className='dropdown'>
                <button
                  className='btn btn-secondary dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  {selectedOption ? selectedOption : 'Select an option'}
                </button>
                <div
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton'
                >
                  {results
                    .filter((product) => product?.result_type === 'product')
                    .map((result, index) => {
                      let optionContent = null;

                      if (result?.result_type === 'product') {
                        optionContent = (
                          <a
                            key={index}
                            className='dropdown-item'
                            href={`/product-detail/${result?.product_slug}`}
                          >
                            {result?.product_name}
                          </a>
                        );
                      } else if (result?.result_type === 'category') {
                        // Handle the case where category_name is null here
                        optionContent = (
                          <a
                            key={index}
                            className='dropdown-item'
                            href={'/' + result?.category_slug}
                          >
                            {result?.category_name}
                          </a>
                        );
                      }

                      return optionContent;
                    })}
                </div>
              </div>
            </div> */}
          {/* </div> */} 
        {/* Search */}
        <div className={`${styles.searchContainer} ${styles.searchSection} `}>
          <AutoComplete
            value={searchTerm}
            suggestions={results}
            completeMethod={searchSuggestions} // Change this line
            itemTemplate={itemTemplate}
            field='product_name'
            onChange={(e) => handleInputChange(e)}
            placeholder='Search...'
            color='black'
            className='search'
            
          />
        </div>

          <ul className={navbarStyles.navList}>
            {menus?.map((res, index) => (
              <li 
               key={index}
                className={`${navbarStyles.navItem} ${navbarStyles.farmersMenu}`}
                onClick={(e) => {
                  showSubMenus(e, res);
                }}
               
              >
                              {res?.submenus?.length > 0 ? (
                <span style={{ cursor: 'pointer' }}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      showSubMenus(e, res);
                    }}
                    className={`${
                      showSubMenu === res.id ? styles.menuIcon : ''
                    }`}
                  >
                    {res.title}
                  </span>
                </span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => hideShow(e, res)}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      showSubMenus(e, res);
                    }}
                    className={`${
                      showSubMenu === res.id ? styles.menuIcon : ''
                    }`}
                  >
                    {res.title}
                  </span>
                </span>
              )}
                {/* <span >{res.title}</span>{' '} */}
                {show}
                {res.submenus.length > 0 ? (
                  <MdKeyboardArrowDown
                    className={`${show ? styles.menuIcon : ''}`}

                  />
                ) : (
                  ''
                )}
                {showSubMenu[res.id] ? (
                  <ul
                    className={`${styles.farmersLink} ${
                      showSubMenu[res.id] ? styles.showMenu : styles.hideMenu
                    }`}
                  >
                    {res.submenus.map((resp) => (
                      <span style={{ cursor: 'pointer' }}>
                        <li className={`${styles.subMenu} mx-2`}>
                          {' '}
                          <span onClick={(e) => hideShow(e, resp)}>
                            {resp.title}
                          </span>
                        </li>
                      </span>
                    ))}
                  </ul>
                ) : (
                  ''
                )}
              </li>
            ))}
          </ul>
          <div className={`${styles.social}`}>
            <Link to='/'>
              <div className={styles.socialLink}>
                <ImFacebook />
              </div>
            </Link>

            <Link to='/'>
              <div className={styles.socialLink}>
                <AiFillInstagram />
              </div>
            </Link>

            <Link to='/'>
              <div className={styles.socialLink}>
                <FaTwitter />
              </div>
            </Link>

            <Link to='/'>
              <div className={styles.socialLink}>
                <IoLogoLinkedin />
              </div>
            </Link>

            <Link to='/'>
              <div className={styles.socialLink}>
                <TiSocialYoutube />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Logo); // Memoize the Logo;
