import React, { useState, useEffect, useContext } from "react";
import TopNavBar from "../../backend/layouts/TopNavBar";
import DataTable2 from "../../DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import AlertContext from '../../../context/Alert/AlertContext';
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";
function DistributorPageTemplate() {
  const [distributorstate, setdistributorstate] = useState([]);
  const [refreshState, setRefreshState] = useState(false);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);

  const headers = [
    { name: "Page Name", field: "name", classKey: "" },
    { name: "Slug", field: "slug", classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];
  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/pages`, options)
      .then((res) => {
        setLoaderCheck(false);
        const distributorpagedata = res.data.data.filter(
          (res) => res.template_type === "distributor"
        );

        const distributordata = distributorpagedata.map((value, index) => {
          let buttons = [];
          if (authPermissions.includes('Distributor Template-update'))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/distributor/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (authPermissions.includes('Distributor Template-delete'))
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => categoryDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          value['sr-no'] = index + 1;
          return value;
        });

        // Sort the array based on the 'created_at' property in descending order
        const sortedDistributorData = distributordata.sort((a, b) =>
          new Date(b.created_at) - new Date(a.created_at)
        );

        setdistributorstate(sortedDistributorData);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.message);
        }
      });
  }, [refreshState]);

  const categoryDeleteHandler = async (id) => {
    setLoaderCheck(true);
    axiosPrivate
      .delete(`/pages/${id}`)
      .then(async (response) => {
        setLoaderCheck(false);
        if (response.data.code == 200) {
          setRefreshState(!refreshState);
          setdistributorstate([]);
          showSuccessToast("Category Deleted Successfully");
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          console.log(error.response.status);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.message);
        }
      });
  };

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const addLink = authPermissions.includes('Distributor Template-create') === true ? "/admin/distributor/add" : '';

  return (
    <>
      <TopNavBar
        links={{ list: "/admin/distributor", add: addLink }}
      />
      <div className="section-body pt-3">
        <div className="container-fluid">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="category-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  {/* <h3 className="card-title">Distributor LIST</h3> */}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={distributorstate}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DistributorPageTemplate;