import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/Auth/AuthContext';
import * as Yup from 'yup';
import axiosApiSigninInstance from '../../hooks/axiosLogin';
import { useFormik } from 'formik';
import { showErrorToast } from '../../Toster';

const SignUp = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  const loginSchma = Yup.object({
    email: Yup.string().email().required('Email is Required'),
    name: Yup.string().required('Name is Required'),
    mobile: Yup.string()
      .required('Mobile number is required')
      .min(10, 'Mobile number must be of 10 digits')
      .max(10, 'Mobile number must be of 10 digits'),
    password: Yup.string().required(' Password is Required'),
  });

  const loginForm = {
    name: '',
    email: '',
    password: '',
    mobile: '',
  };

  function restrictChar(event) {
    const numericValue = event.target.value.replace(/\D/g, '');
    setFieldValue(event.target.name, numericValue);
    handleChange(numericValue);
  }

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: loginForm,
    validationSchema: loginSchma,
    onSubmit: (data, values) => {
      axiosApiSigninInstance
        .post('register', data)
        .then((res) => {
          localStorage.setItem('userData', JSON.stringify(res.data));
          setUser(res.data);
          if (res.data.role === 'client') {
            navigate('/');
          } else {
            navigate('/admin');
          }
        })
        .catch((err) => {
          showErrorToast(err?.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    if (user.token && user.role !== 'client') {
      navigate('/admin', { replace: true });
    }
  }, [user]);

  return (
    <Fragment>
      <div className='auth_left'>
        <div className='card'>
          <div className='text-center mb-5'>
            <Link className='header-brand' to='/'>
              <img
                src='./../assets/images/home/logo.webp'
                alt='test'
                loading='lazy'
              />
            </Link>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='card-body'>
              <div className='card-title'>Create new account</div>
              <div className='form-group'>
                <label className='form-label'>
                  Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='name'
                  id='name'
                />

                {touched.name && errors.name ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.name}
                  </div>
                ) : null}
              </div>

              <div className='form-group'>
                <label className='form-label'>
                  Email address <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='email'
                  id='email'
                  className='form-control'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Email'
                />

                {touched.email && errors.email ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.email}
                  </div>
                ) : null}
              </div>

              <div className='form-group'>
                <label className='form-label'>
                  Mobile No. <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  id='mobile'
                  name='mobile'
                  type='text'
                  maxLength={10}
                  minLength={10}
                  className='form-control'
                  placeholder='Enter Mobile Number *'
                  onChange={(e) => restrictChar(e)}
                  onBlur={handleBlur}
                  value={values.mobile}
                />
                {touched.mobile && errors.mobile ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.mobile}
                  </div>
                ) : null}
              </div>

              <div className='form-group'>
                <label className='form-label'>
                  Password <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='password'
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id='password'
                  value={values.password}
                  className='form-control'
                  placeholder='Password'
                />

                {touched.password && errors.password ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.password}
                  </div>
                ) : null}
              </div>

              <div className='form-footer'>
                <button className='btn btn-block submit-button' type='submit'>
                  Sign Up
                </button>
              </div>
            </div>
          </form>
          <div className='text-center text-muted'>
            Already have account? <Link to='/auth/signin'>Sign In</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUp;
