import React, { useEffect, useState, useContext } from "react";
import styles from "./ProductTemplate.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../../Toster";
import AuthContext from "../../../../context/Auth/AuthContext";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { Dropdown, MultiSelect } from "react-multi-select-component";
import TopNavBar from "../../../backend/layouts/TopNavBar";
import { errorToast } from "../../common/Toaster";
import { event } from "jquery";
import JoditEditor from "jodit-react";
import { WithContext as ReactTags } from 'react-tag-input';
const AddProduct = () => {
  const { authPermissions } = useContext(AuthContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [submitButton, setSubmitButton] = useState("Submit");
  const [pageName, setPagename] = useState("Add Home Template");
  const [selected, setSelected] = useState("");
  const [category, setCategory] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [slug, setslug] = useState("");

  const { id } = useParams();
  const aboutTemplateValidation = Yup.object({
    pageName: Yup.string().required("Page Name is Required"),
    slug: Yup.string().required("Slug is Required"),
  });

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get("pages/" + id)
        .then((res) => {
          setLoaderCheck(false);
          const parsedValues = JSON.parse(res?.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });
          setKeywords(parsedValues?.keywords);
          setTags(parsedValues?.tags);
          setSubmitButton("Update");
          setPagename("Edit Product Template");
        })

        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error?.response?.data?.errors == "array") {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          } else {
            showErrorToast(error?.response?.data?.errors?.msg);
          }
        });
    }
  }, []);
  const config = {
    controls: {
      font: {
        list: {
          "var(--alice)": "Alice",
        },
      },
    },
  };
  const initialValues = {
    pageName: "",
    slug: "",
    mainImage: "",
    meta_title: '',
    meta_description: '',
    keywords: [],
    tags: [],
    og_title: '',
    og_description: '',
    og_image: '',
    og_alt: '',
    canonical_tags: '',
    categorySection: [
      {
        heading: "",
        content: "",
        categorySlug: "",
      },
    ],
    ProductSection: [
      {
        name: "",
        slug: "",
        short_description: "",
        category_name: "",
        category_slug: "",
        types: "",
        forms: "",
        productImage: "",
        product_pdf: "",
        amazon_link: "",
        flipkart_link: "",
      },
    ],
  };


  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const handleDeleteKeywords = (i) => {
    setKeywords(keywords.filter((tag, index) => index !== i));
  };

  const handleAdditionKeywords = (keyword) => {
    setKeywords([...keywords, keyword]);
  };

  const handleDragKeywords = (keyword, currPos, newPos) => {
    const newkeywords = keywords.slice();

    newkeywords.splice(currPos, 1);
    newkeywords.splice(newPos, 0, keyword);

    setKeywords(newkeywords);
  };

  const handleDeleteTags = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAdditionTags = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDragTags = (tags, currPos, newPos) => {
    const newtags = tags.slice();

    newtags.splice(currPos, 1);
    newtags.splice(newPos, 0, tags);

    setTags(newtags);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];


  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name === "pageName") {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
      setFieldValue("slug", slug);
    } else {
      handleBlur(event);
    }
  };

  const selectOnChange = (e) => {
    setSelectedValue(e);

    const idsArray = e.map((item) => item.value);

    setValues({ ...values, type_id: idsArray });
  };

  const onFileUpload = (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    setLoaderCheck(true);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        setLoaderCheck(false);
        setFieldValue(event?.target?.name, res?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        setFieldValue(event.target.name, "");
      });
    handleChange(event);
  };

  const onAddRow = (name, index, check) => {
    if (name === "ProductSection") {
      if (check) {
        let newObj = {
          name: "",
          slug: "",
          short_description: "",
          category_name: "",
          category_slug: "",
          types: "",
          forms: "",
          productImage: "",
          product_pdf: "",
          amazon_link: "",
          flipkart_link: "",
        };
        let rowsData = values.ProductSection;
        if (rowsData?.length < 15) {
          rowsData.push(newObj);
          setFieldValue("ProductSection", rowsData);
        }
      } else {
        let rowsData = values.ProductSection;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue("ProductSection", rowsData);
        }
      }
    }
  };

  const navigate = useNavigate();

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: aboutTemplateValidation,
    onSubmit: (values) => {
      values.keywords = keywords;
      values.tags = tags;
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "product",
      };
      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put("/pages/update/" + id, formData)
          .then((res) => {
            setLoaderCheck(false);
            showSuccessToast("Product Page Updated");
            navigate("/admin/ProductTemplate");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post("pages/create", formData)
          .then((res) => {
            setLoaderCheck(false);
            showSuccessToast("Product Page Created");
            navigate("/admin/ProductTemplate");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      }
    },
  });
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("categories")
      .then((parentCat) => {
        setLoaderCheck(false);
        setCategory(parentCat?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, []);
  console.log("content:", values.categorySection[0].content);
  return (
    <div>
      <TopNavBar
        links={{
          list: "/admin/ProductTemplate",
          add: "/admin/ProductTemplate/add",
        }}
      />
      <div className={styles.templateContainer}>
        <h1 className={styles.mainHeading}>{pageName}</h1>
        <form onSubmit={handleSubmit} className={styles.aboutForm}>
          <div className={styles.mainFormContainer}>
            <div className="row">
              {/* Page name field */}
              <div className="my-2 col-12 col-lg-6">
                <label
                  htmlFor="pagename"
                  className={` ${styles.fieldName} form-label`}
                >
                  Page name<span className={styles.red}>*</span>
                </label>
                <input
                  name="pageName"
                  id="pageName"
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  value={values.pageName}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                />
                {touched.pageName && errors.pageName ? (
                  <div className={styles.red}>{errors.pageName}</div>
                ) : null}
              </div>

              {/* slug field */}
              <div className="my-2 col-12 col-lg-6">
                <label
                  htmlFor="slug"
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name="slug"
                  id="slug"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.slug}
                />
                {touched.slug && errors.slug ? (
                  <div className={styles.red}>{errors.slug}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={`${styles.mainFormContainer} my-2`}>
            <h5 className="mt-2">Category</h5>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label className={` ${styles.fieldName} form-label`}>
                  Name<span className={styles.red}>*</span>
                </label>
                <input
                  name="categorySection[0].heading"
                  id="categorySection[0].heading"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.categorySection[0].heading}
                />
              </div>

              {/* <div className="  col-12 col-lg-6">
                <label
                  htmlFor="slug"
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name="categorySection[0].categorySlug"
                  id="categorySection[0].categorySlug"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.categorySection[0].categorySlug}
                />
                {touched.categorySlug && errors.categorySlug ? (
                  <div className={styles.red}>{errors.categorySlug}</div>
                ) : null}
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label className={` ${styles.fieldName} form-label`}>
                  Image<span className={styles.red}>*</span>
                </label>
                <input
                  name="mainImage"
                  id="mainImage"
                  type="file"
                  className="form-control"
                  onChange={(e) => onFileUpload(e)}
                  onBlur={handleBlur}
                />
                {values.mainImage && (
                  <img
                    style={{ width: "100px", height: "100px" }}
                    className="mt-2"
                    src={values.mainImage}
                    alt="main"
                    loading="lazy"
                  />
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <label className={` ${styles.fieldName} form-label`}>
                  Description<span className={styles.red}>*</span>
                </label>
                <JoditEditor
                  config={config}
                  name="categorySection[0].content"
                  id="categorySection[0].content"
                  type="text"
                  className="form-control"
                  onChange={(newContent) =>
                    setFieldValue(`categorySection[0].content`, newContent)
                  }
                  onBlur={handleBlur}
                  value={values.categorySection[0].content}
                />
              </div>
            </div>
          </div>
          <div>
            <div className={`${styles.mainFormContainer} my-2`}>
              <h5 className="mt-2">Products</h5>
              {values.ProductSection.map((res, index) => (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id={`ProductSection.[${index}].name`}
                          name={`ProductSection.[${index}].name`}
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Name *"
                          value={values.ProductSection[index].name}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label
                        htmlFor="slug"
                        className={` ${styles.fieldName} form-label`}
                      >
                        Slug<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`ProductSection.[${index}].slug`}
                        id={`ProductSection.[${index}].slug`}
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ProductSection[index].slug}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Short Description/Usage{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <JoditEditor
                          config={config}
                          id={`ProductSection.[${index}].short_description`}
                          name={`ProductSection.[${index}].short_description`}
                          className="form-control"
                          value={values.ProductSection[index].short_description}
                          onChange={(newContent) =>
                            setFieldValue(
                              `ProductSection.[${index}].short_description`,
                              newContent
                            )
                          }
                          style={{ height: "200px" }}
                        />
                        {touched.short_description &&
                        errors.short_description ? (
                          <div className={styles.invalidDataError}>
                            {errors.short_description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Category Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name={`ProductSection.[${index}].category_name`}
                          id={`ProductSection.[${index}].category_name`}
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue(
                              `${values.ProductSection[index]?.category_name}`,
                              e.target.value
                            );
                            handleChange(e);
                          }}
                          value={values.ProductSection[index]?.category_name}
                        >
                          {category.map((res) => (
                            <option value={res.name}>{res.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Category Slug <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name={`ProductSection.[${index}].category_slug`}
                          id={`ProductSection.[${index}].category_slug`}
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue(
                              `${values.ProductSection[index]?.category_slug}`,
                              e.target.value
                            );
                            handleChange(e);
                          }}
                          value={values.ProductSection[index]?.category_slug}
                        >
                          {category.map((res) => (
                            <option value={res.slug}>{res.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id={`ProductSection.[${index}].types`}
                          name={`ProductSection.[${index}].types`}
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="type *"
                          value={values.ProductSection[index].types}
                        />
                        {touched.types && errors.types ? (
                          <div className={styles.invalidDataError}>
                            {errors.types}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          About Product<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id={`ProductSection.[${index}].forms`}
                          name={`ProductSection.[${index}].forms`}
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="form *"
                          value={values.ProductSection[index].forms}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Image<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`ProductSection.[${index}].productImage`}
                        id={`ProductSection.[${index}].productImage`}
                        type="file"
                        className="form-control"
                        onChange={onFileUpload}
                        onBlur={handleBlur}
                      />
                      {typeof values.ProductSection[index].productImage ==
                        "string" &&
                      values.ProductSection[index].productImage != "" ? (
                        <img
                          style={{ width: "100px", height: "100px" }}
                          className="mt-2"
                          src={values.ProductSection[index].productImage}
                          alt="product"
                          loading="lazy"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Amazon Link <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id={`ProductSection.[${index}].amazon_link`}
                          name={`ProductSection.[${index}].amazon_link`}
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Amazon *"
                          value={values.ProductSection[index].amazon_link}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Flipkart Link <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id={`ProductSection.[${index}].flipkart_link`}
                          name={`ProductSection.[${index}].flipkart_link`}
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          placeholder="Flipkart *"
                          value={values.ProductSection[index].flipkart_link}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label className="form-label">
                        Product PDF<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`ProductSection.[${index}].product_pdf`}
                        id={`ProductSection.[${index}].product_pdf`}
                        type="file"
                        className="form-control"
                        onChange={onFileUpload}
                        onBlur={handleBlur}
                      />
                      {values.ProductSection[index].product_pdf && (
                        <div>
                          File Name : {values.ProductSection[index].product_pdf}
                        </div>
                      )}
                    </div>

                    <div
                      className={`${styles.addDataButton_home} col-12 col-lg-1`}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          onAddRow("ProductSection", index, true);
                        }}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          onAddRow("ProductSection", index, false);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
            
            <div
              className={styles.mainFormContainer}
              style={{ paddingRight: "3%" }}
            >
              <h5 className="mt-2 ml-2">Meta Fields:</h5>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="meta_title"
                      name="meta_title"
                      type="text"
                      className="form-control"
                      placeholder="meta title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.meta_title}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="meta_description"
                      name="meta_description"
                      type="text"
                      className="form-control"
                      placeholder="meta description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.meta_description}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginLeft: "1%" }}
                >
                  <h5 style={{ marginTop: "1%" }}>Keywords:</h5>
                  <ReactTags
                  tags={keywords}
                  id="keywords"
                  name="keywords"
                  delimiters={delimiters}
                  handleDelete={handleDeleteKeywords}
                  handleAddition={handleAdditionKeywords}
                  handleDrag={handleDragKeywords}
                  inputFieldPosition="bottom"
                  autocomplete
                  handleInputChange={handleChange}
                  handleInputBlur={handleBlur}
                  value={values?.keywords}
                  placeholder="Enter keywords"
                />
                </div>
                <div
                  className="col-md-12 col-sm-12 "
                  style={{ marginLeft: "1%" }}
                >
                  <h5 style={{ marginTop: "1%" }}>Tags:</h5>
                  <ReactTags
                  tags={tags}
                  id="tags"
                  name="tags"
                  delimiters={delimiters}
                  handleDelete={handleDeleteTags}
                  handleAddition={handleAdditionTags}
                  handleDrag={handleDragTags}
                  inputFieldPosition="bottom"
                  autocomplete
                  handleInputChange={handleChange}
                  handleInputBlur={handleBlur}
                  value={values?.tags}
                  placeholder="Enter tags"
                />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="og_title"
                      name="og_title"
                      type="text"
                      className="form-control"
                      placeholder="Og title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.og_title}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="og_description"
                      name="og_description"
                      type="text"
                      className="form-control"
                      placeholder="Og description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.og_description}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="og_image"
                      name="og_image"
                      type="file"
                      className="form-control"
                      placeholder="Og Image"
                      onChange={onFileUpload}
                      accept="image/*"
                      onBlur={handleBlur}
                      style={{ margin: "2%" }}
                    />
                    {typeof values?.og_image === "string" &&
                    values?.og_image !== "" ? (
                      <img
                        className={styles.mainImage}
                        src={values?.og_image}
                        alt="Og Image Alt"
                        loading="lazy"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="og_alt"
                      name="og_alt"
                      type="text"
                      className="form-control"
                      placeholder="Og alt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.og_alt}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="">
                    <input
                      id="canonical_tags"
                      name="canonical_tags"
                      type="text"
                      className="form-control"
                      placeholder="Canonical"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.canonical_tags}
                      style={{ margin: "2%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                id="button_1"
                className={`${styles.closeButton} btn`}
              >
                <Link style={{ color: "white" }} to="/admin/ProductTemplate">
                  {" "}
                  Close
                </Link>
              </button>

              <button
                type="submit"
                id="button_2"
                className={`${styles.submitButton} btn`}
              >
                {submitButton}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
