import React, { useEffect, useState, useContext } from 'react';
import { FaDiceD6, FaUser, FaShoppingCart } from 'react-icons/fa';
import { MdPayment } from 'react-icons/md';
import { BiLogOut } from 'react-icons/bi';
import { PiHouseFill } from 'react-icons/pi';
import { Link, useLocation } from 'react-router-dom';
import styles from './UserDashboard.module.css';
import ViewOrders from './orders/ViewOrders';
import PersonalDetails from './personalDetails/PersonalDetails';
import UserAddresses from './manageAddresses/UserAddresses';
import Wishlist from './savedproducts/Wishlist';
import CartCountContext from '../../../context/CartCount/CartCountContext';

function UserDashboard() {
  const [activeLink, setActiveLink] = useState('View Orders');
  const location = useLocation();

  const { setCartCount } = useContext(CartCountContext);

  const showActive = (string) => {
    setActiveLink(string);
  };
  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('cartItems');
    setCartCount(0);
  };

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.sideBar}>
        <ul className={styles.menuList}>
          <Link
            to='/user-dashboard'
            onClick={() => {
              showActive('View Orders');
            }}
          >
            {' '}
            <li
              className={`${styles.menuItem} ${
                activeLink == 'View Orders' ? styles.active : ''
              }`}
            >
              <FaDiceD6 className={styles.icons} />
              <p>View Orders</p>
            </li>
          </Link>

          <Link
            to='/user-dashboard'
            onClick={() => {
              showActive('WishList');
            }}
          >
            {' '}
            <li
              className={`${styles.menuItem} ${
                activeLink == 'WishList' ? styles.active : ''
              }`}
            >
              <FaDiceD6 className={styles.icons} />
              <p>Wishlist</p>
            </li>
          </Link>

          <Link
            to='/user-dashboard'
            onClick={() => {
              showActive('Personal Details');
            }}
          >
            {' '}
            <li
              className={`${styles.menuItem} ${
                activeLink == 'Personal Details' ? styles.active : ''
              }`}
            >
              <FaUser className={styles.icons} />
              <p>Personal Details</p>
            </li>
          </Link>

          {/* <Link to='/user-dashboard' onClick={() => { showActive('My orders') }}> <li className={`${styles.menuItem} ${activeLink == 'My orders' ? styles.active  : ''}`}>
            <MdPayment className={styles.icons} />
            <p>Payment Details</p>
          </li>
          </Link> */}

          <Link
            to='/user-dashboard'
            onClick={() => {
              showActive('Manage addresses');
            }}
          >
            {' '}
            <li
              className={`${styles.menuItem} ${
                activeLink == 'Manage addresses' ? styles.active : ''
              }`}
            >
              <PiHouseFill className={styles.icons} />
              <p>Manage Addresses</p>
            </li>
          </Link>
          <Link to='/'>
            <li className={styles.menuItem} onClick={handleLogout}>
              <BiLogOut className={styles.icons} />
              <p>Log out</p>
            </li>
          </Link>
        </ul>
      </div>

      <div className={styles.userDataContainer}>
        {activeLink == 'View Orders' && <ViewOrders />}
        {activeLink == 'WishList' && <Wishlist />}
        {activeLink == 'Personal Details' && <PersonalDetails />}
        {activeLink == 'Manage addresses' && <UserAddresses />}
      </div>
    </div>
  );
}

export default React.memo(UserDashboard); // Memoize the UserDashboard;
