import {useState} from 'react';
import CheckoutContext from './CheckoutContext';

const CheckoutState = (props) => {
    const [showCheckout, setShowCheckout] = useState(false);

    return (
        <CheckoutContext.Provider value={{ showCheckout, setShowCheckout }}>
            {props.children}
        </CheckoutContext.Provider>
    );
}

export default CheckoutState;