import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const IndiaMap = () => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 27.0238,
    lng: 74.2179,
  };

  const locations = [
    {
      name: "Telangana",
      location: { lat: 18.1124, lng: 79.0193 },
    },
    {
      name: "Andhra Pradesh",
      location: { lat: 15.9129, lng: 79.74 },
    },
    {
      name: "Maharashtra",
      location: { lat: 19.7515, lng: 75.7139 },
    },
    {
      name: "Rajasthan",
      location: { lat: 27.0238, lng: 74.2179 },
    },
    {
      name: "Bihar",
      location: { lat: 25.0961, lng: 85.3131 },
    },
    {
      name: "West Bengal",
      location: { lat: 22.9868, lng: 87.855 },
    },
    {
      name: "Madhya Pradesh",
      location: { lat: 22.9734, lng: 78.6569 },
    },
    {
      name: "Gujarat",
      location: { lat: 22.2587, lng: 71.1924 },
    },
    {
      name: "Nairobi, Kenya",
      location: { lat: 1.2921, lng: 36.8219 },
    },
  ];
  //googleMapsApiKey='AIzaSyAZR_vAqrugHzdTRe2peVW4ZIZklIGSaiA'
  return (
    <div className="india-map">
      <GoogleMap mapContainerStyle={mapStyles} zoom={3} center={defaultCenter}>
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={location.location}
            title={location.name}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default IndiaMap;
