import { useFormik } from "formik";
import React, { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "./careerPage.css";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { showErrorToast } from "../../../Toster";

const careerFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    })
    .min("10", "Mobile number must be of 10 digits")
    .max("10", "Mobile number must be of 10 digits"),
    email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ ,'Invalid email address')
    .required("Email is Required"),
  resume: Yup.string().required("Resume is Required"),
  applied_for: Yup.string().required("Field is Required"),
});

function CareerPage() {
  const [successMsg, setSuccessMsg] = useState(false);
  const { setLoaderCheck } = useContext(LoaderContext);
  const fileInputRef = useRef(null);

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    applied_for: "",
    resume: null,
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: careerFormValidation,
    onSubmit: (values, { setFieldError, resetForm }) => {
      let formData = new FormData();
      Object.keys(values).map((res) => {
        formData.append(res, values[res]);
      });
      setLoaderCheck(true);
      axiosPrivate
      .post("career", formData)
      .then((res) => {
          setLoaderCheck(false);
          // alert(res?.data?.message);
          resetForm();
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the input field value
          }
          window.scroll({ top: 0, behavior: "smooth" });
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 3000);
        
        })
        .catch((error) => {
          setLoaderCheck(true);
          showErrorToast("Internal Server Error")
        });
    },
  });


  return (
    <>
      <div className="d-flex justify-content-between career-page-container">
        <div className="career-choose-container">
          <h2 className="choose-us-heading">Why Choose Us?</h2>
          <p className="choose-us-detail">
            The plant food manufacturer MINFERT is a new venture of 20 Microns
            Limited. It comes from a state of the art research centre and
            technical expertise in the field of farming and offers a range of
            mineral-based plant food, insecticides, and soil conditioners to
            gain better yield and quality crops. In today’s world, harmful and
            toxic synthetic chemicals that come from chemical fertilizers are
            affecting the human lives drastically. To overcome these challenges,
            20 Microns has taken the initiative to develop an alternative
            solution towards organic farming, crop protection, and landscape
            management so that we can contribute to the improvement of people’s
            lives with better farming solutions. Our range of plant growth
            stimulators has been recreated to provide a better natural solution
            for the agriculture, plant growth & crop protection industry.
            <br /> Team Minfert
          </p>
        </div>
        {/* <div className='career-form'> */}
        <div className="career-form">
          <div className="col-12 contact_field">
            <h2 className="choose-us-heading">Career</h2>
          </div>
          {successMsg ? (
            <div className="success_msg">
              <p className="m-0">Thank you for the enquiry!</p>
            </div>
          ) : (
            ""
          )}
          <form initialValues={initialValues} onSubmit={handleSubmit}>
            <div className="row row-cols-2 form-container g-0 justify-content-between">
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="name"
                  id="name"
                  placeholder="Your Name"
                  className="form-input"
                />
                {touched.name && errors.name ? (
                  <div className="invalidDataError">{errors.name}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  name="mobile"
                  value={values?.mobile}
                  onChange={(event) => {
                    const numRejex = /^[0-9\b]+$/;
                    if (
                      event.target.value === "" ||
                      (numRejex.test(event.target.value) &&
                        event.target.value.length <= 10)
                    ) {
                      handleChange(event);
                    }
                  }}
                  onBlur={handleBlur}
                  id="number"
                  placeholder="Mobile Number"
                  className="form-input"
                />
                {touched.mobile && errors.mobile ? (
                  <div className="invalidDataError">{errors.mobile}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="email"
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailid"
                  placeholder="Email"
                  
                  className="form-input"
                />
                {touched.email && errors.email ? (
                  <div className="invalidDataError">{errors.email}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  name="applied_for"
                  value={values?.applied_for}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="applied_for"
                  placeholder="Position Applying For"
                  className="form-input"
                />
                {touched.applied_for && errors.applied_for ? (
                  <div className="invalidDataError">{errors.applied_for}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12 contact_field">
  <label htmlFor="file" className="file-label">
    Upload Resume
  </label>
  <input
    type="file"
    name="resume"
    ref={fileInputRef}
    onChange={(event) => setFieldValue("resume", event.target.files[0])}
    onBlur={handleBlur}
    id="file"
    className="file-input"
  />
  {touched.resume && errors.resume ? (
    <div className="invalidDataError">{errors.resume}</div>
  ) : null}
</div>



            <div className="submit-btn-container col-12">
              <button type="submit" className="career-submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default React.memo(CareerPage);
