
import React, { useContext, useEffect, useState } from "react";
import TopNavBar from "../../backend/layouts/TopNavBar";
import DataTable2 from "../../DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";



const HomeTemplate = () => {
  const [pageState, setPageState] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const { authPermissions } = useContext(AuthContext);
  const { setLoaderCheck } = useContext(LoaderContext);


  const headers = [
    { name: "NAME", field: "name", sortable: true, classKey: "" },
    { name: "SLUG", field: "slug", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];
  const getHomePageData = async () => {
	try {
	  setLoaderCheck(true);
	  const options = {
		headers: { "content-type": "application/json" },
	  };

	  const pages = await axiosPrivate.get("pages", options);
	  setLoaderCheck(false);

	  let filteredData = pages.data.data.filter(
		(res) => res.template_type === "home"
	  );

	  const pageData = filteredData.map((value, index) => {
		let buttons = [];

		if (authPermissions.includes("Home Template-update"))
		  buttons.push(
			<Link
			  key={`editButton##${index}`}
			  type="button"
			  to={`/admin/home-template/edit/${value.id}`}
			  className="btn btn-icon"
			  title="Edit"
			>
			  <i className="fa fa-edit"></i>
			</Link>
		  );
		if (authPermissions.includes("Home Template-delete"))
		  buttons.push(
			<button
			  key={`deleteButton##${index}`}
			  type="button"
			  data-id={value.id}
			  onClick={() => pageDeleteHandler(value.id)}
			  className="btn btn-icon js-sweetalert"
			  title="Delete"
			>
			  <i className="fa fa-trash-o text-danger"></i>
			</button>
		  );
		value["action"] = buttons.length > 0 ? buttons : "-";
		value["sr.no."] = index + 1;
		return value;
	  });

	  // Sort the pageData in descending order based on created_at timestamp
	  const sortedPageData = pageData.sort(
		(a, b) => new Date(b.created_at) - new Date(a.created_at)
	  );

	  if (authPermissions.includes("Home Template-read")) {
		setPageState(sortedPageData);
	  } else {
		setPageState([]);
	  }
	} catch (error) {
	  setLoaderCheck(false);
	  if (error.response) {
		console.log(error.response.status);
	  } else if (error.request) {
		console.log(error.request);
	  } else {
		console.log(error.message);
	  }
	}
  };
  useEffect(() => {
	getHomePageData();
  }, [refreshTable, authPermissions]);
  

  const pageDeleteHandler = async (id) => {
    try {
     const res= await axiosPrivate.delete(`pages/${id}`);
      showSuccessToast("Page Deleted Successfully");
      setRefreshTable(true);
      getHomePageData();
    } catch (error) {
      showErrorToast(error.response?.status || error.response?.data || error.response);
    }
  };

  const addLink =
    authPermissions.includes("Home Template-create") === true
      ? "/admin/home-template/add"
      : "";

	return (
		<div>
			<TopNavBar
				links={{
					list: "/admin/home-template",
					add: addLink,
				}}
			/>
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="category-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h3 className="card-title">PAGES LIST</h3> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={pageState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeTemplate;
