import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';
import TopNavBar from '../../layouts/TopNavBar';
import DataTable2 from '../../../DataTable2';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import AuthContext from '../../../../context/Auth/AuthContext';
import { errorToast } from '../../common/Toaster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { showErrorToast } from '../../../../Toster';

const Categories = (props) => {
    const { alertMessage, setAlertMessage } = useContext(AlertContext);
    const { authPermissions } = useContext(AuthContext);
    const [categoryState, setCategoryState] = useState([]);
    const { setLoaderCheck } = useContext(LoaderContext);

    const headers = [
        // { name: 'S NO', field: 'sr_no', sortable: true, classKey: '' },
        { name: 'NAME', field: 'name', sortable: true, classKey: '' },
        { name: 'SLUG', field: 'slug', sortable: true, classKey: '' },
        { name: 'ACTION', field: 'action', classKey: '' },
    ];

    const searchItems = ['name'];

    useEffect(() => {
        const options = {
            headers: { 'content-type': 'application/json' },
        };
        setLoaderCheck(true);
        axiosPrivate
            .get('categories', options)
            .then((categories) => {
                setLoaderCheck(false);
                const categoriesData = categories.data.data.map((value, index) => {
                    let buttons = [];
                    if (authPermissions.includes('Category-update'))
                        buttons.push(
                            <Link
                                key="editButton##1"
                                type="button"
                                to={`/admin/categories/edit/${value.id}`}
                                className="btn btn-icon"
                                title="Edit"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                        );
                    if (authPermissions.includes('Category-delete'))
                        buttons.push(
                            <button
                                key="deleteButton##1"
                                type="button"
                                data-id={value.id}
                                onClick={() => categoryDeleteHandler(value.id)}
                                className="btn btn-icon js-sweetalert"
                                title="Delete"
                            >
                                <i className="fa fa-trash-o text-danger"></i>
                            </button>
                        );
                    value['action'] = buttons.length > 0 ? buttons : '-';
                    value['sr_no'] = index + 1;
                    return value;
                });

                if (authPermissions.includes('Category-read')) {
                    setCategoryState(categoriesData);
                } else {
                    setCategoryState([]);
                }
            })
            .catch((error) => {
                errorToast(error.message);
                setLoaderCheck(false);
                if (error.response) {
                    showErrorToast("Internal Server Error")
                }
            });
    }, [authPermissions]);

    useEffect(() => {
        if (alertMessage != null) {
            toast(alertMessage.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setAlertMessage(null);
        }
    }, [alertMessage]);

    const categoryDeleteHandler = async (id) => {
        setLoaderCheck(true);
        axiosPrivate
            .delete(`categories/${id}`)
            .then(async (response) => {
                setLoaderCheck(false);
                if (response.data.code == 200) {
                    setCategoryState([]);
                    setAlertMessage({ message: 'Category deleted successfully' });
                    await refreshTable();
                }
            })
            .catch((error) => {
                errorToast(error.message);
                setLoaderCheck(false);
                if (error.response) {
                    showErrorToast("Internal Server Error")
                }
            });
    };

    const refreshTable = () => {
        return new Promise((resolve, reject) => {
            const options = {
                headers: { 'content-type': 'application/json' },
            };
            setLoaderCheck(true);
            axiosPrivate
                .get('categories', options)
                .then((categories) => {
                    setLoaderCheck(false);
                    const categoriesData = categories.data.data.map((value, key) => {
                        let buttons = [];
                        if (authPermissions.includes('Category-update'))
                            buttons.push(
                                <Link
                                    key="editButton##1"
                                    type="button"
                                    to={`/admin/categories/edit/${value.id}`}
                                    className="btn btn-icon"
                                    title="Edit"
                                >
                                    <i className="fa fa-edit"></i>
                                </Link>
                            );
                        if (authPermissions.includes('Category-delete'))
                            buttons.push(
                                <button
                                    key="deleteButton##1"
                                    type="button"
                                    data-id={value.id}
                                    onClick={() => categoryDeleteHandler(value.id)}
                                    className="btn btn-icon js-sweetalert"
                                    title="Delete"
                                >
                                    <i className="fa fa-trash-o text-danger"></i>
                                </button>
                            );
                        value['action'] = buttons.length > 0 ? buttons : '-';
                        return value;
                    });

                    if (authPermissions.includes('Category-read')) {
                        setCategoryState(categoriesData);
                    } else {
                        setCategoryState([]);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    reject(error);
                    errorToast(error.message);
                });
        });
    };

    const addLink = authPermissions.includes('Category-create') === true ? '/admin/categories/add' : '';

    return (
        <div>
            <TopNavBar links={{ list: '/admin/categories', add: addLink }} />
            <div className="section-body ">
                <div className="container-fluid">
                    <div className="tab-content pt-3">
                        <div
                            className="tab-pane fade show active"
                            id="category-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-header">
                                    {/* <h3 className="card-title">CATEGORY LIST</h3> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={categoryState}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;
