import React from 'react';
import BannerImage from './BannerImage';
import PlantGrowth from './PlantGrowth';
import Products from './Products';
import CoreIdea from './CoreIdea';
import Blogpost from './BlogPost';
import NavigationSection from './NavigationSection';

function Home(props) {
 
  const bannerValues = {
    mainHeading: "Your reliable organic fertilizers source",
    bannerImage: "./assets/images/home/home page banner1 2.webp",
    boxes: [
      {
        id: 1,
        heading: "Liquid Fertilizers",
        image: "/assets/images/home/Group 236.webp",
        backgroundColor: "box-1"
      },
      {
        id: 2,
        heading: "Solid Fertilizers",
        image: "/assets/images/home/Group 237.webp",
        backgroundColor: "box-2"
      },
      {
        id: 3,
        heading: "We're Minfert",
        image: "/assets/images/home/Group 238.webp",
        backgroundColor: "box-3"
      },
      {
        id: 4,
        heading: "Dealers and Distributors",
        image: "/assets/images/home/Group 239.webp",
        backgroundColor: "box-4"
      },
    ]

  }

  const plantGrowthValues = {
    mainHeading: 'Plant Growth Stimulator That Gives Astonishing Results',
    leafImage: './assets/images/home/leaf-1-1 1.webp',
    mainContent: '<p>The plant food mnufacturer MINFERT is a new venture of <a href="https://www.20microns.com/" className={styles.link}>20 Microns Limited </a>. It comes from a state of the art research centre and technical expertise in the field of farming and offers a range of <a href="https://minfert.in/geocare/" className={styles.link}>mineral-based plant food</a>, insecticides, and <a href="https://minfert.in/humicrons/" className={styles.link}>soil conditioners</a> to gain better yield and quality crops. In today’s world, harmful and toxic synthetic chemicals that come from chemical fertilizers are affecting the human lives drastically. To overcome these challenges, 20 Microns has taken the initiative to develop an alternative solution towards organic farming, crop protection, and landscape management so that we can contribute to the improvement of people’s lives with better farming solutions. Our range of plant growth stimulators has been recreated to provide a better natural solution for the agriculture, plant growth & crop protection industry. Team Minfert</p>',

    plantInformation: [
      {
        content1: {
          subHeading1: 'Lorem ipsum',
          subContent1: 'Unlike most fertilizers which dissolve immediately in water, Minfert plant food being in pallet form dissolves slowly and serves as food for the crops, which they can consume little by little every day,like most other living creatures.',
        },

        image1: './assets/images/home/Mask group.webp',

        content2: {
          subHeading2: 'Lorem ipsum',
          subContent2: 'Unlike most fertilizers which dissolve immediately in water, Minfert plant food being in pallet form dissolves slowly and serves as food for the crops, which they can consume little by little every day,like most other living creatures.',
        },
      },

      {
        image1: './assets/images/home/Rectangle 101.webp',
        content1: {
          subHeading1: 'Lorem ipsum',
          subContent1: 'Unlike most fertilizers which dissolve immediately in water, Minfert plant food being in pallet form dissolves slowly and serves as food for the crops, which they can consume little by little every day,like most other living creatures.',
        },
        image2: './assets/images/home/Mask group-1.webp'
      },

      {
        content1: {
          subHeading1: 'Lorem ipsum',
          subContent1: 'Unlike most fertilizers which dissolve immediately in water, Minfert plant food being in pallet form dissolves slowly and serves as food for the crops, which they can consume little by little every day,like most other living creatures.',
        },

        image1: './assets/images/home/Mask group.webp',

        content2: {
          subHeading2: 'Lorem ipsum',
          subContent2: 'Unlike most fertilizers which dissolve immediately in water, Minfert plant food being in pallet form dissolves slowly and serves as food for the crops, which they can consume little by little every day,like most other living creatures.',
        },
      },

    ],

  }

  const blogValues = {
    mainHeading: "Blog Post",
    currentPage : 'home',
    mainContent: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    viewMoreBlogBtn: 'View More Blogs',
    posts: [
      {
        heading: "Lorem Ipsum is simply dummy text",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        readMore: "Read more",
        image: "./assets/images/home/Rectangle 29.webp",
        writerName: "Writer name",
        writerImage: "./assets/images/home/Ellipse 28.webp",
      },

      {
        heading: "Lorem Ipsum is simply dummy text",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        readMore: "Read more",
        image: "./assets/images/home/Rectangle 33.webp",
        writerName: "Writer name",
        writerImage: "./assets/images/home/Ellipse 28.webp",
      },

      {
        heading: "Lorem Ipsum is simply dummy text",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        readMore: "Read more",
        image: "./assets/images/home/Rectangle 34.webp",
        writerName: "Writer name",
        writerImage: "./assets/images/home/Ellipse 28.webp",
      },

    ]

  }

  const coreValues = {
    backgroundImage: "./assets/images/home/Group 244.webp",
    leafImage: "./assets/images/home/leaf-1-1 1.webp",
    mainHeading: "The Core Idea of Minfert",

    card: [
      {
        heading: 'COST SAVING',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        icon: 'HiCurrencyRupee'
      },
      {
        heading: 'YIELD QUANTITY',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        icon: 'TbTarget'

      },
      {
        heading: 'PELLET FORM',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        icon: 'FaDiceD6'
      },
      {
        heading: 'ECO-FRIENDLY',
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        icon: 'FaHeart'
      },

    ]
  }

 
  return (
    <>
      <BannerImage bannerValues={props.pageData.bannerValues} />
      <PlantGrowth plantValues={props.pageData.plantGrowthValues} />
      <NavigationSection initialValues={props.pageData.navigation} />
      <Products />
      <CoreIdea initialValues={props.pageData.coreValues} />
      <Blogpost initialValues={props.pageData.blogValues} />
    </>
  )
}

export default React.memo(Home) // Memoize the Home

