import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import TopNavBar from '../../layouts/TopNavBar';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addProductStyle.module.css';
import { errorToast } from '../../common/Toaster';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import JoditEditor from 'jodit-react';
// import { Editor } from "primereact/editor";
import DOMPurify from 'dompurify';
//Validation rules
const validationRules = {
  name: Yup.string()
    .max(120, 'Name must not exceed 120 characters')
    .required('Name is required'),
  parent_id: Yup.string().required('Parent Category is required'),
  slug: Yup.string()
    .max(120, 'Slug must not exceed 120 characters')
    .required('Slug is required'),
  short_description: Yup.string().required('Excerpt is required'),
  description: Yup.string().required('Description is required'),
  // meta_title: Yup.string().required("Meta Title is required"),
  // meta_keywords: Yup.string().required("IMeta Keywords is required"),
  // meta_description: Yup.string().required("Meta Description is required"),
  // structured_data: Yup.string().required("Schema is required"),
  // tableData: Yup.array().of(
  //   Yup.object().shape({
  //     product_image: Yup.mixed()
  //     .required("Product Image is required")
  //     .test('fileFormat', 'Invalid file format', (value) => {
  //       if (!value) return true; // Allow empty values to be handled by the required() rule
  //       const acceptedFormats = ['image/jpeg', 'image/png', 'image/gif','image/jpg','image/svg'];
  //       return acceptedFormats.includes(value.type)}),
  //     alt_title: Yup.string().required("Image Alt is required"),
  //   })
  // ),
};

const AddProduct = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [parentCategories, setParentCategories] = useState([]);
  const [tableData, setTableData] = useState([
    {
      product_image: '',
      alt_title: '',
      product_pdf: '',
    },
  ]);

  //Get Category List
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('categories/get')
      .then((parentCat) => {
        setLoaderCheck(false);
        setParentCategories(parentCat?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, []);
  const config = {
    controls: {
      font: {
        list: {
          'var(--alice)': 'Alice',
        }
      }
    }
  }; 
  //Function to store product
  const formik = useFormik({
    initialValues: {
      name: '',
      parent_id: '',
      slug: '',
      types: '',
      forms: '',
      short_description: '',
      sequence: 0,
      description: '',
      product_image: [],
      alt_title: [],
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
      structured_data: '',
      product_pdf: [],
      amazon_link: '',
      flipkart_link: '',
    },
  
  

    validationSchema: Yup.object(validationRules),
    onSubmit: (values) => {
      var formData = new FormData();
      tableData.map((value, index) => {
        formData.append('product_image', value.product_image);
        formData.append('alt_title', value.alt_title);
        formData.append('product_pdf', value.product_pdf);
        return true;
      });
      formData.append('name', values.name);
      formData.append('parent_id', values.parent_id);
      formData.append('slug', values.slug);
      formData.append('types', values.types);
      formData.append('forms', values.forms);
      formData.append('short_description', values.short_description);
      formData.append('sequence', values.sequence);
      formData.append('description', values.description);
      formData.append('meta_title', values.meta_title);
      formData.append('meta_keywords', values.meta_keywords);
      formData.append('meta_description', values.meta_description);
      formData.append('structured_data', values.structured_data);
      formData.append('amazon_link', values.amazon_link);
      formData.append('flipkart_link', values.flipkart_link);
      setLoaderCheck(true);
      axiosPrivate
        .post('products/create', formData)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: 'New product created successfully' });
          navigate(
            `/admin/products/variants/${response?.data?.data?.insertId}`
          );
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.data.errors.length === undefined) {
              errorToast(error.response.data.errors.msg);
            } else {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                errorToast(error.response.data.errors[i].msg);
              }
            }
          }
        });
    },
  });
  
  //Function to add new product image row
  const addNewRow = (eve) => {
    eve.preventDefault();
    setTableData([
      ...tableData,
      {
        product_image: '',
        alt_title: '',
      },
    ]);
  };

  //Function to remove product image row
  const removeRow = (index, eve) => {
    eve.preventDefault();
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, i) => i !== index));
    }
    return true;
  };
  return (
    <>
      <TopNavBar
        links={{ list: '/admin/products', add: '/admin/products/add' }}
      />
      <div className='section-body'>
        <div className='container-fluid'>
          <div className='tab-content'>
            <form onSubmit={formik.handleSubmit}>
              <div
                className='tab-pane fade show active'
                id='product-add'
                role='tabpanel'
              >
                <div className='card'>
                  <div className='card-header'>
                    <strong>Add Product</strong>
                  </div>
                  <div className='card-body'>
                    <div className='row clearfix'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='name'
                            name='name'
                            type='text'
                            className='form-control'
                            placeholder='Name *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Slug <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='slug'
                            name='slug'
                            type='text'
                            className='form-control'
                            placeholder='Slug *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Parent Category{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <select
                            className='form-control'
                            id='parent_id'
                            name='parent_id'
                            onChange={(e) =>
                              formik.setFieldValue('parent_id', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.parent_id}
                          >
                            <option>Select Parent Category</option>
                            {parentCategories.map((category, key) => (
                              <option
                                key={key + category.name}
                                value={category.id}
                              >
                                {category.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.parent_id &&
                          formik.errors.parent_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.parent_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Type <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='types'
                            name='types'
                            type='text'
                            className='form-control'
                            placeholder='Types *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.types}
                          />
                          {formik.touched.types && formik.errors.types ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.types}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            About Product{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='forms'
                            name='forms'
                            type='text'
                            className='form-control'
                            placeholder='Forms *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                          />
                          {formik.touched.forms && formik.errors.forms ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.forms}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Sequence <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='sequence'
                            name='sequence'
                            type='number'
                            className='form-control'
                            placeholder='Sequence *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sequence}
                          />
                          {formik.touched.sequence && formik.errors.sequence ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.sequence}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Short Description/Usages{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <JoditEditor config={config}
                            id='short_description'
                            name='short_description'
                            type='text'
                            className='form-control'
                            placeholder='Excerpt *'
                            onChange={(newContent) =>
                              formik.setFieldValue(
                                `short_description`,
                                newContent
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.short_description}
                          />
                          {formik.touched.short_description &&
                          formik.errors.short_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.short_description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Description <span style={{ color: 'red' }}>*</span>
                          </label>
                          <JoditEditor config={config}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            id='description'
                            name='description'
                            onChange={(newContent) =>
                              formik.setFieldValue(
                                `description`,
                                newContent
                              )
                            }
                            style={{ height: '200px' }}
                          />

                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>Meta Title</label>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='Meta Title *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_title}
                          />
                          {formik.touched.meta_title &&
                          formik.errors.meta_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_title}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>Meta Keywords </label>
                          <input
                            id='meta_keywords'
                            name='meta_keywords'
                            type='text'
                            className='form-control'
                            placeholder='Meta Keywords *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_keywords}
                          />
                          {formik.touched.meta_keywords &&
                          formik.errors.meta_keywords ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_keywords}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Meta Description{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='Meta Description *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_description}
                          />
                          {formik.touched.meta_description &&
                          formik.errors.meta_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Schema <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='structured_data'
                            name='structured_data'
                            type='text'
                            className='form-control'
                            placeholder='Schema *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.structured_data}
                          />
                          {formik.touched.structured_data &&
                          formik.errors.structured_data ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.structured_data}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Product PDF <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='product_pdf'
                            name='product_pdf'
                            type='file'
                            className='form-control'
                            onChange={(event) => {
                              setTableData((prevData) =>
                                prevData.map((data, i) =>
                                  i === 0
                                    ? {
                                        ...data,
                                        product_pdf: event.target.files[0],
                                      }
                                    : data
                                )
                              );
                              formik.handleChange(event);
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Amazon_link <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='amazon_link'
                            name='amazon_link'
                            type='text'
                            className='form-control'
                            placeholder='amazon_link *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amazon_link}
                          />
                          {formik.touched.amazon_link &&
                          formik.errors.amazon_link ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.amazon_link}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Flipkart_link{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='flipkart_link'
                            name='flipkart_link'
                            type='text'
                            className='form-control'
                            placeholder='flipkart_link *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.flipkart_link}
                          />
                          {formik.touched.flipkart_link &&
                          formik.errors.flipkart_link ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.flipkart_link}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-12'>
                        {/* table */}
                        <div className={`card-body ${classes.tableWrapper}`}>
                          <table
                            className='table table-hover'
                            style={{ width: '100%' }}
                          >
                            <thead>
                              {/* table heading */}
                              <tr>
                                <th>
                                  <label>
                                    Product Image
                                    <span style={{ color: 'red' }}>*</span>
                                  </label>
                                </th>
                                <th>
                                  <label>
                                    Image Alt
                                    <span style={{ color: 'red' }}>*</span>
                                  </label>
                                </th>
                                <th>
                                  <label>
                                    {/* Action:<span className="required">*</span> */}
                                  </label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((item, index) => (
                                <tr key={index}>
                                  {/* Product Image */}
                                  <td>
                                    <input
                                      id={`product_image[${index}]`}
                                      type='file'
                                      className='form-control'
                                      name={`tableData[${index}].product_image`}
                                      onChange={(event) => {
                                        setTableData((prevData) =>
                                          prevData.map((data, i) =>
                                            i === index
                                              ? {
                                                  ...data,
                                                  product_image:
                                                    event.target.files[0],
                                                }
                                              : data
                                          )
                                        );
                                        formik.handleChange(event); // Call handleChange with the event
                                      }}
                                    />
                                    {formik.touched.product_image &&
                                    formik.errors.product_image ? (
                                      <div className={classes.invalidDataError}>
                                        {formik.errors.product_image}
                                      </div>
                                    ) : null}
                                  </td>
                                  {/* alt_title */}
                                  <td>
                                    <input
                                      id={`alt_title[${index}]`}
                                      type='text'
                                      className='form-control'
                                      name={`tableData[${index}].alt_title`}
                                      value={item.alt_title}
                                      onChange={(event) => {
                                        setTableData((prevData) =>
                                          prevData.map((data, i) =>
                                            i === index
                                              ? {
                                                  ...data,
                                                  alt_title: event.target.value,
                                                }
                                              : data
                                          )
                                        );
                                        formik.handleChange(event);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className={classes.actionBtns}>
                                      <button
                                        className={`btn ${classes.addBtn}`}
                                        onClick={addNewRow}
                                      >
                                        <GrAddCircle />
                                      </button>
                                      {tableData.length > 1 && (
                                        <button
                                          className={`btn ${classes.removeBtn}`}
                                          onClick={(event) =>
                                            removeRow(index, event)
                                          }
                                        >
                                          <GrSubtractCircle />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='col-12  text-right'>
                        <hr className='mt-4' />
                        <button
                          type='button'
                          id='button_1'
                          className='btn btn-secondary mx-1'
                          data-dismiss='modal'
                          onClick={() => navigate('/admin/products')}
                        >
                          CLOSE
                        </button>
                        {authPermissions.includes('Product-create') && (
                          <button
                            type='submit'
                            id='button_2'
                            className='btn btn-primary'
                          >
                            SUBMIT
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
