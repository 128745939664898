import React from 'react';
import styles from './CoreIdea.module.css';
import Icon from './Icon';
import { Link } from 'react-router-dom';

function NavigationSection({ initialValues }) {
  const hoverCardStyle = {
    backgroundSize: 'cover',
    borderRadius: '20px',
    justifyContent: 'end',
    transition: 'transform 0.2s',
  };

  // const handleCardHover = (event) => {
  //   // Apply the transform effect for scaling
  //   event.target.style.transform = 'scale(1.1)';

  //   // Apply the opacity and background color changes

  //   event.target.style.backgroundColor = '';
  // };

  // const handleCardHoverExit = (event) => {
  //   event.target.style.transform = 'scale(1)';
  //   event.target.style.backgroundColor = 'var(--mustard)';
  // };

  return (
    <div className={styles.container}>
      <img
        src='./assets/images/home/Group 244.webp'
        alt='Background'
        loading='lazy'
        className={styles.background}
      />
      <div className={styles.headingContainer}>
        <h2 className={styles.mainHeading}>
          {initialValues?.mainHeading}
          <img
            src={initialValues?.leafImage}
            alt='Leaf'
            loading='lazy'
            className={styles.leaf}
          />
        </h2>
      </div>
      <div className={styles.coreContainer}>
        {initialValues?.card?.map((element, index) => (
          <div
            className={styles.coreIdea}
            key={index}
            style={{
              background: `url(${element?.image})`,
              backgroundSize: 'cover',
              borderRadius: '5px',
              justifyContent: 'end',
            }}
            // onMouseEnter={handleCardHover}
            // onMouseLeave={handleCardHoverExit}
          >
            <Link to={element.url}>
              <button className={styles.btn}>{element?.heading}</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(NavigationSection); // Memoize the NavigationSection;
