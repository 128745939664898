import React, { useEffect, useState, useContext } from "react";
import styles from "../about-template/AboutPageTemplate.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useParams, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";
import TopNavBar from "../../backend/layouts/TopNavBar";
import { WithContext as ReactTags } from 'react-tag-input';
function AddDistributorTemplate() {
  const { authPermissions } = useContext(AuthContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [submitButton, setSubmitButton] = useState("Submit");
  const [pageName, setPagename] = useState("Add Distributor Template");
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const aboutTemplateValidation = Yup.object({
    pageName: Yup.string().required("Page Name is Required"),
    slug: Yup.string().required("Slug is Required"),
    mainImage: Yup.mixed().required("Banner Image is Required"),
    maincontent: Yup.string().required("Main content is Required"),
    experience: Yup.number().required("Experience is Required"),
    dealer: Yup.number().required("Dealer Count is Required"),
    manufacture: Yup.number().required("Manufacture count is Required"),
  });
  const initialValues = {
    pageName: "",
    slug: "",
    mainImage: "",
    maincontent: "",
    experience: "",
    dealer: "",
    manufacture: "",
    meta_title: '',
    meta_description: '',
    keywords: [],
    tags: [],
    og_title: '',
    og_description: '',
    og_image: '',
    og_alt: '',
    canonical_tags: '',
  };


  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const handleDeleteKeywords = (i) => {
    setKeywords(keywords.filter((tag, index) => index !== i));
  };

  const handleAdditionKeywords = (keyword) => {
    setKeywords([...keywords, keyword]);
  };

  const handleDragKeywords = (keyword, currPos, newPos) => {
    const newkeywords = keywords.slice();

    newkeywords.splice(currPos, 1);
    newkeywords.splice(newPos, 0, keyword);

    setKeywords(newkeywords);
  };

  const handleDeleteTags = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAdditionTags = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDragTags = (tags, currPos, newPos) => {
    const newtags = tags.slice();

    newtags.splice(currPos, 1);
    newtags.splice(newPos, 0, tags);

    setTags(newtags);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  

  // page name onBlur method
  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name === "pageName") {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
      setFieldValue("slug", slug);
    } else {
      handleBlur(event);
    }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: aboutTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      values.keywords = keywords;
      values.tags = tags;
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "distributor",
      };
      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put("/pages/update/" + id, formData)
          .then((res) => {
            setLoaderCheck(false);
            showSuccessToast("Category Updated Successfully");
            navigate("/admin/distributor");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == "array") {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              showErrorToast(error.response.data.errors.msg);
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post("pages/create", formData)
          .then((res) => {
            setLoaderCheck(false);
            navigate("/admin/distributor");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == "array") {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              showErrorToast(error.response.data.errors.msg);
            }
          });
      }
    },
  });

  //  Image upload method
  const onFileUpload = (event) => {
    console.log(event.target.files[0], event.target.name);
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        console.log(res, event.target.name);
        setFieldValue(event.target.name, res.data.data);
      })
      .catch((error) => {
        setFieldValue(event.target.name, "");
      });
    handleChange(event);
  };

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get("pages/" + id)
        .then((res) => {
          setLoaderCheck(false);
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });

          setKeywords(parsedValues?.keywords);
          setTags(parsedValues?.tags);
          setPagename("Edit Distributor Template");
          setLoaderCheck(false);
          setSubmitButton("Update");
        })

        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    }
  }, []);
  const addLink =
    authPermissions.includes("Distributor Template-create") === true
      ? "/admin/distributor/add"
      : "";

  return (
    <>
      <TopNavBar links={{ list: "/admin/distributor", add: addLink }} />
      <div className={styles.templateContainer}>
        <h1 className={styles.mainHeading}>{pageName}</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.mainFormContainer}>
            <div className="row">
              {/* Page name field */}
              <div className=" my-2 col-12 col-lg-6">
                <label
                  htmlFor="pagename"
                  className={` ${styles.fieldName} form-label`}
                >
                  Page name<span className={styles.red}>*</span>
                </label>
                <input
                  name="pageName"
                  id="pageName"
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  value={values.pageName}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                />
                {touched.pageName && errors.pageName ? (
                  <div className={styles.red}>{errors.pageName}</div>
                ) : null}
              </div>

              {/* slug field */}
              <div className=" my-2 col-12 col-lg-6">
                <label
                  htmlFor="slug"
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name="slug"
                  id="slug"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.slug}
                />
                {touched.slug && errors.slug ? (
                  <div className={styles.red}>{errors.slug}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.mainFormContainer}>
            <div className="row">
              {/* Main content field */}
              <div className=" my-2 col-12 col-lg-4">
                <label
                  htmlFor="maincontent"
                  className={` ${styles.fieldName} form-label`}
                >
                  Main Content<span className={styles.red}>*</span>
                </label>
                <input
                  name="maincontent"
                  id="maincontent"
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  value={values.maincontent}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                />
                {touched.maincontent && errors.maincontent ? (
                  <div className={styles.red}>{errors.maincontent}</div>
                ) : null}
              </div>

              {/* experience field */}
              <div className=" my-2 col-12 col-lg-4">
                <label
                  htmlFor="experience"
                  className={` ${styles.fieldName} form-label`}
                >
                  Experience Year<span className={styles.red}>*</span>
                </label>
                <input
                  name="experience"
                  id="experience"
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.experience}
                />
                {touched.experience && errors.experience ? (
                  <div className={styles.red}>{errors.experience}</div>
                ) : null}
              </div>
              {/* banner image */}
              <div className=" my-2 col-12 col-lg-4">
                <label
                  htmlFor="mainImage"
                  className={` ${styles.fieldName} form-label`}
                >
                  Main image<span className={styles.red}>*</span>
                </label>

                <input
                  id="mainImage"
                  name="mainImage"
                  type="file"
                  className="form-control"
                  onChange={onFileUpload}
                  onBlur={handleBlur}
                />
                {typeof values.mainImage === "string" &&
                values.mainImage !== "" ? (
                  <img
                    className={styles.mainImage}
                    src={values.mainImage}
                    alt="About"
                    loading="lazy"
                  />
                ) : (
                  ""
                )}
                {touched.mainImage && errors.mainImage ? (
                  <div className={styles.red}>{errors.mainImage}</div>
                ) : null}
              </div>
              {/* dealer field */}
              <div className=" my-2 col-12 col-lg-6">
                <label
                  htmlFor="dealer"
                  className={` ${styles.fieldName} form-label`}
                >
                  Dealer Count<span className={styles.red}>*</span>
                </label>
                <input
                  name="dealer"
                  id="dealer"
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dealer}
                />
                {touched.dealer && errors.dealer ? (
                  <div className={styles.red}>{errors.dealer}</div>
                ) : null}
              </div>

              {/* Manufacture field */}
              <div className=" my-2 col-12 col-lg-6">
                <label
                  htmlFor="manufacture"
                  className={` ${styles.fieldName} form-label`}
                >
                  Manufacture Factories Count
                  <span className={styles.red}>*</span>
                </label>
                <input
                  name="manufacture"
                  id="manufacture"
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.manufacture}
                />
                {touched.manufacture && errors.manufacture ? (
                  <div className={styles.red}>{errors.manufacture}</div>
                ) : null}
              </div>
            </div>
          </div>
          <h5>Meta Fields:</h5>
          <div
            className={styles.mainFormContainer}
            style={{ paddingRight: "3%" }}
          >
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="meta_title"
                    name="meta_title"
                    type="text"
                    className="form-control"
                    placeholder="meta title *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.meta_title}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="meta_description"
                    name="meta_description"
                    type="text"
                    className="form-control"
                    placeholder="meta description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.meta_description}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12" style={{ marginLeft: "1%" }}>
                <h5 style={{ marginTop: "1%" }}>Keywords:</h5>
                <ReactTags
                  tags={keywords}
                  id="keywords"
                  name="keywords"
                  delimiters={delimiters}
                  handleDelete={handleDeleteKeywords}
                  handleAddition={handleAdditionKeywords}
                  handleDrag={handleDragKeywords}
                  inputFieldPosition="bottom"
                  autocomplete
                  handleInputChange={handleChange}
                  handleInputBlur={handleBlur}
                  value={values?.keywords}
                  placeholder="Enter keywords"
                />
              </div>
              <div
                className="col-md-12 col-sm-12 "
                style={{ marginLeft: "1%" }}
              >
                <h5 style={{ marginTop: "1%" }}>Tags:</h5>
                <ReactTags
                  tags={tags}
                  id="tags"
                  name="tags"
                  delimiters={delimiters}
                  handleDelete={handleDeleteTags}
                  handleAddition={handleAdditionTags}
                  handleDrag={handleDragTags}
                  inputFieldPosition="bottom"
                  autocomplete
                  handleInputChange={handleChange}
                  handleInputBlur={handleBlur}
                  value={values?.tags}
                  placeholder="Enter tags"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="og_title"
                    name="og_title"
                    type="text"
                    className="form-control"
                    placeholder="Og title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.og_title}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="og_description"
                    name="og_description"
                    type="text"
                    className="form-control"
                    placeholder="Og description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.og_description}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="og_image"
                    name="og_image"
                    type="file"
                    className="form-control"
                    placeholder="Og Image"
                    onChange={onFileUpload}
                    accept="image/*"
                    onBlur={handleBlur}
                    style={{ margin: "2%" }}
                  />
                  {typeof values?.og_image === "string" &&
                  values?.og_image !== "" ? (
                    <img
                      className={styles.mainImage}
                      src={values?.og_image}
                      alt="Og Image Alt"
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="og_alt"
                    name="og_alt"
                    type="text"
                    className="form-control"
                    placeholder="Og alt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.og_alt}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <input
                    id="canonical_tags"
                    name="canonical_tags"
                    type="text"
                    className="form-control"
                    placeholder="Canonical"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.canonical_tags}
                    style={{ margin: "2%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              style={{ color: "white" }}
              className={`${styles.closeButton} btn`}
              id="button_1"
              onClick={() => navigate("/admin/distributor")}
            >
              Close
            </button>
            {authPermissions.includes("Distributor Template-create") && (
              <button
                type="submit"
                id="button_2"
                className={`${styles.submitButton} btn`}
              >
                {submitButton}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
export default AddDistributorTemplate;
