import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import classes from "../Blog/Blog.module.css"; // Make sure to specify the correct CSS file path
import { showSuccessToast } from "../../../../Toster";

const EditTestimonials = () => {
  let { id } = useParams();
  const { setAlertremark } = useContext(AlertContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);


  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`testimonials/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        const remarkData = response.data?.data;
        console.log(remarkData)
        // Set values in formik for each input field
        formik.setValues({...remarkData})
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          console.log(error.response.status);
        }
      });
  }, [id]);

  const initialValues = {
    status: "",
    name: "",
    remark:"",
    location:'',
    product_name:"",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setLoaderCheck(true);
      axiosPrivate
        .put(`testimonials/${id}`, values)
        .then((response) => {
          setLoaderCheck(false);
          navigate("/admin/testimonials");
          showSuccessToast("Status Updated")
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.status !== 200) {
              for (let i = 0; i < error.response?.data?.errors?.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  // enquairy get by id
  

  return (
    <div className="section-body">
      <div className="container-fluid">
        {/* header breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit Testimonials</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>

            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/testimonials">Edit Testimonials</Link>
            </li>
          </ol>
        </div>

        <div className="tab-content">
          <form onSubmit={formik.handleSubmit}>
            <div
             
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    {/* Name */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Name:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name *"
                          value={formik.values.name}
                          readOnly
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/*product_name */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                       Product Name:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="product_name"
                          name="product_name"
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_name}
                          readOnly
                        />
                        {formik.touched.product_name && formik.errors.product_name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.product_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* location */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Location:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="location"
                          name="location"
                          type="text"
                          className="form-control"
                          placeholder="location ID *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.location}
                          readOnly
                        />
                        {formik.touched.location && formik.errors.location ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* remark */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Remark:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="remark"
                          name="remark"
                          type="text"
                          className="form-control"
                          placeholder="remark"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.remark}
                          readOnly
                        />
                        {formik.touched.remark && formik.errors.remark ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.remark}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Status:<span className="required"></span>
                        </label>
                        <select
                          className="form-control show-tick"
                          id="status"
                          name="status"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.status}
                        >
                          <option>Select Status</option>
                          <option value="Pending">Pending</option>
                          <option value="Published">Publish</option>
                          <option value="Rejected">Reject</option>
                        </select>
                        {formik.touched.status && formik.errors.status ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.status}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Close button */}
                    <div className="col-12 text-right">
                      <button
                        type="button"
                        id="button_1"
                        className="btn btn-secondary mx-1"
                        onClick={() => navigate("/admin/testimonials")}
                      >
                        CLOSE
                      </button>

                      <button
                        type="submit"
                        id="button_2"
                        className="btn btn-primary float-right"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTestimonials;
