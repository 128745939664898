import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../../Toster';
import AlertContext from '../../../../context/Alert/AlertContext';
import AuthContext from '../../../../context/Auth/AuthContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './Gallery.module.css';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import TopNavBar from '../../layouts/TopNavBar';

// validation
const validationRules = {
  title: Yup.string()
    .max(120, 'Name must not exceed 120 characters')
    .required('Title is required'),
  alt: Yup.string().required('Alt is required'),
  // media: Yup.string().required("media is required"),
  //  media_for: Yup.string().required("'Media For' is required"),
};

const EditGallery = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const { setLoaderCheck } = useContext(LoaderContext);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get(`crop-category`)
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {
        showErrorToast(error.message);
      });
  }, []);

  //  season get by id
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`product-gallery/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        if (response.data.code == 200) {
          formik.setValues(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, []);

  // formik (send data)

  const formik = useFormik({
    initialValues: {
      title: '',
      alt: '',
      image: '',
      media_for: '',
      media_type: '',
      crop_category: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      let formData = new FormData();
      formData.append('title', values.title);
      formData.append('media_type', values.media_type);
      formData.append('alt', values.alt);
      formData.append('image', values.image);
      formData.append('media_for', values.media_for);
      formData.append('crop_category', values.crop_category);
      setLoaderCheck(true);
      axiosPrivate
        .put(`product-gallery/update/${id}`, formData)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: 'Media updated successfully' });
          navigate('/admin/gallery');
        })
        .catch((error) => {
          setLoaderCheck(false);
          showErrorToast(error);
        });
    },
  });

  return (
    <>
      <TopNavBar
        links={{ list: '/admin/gallery', add: '/admin/gallery/add' }}
      />
      <div className='section-body '>
        <div className='container-fluid'>
          <div className='tab-content '>
            <form onSubmit={formik.handleSubmit}>
              <div
                className='tab-pane fade show active'
                id='user-add'
                role='tabpanel'
              >
                <div className='card'>
                  <div className='card-header'>
                    <strong>Edit Gallery</strong>
                  </div>
                  <div className='card-body'>
                    <div className='row clearfix'>
                      {/* title */}
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Title:<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='title'
                            name='title'
                            type='text'
                            className='form-control'
                            placeholder='Title '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* alt */}

                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Alt :<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <input
                            id='alt'
                            name='alt'
                            type='text'
                            className='form-control'
                            placeholder='Alt '
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alt}
                          />
                          {formik.touched.alt && formik.errors.alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* medias*/}

                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                        Media:<span className='required'>*</span>
                        </label>

                        <div className='form-group d-flex align-items-center'>
                          <input
                            id='image'
                            name='image'
                            type='file'
                            className='form-control'
                            placeholder='images'
                            onChange={(e) =>
                              formik.setFieldValue(
                                'image',
                                e.currentTarget.files[0]
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.image && formik.errors.image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.image}
                            </div>
                          ) : null}
                        </div>
                        {typeof (
                          process.env.REACT_APP_COMMON_FILE_URL +
                            'product-gallery/' +
                            formik.values.media ===
                          'string'
                        ) &&
                        process.env.REACT_APP_COMMON_FILE_URL +
                          'product-gallery/' +
                          formik.values.media !==
                          '' ? (
                          <img
                            className='mx-3'
                            src={
                              process.env.REACT_APP_COMMON_FILE_URL +
                              'product-gallery/' +
                              formik.values.media
                            }
                            style={{ width: '80px' }}
                            alt='galleryImg'
                            loading='lazy'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Media Type<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <select
                            id='media_type'
                            name='media_type'
                            className='form-control'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.media_type}
                          >
                            <option>Select Media</option>
                            <option value='image'>Image</option>
                            <option value='video'>Video</option>
                          </select>
                          {formik.touched.media_type &&
                          formik.errors.media_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.media_type}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label>
                          Media For<span className='required'>*</span>
                        </label>
                        <div className='form-group'>
                          <select
                            id='media_for'
                            name='media_for'
                            className='form-control'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.media_for}
                          >
                            <option value=''>Select Media</option>
                            <option value='Gallery'>Gallery</option>
                            <option value='Crop'>Crop</option>
                            <option value='Farmers Training'>
                              Farmers Training
                            </option>
                          </select>
                          {formik.touched.media_for &&
                          formik.errors.media_for ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.media_for}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {formik.values.media_for === 'Crop' && (
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                          <label>
                            Category:<span className='required'>*</span>
                          </label>
                          <div className='form-group'>
                            <select
                              id='crop_category'
                              name='crop_category'
                              className='form-control'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.crop_category}
                            >
                              <option value=''>Select Category</option>
                              {category.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      {/* update and close button */}

                      <div className='col-12 submit-btn text-right'>
                        <hr className='mt-4' />
                        <div className='table-responsive'>
                          <table className='table table-striped'></table>
                        </div>
                        <button
                          type='button'
                          id='button_1'
                          className='btn btn-secondary mx-1'
                          data-dismiss='modal'
                          onClick={() => navigate('/admin/gallery')}
                        >
                          Close
                        </button>
                        {authPermissions.includes('Gallery-update') && (
                          <button
                            type='submit'
                            id='button_2'
                            className='btn btn-primary'
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGallery;
