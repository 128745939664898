import { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';
import DataTable2 from '../../../../components/DataTable2';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import TopNavBar from '../../layouts/TopNavBar';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import AuthContext from '../../../../context/Auth/AuthContext';

const Orders = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const { authPermissions } = useContext(AuthContext);
	const [orderState, setOrderState] = useState([]);

	// headrs
	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{ name: 'ORDER ID', field: 'order_id', sortable: true, classKey: '' },

		{
			name: 'CUSTOMER NAME',
			field: 'bill_name',
			sortable: true,
			classKey: '',
		},
		{
			name: 'ORDER DATE',
			field: 'order_date',
			sortable: true,
			classKey: '',
		},

		{
			name: 'ORDER STATUS',
			field: 'order_status',
			sortable: true,
			classKey: '',
		},

		// {
		// 	name: 'PAYMENT STATUS',
		// 	field: 'payment_status',
		// 	sortable: true,
		// 	classKey: '',
		// },

		// {
		// 	name: 'SHIPPING MOBILE NO.',
		// 	field: 'ship_mobile',
		// 	sortable: true,
		// 	classKey: '',
		// },
		// {
		// 	name: 'TOTAL AMOUNT',
		// 	field: 'total_amount',
		// 	sortable: true,
		// 	classKey: '',
		// },

		
		// { name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ["bill_name"];

	// get All  ordrs lits api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get('orders', options)
			.then((order) => {
				setLoaderCheck(false);
				const orderData = order?.data?.data?.map((value,index) => {
					
					// let buttons = [];
					// if (true)
					// 	buttons.push(
					// 		<Link
					// 			key={`editButton##${key}`}
					// 			type="button"
					// 			to={`/admin/Ecom-orders/edit/${value.id}`}
					// 			className="btn btn-icon"
					// 			title="Edit"
					// 		>
					// 			<BiEdit style={{ color: 'green' }} size={18} />
					// 		</Link>
					// 	);

					// if (true)
					// 	buttons.push(
					// 		<Link
					// 			key={`viewButton##${key}`}
					// 			type="button"
					// 			to={`/admin/Ecom-orders/view/${value.id}`}
					// 			className="btn btn-icon"
					// 			title="View"
					// 		>
					// 			<GrView style={{ color: 'blue' }} size={18} />
					// 		</Link>
					// 	);

					// value['action'] = buttons.length > 0 ? buttons : '-';
					const formattedDate = new Date(value.order_date).toLocaleDateString(
						'en-US',
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						}
					);
					value["order_status"] = (value.order_status === "pending") ? 
					<button className='btn' style={{ color:"white",background:"orange", minWidth: "105px" }}>Pending</button> :
					(value.order_status === "confirmed") ?
					<button className='btn' style={{ color: 'white',background:"green", minWidth: "105px" }}>Completed</button> :
					(value.order_status === "cancel") ?
					<button className='btn' style={{ color: 'white' ,background:'red', minWidth: "105px"}}>Cancelled</button> :
					(value.order_status === "cancelled") ?
					<button className='btn' style={{ color: 'white' ,background:'red', minWidth: "105px"}}>Cancelled</button> :
					(value.order_status === "shipped") ?
					<button className='btn' style={{ color: 'white' ,background:'#646405', minWidth: "105px"}}>Shipped</button> :
					value.order_status;

					value["payment_status"] = (value.payment_status === "pending") ? 
					<button className='btn' style={{ color:"white",background:"orange", minWidth: "105px" }}>Pending</button> :
					(value.payment_status === "confirmed") ?
					<button className='btn' style={{ color: 'white',background:"green", minWidth: "105px" }}>Confirmed</button> :
					(value.payment_status === "cancel") ?
					<button className='btn' style={{ color: 'white' ,background:'red', minWidth: "105px"}}>Cancel</button> :
					value.payment_status;
					value["sr-no"]=index+1

					return {
						...value,
						order_date: formattedDate,

						order_id: (
							<Link
								to={`view/${value.order_id}`}
								style={{ textDecoration: 'underline' }}
							>
								{value.order_id}
							</Link>
						),
					};
				});

				if (authPermissions.includes('Order-read')) {
					setOrderState(orderData);
				} else {
					setOrderState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				console.log(error);
			})
	}, [authPermissions]);

	// alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
				<div className="card-header">
						<strong className="top-left-heading">Orders</strong>
						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Orders</li>
						</ol>
					</div>
					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="orders-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={orderState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Orders;
