import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addProductStyle.module.css';
import { errorToast, successToast } from '../../common/Toaster';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import SiteImage from '../../common/SiteImage';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { useContext } from 'react';

//Validation rules
const validationRules = {
    variant: Yup.string()
        .required('Variant is required'),
    sequence: Yup.number()
        .required('Sequence is required'),
    regular_price: Yup.number()
        .required('Regular Price is required'),
    sale_price: Yup.number()
        .required('Sale Price is required'),
    // gst: Yup.number()
    //     .required('GST is required'),
    // quantity: Yup.number()
    //     .required('Stock is required'),
    // sku: Yup.string()
    //     .required('SKU is required'),
};

const EditVariant = ({ isOpen, variantId, setModalShow }) => {
    const [regularPrice, setRegularPrice] = useState(0);
    const { setLoaderCheck } = useContext(LoaderContext);
    const [gst, setGst] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [tableData, setTableData] = useState([
        {
            image: '',
            alt_title: '',
            img_sequence:'',
        },
    ]);

    //Function to calculate sale price
    useEffect(() => {
        if (isOpen) {
            let price = regularPrice;
            if (gst > 0) {
                price = parseFloat(price) + parseFloat((price * gst) / 100);
            }
            if (discount > 0) {
                price = parseFloat(price) - parseFloat((price * discount) / 100);
            }
            formik.setFieldValue('sale_price', (Math.round(price * 100) / 100).toFixed(2));
        }
    }, [regularPrice, gst, discount]);

    //Function to get variant details
    useEffect(() => {
        if (isOpen) {
            setLoaderCheck(true);
            axiosPrivate
                .get(`product-variants/${variantId}`)
                .then((variants) => {
                    formik.setValues({ ...variants?.data?.data, product_id: variants?.data?.data?.product_id, id: variants?.data?.data?.id });
                    setRegularPrice(variants?.data?.data?.regular_price);
                    setGst(variants?.data?.data?.gst);
                    setDiscount(variants?.data?.data?.discount);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    // errorToast(error.message);
                });
        }
    }, [variantId, isOpen]);

    //Function to update product variant
    const formik = useFormik({
        initialValues: {
            product_id: '',
            id: '',
            variant: '',
            sequence: '',
            regular_price: '',
            sale_price: '',
            gst: 0,
            image: [],
            alt_title: [],
            img_sequence: [],
            warranty: 0,
            guarantee: 0,
            quantity: 0,
            sku: '',
        },
        validationSchema: Yup.object(validationRules),
        onSubmit: async (values) => {
            var formData = new FormData();
            tableData.map((value) => {
                formData.append("image", value.image);
                formData.append("alt_title", value.alt_title);
                formData.append("img_sequence", value.img_sequence);
                return true;
            });
            formData.append("id", values.id);
            formData.append("product_id", values.product_id);
            formData.append("variant", values.variant);
            formData.append("sequence", values.sequence);
            formData.append("regular_price", values.regular_price);
            formData.append("sale_price", values.sale_price);
            formData.append("gst", values.gst);
            formData.append("warranty", values.warranty);
            formData.append("guarantee", values.guarantee);
            formData.append("quantity", values.quantity);
            formData.append("sku", values.sku);
            formData.append("discount", values.discount);
            setLoaderCheck(true);
            axiosPrivate
            .post(`product-variants/update/${variantId}?_method=PUT`, formData)
            .then((response) => {
                    setLoaderCheck(false);
                    setModalShow(false);
                    setTableData([
                        {
                            image: '',
                            alt_title: '',
                            img_sequence: '',
                        },
                    ]);
                    successToast('Product variant update successfully');
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    if (error.response) {
                        if (error.response.status === 422) {
                            if (error.response.data.errors.length === undefined) {
                                errorToast(error.response.data.errors.msg);
                            } else {
                                for (let i = 0; i < error.response.data.errors.length; i++) {
                                    errorToast(error.response.data.errors[i].msg);
                                }
                            }
                        }
                    }
                });
        },
    });

    //Function to add new product image row
    const addNewRow = (eve) => {
        eve.preventDefault();
        setTableData([
            ...tableData,
            {
                image: '',
                alt_title: '',
                img_sequence: '',
            },
        ]);
    };

    //Function to remove product image row
    const removeRow = (index, eve) => {
        eve.preventDefault();
        if (tableData.length > 1) {
            setTableData(tableData.filter((_, i) => i !== index));
        }
        return true;
    };

    //Function to close modal
    const modalClose = async () => {
        setModalShow(false);
    }

    if (!isOpen) return null;
    return (
        <div className="modal fade show bd-example-modal-lg" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ paddingRight: "0px", display: "block", background: "rgba(0,0,0,0.4)" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Edit Product Variant</h5>
                        <button type="button" className="close" onClick={() => modalClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Variant <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="variant"
                                                    name="variant"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Variant Name *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.variant || ''}
                                                />
                                                {formik.touched.variant && formik.errors.variant ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.variant}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Sequence <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="sequence"
                                                    name="sequence"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Sequence *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.sequence || ''}
                                                />
                                                {formik.touched.sequence && formik.errors.sequence ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.sequence}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Quantity <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="quantity"
                                                    name="quantity"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Variant Stock *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.quantity || ''}
                                                />
                                                {formik.touched.quantity && formik.errors.quantity ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.quantity}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">SKU</label>
                                                <input
                                                    id="sku"
                                                    name="sku"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Variant SKU *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.sku || ''}
                                                />
                                                {formik.touched.sku && formik.errors.sku ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.sku}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Regular Price <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="regular_price"
                                                    name="regular_price"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Regular Price *"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setRegularPrice(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.regular_price || ''}
                                                />
                                                {formik.touched.regular_price && formik.errors.regular_price ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.regular_price}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">GST(%) <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="gst"
                                                    name="gst"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="GST(%) *"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setGst(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.gst || ''}
                                                />
                                                {formik.touched.gst && formik.errors.gst ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.gst}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Discount</label>
                                                <input
                                                    id="discount"
                                                    name="discount"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Discount(%)"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setDiscount(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discount || ''}
                                                />
                                                {formik.touched.discount && formik.errors.discount ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.discount}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Sale Price <span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    id="sale_price"
                                                    name="sale_price"
                                                    type="number"
                                                    readOnly
                                                    className="form-control"
                                                    placeholder="Sale Price *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.sale_price || ''}
                                                />
                                                {formik.touched.sale_price && formik.errors.sale_price ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.sale_price}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Warranty</label>
                                                <input
                                                    id="warranty"
                                                    name="warranty"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Warranty"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.warranty || 0}
                                                />
                                                {/* {formik.touched.warranty && formik.errors.warranty ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.warranty}
                                                    </div>
                                                ) : null} */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Guarantee</label>
                                                <input
                                                    id="guarantee"
                                                    name="guarantee"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Guarantee"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.guarantee || ''}
                                                />
                                                {/* {formik.touched.guarantee && formik.errors.guarantee ? (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.guarantee}
                                                    </div>
                                                ) : null} */}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            {/* table */}
                                            <div className={`card-body ${classes.tableWrapper}`}>
                                                <table
                                                    className="table table-hover"
                                                    style={{ width: '100%' }}
                                                >
                                                    <thead>
                                                        {/* table heading */}
                                                        <tr>
                                                            <th>
                                                                <label>
                                                                    Variant Image<span style={{ color: "red" }}>*</span>
                                                                </label>
                                                            </th>
                                                            <th>
                                                                <label>
                                                                    Image Alt<span style={{ color: "red" }}>*</span>
                                                                </label>
                                                            </th>
                                                            <th>
                                                                    <label>
                                                                        Image Seq<span style={{ color: "red" }}>*</span>
                                                                    </label>
                                                                </th>
                                                            <th>
                                                                <label>
                                                                    {/* Action:<span className="required">*</span> */}
                                                                </label>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((item, index) => (
                                                            <tr key={index}>
                                                                {/* Product Image */}
                                                                <td>
                                                                    <input
                                                                        id={`image[${index}]`}
                                                                        type="file"
                                                                        className="form-control"
                                                                        name={`tableData[${index}].image`}
                                                                        onChange={(event) => {
                                                                            setTableData((prevData) =>
                                                                                prevData.map((data, i) =>
                                                                                    i === index
                                                                                        ? { ...data, image: event.target.files[0] }
                                                                                        : data
                                                                                )
                                                                            );
                                                                            formik.handleChange(event); // Call handleChange with the event
                                                                        }}
                                                                    />
                                                                    {formik.touched.image && formik.errors.image ? (
                                                                        <div className={classes.invalidDataError}>
                                                                            {formik.errors.image}
                                                                        </div>
                                                                    ) : null}
                                                                </td>
                                                                {/* alt_title */}
                                                                <td>
                                                                    <input
                                                                        id={`alt_title[${index}]`}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={`tableData[${index}].alt_title`}
                                                                        value={item.alt_title}
                                                                        onChange={(event) => {
                                                                            setTableData((prevData) =>
                                                                                prevData.map((data, i) =>
                                                                                    i === index
                                                                                        ? { ...data, alt_title: event.target.value }
                                                                                        : data
                                                                                )
                                                                            );
                                                                            formik.handleChange(event);
                                                                        }}
                                                                    />
                                                                </td>
                                                                {/* img_sequence */}
                                                                <td>
                                                                        <input
                                                                            id={`img_sequence[${index}]`}
                                                                            type="number"
                                                                            className="form-control"
                                                                            name={`tableData[${index}].img_sequence`}
                                                                            value={item.img_sequence}
                                                                            onChange={(event) => {
                                                                                setTableData((prevData) =>
                                                                                    prevData.map((data, i) =>
                                                                                        i === index
                                                                                            ? { ...data, img_sequence: event.target.value }
                                                                                            : data
                                                                                    )
                                                                                );
                                                                                formik.handleChange(event);
                                                                            }}
                                                                        />
                                                                </td>
                                                            <td>
                                                                    <div className={classes.actionBtns}>
                                                                        <button
                                                                        className={`btn ${classes.addBtn}`}
                                                                            onClick={addNewRow}
                                                                        >
                                                                            <GrAddCircle />
                                                                        </button>
                                                                        {tableData.length > 1 && (
                                                                            <button
                                                                                className={`btn ${classes.removeBtn}`}
                                                                                onClick={(event) =>
                                                                                    removeRow(index, event)
                                                                                }
                                                                            >
                                                                                <GrSubtractCircle />
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <SiteImage id={variantId} baseUrl={`${process.env.REACT_APP_COMMON_FILE_URL}product-variants`} />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12  text-right">
                                            <hr className="mt-4" />
                                            <button
                                                type="button"
                                                id='button_1'
                                                className="btn btn-secondary mx-1"
                                                data-dismiss="modal"
                                                onClick={() => modalClose()}
                                            >
                                                CLOSE
                                            </button>
                                            <button type="submit" id='button_2' className="btn btn-primary ">
                                                UPDATE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditVariant