import React from 'react';
import {BsFillCalculatorFill} from 'react-icons/bs'
import {FaDiceD6} from 'react-icons/fa'
import {FaHeart} from 'react-icons/fa'
import {TbTarget} from 'react-icons/tb'

function Icon({ name }) {
    const icons = {
        BsFillCalculatorFill,
        FaDiceD6,
        FaHeart,
        TbTarget
    };

    const IconComponent = icons[name];

    return <IconComponent />;
}

export default Icon;