import { useFormik } from 'formik';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import TopNavBar from '../../layouts/TopNavBar';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addCategoryStyle.module.css';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { useEffect } from 'react';
import { errorToast } from '../../common/Toaster';

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	description: Yup.string()
		.required('Description is required'),

};

const AddCropCategory = () => {
	const {id}=useParams()
	const { setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const navigate = useNavigate();

	useEffect(()=>{
     if(id){
        axiosPrivate
            .get(`crop-category/${id}`)
            .then((res)=>{
				formik.setValues({...res?.data?.data})
			})
            .catch((error) => {
                errorToast(error.message);
            });
      }
	},[id])

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			setLoaderCheck(true);
			if(id){
				axiosPrivate
			.put(`crop-category/${id}`, values)
			.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: 'New category created successfully' });
					navigate('/admin/crop-categories');
					
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			}else{
				axiosPrivate
				.post('crop-category', values)
				.then((response) => {
						setLoaderCheck(false);
						setAlertMessage({ message: 'New category created successfully' });
						navigate('/admin/crop-categories');
						
					})
					.catch((error) => {
						setLoaderCheck(false);
						if (error.response) {
							if (error.response.status == 422) {
								for (let i = 0; i < error.response.data.errors.length; i++) {
									setFieldError(
										error.response.data.errors[i].param,
										error.response.data.errors[i].msg
									);
								}
							}
						}
					});
			}
			
		},
	});

	return (
		<div>
			<TopNavBar links={{ list: '/admin/crop-categories', add: '/admin/crop-categories/add' }} />
			<div className="section-body pt-1">
				<div className="container-fluid">
					<div className="tab-content pt-2">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="category-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<textarea
														id="description"
														name="description"
														type="text"
														className="form-control"
														placeholder="Description *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.description}
													/>
													{formik.touched.description && formik.errors.description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12  text-right">
												<hr className="mt-4" />
												<button
													type="button"
													id='button_1'
													className="btn btn-secondary mx-1"
													data-dismiss="modal"
													onClick={() => navigate('/admin/crop-categories')}
												>
													CLOSE
												</button>
												{authPermissions.includes('Category-create') && (
													<button type="submit" id='button_2' className="btn btn-primary ">
														SUBMIT
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCropCategory;
