import React, { useState, useEffect } from "react";
import styles from "./ViewOrders.module.css";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { useContext } from "react";

function ViewOrderDetails() {
  const { id } = useParams();

  const [returnstate, setReturnstate] = useState({});
  const [visible, setVisible] = useState(false);
  const [itemid, setItemid] = useState('');
  const [productid, setProductId] = useState('');
  const { setLoaderCheck } = useContext(LoaderContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //   const options = {
  //     headers: { "content-type": "application/json" },
  //   };
  //   axiosPrivate
  //     .get(`/order-returnstates/${id}`, options)
  //     .then((res) => {
  //       setReturnstate(res.data.data);
  //       console.log(returnstate, "order");
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         //response status is an error code
  //         console.log(error.response.status);
  //       } else if (error.request) {
  //         //response not received though the request was sent
  //         console.log(error.request);
  //       } else {
  //         //an error occurred when setting up the request
  //         console.log(error.message);
  //       }
  //     });
  // }, []);
  //  let ids =  '7152-566372-4419'

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
    .get(`/orders/${id}`, options)
    .then((res) => {
        setLoaderCheck(false);
        console.log(res.data.data);
        setReturnstate(res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          //response status is an error code
          console.log(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
          console.log(error.request);
        } else {
          //an error occurred when setting up the request
          console.log(error.message);
        }
      });
  }, []);

  console.log(returnstate, "test");
  const onSubmit = (data) => {
    data.order_id = itemid;
    data.product_id = productid;
    data.status = "Return";
    setLoaderCheck(true);
    axiosPrivate
    .post("/return-orders/create", data)
    .then((res) => {
        setLoaderCheck(false);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          //response status is an error code
          console.log(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
          console.log(error.request);
        } else {
          //an error occurred when setting up the request
          console.log(error.message);
        }
      });
  };

  console.log(returnstate.order_items, "test");
  return (
    <div className={`${styles.orderContainer} ${styles.ViewOrderDetails}`}>
      <h1 className={styles.orderHistory}>Order Details</h1>
      {/* <p className={styles.history}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.{" "}
      </p> */}

      <div className={`${styles.orderDetail} table-responsive`}>
        <table className='table'>
          <thead>
            <tr>
              <th scope="col" className={styles.tableHeading}>Billing Address</th>
              <th scope="col" className={styles.tableHeading}>Shhiping Address</th>
              <th scope="col" className={styles.tableHeading}>Billing Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <th scope="row"></th> */}
              <td>
                <p>{returnstate.bill_name}</p>
                <p>{returnstate.bill_address}</p>
                <p>{returnstate.bill_city}</p>
                <p>{returnstate.bill_landmark}</p>
                <p>{returnstate.bill_mobile}</p>
                <p>{returnstate.bill_state}</p>
                <p>{returnstate.bill_pincode}</p>
              </td>
              <td>
                <p>{returnstate.ship_name}</p>
                <p>{returnstate.ship_address}</p>
                <p>{returnstate.ship_city}</p>
                <p>{returnstate.ship_landmark}</p>
                <p>{returnstate.ship_mobile}</p>
                <p>{returnstate.ship_state}</p>
                <p>{returnstate.ship_pincode}</p>
              </td>
              <td>
                <p>order amount :{returnstate.order_amount}</p>
                <p>{returnstate.shipping_amount}</p>
                <p>{returnstate.total_amount}</p>
                <p>vwqdjvvdavcsadvjdchvscvc</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div className={styles.product}>
                <div className={styles.image}>
                  <img
                    src={
                      process.env.REACT_APP_COMMON_IMAGE_URL +
                      "product-variants/" +
                      res.order_items[0].variant_images[0].image
                    }
                    alt={res.order_items[0].variant_images[0].alt}
                    loading="lazy"
                  />
                </div>
                <div className={styles.productDetails}>
                  <h3 className={styles.productName}>
                    {" "}
                    {res.order_items[0].product_name}
                  </h3>
                  <p className={styles.varient}>350 GMS</p>
                  <p className={styles.price}>
                    <LiaRupeeSignSolid />
                    {res.order_items[0].price}
                  </p>
                </div>
              </div> */}

      {/* <div className="row">
        <>
          {" "}
          <div className="col-md-4">
            <h2>Billing Address</h2>
            <p>{returnstate.bill_name}</p>
            <p>{returnstate.bill_address}</p>
            <p>{returnstate.bill_city}</p>
            <p>{returnstate.bill_landmark}</p>
            <p>{returnstate.bill_mobile}</p>
            <p>{returnstate.bill_state}</p>
            <p>{returnstate.bill_pincode}</p>
          </div>
          <div className="col-md-4">
            <h2>Shhiping Address</h2>
            <p>{returnstate.ship_name}</p>
            <p>{returnstate.ship_address}</p>
            <p>{returnstate.ship_city}</p>
            <p>{returnstate.ship_landmark}</p>
            <p>{returnstate.ship_mobile}</p>
            <p>{returnstate.ship_state}</p>
            <p>{returnstate.ship_pincode}</p>
          </div>
          <div className="col-md-4">
            <h2>Billing Details</h2>
            <p>order amount :{returnstate.order_amount}</p>
            <p>{returnstate.shipping_amount}</p>
            <p>{returnstate.total_amount}</p>
            <p>vwqdjvvdavcsadvjdchvscvc</p>
          </div>
        </>
      </div> */}

      {/* product dispatch card */}
      <div className={styles.card}>
        <div className={styles.status}>
          <p className={styles.statusDot}></p>
          <p className={styles.orderStatus}>
            <span className={styles.orderId}>
              Order Id: #{returnstate.order_id}
            </span>
          </p>
        </div>
        <div className={styles.innerCard}>
          {/* product inner card */}

          <div className={`${styles.product} table-responsive`}>
            {returnstate?.order_items?.map((item, key) => (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className={styles.headingWidth}>image</th>
                    <th scope="col" className={styles.headingWidth}>Product</th>
                    <th scope="col" className={styles.headingQuantity}>Quality</th>
                    <th scope="col" ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">{item.product_description}</td>
                    <td>{item.product_name}</td>
                    <td>{item.quantity}</td>
                    <td className={styles.returnOrder}>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => { setVisible(true); setItemid(item.order_id); setProductId(item.product_id); }}
                      >
                        Return Orders
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>

          {/* tracking detail */}
        </div>
      </div>
      <div className="card flex justify-content-center">
        <Dialog
          header="Return Request"
          visible={visible}
          className={styles.returnModal}
          // style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <div className={styles.retunorder}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h4 className={styles.retunorderh4}>Return/Exchange Request </h4>
              <select
                name="dropdown"
                className={`form-control ${styles.dropdown}`}
                {...register("reason", { required: true })}
              >
                <option value="">Reason Of Return</option>
                <option value="Accidentally Placed Order">
                  Accidentally Placed Order
                </option>
                <option value="Ordered Wrong Product">
                  Ordered Wrong Product
                </option>
                <option value="Product is not good as per requirement">
                  Product is not good as per requirement
                </option>
                <option value="Didn't like the Product">
                  Didn't like the Product
                </option>
                <option value="Defective Product">Defective Product</option>
              </select>
              {errors.reason && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <h4 className={styles.retunorderh4}>
                Please Describe the Issue *
              </h4>
              <textarea
                id="w3review"
                name="w3review"
                className={`form-control ${styles.textareaformControl}`}
                placeholder="Please Describe the Issue"
                {...register("message", { required: true })}
              ></textarea>
              {errors?.message && (
                <span style={{ color: "red" }}>
                  Please Insert Describe Issues
                </span>
              )}

              <button className={`${styles.trackOrder} ${styles.trackOrderButton}`}>Return Orders</button>
            </form>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default React.memo(ViewOrderDetails); // Memoize the ViewOrderDetails;
