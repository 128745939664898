import React, { useRef } from "react";

const Broucher = () => {
  const iframeRef = useRef(null);

  const printIframeContentAsPDF = () => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;

      iframeWindow.focus();
      iframeWindow.print();
    }
  };

  return (
    <div className="text-center mt-2" style={{ fontFamily: "var(_alice)" }}>
      <button onClick={printIframeContentAsPDF} className="btn btn-primary">
        Download or Print
      </button>
      <p style={{ fontFamily: "var(_alice)", fontSize: "0.6rem" }}>
        (Please select you native language from top-right corner)
      </p>
      <iframe
        ref={iframeRef}
        src="/Brochure2.html" // Replace with your iframe source
        title="Brochure"
        width="100%"
        style={{ height: "100vh" }}
      ></iframe>
    </div>
  );
};

export default React.memo(Broucher);
