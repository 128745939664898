import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import Logo from "./Logo";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FooterBar from "./FooterBar";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../../../context/Loader/Loader";
import WhatsappLogo from "./WhatsappLogo";
const FrontMain = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    return (
        <>
            <Loader />
            <Topbar />
            <Logo />
            <Navbar />
            <Outlet />
            <Footer />
            <FooterBar />
            < ToastContainer />
            <WhatsappLogo/>
        </>
    );
}

export default React.memo(FrontMain) // Memoize the FrontMain