import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/contact-us.css";
import { useForm } from "react-hook-form";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../../Toster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { successToast, errorToast } from "./../../backend/common/Toaster";
import youTubeImg from "../../../assets/images/Youtube.png";
import { useFormik } from "formik";
import * as Yup from "yup";

function ContactUsPage() {
  const navigate = useNavigate();

  const { setLoaderCheck } = useContext(LoaderContext);

  const formSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid phone number', function(value) {
      // Check if the mobile number does not start from 0 to 5
      if (!value) return true; // If no value, let Yup.string().required handle it
      return !/^[0-5]/.test(value);
    }).required("Phone number is required"),
    email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid Email!"
      )
      .required("Email is required"),
    
    message: Yup.string().required("Message is required"),
    sell_platform: Yup.string().required("Category is required"),
  });

  const formValues = {
    name: "",
    mobile: "",
    email: "",
    message: "",
    sell_platform: "",
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: formValues,
    validationSchema: formSchema,
    onSubmit: (data, { resetForm }) => {
      data.form_type = "contact";
      setLoaderCheck(true);
      axiosPrivate
        .post("/contact-us", data)
        .then((res) => {
          setLoaderCheck(false);
          resetForm();
          successToast("Form Submitted Successfully!");
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error.response.data.errors == "array") {
            errorToast(error.response.data.errors[0].msg);
          } else {
            errorToast(error.response.data.errors.msg);
          }
        });

    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  useEffect(() => {
    const div_section = document.querySelector(".contact-us");
    const first_section = document.querySelector(".first-section");
  });
  return (
    <>
      <div className="contact-us">
        <div className="first-section">
          <div className="column-1">
            <div className="contact-us-heading custom-md-hidden d-none d-md-inline">
              <h1 className="heading">Contact Information</h1>
              <img
                src="../assets/images/home/leaf-1-1 1.webp"
                alt="Leaf"
                loading="lazy"
                className="leaf"
              />{" "}
            </div>
            <div className="contact-section">
              <div className="first_section">
                <div>
                  <h2>Address</h2>
                  <p>20MCC PRIVATE LIMITED</p>
                  <p>Plot No.:256, GIDC, Industrial Estate, Waghodia,</p>
                  <p> 391760 Dist. Vadodara, Gujarat, India</p>
                </div>
                <div>
                  <h2>Working Days</h2>
                  <p>Monday - Saturday</p>
                  <p>09:00 AM to 06:00 PM</p>
                </div>
                {/* <div>
                  <h2>E-mail</h2>
                  <p>Customercare@minfert.in</p>
                </div> */}
              </div>
              <div className="second_section">
                <div>
                  <h2>E-mail</h2>
                  <p>
                    <a href="mailto:sales@minfert.in">sales@minfert.in </a>
                  </p>
                </div>

                <div className="second-1 pt-1">
                  <h2>Phone</h2>
                  <p>
                    Tollfree: <a href="tel:1800-313-1171">1800-313-1171</a>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2>Follow Us</h2>
            </div>
            <div className="contact-social">
              <Link to="https://www.facebook.com/minfert/" target="_blank">
                <div className="social-button">
                  <img
                    src="/contact-us/facebook.png"
                    alt="facebook-icon"
                    loading="lazy"
                  />
                </div>
              </Link>
              <Link to="https://www.instagram.com/minfert.in/" target="_blank">
                <div className="social-button">
                  <img
                    src="/contact-us/vector.png"
                    alt="contact-us-icon"
                    loading="lazy"
                  />
                </div>
              </Link>
              <Link to="https://twitter.com/Minfert_Organic" target="_blank">
                <div className="social-button">
                  <img
                    src="/contact-us/twitter.png"
                    alt="twitter-icon"
                    loading="lazy"
                  />
                </div>
              </Link>
              <Link
                to="https://in.linkedin.com/company/minfert"
                target="_blank"
              >
                <div className="social-button">
                  <img
                    src="/contact-us/linkedin.png"
                    alt="linkedin-icon"
                    loading="lazy"
                  />
                </div>
              </Link>
              <Link to="https://www.youtube.com/@minfert" target="_blank">
                <div className="social-button">
                  <img src={youTubeImg} alt="youtube-icon" loading="lazy" />
                </div>
              </Link>
            </div>
          </div>
          <div className="column-2">
            {/* <h1 className="mobile_heading">Contact Information</h1> */}
            <div className="contact-us-heading  custom-md-visible d-inline d-md-none">
              <h1 className="heading">Contact Information</h1>
              <img
                src="../assets/images/home/leaf-1-1 1.webp"
                alt="Leaf"
                loading="lazy"
                className="leaf2"
              />{" "}
            </div>
            <div className="contact-us-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="contact-form-inputs col-lg-12">
                    <label>Name *</label>
                    <input
                    className="form-control"
                      type="text"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        nameFilter(event);
                      }}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {touched.name && errors.name ? (
                      <div style={{ color: "red" }}>{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="contact-form-inputs col-lg-12">
                    <label>Mobile Number *</label>
                    <input
                    className="form-control"
                      type="text"
                      name="mobile"
                      id="mobile"
                      onChange={(event) => {
                        numberFilter(event);
                      }}
                      onBlur={handleBlur}
                      value={values.mobile}
                      maxLength={10}
                    />
                    {touched.mobile && errors.mobile ? (
                      <div style={{ color: "red" }}>{errors.mobile}</div>
                    ) : null}
                  </div>
                  <div className="contact-form-inputs col-lg-12">
                    <label>Email Address *</label>
                    <input
                    className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="contact-form-inputs col-lg-12">
                    <label>Category*</label>
                    <select
                      className="form-control placeholderText"
                      name="sell_platform"
                      id="sell_platform"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sell_platform}
                    >
                      <option value="">Select Category </option>
                      <option value="Farmer">Farmer</option>
                      <option value="Distributor">Distributor</option>
                      <option value="Dealer">Dealer</option>
                      <option value="Home Garden">Home Garden</option>
                    </select>
                    {touched.sell_platform && errors.sell_platform ? (
                      <div style={{ color: "red" }}>{errors.sell_platform}</div>
                    ) : null}
                  </div>
                  <div className="contact-form-inputs col-lg-12">
                    <label>Message*</label>
                    <textarea
                    className="form-control"
                      name="message"
                      id="message"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    {touched.message && errors.message ? (
                      <div style={{ color: "red" }}>{errors.message}</div>
                    ) : null}
                  </div>
                </div>

                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ContactUsPage); // Memoize the ContactUsPage;
