import React, { useEffect, useState } from 'react';
import styles from './BlogDetail.module.css';
import { useParams } from 'react-router-dom';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { useContext } from 'react';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import { FaUser, FaComment } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';

const phoneRegExp =
/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function BlogDetail () {
  const { id } = useParams();
  const [Blog, setBlog] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [comment, setComment] = useState([]);
  const contactFormSchema = Yup.object({

  	mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number must be of 10 digits"),
  });

  const contactForm = {
    name: '',
    mobile: '',
    email: '',
    message: '',
    status: 'Pending',
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: contactForm,
    validationSchema: contactFormSchema,
    onSubmit: (values) => {
      setLoaderCheck(true);
      axiosPrivate
        .post('comment', values)
        .then((res) => {
          setLoaderCheck(false);
          showSuccessToast('Successfully Posted');
          resetForm();
        })
        .catch((errors) => {
          setLoaderCheck(false);
          showErrorToast('Internal Server Error');
        });
    },
  });

  // const formattedDate = created_at.toISOString().substring(0, 10);

  useEffect(() => {
    const options = {
      headers: { 'content-type': 'application/json' },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`comment?status=Published`, options)
      .then((comment) => {
        setLoaderCheck(false);
        setComment(comment.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast('Internal Server Error');
        }
      });
  }, []);
  

  useEffect(() => {
    const options = {
      headers: { 'content-type': 'application/json' },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/blogs/${id}`, options)
      .then((blog) => {
        setLoaderCheck(false);
        setBlog(blog.data.data);
        
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast('Internal Server Error');
        }
      });
  }, []);

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  

const arrayToString = (array) => {
  let parcedArray=[]
  if(array!=undefined) {
  parcedArray=JSON.parse(array)
  }
  return parcedArray.map((item) => item.text).join(', ');
}
   
  return (
    <>
      {/* detail section */}

      <Helmet>
          <title>{Blog?.meta_title}</title>
          <meta name="description" content={Blog?.meta_description} />
          <meta name="keywords" content={arrayToString(Blog.keywords)}/>
          <meta name="tags" content={arrayToString(Blog.tags)} />
          <meta property="og:title" content={Blog?.og_title} />
          <meta
            property="og:description"
            content={Blog?.og_description}
          />
          <meta property="og:image" content={Blog?.og_image} />
          <meta property="og:url" content={Blog?.og_image} />
          <meta name="twitter:title" content={Blog?.meta_title} />
          <meta
            name="twitter:description"
            content={Blog?.meta_description}
          />
          <meta name="twitter:image" content={Blog?.og_image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

      <div className={styles.blogDetailPage}>
        <div className='row'>
          <div
            className={`${styles.imageAndTagsContainer} col-lg-6 col-md-12 col-sm-12`}
          >
            {/* Left column for Image and Tags */}
            <div className={styles.leftColumn}>
              <div className={styles.blogImage}>
                <img
                  className='img-fluid'
                  src={
                    process.env.REACT_APP_COMMON_FILE_URL +
                    'blogs/' +
                    Blog?.featured_image
                  }
                  alt='blog'
                  loading='lazy'
                />
                <div className={styles.descriptionContainer}>
                  <p className={styles.blogDescription}>{Blog?.description}</p>
                </div>
              </div>
              <div className={styles.tagContainer}>
                <h3 className={styles.tag}>Tags:</h3>
                <div className={styles.tagFields}>
                  <div className={styles.productTags}>
                    <span className={styles.tagText}>{Blog.tags}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Right column for Leave a Reply */}
          <div className='col-lg-6 col-md-12 col-sm-12'>
            <div className={styles.rightColumn}>
              <div className={styles.replyContainer}>
                <h3 className={`${styles.tag} ${styles.leaveReply}`}>
                  Leave a Reply
                </h3>
                <p className='text-small'>
                  Your email address will not be published. Required fields are
                  marked *
                </p>

                <form onSubmit={handleSubmit}>
                  <div
                    className={`row row-cols-2 g-0 justify-content-between ${styles.formContainer}`}
                  >
                    <div className={`${styles.fieldWidth} col-12`}>
                      <div className={`col-sm-6 col-12 ${styles.contactField}`}>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          placeholder='Your Name*'
                          className='form-input'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name ? (
                          <div className={styles.invalidDataError}>
                            {errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className={`col-sm-6 col-12 ${styles.contactField}`}>
                        <input
                          type='number'
                          name='mobile'
                          id='mobile'
                          placeholder='Mobile Number'
                          className='form-input'
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.mobile && errors.mobile ? (
                          <div className={styles.invalidDataError}>
                            {errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={`col-12 ${styles.contactField}`}>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Email'
                        className='form-input'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className={`col-12 ${styles.contactField}`}>
                    <textarea
                      name='message'
                      id='message'
                      cols='20'
                      rows='10'
                      className=''
                      placeholder='Message'
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div
                    className={`${styles.submitBtnContainer} col-12 ${styles.commnent}`}
                  >
                    <button type='submit' className={styles.careerSubmitBtn}>
                      Post Comment
                    </button>
                  </div>

           

                </form>
              </div>
            </div>

          </div>
        </div>
        <div className={styles.publishedComments}>
  {comment.filter((c) => c.status === 'Published').length > 0 && (
    <div>
      <h3>Comments:</h3>
      {comment
        .filter((c) => c.status === 'Published')
        .map((c) => (
          <div key={c.id} className={styles.commentContainer}>
            <div className={styles.commentContent}>
              <div className={styles.container}>
                <div className={styles.nameSection}>
                  <FaUser />
                </div>
                <div className={styles.name}> {c.name} </div>
                <div className={styles.dateSection}>
                  {formatDate(c.created_at)}
                </div>
              </div>
              <div className={styles.messageSection}>
                <div className={styles.commentMessage}>
                  <FaComment />
                </div>{' '}
                <div className={styles.message}> {c.message} </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )}
</div>

      </div>
    </>
  );
}
export default React.memo(BlogDetail);