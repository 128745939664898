import React from "react";
import styles from "./Gallery.module.css";
import { useState, useEffect } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { showErrorToast } from "../../../Toster";

function FarmersTraining() {
  const [gallery, setGallery] = useState([]);

  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("product-gallery")
      .then((res) => {
        setLoaderCheck(false);
        setGallery(res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast("Internal Server Error");
        }
      });
  }, []);

  return (
    <div>
      <div className={styles.galleryContainer}>
        <div className={styles.videoContainer}>
          <img
            src="../assets/images/home/Group 244.webp"
            className={styles.background}
            alt="background"
            loading="lazy"
          />
          <h1 className={`${styles.subHeading}`}>
            Farmer Training
            <img
              src="../assets/images/home/leaf-1-1 1.webp"
              alt="Leaf"
              loading="lazy"
              className={styles.leaf2}
            />
          </h1>
          <div className={`${styles.imageContainer} row`}>
            {gallery
              ?.filter((data) => data.media_for == "Farmers Training")
              .map((image, index) => {
                let mediaType = image.media.split(".");
                if (mediaType[1] == "mp4") {
                  return (
                    <div
                      key={index}
                      className={`${styles.image} col-lg-4 col-sm-6 col-12 my-2`}
                    >
                      <video controls className={styles.galleryContent}>
                        <source
                          src={
                            process.env.REACT_APP_COMMON_FILE_URL +
                            "product-gallery/" +
                            image.media
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FarmersTraining); // Memoize the FarmersTraining;
