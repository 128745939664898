import classes from "./ImagePopup.module.css";

const ImagePopup = ({ isImageModalOpen, imageUrl, imageAlt, setImageModalShow }) => {
    const modalClose = async () => {
        setImageModalShow(false);
    }

    if (!isImageModalOpen) return null;
    return (
        <div className="modal fade show bd-example-modal-lg" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ paddingRight: "0px", display: "block", background: "rgba(0,0,0,0.4)" }} aria-modal="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <button type="button" className={`${classes.close_btn}`} onClick={() => modalClose()}>
                            <span aria-hidden="true" style={{ fontSize: "30px" }}>&times;</span>
                        </button>
                        <img src={imageUrl} alt={imageAlt} className={classes.image} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImagePopup