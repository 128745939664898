import React, { useEffect, useState } from "react";
import styles from "../orders/ViewOrders.module.css";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../../Toster";
import { errorToast, successToast } from "../../../backend/common/Toaster";

function Wishlist() {
  const [userProducts, setUserProducts] = useState([]);
  
  const navigate=useNavigate()
  const userProductDetails = () => {
    axiosPrivate
      .get(`wishlist/get`)
      .then((res) => {
        setUserProducts(res?.data?.data);
      })
      .catch((error) => {
        if (typeof error?.response?.data?.errors == "array") {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        } else {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };
  useEffect(() => {
    userProductDetails();
  }, []);

 const addToCartHandler=(e,res)=>{

  let object={
    quantity:1,
    ...res
  }
    axiosPrivate
      .post("/carts/create",object)
      .then((res) => {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        successToast("Item Added in Cart!");
        navigate("/cart");
      })
      .catch((error) => {
        errorToast("Something Went Wrong!");
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      });
  
 }

 const productDeleteHandler=(e,res)=>{
  axiosPrivate
  .delete(`/wishlist/${res.id}`)
  .then((res) => {
    userProductDetails();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    successToast("Product deleted");
  
  })
  .catch((error) => {
    errorToast("Something Went Wrong!");
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });
 }

  return (
    <>
      <div className={styles.orderContainer}>
        <h1 className={styles.orderHistory}></h1>
        <p className={styles.history}>
         
        </p>
        {userProducts?.length >0 ?userProducts.map((res) => (
           
          <div className={styles.card}>
            <div className={styles.status}>
              <p className={styles.statusDot}></p>
              <p className={styles.orderStatus}>
                {/* {res.order_status},{" "} */}
                <span className={styles.orderId}>
                  Product Name:{" "}{res.product_name}
                  {res.product_image.image}
                </span>
              </p>
            </div>
            <div className={styles.innerCard}>
              {/* product inner card */}
              <div className={styles.product}>
                <div className={styles.image}>
                  <img
                    src={
                      process.env.REACT_APP_COMMON_FILE_URL +
                      `product-variants/` +
                      res?.variant_image[0]?.image
                    }
                    alt={res.order}
                    loading="lazy"
                  />
                </div>
                <div className={styles.productDetails}>
                  <h3 className={styles.productName}>
                    {" "}
                    {/* {res.order_items} */}
                  </h3>
                  <p className={styles.varient}>{res.product_variant}</p>
                  <p className={styles.price}>
                    <LiaRupeeSignSolid />
                    {res.regular_price}
                  </p>
                </div>
              </div>

              {/* tracking detail */}
              <div className={styles.productTracking} style={{justifyContent:"space-around"}}>
                  <button className={styles.trackOrder} onClick={(e) =>addToCartHandler(e,res)}>Add To Cart</button>
                  <button
                    className={`${styles.trackOrder} ${styles.spacing}`}
                   onClick={(e) => productDeleteHandler(e,res)}
                  >
                    Remove Product
                  </button>

                {/* <Link
                  to={`/order-detail/${res.order_id}`}
                  className={`${styles.viewOrderDetail} ${styles.spacing}`}
                >
                  View order details
                </Link>
                <p className={`${styles.invoice} ${styles.spacing}`}>
                  Get invoice
                </p> */}
              </div>
            </div>
          </div>
        )):<div className="text-center text-bold">No Product Found !</div>}
      </div>
    </>
  );
}

export default Wishlist;
