import React from 'react'
import styles from './MineralPallet.module.css'

function MineralPallet() {
    return (
        <div className={styles.palletContainer}>
            <div className={styles.responsiveHeading}>
                <h2 className={styles.heading}>Why is <span className={styles.organic}>organic</span> mineral pallets important?
                    <img src='../../assets/images/home/leaf-1-1 1.webp' alt='leaf' loading='lazy' className={styles.leaf} />
                </h2>
            </div>
            <div className={styles.pallet}>
                <div className={styles.mineralPallet}>
                    <div className={styles.desktopHeading}>
                        <h2 className={styles.heading}>Why is <span className={styles.organic}>organic</span> mineral pallets important?
                            <img src='../../assets/images/home/leaf-1-1 1.webp' alt='leaf' loading='lazy' className={styles.leaf} />
                        </h2>
                    </div>

                    <p>Minfert | BLK exists in a uniquely crafted pellet form, and the concept behind it is simple: In traditional farming, the farmers will haphazardly add fertilizers around their crops and plants when they feel necessary. However, this causes the plants to absorb only a small fraction of the manure while the rest goes to waste. This process is repeated all season, leading to a lot of waste. However, Minfert | BLK is different. It should be added along with the traditional fertilizer at the time of sowing and plantation. Then unlike most fertilizers which dissolve immediately in water, BLK dissolves slowly and serves as food for the crops, which they can consume little by little every day, like most other living creatures.</p>
                </div>
                <div className={styles.image}>
                    <img src="../../assets/images/home/image 64.webp" alt="Mineral pallet" loading='lazy' />
                </div>
            </div>
        </div>
    )
}

export default React.memo(MineralPallet) // Memoize the MineralPallet
