import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../../context/Alert/AlertContext';
import DataTable2 from '../../../../../components/DataTable2';
import axiosPrivate from '../../../../../hooks/axiosPrivate';
import LoaderContext from '../../../../../context/Loader/LoaderContext';


const ContactListing = () => {
	const [contactList , setContactList ] = useState([])
	const location = useLocation();
	const { setLoaderCheck } = useContext(LoaderContext);

	// header
	const headers = [
		{ name: 'Name', field: 'name', sortable: true, classKey: 'image-column' },

		{ name: 'Email Address', field: 'email', sortable: true, classKey: '' },

		{ name: 'Message', field: 'message', classKey: '' },
	];

	const headers1 = [
		{ name: 'Name', field: 'name', sortable: true, classKey: 'image-column' },

		{ name: 'Email Address', field: 'email', sortable: true, classKey: '' },

		{ name: 'Mobile No.', field: 'mobile', sortable: true, classKey: '' },
		
		{ name: 'City', field: 'city', classKey: '' },

		{ name: 'District', field: 'district', classKey: '' },
		
		{ name: 'State', field: 'state', classKey: '' },

		{ name: 'Taluka', field: 'taluka', classKey: '' },

		{ name: 'Message', field: 'message', classKey: '' },

		{ name: 'Sell Platform', field: 'sell_platform', classKey: '' },
	]

	//  contact data list
	useEffect(() => {
		let string = location.pathname == '/admin/contact-listing' ? 'contact-us' : '/distributor'
		setLoaderCheck(true);
		axiosPrivate
		.get(string)
		.then((list) => {
				setLoaderCheck(false);
				const contactList = list.data.data.map((value) => {
					return value;
				});

				setContactList(contactList)

			})
			.catch((error)=>{
				setLoaderCheck(false);
				console.log(error);
			})

			console.log(contactList);
 	}, [location.pathname]);


	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center ">
						{/* hearder breadcrumb */}
						<div className="card-header">
							<strong className="top-left-heading">{location.pathname == '/admin/contact-listing' ? 'Contact Form' : 'Distributor Form'}</strong>

							<ol className="headerroutes breadcrumb m-0 bg-none ">
								<li className="item color-gray">|</li>
								<li className="item">
									<Link
										to="/admin/dashboard"
										className="route-heading item mx-2"
									>
										Dashboard
									</Link>
								</li>
								<li className="item">
									<FaGreaterThan className="grater-than-route" />
								</li>
								<li className="route-heading item mx-2 mt-1">{location.pathname == '/admin/contact-listing' ? 'Contact Form' : 'Distributor Form'}</li>
							</ol>
						</div>
					</div>
				</div>
				<div className="section-body ">
					<div className="container-fluid">
						<div className="tab-content ">
							<div
								className="tab-pane fade show active"
								id=" contact-list"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											{
												<DataTable2
													lists={contactList}
													headers={location.pathname == '/admin/contact-listing' ? headers : headers1}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactListing;
