import React, { useEffect, useState } from 'react';
import styles from './ViewOrders.module.css';
import { Link } from 'react-router-dom';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import { showErrorToast, showSuccessToast } from '../../../../Toster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function ViewOrders() {
  const [userOrders, setUserOrders] = useState([]);
  const [userReturnOrders, setUserReturnOrders] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    reason: '',
    message: '',
    status: 'Return Requested',
  });
  const [orderDetails, setOrderDetails] = useState({});

  if (localStorage.getItem('userData')) {
    var { userId } = JSON.parse(localStorage.getItem('userData'));
  }

  const userOrderDetails = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`orders/user`)
      .then((res) => {
        setLoaderCheck(false);
        setUserOrders(res?.data?.data);
      })
      .catch((err) => {
        // showErrorToast(
        //   err?.response?.data?.message ||
        //     err?.response?.data?.errors[0]?.msg ||
        //     err?.response?.data?.errors?.msg
        // );
        setLoaderCheck(false);
      });
    setLoaderCheck(false);
  };

  const userReturnOrder = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`return-orders/${userId}`)
      .then((res) => {
        setLoaderCheck(false);
        setUserReturnOrders(res?.data?.data);
      })
      .catch((err) => {
        // showErrorToast(
        //   err?.response?.data?.message ||
        //     err?.response?.data?.errors[0]?.msg ||
        //     err?.response?.data?.errors?.msg
        // );
        setLoaderCheck(false);
      });
  };

  useEffect(() => {
    userOrderDetails();
    userReturnOrder();
  }, []);

  const orderEditHandler = (e, res) => {
    e.preventDefault();
    setLoaderCheck(true);
    axiosPrivate
      .get(`orders/update-status/${res.id}/cancelled`)
      .then((res) => {
        setLoaderCheck(false);
        userOrderDetails();
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (typeof error?.response?.data?.errors == 'array') {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        } else {
          showErrorToast(
            error?.response?.data?.errors?.msg || error?.response?.data?.message
          );
        }
      });
  };

  const handleButtonClick = (e, res) => {
    setOrderDetails(res);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleReturnFormSubmit = (e) => {
    e.preventDefault();
    let object = {
      ...formData,
      order_id: orderDetails?.order_id,
      product_id: orderDetails?.product_id,
    };
    setLoaderCheck(true);
    axiosPrivate
      .post(`return-orders/create`, object)
      .then((res) => {
        showSuccessToast('Return order created successfully');
        setLoaderCheck(false);
        setIsModalOpen(false);
        userOrderDetails();
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (typeof error?.response?.data?.errors == 'array') {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        } else {
          showErrorToast(
            error?.response?.data?.errors?.msg || error?.response?.data?.message
          );
        }
      });
    // Close the modal after submission
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function formatOrderDate(orderDate) {
    if (orderDate) {
      const dateObject = new Date(orderDate);
      if (!isNaN(dateObject.getTime())) {
        return dateObject.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      }
    }
    return 'Invalid Date';
  }

  return (
    <>
      <div className={styles.orderContainer}>
        {userOrders.length > 0 ? (
          <h1 className={styles.orderHistory}>Order History</h1>
        ) : (
          "You Haven't Placed any Order"
        )}

        {userOrders?.map((res) => (
          <>
            <div className={styles.card}>
              <div className={styles.status}>
                <p className={styles.statusDot}></p>
                <p className={styles.orderStatus} style={{ marginLeft: '2vw' }}>
                  {res.order_status},{' '}
                  <span className={styles.orderId}>
                    {formatOrderDate(res?.order_date)}
                  </span>
                </p>
                <p className={styles.orderStatus} style={{ marginLeft: '2vw' }}>
                  Order Amount - {res.order_amount}
                </p>
                <div className=''>
                  <p
                    className={`${styles.orderStatus} text-end`}
                    style={{ marginLeft: '2vw' }}
                  >
                    Order Id - {res.order_id}
                  </p>
                </div>
              </div>

              {res?.order_items
                ?.filter((res) => res.status === null)
                .map((items) => (
                  <div className={styles.innerCard}>
                    {/* product inner card */}
                    <div className={styles.product}>
                      <div className={styles.image}>
                        <Link to={`/product-detail/${items?.product_slug}`}>
                          <img
                            src={
                              process.env.REACT_APP_COMMON_FILE_URL +
                              'product-variants/' +
                              items?.variant_images[0]?.image
                            }
                            alt={items?.variant_images[0]?.alt}
                            loading='lazy'
                          />
                        </Link>
                      </div>

                      <div className={styles.productDetails}>
                        <h3 className={styles.productName}>
                          {' '}
                          {items?.product_name}
                        </h3>
                        <p className={styles.varient}>
                          {items?.product_variant}
                        </p>
                        <p className={styles.orderStatus}>
                          {/* <LiaRupeeSignSolid />
                  {items?.price} */}
                          Quantity - {items.quantity}
                        </p>
                      </div>
                    </div>

                    {/* tracking detail */}
                    <div className={styles.productTracking}>
                      <button
                        className={`${styles.trackOrder} ${
                          (res?.order_status !== 'confirmed' ||
                            items?.status === 'return') &&
                          styles.disabled
                        }`}
                        onClick={(e) => handleButtonClick(e, items)}
                        disabled={
                          res?.order_status !== 'confirmed' ||
                          items?.status === 'return'
                        }
                      >
                        Return Order
                      </button>

                      <Dialog
                        visible={isModalOpen}
                        onHide={handleCloseModal}
                        header='Return Order Modal'
                      >
                        <form onSubmit={handleReturnFormSubmit}>
                          <div className='p-field p-2 search'>
                            <label htmlFor='reason'>
                              Reason<span style={{ color: 'red' }}>*</span> :{' '}
                            </label>
                            <InputText
                              id='reason'
                              name='reason'
                              value={formData.reason}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className='p-field p-2 search'>
                            <label htmlFor='message'>
                              Message<span style={{ color: 'red' }}>*</span> :{' '}
                            </label>
                            <InputText
                              id='message'
                              name='message'
                              value={formData.message}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className='p-field'>
                            <Button label='Submit' type='submit' />
                          </div>
                        </form>
                      </Dialog>

                      <button
                        className={`${styles.trackOrder} ${styles.spacing} ${
                          res?.order_status === 'cancelled' && styles.disabled
                        }`}
                        onClick={(e) => orderEditHandler(e, res)}
                        disabled={res?.order_status === 'cancelled'}
                      >
                        Cancel order
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ))}
      </div>
      <div className={styles.orderContainer}>
        {userReturnOrders.length > 0 && (
          <h1 className={styles.orderHistory}>Return Order History</h1>
        )}
        {userReturnOrders.map((res, i) => (
          <div className={styles.card}>
            <div className={styles.status}>
              <p className={styles.orderStatus} style={{ marginLeft: '2vw' }}>
                {res.status},{'  '}
                <span className={styles.orderId}>
                  {formatOrderDate(res?.date)}
                </span>
              </p>
              <p
                className={`${styles.orderStatus} text-end`}
                style={{ marginLeft: '2vw' }}
              >
                Order Id - {res.order_id}
              </p>
            </div>
            {res?.order_items?.map((items) => (
              <div className={styles.innerCard}>
                {/* product inner card */}
                <div className={styles.product}>
                  <div className={styles.image}>
                    <Link to={`/product-detail/${res?.product_slug}`}>
                      <img
                        src={
                          process.env.REACT_APP_COMMON_FILE_URL +
                          'product-variants/' +
                          items?.variant_images[0]?.image
                        }
                        alt={items?.variant_images[0]?.alt}
                        loading='lazy'
                      />
                    </Link>
                  </div>

                  <div className={styles.productDetails}>
                    <h3 className={styles.productName}> {res?.product_name}</h3>
                    <p className={styles.varient}>{items?.product_variant}</p>
                    <p className={styles.price}>
                      {/* <LiaRupeeSignSolid />
                    {res?.amount} */}
                      Quantity - {items.quantity}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default React.memo(ViewOrders); // Memoize the ViewOrders;
