import React, { useEffect, useState, useContext } from 'react';
import styles from '../about-template/AboutPageTemplate.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Editor } from 'primereact/editor';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import AuthContext from '../../../context/Auth/AuthContext';
import LoaderContext from '../../../context/Loader/LoaderContext';
import TopNavBar from '../../backend/layouts/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import { toast } from "react-toastify";
import { WithContext as ReactTags } from 'react-tag-input';

function AddHomePageTemplate() {
  const navigate = useNavigate();
  const { authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const aboutTemplateValidation = Yup.object({
    pageName: Yup.string().required('Page Name is Required'),
    slug: Yup.string().required('Slug is Required'),
    //mainImage: Yup.mixed().required("Banner Image is Required"),
  });

  const [submitButton, setSubmitButton] = useState('Submit');
  const { setLoaderCheck } = useContext(LoaderContext);
  const [pageName, setPagename] = useState('Add Home Template');
  const { id } = useParams();
  const coreImages = [
    'BsFillCalculatorFill',
    'TbTarget',
    'FaDiceD6',
    'FaHeart',
  ];

  const initialValues = {
    pageName: '',
    slug: '',
    meta_title: '',
    meta_description: '',
    keywords: [],
    tags: [],
    og_title: '',
    og_description: '',
    og_image: '',
    og_alt: '',
    canonical_tags: '',
    bannerValues: {
      mainHeading: '',
      bannerImage: [
        {
          Image: '',
        },
      ],
      boxes: [
        {
          id: 1,
          heading: '',
          image: '',
          backgroundColor: 'box-1',
          Url: '',
        },
        {
          id: 2,
          heading: '',
          image: '',
          backgroundColor: 'box-2',
          Url: '',
        },
        {
          id: 3,
          heading: '',
          image: '',
          backgroundColor: 'box-3',
          Url: '',
        },
        {
          id: 4,
          heading: '',
          image: '',
          backgroundColor: 'box-4',
          Url: '',
        },
      ],
    },

    plantGrowthValues: {
      mainHeading: '',
      leafImage: './assets/images/home/leaf-1-1 1.webp',
      mainContent: '',

      plantInformation: [
        {
          content1: {
            subHeading: '',
            subContent: '',
          },

          image: '',

          content2: {
            subHeading: '',
            subContent: '',
          },
        },

        {
          image: '',
          content: {
            subHeading: '',
            subContent: '',
          },
          image1: '',
        },

        {
          content1: {
            subHeading: '',
            subContent: '',
          },

          image: '',

          content2: {
            subHeading: '',
            subContent: '',
          },
        },
      ],
    },
    navigation: {
      leafImage: './assets/images/home/leaf-1-1 1.webp',
      mainHeading: '',
      mainContent: '',
      card: [
        {
          heading: '',
          image: '',
          url: '',
        },
      ],
    },

    blogValues: {
      mainHeading: '',
      mainContent: '',
      viewMoreBlogBtn: 'View More Blogs',
      posts: [
        {
          heading: '',
          content: '',
          readMore: '',
          image: '',
          writerName: '',
          writerImage: '',
        },
      ],
    },

    coreValues: {
      backgroundImage: '',
      leafImage: './assets/images/home/leaf-1-1 1.webp',
      mainHeading: '',

      card: [
        {
          heading: '',
          content: '',
          icon: 'BsFillCalculatorFill',
        },
        // {
        //   heading: "YIELD QUANTITY",
        //   content:
        //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        //   icon: "TbTarget",
        // },
        // {
        //   heading: "PELLET FORM",
        //   content:
        //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        //   icon: "FaDiceD6",
        // },
        // {
        //   heading: "ECO-FRIENDLY",
        //   content:
        //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        //   icon: "FaHeart",
        // },
      ],
    },
  };


  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const handleDeleteKeywords = (i) => {
    setKeywords(keywords.filter((tag, index) => index !== i));
  };

  const handleAdditionKeywords = (keyword) => {
    setKeywords([...keywords, keyword]);
  };

  const handleDragKeywords = (keyword, currPos, newPos) => {
    const newkeywords = keywords.slice();

    newkeywords.splice(currPos, 1);
    newkeywords.splice(newPos, 0, keyword);

    setKeywords(newkeywords);
  };

  const handleDeleteTags = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAdditionTags = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDragTags = (tags, currPos, newPos) => {
    const newtags = tags.slice();

    newtags.splice(currPos, 1);
    newtags.splice(newPos, 0, tags);

    setTags(newtags);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];


  function setTextData(e) {
    console.log(e.htmlValue);
    setFieldValue(values.plantGrowthValues.mainContent, e.htmlValue);
  }

  // page name onBlur method
  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name === 'pageName') {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
      setFieldValue('slug', slug);
    } else {
      handleBlur(event);
    }
  };

  //  Image upload method
  const onFileUpload = (event) => {
    console.log(event.target.files[0], event.target.name);
    let formData = new FormData();
    formData.append('image', event.target.files[0]);
    setLoaderCheck(true);
    axiosPrivate
      .post('pages/file-upload', formData)
      .then((res) => {
        setLoaderCheck(false);
        console.log(res?.data?.data, event?.target?.name);
        setFieldValue(event?.target?.name, res?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        setFieldValue(event?.target?.name, '');
      });
    handleChange(event);
  };

  // add row in first section
  const onAddRow = (name, index, check) => {
    if (name === 'banner_value') {
      if (check) {
        let newObj = {
          Image: '',
        };
        let rowsData = values.bannerValues.bannerImage;
        if (rowsData?.length < 10) {
          rowsData.push(newObj);
          setFieldValue(`bannerValues.bannerImage`, rowsData);
        }
      } else {
        let rowsData = values.bannerValues.bannerImage;
        if (rowsData?.length > 1) {
          rowsData?.splice(index, 1);
          setFieldValue(`bannerValues.bannerImage`, rowsData);
        }
      }
    }
    if (name === 'blog_value') {
      if (check) {
        let newObj = {
          heading: '',
          content: '',
          readMore: 'Read more',
          image: './assets/images/home/Rectangle 29.webp',
          writerName: '',
          writerImage: '',
        };
        let rowsData = values.blogValues.posts;
        if (rowsData?.length < 3) {
          rowsData.push(newObj);
          setFieldValue(`posts`, rowsData);
        }
      } else {
        let rowsData = values.blogValues.posts;
        if (rowsData?.length > 1) {
          rowsData?.splice(index, 1);
          setFieldValue('posts', rowsData);
        }
      }
    }
    if (name === 'core_value') {
      let rowsData = values.coreValues.card;
      if (check) {
        let newObj = {
          heading: '',
          content: '',
          icon: coreImages[rowsData.length],
        };
        if (rowsData?.length < 4) {
          rowsData.push(newObj);
          setFieldValue('card', rowsData);
        }
      } else {
        let rowsData = values.coreValues.card;
        if (rowsData?.length > 1) {
          rowsData?.splice(index, 1);
          setFieldValue('card', rowsData);
        }
      }
    }
    if (name === 'navigation') {
      let rowsData = values.navigation.card;
      if (check) {
        let newObj = {
          heading: '',
          image: '',
          url: '',
        };
        if (rowsData?.length < 4) {
          rowsData.push(newObj);
          setFieldValue('navigation.card', rowsData);
        }
      } else {
        let rowsData = values.navigation.card;
        if (rowsData?.length > 1) {
          rowsData?.splice(index, 1);
          setFieldValue('navigation.card', rowsData);
        }
      }
    }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: aboutTemplateValidation,
    onSubmit: async (values, { setFieldError }) => {
      values.keywords = keywords;
      values.tags = tags;
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: 'home',
      };

      console.log(values);
      
      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put(`pages/update/${id}`, formData)
          .then((response) => {
            setLoaderCheck(false);
           
            showSuccessToast("Home Template Updated")
            navigate('/admin/home-template');
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              showErrorToast('Internal server error');

              if (error.response.status == 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error?.response?.data?.errors[i]?.param,
                    error?.response?.data?.errors[i]?.msg
                  );
                }
              }
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post('pages/create', formData)
          .then((response) => {
            setLoaderCheck(false);
            navigate('/admin/home-template');
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              showErrorToast('Internal server error');

              if (error.response.status == 422) {
                for (let i = 0; i < error?.response?.data?.errors?.length; i++) {
                  setFieldError(
                    error?.response?.data?.errors[i]?.param,
                    error.response?.data?.errors[i]?.msg
                  );
                }
              }
            }
          });
      }
    },
  });


  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get('pages/' + id)
        .then((res) => {
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          setKeywords(parsedValues?.keywords);
          setTags(parsedValues?.tags);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });

          setSubmitButton('Update');
          setPagename('Edit Home Template');
          setLoaderCheck(false);
        })

        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error?.response?.data?.errors == 'array') {
            showErrorToast(error?.response?.data?.errors[0].msg);
          } else {
            showErrorToast(error?.response?.data?.errors?.msg);
          }
        });
    }
  }, []);

  return (
    <div>
      <TopNavBar
        links={{
          list: '/admin/home-template',
          add: '/admin/home-template/add',
        }}
      />
      <div className={styles.templateContainer}>
        <h1 className={styles.mainHeading}>{pageName}</h1>
        <form onSubmit={handleSubmit} className={styles.aboutForm}>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              {/* Page name field */}
              <div className=' my-2 col-12 col-lg-4'>
                <label
                  htmlFor='pagename'
                  className={` ${styles.fieldName} form-label`}
                >
                  Page name<span className={styles.red}>*</span>
                </label>
                <input
                  name='pageName'
                  id='pageName'
                  type='text'
                  className='form-control'
                  aria-describedby='emailHelp'
                  value={values.pageName}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                  required
                />
                {touched.pageName && errors.pageName ? (
                  <div className={styles.red}>{errors.pageName}</div>
                ) : null}
              </div>

              {/* slug field */}
              <div className=' my-2 col-12 col-lg-4'>
                <label
                  htmlFor='slug'
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name='slug'
                  id='slug'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.slug}
                />
                {touched.slug && errors.slug ? (
                  <div className={styles.red}>{errors.slug}</div>
                ) : null}
              </div>
            </div>
          </div>

          <h6>Banner Section</h6>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              <div className='col-12 col-lg-12'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='bannerValues.mainHeading'
                  id='bannerValues.mainHeading'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bannerValues.mainHeading}
                />
              </div>
              {values?.bannerValues?.bannerImage?.map((res, index) => (
                <>
                  <div className='col-12 col-lg-4'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Banner Image<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`bannerValues.bannerImage[${index}].Image`}
                      id={`bannerValues.bannerImage[${index}].Image`}
                      type='file'
                      className='form-control'
                      onChange={onFileUpload}
                      onBlur={handleBlur}
                    />
                    {typeof values.bannerValues.bannerImage[index].Image ==
                      'string' &&
                    values.bannerValues.bannerImage[index].Image !== '' ? (
                      <img
                        style={{ width: '100px', height: '100px' }}
                        className='mt-2'
                        src={values.bannerValues.bannerImage[index].Image}
                        alt='banner'
                        loading='lazy'
                      />
                    ) : (
                      ''
                    )}
                    <div
                      className={`${styles.addDataButton_home} col-12 col-lg-1`}
                    >
                      <button
                        type='button'
                        onClick={() => {
                          onAddRow('banner_value', index, true);
                        }}
                      >
                        +
                      </button>
                      <button
                        type='button'
                        onClick={() => {
                          onAddRow('banner_value', index, false);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <h5>Banner Boxes</h5>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              {values.bannerValues.boxes.map((res, index) => (
                <>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Heading<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`bannerValues.boxes[${index}].heading`}
                      id={`bannerValues.boxes[${index}].heading`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bannerValues.boxes[index].heading}
                    />
                  </div>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      box Image<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`bannerValues.boxes[${index}].image`}
                      id={`bannerValues.boxes[${index}].image`}
                      type='file'
                      className='form-control'
                      onChange={onFileUpload}
                      onBlur={handleBlur}
                    />
                    {typeof values.bannerValues.boxes[index].image ==
                      'string' &&
                    values.bannerValues.boxes[index].image != '' ? (
                      <img
                        style={{ width: '100px', height: '100px' }}
                        className='mt-2'
                        src={values.bannerValues.boxes[index].image}
                        alt='banner'
                        loading='lazy'
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      URL<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`bannerValues.boxes[${index}].Url`}
                      id={`bannerValues.boxes[${index}].Url`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bannerValues.boxes[index].Url}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>

          <h5>Plan Growth Values</h5>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              <div className='col-6 col-lg-6 mt-3'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='plantGrowthValues.mainHeading'
                  id='plantGrowthValues.mainHeading'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.plantGrowthValues.mainHeading}
                />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-3'>
                <label className={` ${styles.fieldName} form-label`}>
                  Content<span className={styles.red}>*</span>
                </label>
                <textarea
                  onBlur={handleBlur}
                  value={values.plantGrowthValues.mainContent}
                  rows={4}
                  cols={40}
                  id='plantGrowthValues.mainContent'
                  name='plantGrowthValues.mainContent'
                  //   onTextChange={(e) =>
                  //     setFieldValue(
                  //       values.plantGrowthValues.mainContent,
                  //       e.htmlValue
                  //     )
                  //   }
                  onChange={(e) => {
                    setFieldValue(
                      values.plantGrowthValues.mainContent,
                      e.htmlValue
                    );
                    handleChange(e);
                  }}
                />
              </div>
            </div>

            {values.plantGrowthValues.plantInformation.map((res, index) => (
              <>
                {index % 2 == 0 ? (
                  <>
                    <div className={`${styles.mainFormContainer} my-2`}>
                      <h5 className='mt-2'>Content 1</h5>
                      <div className='row'>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Heading<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].content1.subHeading`}
                            id={`plantGrowthValues.plantInformation${index}.content1.subHeading`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content1.subHeading
                            }
                          />
                        </div>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Content<span className={styles.red}>*</span>
                          </label>
                          <textarea
                            name={`plantGrowthValues.plantInformation[${index}].content1.subContent`}
                            id={`plantGrowthValues.plantInformation${index}.content1.subContent`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content1.subContent
                            }
                          ></textarea>
                        </div>

                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Image<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].image`}
                            id={`plantGrowthValues.plantInformation[${index}].image`}
                            type='file'
                            className='form-control'
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                          />
                          {typeof values.plantGrowthValues.plantInformation[
                            index
                          ].image == 'string' &&
                          values.plantGrowthValues.plantInformation[index]
                            .image != '' ? (
                            <img
                              style={{ width: '100px', height: '100px' }}
                              className='mt-2'
                              src={
                                values.plantGrowthValues.plantInformation[index]
                                  .image
                              }
                              alt='pathInformation'
                              loading='lazy'
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <h5 className='mt-3'>Content 2</h5>
                      <div className='row'>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Heading<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].content2.subHeading`}
                            id={`plantGrowthValues.plantInformation[${index}].content2.subHeading`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content2.subHeading
                            }
                          />
                        </div>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Content<span className={styles.red}>*</span>
                          </label>
                          <textarea
                            name={`plantGrowthValues.plantInformation[${index}].content2.subContent`}
                            id={`plantGrowthValues.plantInformation${index}.content2.subContent`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content2.subContent
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`${styles.mainFormContainer} my-2`}>
                      <h5 className='mt-2'>Content 1</h5>
                      <div className='row'>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Heading<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].content.subHeading`}
                            id={`plantGrowthValues.plantInformation${index}.content.subHeading`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content.subHeading
                            }
                          />
                        </div>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Sub Content<span className={styles.red}>*</span>
                          </label>
                          <textarea
                            name={`plantGrowthValues.plantInformation[${index}].content.subContent`}
                            id={`plantGrowthValues.plantInformation${index}.content.subContent`}
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.plantGrowthValues.plantInformation[index]
                                .content.subContent
                            }
                          ></textarea>
                        </div>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Image<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].image`}
                            id={`plantGrowthValues.plantInformation[${index}].image`}
                            type='file'
                            className='form-control'
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                          />
                          {typeof values.plantGrowthValues.plantInformation[
                            index
                          ].image == 'string' &&
                          values.plantGrowthValues.plantInformation[index]
                            .image != '' ? (
                            <img
                              style={{ width: '100px', height: '100px' }}
                              className='mt-2'
                              src={
                                values.plantGrowthValues.plantInformation[index]
                                  .image
                              }
                              alt='plantInformation'
                              loading='lazy'
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='col-6 col-lg-6 mt-2'>
                          <label className={` ${styles.fieldName} form-label`}>
                            Image<span className={styles.red}>*</span>
                          </label>
                          <input
                            name={`plantGrowthValues.plantInformation[${index}].image1`}
                            id={`plantGrowthValues.plantInformation[${index}].image1`}
                            type='file'
                            className='form-control'
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                          />
                          {typeof values.plantGrowthValues.plantInformation[
                            index
                          ].image1 == 'string' &&
                          values.plantGrowthValues.plantInformation[index]
                            .image1 != '' ? (
                            <img
                              style={{ width: '100px', height: '100px' }}
                              className='mt-2'
                              src={
                                values.plantGrowthValues.plantInformation[index]
                                  .image1
                              }
                              alt='plantInformation'
                              loading='lazy'
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <h5>Navigation Section</h5>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              <div className='col-6'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='navigation.mainHeading'
                  id='navigation.mainHeading'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.navigation.mainHeading}
                />
              </div>
              <div className='col-6'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Content<span className={styles.red}>*</span>
                </label>
                <input
                  name='navigation.mainContent'
                  id='navigation.mainContent'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.navigation.mainContent}
                />
              </div>
            </div>
            <div className='row'>
              {values.navigation.card.map((res, index) => (
                <>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Heading<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`navigation.card[${index}].heading`}
                      id={`navigation.card[${index}].heading`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.navigation.card[index].heading}
                    />
                  </div>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Image<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`navigation.card[${index}].image`}
                      id={`navigation.card[${index}].image`}
                      type='file'
                      className='form-control'
                      onChange={onFileUpload}
                      onBlur={handleBlur}
                    />
                    {typeof values.navigation.card[index].image == 'string' &&
                    values.navigation.card[index].image != '' ? (
                      <img
                        style={{ width: '100px', height: '100px' }}
                        className='mt-2'
                        src={values.navigation.card[index].image}
                        alt='card'
                        loading='lazy'
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='col-6 col-lg-4 mt-3'>
                    <label className={` ${styles.fieldName} form-label`}>
                      URL<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`navigation.card[${index}].url`}
                      id={`navigation.card[${index}].url`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.navigation.card[index].url}
                    />
                  </div>
                  <div className={`${styles.addDataButton_home} col-12 `}>
                    <button
                      type='button'
                      onClick={() => {
                        onAddRow('navigation', index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        onAddRow('navigation', index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </>
              ))}
            </div>
          </div>
          <h5>Blog Values</h5>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              <div className='col-6 col-lg-5'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='blogValues.mainHeading'
                  id='blogValues.mainHeading'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.blogValues.mainHeading}
                />
              </div>
              <div className='col-6 col-lg-5'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Content<span className={styles.red}>*</span>
                </label>
                <input
                  name='blogValues.mainContent'
                  id='blogValues.mainContent'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.blogValues.mainContent}
                />
              </div>
            </div>

            {/* {values.blogValues.posts.map((res, index) => (
              <>
                <div className="row my-4">
                  <div className="col-6 col-lg-5">
                    <label className={` ${styles.fieldName} form-label`}>
                      Heading<span className={styles.red}>*</span>
                    </label>
                    <input
                      name="blogValues.heading"
                      id="blogValues.heading"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.blogValues.posts[index].heading}
                    />
                  </div>

                  <div className="col-6 col-lg-5">
                    <label className={` ${styles.fieldName} form-label`}>
                      Content<span className={styles.red}>*</span>
                    </label>
                    <textarea
                      name={`blogValues.posts[${index}].content`}
                      id={`blogValues.posts[${index}].content`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.blogValues.posts[index].content}
                    ></textarea>
                  </div>

                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Writer Name<span className={styles.red}>*</span>
                    </label>
                    <input
                      name="blogValues.writerName"
                      id="blogValues.writerName"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.blogValues.posts[index].writerName}
                    />
                  </div>
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Writer Image<span className={styles.red}>*</span>
                    </label>
                    <input
                      name="blogValues.writerImage"
                      id="blogValues.writerImage"
                      type="file"
                      className="form-control"
                      onChange={onFileUpload}
                      onBlur={handleBlur}
                    />
                    {typeof values.blogValues.writerImage == "string" &&
                    values.blogValues.writerImage != "" ? (
                      <img
                        style={{ width: "100px", height: "100px" }}
                        className="mt-2"
                        src={values.blogValues.writerImage}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("blog_value", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("blog_value", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))} */}
          </div>

          <h5>Core Values</h5>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              <div className='col-6 col-lg-5'>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='coreValues.mainHeading'
                  id='coreValues.mainHeading'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.coreValues.mainHeading}
                />
              </div>
            </div>
            {values.coreValues.card.map((res, index) => (
              <>
                <div className='row my-2'>
                  <div className='col-6 col-lg-5 mt-2'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Heading<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`coreValues.card[${index}].heading`}
                      id={`coreValues.card[${index}].heading`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coreValues.card[index].heading}
                    />
                  </div>
                  <div className='col-6 col-lg-5 mt-2'>
                    <label className={` ${styles.fieldName} form-label`}>
                      Content<span className={styles.red}>*</span>
                    </label>
                    <textarea
                      name={`coreValues.card[${index}].content`}
                      id={`coreValues.card[${index}].content`}
                      type='text'
                      className='form-control'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.coreValues.card[index].content}
                    ></textarea>
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type='button'
                      onClick={() => {
                        onAddRow('core_value', index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        onAddRow('core_value', index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          <h5>Meta Fields:</h5>
          <div className={styles.mainFormContainer} style={{paddingRight:'3%'}}>
            <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='meta title *'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='meta description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div
                        className='col-md-12 col-sm-12'
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Keywords:</h5>
                        <ReactTags
                          tags={keywords}
                          id='keywords'
                          name='keywords'
                          delimiters={delimiters}
                          handleDelete={handleDeleteKeywords}
                          handleAddition={handleAdditionKeywords}
                          handleDrag={handleDragKeywords}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.keywords}
                          placeholder='Enter keywords'
                        />
                      </div>
                      <div
                        className='col-md-12 col-sm-12 '
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Tags:</h5>
                        <ReactTags
                          tags={tags}
                          id='tags'
                          name='tags'
                          delimiters={delimiters}
                          handleDelete={handleDeleteTags}
                          handleAddition={handleAdditionTags}
                          handleDrag={handleDragTags}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.tags}
                          placeholder='Enter tags'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_title'
                            name='og_title'
                            type='text'
                            className='form-control'
                            placeholder='Og title'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_description'
                            name='og_description'
                            type='text'
                            className='form-control'
                            placeholder='Og description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_image'
                            name='og_image'
                            type='file'
                            className='form-control'
                            placeholder='Og Image'
                            onChange={onFileUpload}
                            accept='image/*'
                            onBlur={handleBlur}
                            style={{ margin: '2%' }}
                          />
                          {typeof values?.og_image === 'string' && values?.og_image !== '' ? (
                            <img
                            className={styles.mainImage}
                            src={values?.og_image}
                            alt="Og Image Alt"
                            loading='lazy'
                          />
                          ) : (
                            ''
                          )}
                          
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_alt'
                            name='og_alt'
                            type='text'
                            className='form-control'
                            placeholder='Og alt'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.og_alt}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='canonical_tags'
                            name='canonical_tags'
                            type='text'
                            className='form-control'
                            placeholder='Canonical'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.canonical_tags}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

            
           
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              id='button_1'
              className={`${styles.closeButton} btn`}
            >
              <Link style={{ color: 'white' }} to='/admin/home-template'>
                {' '}
                Close
              </Link>
            </button>
            {authPermissions.includes('Home Template-create') && (
              <button
                type='submit'
                id='button_2'
                className={`${styles.submitButton} btn`}
              >
                {submitButton}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddHomePageTemplate;
