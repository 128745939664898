import React, { useEffect, useState, useContext } from 'react';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { showErrorToast,showSuccessToast } from '../../../Toster';
import axiosPrivate from '../../../hooks/axiosPrivate';
import DataTable2 from '../../DataTable2';
import TopNavBar from '../../backend/layouts/TopNavBar';
import LoaderContext from '../../../context/Loader/LoaderContext';
import AuthContext from "../../../context/Auth/AuthContext";

const MenuListing = () => {
    const [menuList, setMenuList] = useState([]);
    const [refreshState, setRefreshState] = useState(false)
    const { setLoaderCheck } = useContext(LoaderContext);
    const { authPermissions } = useContext(AuthContext);

    const headers = [
     
        { name: 'Menu Name', field: 'title', classKey: '' },
        { name: 'Slug', field: 'slug', classKey: '' },
        { name: 'Status', field: 'is_active', classKey: '' },
        { name: 'Action', field: 'action', classKey: '' },
    ];

    const searchItems = ['title', 'slug'];

    const onPageDelete = (id) => {
        setLoaderCheck(true);
        axiosPrivate
          .post(`menus/${id}?_method=DELETE`)
          .then((res) => { 
            showSuccessToast("Menu Deleted");
            setLoaderCheck(false);
            setRefreshState(!refreshState);
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == 'array') {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              showErrorToast(error.response.data.errors.msg);
            }
          });
      };
      
      useEffect(() => {
        setLoaderCheck(true);
        axiosPrivate
          .get('menus/get')
          .then((res) => {
            setLoaderCheck(false);
            const getAllMenus = res.data.data.map((value, index) => {
              let buttons = [];
              if (authPermissions.includes('Menu-update'))
                buttons.push(
                  <Link
                    key="editButton##1"
                    type="button"
                    to={`/admin/menus/edit/${value.id}`}
                    className="btn btn-icon"
                    title="Edit"
                  >
                    <BiEdit style={{ color: 'green' }} size={18} />
                  </Link>
                );
              if (authPermissions.includes('Menu-delete'))
                buttons.push(
                  <button
                    key="deleteButton##1"
                    type="button"
                    data-id={value.id}
                    onClick={() => onPageDelete(value.id)}
                    className="btn btn-icon js-sweetalert"
                    title="Delete"
                  >
                    <RiDeleteBinLine style={{ color: 'red' }} size={17} />
                  </button>
                );
              value['action'] = buttons.length > 0 ? buttons : '-';
              value['sno'] = index + 1;
              if (value.is_active == 0) {
                value['is_active'] = 'Active';
              } else value['is_active'] = 'Inactive';
              return value;
            });
      
            // Sort the array based on the 'created_at' property in descending order
            const sortedMenus = getAllMenus.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
      
            if (authPermissions.includes('Menu-read')) {
              setMenuList(sortedMenus);
            } else {
              setMenuList([]);
            }
          })
          .catch((error) => {
            setLoaderCheck(true);
          });
      }, [refreshState, authPermissions]);
      
      const addLink = authPermissions.includes('Menu-create') === true ? '/admin/menus/add' : '';
      

    return (
        <>
            <TopNavBar links={{ list: '/admin/menus', add: addLink }} />
            <div className="section-body pt-1">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div
                            className="tab-pane fade show active"
                            id="user-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={menuList}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuListing;
