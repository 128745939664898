import { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { GrView } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import DataTable2 from '../../../DataTable2';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';


const Career = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const [careerState, setCareerState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'MOBILE NUMBER', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'RESUME', field: 'resume', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'email'];

	// get All  carrer api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get('career', options)
			.then((career) => {
				setLoaderCheck(false);
				const carrerData = career?.data?.data?.map((value, index) => {
					let buttons = [];
					const data =
						process.env.REACT_APP_COMMON_FILE_URL + 'career/' + value.resume;
					if (authPermissions.includes('Career-read'))
						buttons.push(
							<a
								key={`downloadButton##${index}`}
								href={data}
								target="_blank"
								rel="noreferrer"
							>
								<FiDownload style={{ color: 'blue' }} size={18} />
							</a>
						);

					if (authPermissions.includes('Career-read'))
						buttons.push(
							<Link
								key={`viewButton##${index}`}
								type="button"
								to={`/admin/career/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);
                     value["sr-no"]=index+1
					value['action'] = buttons.length > 0 ? buttons : '-';

					return value;
				});
				if (authPermissions.includes('Career-read')) {
					setCareerState(carrerData);
				} else {
					setCareerState([]);
				}
			}).catch((error) => {
				setLoaderCheck(false);
			})
	}, [authPermissions]);

	// alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Career</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Career</li>
						</ol>
					</div>

					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="carrer-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h1
										className="card-title"
										style={{ fontWeight: '700', fontSize: '18px' }}
									>
										Career
									</h1> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={careerState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Career;
