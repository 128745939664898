import { useContext } from "react";
import LoaderContext from "./LoaderContext";
import "../../../src/assets/css/loader.css";
import loaderGif from "../../assets/images/loader.gif";


const Loader = () => {
    const { loaderCheck } = useContext(LoaderContext);
    
    return (
        <>
            {loaderCheck ? (
                <div className="loader-container">
                    <img
                        src={loaderGif}
                        alt="tytrdtrdfyutdtesdfytx"
                        className="loader-gif"
                    />
                </div>
            ) : null}
        </>
    );
}

export default Loader;
