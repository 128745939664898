import React, { useState, useEffect, useContext } from "react";
import TopNavBar from "../../layouts/TopNavBar";
import { Link } from "react-router-dom";
import { FaGreaterThan } from 'react-icons/fa';
import { GrView } from 'react-icons/gr'
import DataTable2 from "../../../DataTable2";
import axiosPrivate from '../../../../hooks/axiosPrivate';
import LoaderContext from "../../../../context/Loader/LoaderContext";
import AuthContext from '../../../../context/Auth/AuthContext';

function ReturnOrders() {
	const [ReturnorderState, setReturnorderState] = useState([]);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{
			name: 'RETURN NO',
			field: 'return_no',
			sortable: true,
			classKey: '',
		},
		// { name: 'RETURN ORDER ID', field: 'id', sortable: true, classKey: '' },
		
		// {
		// 	name: 'CUSTOMER NAME',
		// 	field: 'user_name',
		// 	sortable: true,
		// 	classKey: '',
		// },
		
		{
			name: 'REASON',
			field: 'reason',
			sortable: true,
			classKey: '',
		},
		{
			name: 'Return Date',
			field: 'date',
			sortable: true,
			classKey: '',
		},
		{
			name: 'ORDER STATUS',
			field: 'status',
			sortable: true,
			classKey: '',
		},

		// { name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['status', 'user_name', 'reason', 'return_order_no', 'id'];

	// get All  ordrs lits api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get('/return-orders', options)
			.then((order) => {
				setLoaderCheck(false);
				const orderData = order?.data?.data?.map((value, index) => {
					let buttons = [];
					// if (true)
					// 	buttons.push(
					// 		<Link
					// 			key={`editButton##${key}`}
					// 			type="button"
					// 			to={`/admin/Ecom-orders/edit/${value.id}`}
					// 			className="btn btn-icon"
					// 			title="Edit"
					// 		>
					// 			<BiEdit style={{ color: 'green' }} size={18} />
					// 		</Link>
					// 	);

					if (authPermissions.includes('Return Order-read'))
						buttons.push(
							<Link
								key={`viewButton##${index}`}
								type="button"
								to={`/admin/returnorders/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);
						value["status"] = (value.status === "pending") ? 
						<button className='btn' style={{ color:"white",background:"orange" }}>{value.status}</button> :
						(value.status === "confirmed") ?
						<button className='btn' style={{ color: 'white',background:"green" }}>{value.status}</button> :
						(value.status === "cancel") ?
						<button className='btn' style={{ color: 'white' ,background:'red'}}>{value.status}</button> :
						(value.status === "shipped") ?
						<button className='btn' style={{ color: 'white' ,background:'yellow'}}>{value.status}</button> :
						value.status;

					value['action'] = buttons.length > 0 ? buttons : '-';
					const formattedDate = new Date(value.date).toLocaleDateString(
						'en-US',
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					);
					value["sr-no"]=index+1
					return {
						...value,
						date: formattedDate,
						return_order_no: value?.return_no,
						

						return_no: (
							<Link
								to={`view/${value.id}`}
								style={{ textDecoration: 'underline' }}
							>
								{value.return_no}
							</Link>
						),
					};
				});

				if (authPermissions.includes('Return Order-read')) {
					setReturnorderState(orderData);
				} else {
					setReturnorderState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				console.log(error);
			})
	}, [authPermissions]);

	console.log(ReturnorderState)

	

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
				<div className="card-header">
						<strong className="top-left-heading">Return Orders</strong>
						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Return Orders</li>
						</ol>
					</div>
					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="orders-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={ReturnorderState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ReturnOrders;