import React, { useState, useEffect } from 'react';
import styles from './ProductListing.module.css';
import { Link, useParams } from 'react-router-dom';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from 'react';
import LoaderContext from '../../../context/Loader/LoaderContext';

function Forestry() {
  const { slug } = useParams();
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get('categories')
      .then((res) => {
        setCategoryData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    const options = {
      headers: { 'content-type': 'application/json' },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get('products', options)
      .then((products) => {
        setLoaderCheck(false);
        setProduct(products?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          //response status is an error code
          console.log(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
          console.log(error.request);
        } else {
          //an error occurred when setting up the request
          console.log(error.message);
        }
      });
  }, []);

  function productslug(slug) {
    return slug
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  return (
    <div>
      <div>
        {categoryData
          ?.filter((res) => res.slug === 'Forestry')
          .map((res) => (
            <>
              <div className='text-center'>
                <h2 className={styles.secondHeading}>{res?.meta_title}</h2>
                <img
              src="../assets/images/home/leaf-1-1 1.webp"
              alt="Leaf"
              loading="lazy"
              className={styles.leaf}
            />
              </div>
              <div className={styles.imgDiv}>
                <img
                  className={styles.categoryImg}
                  src={`${process.env.REACT_APP_COMMON_FILE_URL}categories/${
                    JSON.parse(res?.image)?.file
                  }`}
                  alt='category'
                  loading='lazy'
                />

                <div className={styles.categoryContent}>{res?.description}</div>
              </div>
            </>
          ))}
      </div>
      <div className={styles.productListContainer}>
        {product.length > 0 && (
          <>
            {product
              .filter(
                (product) =>
                  product?.name === 'TIO TIKO' ||
                  product?.name === ' THRIPSKRANTI' ||
                  product?.name === 'RESKUE' ||
                  product?.name === 'BLK' ||
                  product?.name === 'NIPHO' ||
                  product?.name === 'HUMICRONS'
              )
              .map((product, index) => (
                <>
                  {product?.product_variants?.length > 0 ? (
                    <div className={styles.productList}>
                      <div className={styles.productImg}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <img
                            src={product?.product_images[0]?.image}
                            alt={product.name}
                            loading='lazy'
                          />
                        </Link>
                      </div>
                      <div className={`${styles.productDetail} m-4`}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <h1 className={styles.productName}>{product.name}</h1>
                        </Link>
                        <Link to={`/${product.categories_slug}`}>
                          <h4 style={{ fontSize: '1.1rem' }}>
                            {product?.category_name}
                          </h4>
                        </Link>
                        <p>
                          <strong>Types:</strong> {product.types}
                        </p>
                        <p>
                          <strong>Forms:</strong> {product.forms}
                        </p>
                        <p className='pr-5 pr-sm-0'>
                          {' '}
                          <strong>Usage:</strong>
                          {product?.short_description?.length > 364
                            ? product.short_description.slice(0, 364) + '...'
                            : product.short_description}
                        </p>
                        <div className={`${styles.desktopBtn}`}>
                          <Link
                            to={`/product-detail/` + productslug(product.slug)}
                          >
                            <button
                              className='btn btn-primary'
                              style={{ background: '#7fb401' }}
                              //onClick={() => addToCart(product, "buynow")}
                            >
                              Shop Now
                            </button>
                          </Link>
                          <a
                            href={`${process.env.REACT_APP_COMMON_FILE_URL}/products/${product?.product_pdf}`}
                          >
                            <button
                              className='btn btn-primary ml-2'
                              style={{ background: '#7fb401' }}
                            >
                              Know More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className={styles.mobileBtn}>
                        <Link
                          to={`/product-detail/` + productslug(product.slug)}
                        >
                          <button
                            className='btn btn-primary ml-2'
                            style={{ background: '#7fb401' }}
                          >
                            Shop Now
                          </button>
                        </Link>
                        <a
                          href={`${process.env.REACT_APP_COMMON_FILE_URL}/products/${product?.product_pdf}`}
                        >
                          <button
                            className='btn btn-primary ml-2'
                            style={{ background: '#7fb401' }}
                          >
                            Know More
                          </button>
                        </a>
                        {/* <div className={styles.productPriceMobile}>
                          <BsCurrencyRupee />
                          <p>{product.price}</p>
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    <h6 className={styles.secondHeading}>No Products Found</h6>
                  )}
                </>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(Forestry); // Memoize the Forestry;
