import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import DataTable2 from "../../../DataTable2";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import TopNavBar from "../../layouts/TopNavBar";
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";

const Coupon = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const [couponState, setCouponState] = useState([]);

	// header
	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{
			name: "COUPON NAME",
			field: "name",
			sortable: true,
			classKey: "",
		},

		// {
		// 	name: "COUPON CODE",
		// 	field: "coupon_code",
		// 	sortable: true,
		// 	classKey: "",
		// },
		{ name: "COUPON TYPE", field: "coupon_type", sortable: true, classKey: "" },

		{
			name: "COUPON COUNT ",
			field: "coupon_usage",
			sortable: true,
			classKey: "",
		},

		{
			name: "USED COUPON ",
			field: "used_coupon_count",
			sortable: true,
			classKey: "",
		},

		{
			name: "STATUS",
			field: "is_active",
			sortable: true,
			classKey: "",
		},

		{ name: "ACTION", field: "action", classKey: "" },
	];
	// search item
	const searchItems = ["name", "coupon_type"];

	//  coupons data list
	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate.get("coupons", options)
			.then((coupons) => {
				setLoaderCheck(false);
				const couponData = coupons?.data?.data?.map((value,index) => {
					let buttons = [];
					if (authPermissions.includes('Coupon-update'))
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/coupon/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: "green" }} size={18} />
							</Link>
						);
					if (authPermissions.includes('Coupon-delete'))
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => couponDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: "red" }} size={17} />
							</button>
						);
						value["sr-no"]=index+1
					value["action"] = buttons.length > 0 ? buttons : "-";

					return value;
				});

				if (authPermissions.includes('Coupon-read')) {
					setCouponState(couponData);
				} else {
					setCouponState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (typeof error?.response?.data?.errors == "array") {
					showErrorToast(error?.response?.data?.errors[0]?.msg);
				  } else {
					showErrorToast(
					  error?.response?.data?.errors?.msg || error?.response?.data?.message
					);
				  }
			})
	}, [authPermissions]);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function
	const couponDeleteHandler = async (id) => {
		axiosPrivate
			.post(`coupons/${id}?_method=DELETE`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setCouponState([]);
					setAlertMessage({ message: "Coupon Deleted Successfully!" });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (typeof error?.response?.data?.errors == "array") {
					showErrorToast(error?.response?.data?.errors[0]?.msg);
				  } else {
					showErrorToast(
					  error?.response?.data?.errors?.msg || error?.response?.data?.message
					);
				  }
			});
	};

	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { "content-type": "application/json" },
			};
			setLoaderCheck(true);
			axiosPrivate.get("coupons", options)
				.then((coupons) => {
					setLoaderCheck(false);
					const couponData = coupons?.data?.data?.map((value, key) => {
						let buttons = [];
						if (authPermissions.includes('Coupon-update'))
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									to={`/admin/coupon/edit/${value.id}`}
									className="btn btn-icon"
									title="Edit"
								>
									<BiEdit style={{ color: "green" }} size={18} />
								</button>
							);
						if (authPermissions.includes('Coupon-delete'))
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => couponDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<RiDeleteBinLine style={{ color: "red" }} size={17} />
								</button>
							);
						value["action"] = buttons.length > 0 ? buttons : "-";
						return value;
					});

					if (authPermissions.includes('Coupon-read')) {
						setCouponState(couponData);
					} else {
						setCouponState([]);
					}

					resolve(true);
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (typeof error?.response?.data?.errors == "array") {
						showErrorToast(error?.response?.data?.errors[0]?.msg);
					  } else {
						showErrorToast(
						  error?.response?.data?.errors?.msg || error?.response?.data?.message
						);
					  }
				})
		});
	};

	const addLink = authPermissions.includes('Coupon-create') === true ? '/admin/coupon/add' : '';

	return (
		<>
			<TopNavBar links={{ list: '/admin/coupon', add: addLink }} />
			<div className="section-body">
				<div className="section-body">
					<div className="container-fluid">
						<div className="tab-content">
							<div
								className="tab-pane fade show active"
								id="user-list"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											{
												<DataTable2
													lists={couponState}
													headers={headers}
													searchItems={searchItems}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Coupon;
