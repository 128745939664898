import React, { useEffect, useState } from "react";
import ProductDetail from "./ProductDetail";
import MineralPallet from "./MineralPallet";
import ProductQuality from "./ProductQuality";
import Instruction from "./Instruction";
import RecommendedProduct from "./RecommendedProduct";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useParams } from "react-router-dom";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import "./ProductDetailMain.css";
import { showErrorToast } from "../../../Toster";

function ProductDetails() {
  const [product, setProduct] = useState([]);
  const [Details, setDetails] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);

  const slug = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/products/slug/${slug.slug}`, options)
      .then((product) => {
        setLoaderCheck(false);
        setDetails(product.data.data);
        setProduct(product.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, [slug]);

  return (
    <div>
      <ProductDetail product={product} />

      <div
        className=' md-custom-mx-5 md-custom-p-1'
        dangerouslySetInnerHTML={{ __html: product?.description }}
      />
      <ProductQuality />

      {/* <Instruction /> */}

      <RecommendedProduct Details={Details} />
    </div>
  );
}

export default React.memo(ProductDetails); // Memoize the   ProductDetails;
