import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast } from "../../common/Toaster";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./addProductStyle.module.css";
import SiteImage from "../../common/SiteImage";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { Editor } from "primereact/editor";
import AuthContext from "../../../../context/Auth/AuthContext";
import TopNavBar from "../../layouts/TopNavBar";
import JoditEditor from "jodit-react";

const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  parent_id: Yup.string().required("Parent Category is required"),
  slug: Yup.string()
    .max(120, "Slug must not exceed 120 characters")
    .required("Slug is required"),
  short_description: Yup.string().required("Excerpt is required"),
  description: Yup.string().required("Description is required"),
};

const EditProduct = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  let { id } = useParams();
  const navigate = useNavigate();
  const [parentCategories, setParentCategories] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [tableData, setTableData] = useState([
    {
      product_image: "",
      alt_title: "",
      product_pdf: "",
    },
  ]);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("categories/get")
      .then((parentCat) => {
        setLoaderCheck(false);
        setParentCategories(parentCat?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, []);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`products/${id}`)
      .then((productDetail) => {
        formik.setValues({ ...productDetail?.data?.data });
        setLoaderCheck(false);
      })
      .catch((error) => {
        setLoaderCheck(false);
        errorToast(error.message);
      });
  }, [id]);
  const config = {
    controls: {
      font: {
        list: {
          'var(--alice)': 'Alice',
        }
      }
    }
  }; 
  const formik = useFormik({
    initialValues: {
      name: "",
      parent_id: "",
      slug: "",
      types:"",
      forms:"",
      short_description: "",
      description: "",
      product_images: [],
      alt_title: [],
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      structured_data: "",
      product_pdf: [],
      amazon_link:"",
      flipkart_link:"", 
      sequence:0,
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values) => {
      var formData = new FormData();
      tableData.map((value) => {
        formData.append(
          "product_image",
          value.product_image 
        );
        formData.append(
          "alt_title",
          value.alt_title 
        );
        formData.append("product_pdf", value.product_pdf);
        return true;
      });
      formData.append("name", values.name);
      formData.append("parent_id", values.parent_id);
      formData.append("slug", values.slug);
      formData.append("types", values.types);
      formData.append("forms", values.forms);
      formData.append("short_description", values.short_description);
      formData.append("description", values.description);
      formData.append("meta_title", values.meta_title);
      formData.append("meta_keywords", values.meta_keywords);
      formData.append("meta_description", values.meta_description);
      formData.append("structured_data", values.structured_data);
      formData.append("amazon_link", values.amazon_link);
      formData.append("flipkart_link", values.flipkart_link);
      formData.append("sequence", values.sequence);

      setLoaderCheck(true);
      axiosPrivate
        .post(`products/update/${id}?_method=PUT`, formData)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: "Product updated successfully" });
          navigate(`/admin/products/variants/${id}`);
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.status === 422) {
              if (error.response.data.errors.length === undefined) {
                errorToast(error.response.data.errors.msg);
              } else {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  errorToast(error.response.data.errors[i].msg);
                }
              }
            }
          }
        });
    },
  });

  //Function to add new product image row
  const addNewRow = (eve) => {
    eve.preventDefault();
    setTableData([
      ...tableData,
      {
        product_image: "",
        alt_title: "",
      },
    ]);
  };

  //Function to remove product image row
  const removeRow = (index, eve) => {
    eve.preventDefault();
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, i) => i !== index));
    }
    return true;
  };
  const addLink =
    authPermissions.includes("Product-create") === true
      ? "/admin/products/add"
      : "";
  
  return (
    <>
      <TopNavBar links={{ list: '/admin/products', add: addLink }} />
      <div className='section-body pt-1'>
        <div className='container-fluid'>
          <div className='tab-content mt-3'>
            <form onSubmit={formik.handleSubmit}>
              <input type='hidden' name='_method' value='put' />
              <div
                className='tab-pane fade show active'
                id='product-edit'
                role='tabpanel'
              >
                <div className='card'>
                  <div className='card-header'>
                    <strong>Edit Product</strong>
                  </div>
                  <div className='card-body'>
                    <div className='row clearfix'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='name'
                            name='name'
                            type='text'
                            className='form-control'
                            placeholder='Name *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Slug <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='slug'
                            name='slug'
                            type='text'
                            className='form-control'
                            placeholder='Slug *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Parent Category{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <select
                            className='form-control'
                            id='parent_id'
                            name='parent_id'
                            onChange={(e) =>
                              formik.setFieldValue('parent_id', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.parent_id}
                          >
                            <option>Select Parent Category</option>
                            {parentCategories.map((category, key) => (
                              <option
                                key={key + category.name}
                                value={category.id}
                              >
                                {category.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.parent_id &&
                          formik.errors.parent_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.parent_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Short Description{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <JoditEditor config={config}
                            id='short_description'
                            name='short_description'
                            type='text'
                            className='form-control'
                            placeholder='Excerpt *'
                            onChange={(newContent) =>
                              formik.setFieldValue(
                                `short_description`,
                                newContent
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.short_description}
                          />
                          {formik.touched.short_description &&
                          formik.errors.short_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.short_description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Type <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='types'
                            name='types'
                            type='text'
                            className='form-control'
                            placeholder='Types *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.types}
                          />
                          {formik.touched.types && formik.errors.types ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.types}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            About Product<span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='forms'
                            name='forms'
                            type='text'
                            className='form-control'
                            placeholder='Forms *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.forms}
                          />
                          {formik.touched.forms && formik.errors.forms ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.forms}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Sequence <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='sequence'
                            name='sequence'
                            type='number'
                            className='form-control'
                            placeholder='Sequence *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sequence}
                          />
                          {formik.touched.sequence && formik.errors.sequence ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.sequence}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Description <span style={{ color: 'red' }}>*</span>
                          </label>
                          <JoditEditor config={config}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            id='description'
                            name='description'
                            onChange={(newContent) =>
                              formik.setFieldValue('description', newContent)
                            }
                            style={{ height: '200px' }}
                          />

                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>Meta Title</label>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='Meta Title *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_title}
                          />
                          {formik.touched.meta_title &&
                          formik.errors.meta_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_title}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>Meta Keywords</label>
                          <input
                            id='meta_keywords'
                            name='meta_keywords'
                            type='text'
                            className='form-control'
                            placeholder='Meta Keywords *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_keywords}
                          />
                          {formik.touched.meta_keywords &&
                          formik.errors.meta_keywords ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_keywords}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Meta Description{' '}
                          </label>
                          <textarea
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='Meta Description *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_description}
                          />
                          {formik.touched.meta_description &&
                          formik.errors.meta_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>Schema</label>
                          <textarea
                            id='structured_data'
                            name='structured_data'
                            type='text'
                            className='form-control'
                            placeholder='Schema *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.structured_data}
                          />
                          {formik.touched.structured_data &&
                          formik.errors.structured_data ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.structured_data}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Product PDF <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            id='product_pdf'
                            name='product_pdf'
                            type='file'
                            className='form-control'
                            onChange={(event) => {
                              setTableData((prevData) =>
                                prevData.map((data, i) =>
                                  i === 0
                                    ? {
                                        ...data,
                                        product_pdf: event.target.files[0],
                                      }
                                    : data
                                )
                              );
                              formik.handleChange(event);
                            }}
                          />
                          {formik.values.product_pdf && (
                            <p>Selected File: {formik.values.product_pdf}</p>
                          )}
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Amazon_link <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='amazon_link'
                            name='amazon_link'
                            type='text'
                            className='form-control'
                            placeholder='amazon_link *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amazon_link}
                          />
                          {formik.touched.amazon_link &&
                          formik.errors.amazon_link ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.amazon_link}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label className='form-label'>
                            Flipkart_link{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <textarea
                            id='flipkart_link'
                            name='flipkart_link'
                            type='text'
                            className='form-control'
                            placeholder='flipkart_link *'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.flipkart_link}
                          />
                          {formik.touched.flipkart_link &&
                          formik.errors.flipkart_link ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.flipkart_link}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className={`card-body ${classes.tableWrapper}`}>
                          <table
                            className='table table-hover'
                            style={{ width: '100%' }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <label>
                                    Product Image
                                    <span style={{ color: 'red' }}>*</span>
                                  </label>
                                </th>
                                <th>
                                  <label>
                                    Image Alt
                                    <span style={{ color: 'red' }}>*</span>
                                  </label>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type='file'
                                      className='form-control'
                                      onChange={(event) => {
                                        setTableData((prevData) =>
                                          prevData.map((data, i) =>
                                            i === index
                                              ? {
                                                  ...data,
                                                  product_image:
                                                    event.target.files[0],
                                                }
                                              : data
                                          )
                                        );
                                        formik.handleChange(event);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      id={`alt_title[${index}]`}
                                      type='text'
                                      className='form-control'
                                      name={`tableData[${index}].alt_title`}
                                      value={item.alt_title}
                                      onChange={(event) => {
                                        setTableData((prevData) =>
                                          prevData.map((data, i) =>
                                            i === index
                                              ? {
                                                  ...data,
                                                  alt_title: event.target.value,
                                                }
                                              : data
                                          )
                                        );
                                        formik.handleChange(event);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className={classes.actionBtns}>
                                      <button
                                        className={`btn ${classes.addBtn}`}
                                        onClick={addNewRow}
                                      >
                                        <GrAddCircle />
                                      </button>
                                      {tableData.length > 1 && (
                                        <button
                                          className={`btn ${classes.removeBtn}`}
                                          onClick={(event) =>
                                            removeRow(index, event)
                                          }
                                        >
                                          <GrSubtractCircle />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                              {/* To show saved images */}
                              <SiteImage
                                id={id}
                                baseUrl={`${process.env.REACT_APP_COMMON_FILE_URL}/products`}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='col-12  text-right'>
                        <hr className='mt-4' />
                        <button
                          type='button'
                          id='button_1'
                          className='btn btn-secondary mx-1'
                          data-dismiss='modal'
                          onClick={() => navigate('/admin/products')}
                        >
                          CLOSE
                        </button>
                        <button
                          type='submit'
                          id='button_2'
                          className='btn btn-primary'
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
