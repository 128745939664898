import React from 'react'
import styles from './CoreIdea.module.css'
import Icon from './Icon'

function CoreIdea({initialValues}) {
    const coreImages=['BsFillCalculatorFill','TbTarget','FaDiceD6','FaHeart']
    return (
        <div className={styles.container}>
            <img src='./assets/images/home/Group 244.webp' alt="Background" loading='lazy' className={styles.background} />
            <div className={styles.headingContainer}>
                <h2 className={styles.mainHeading}>{initialValues?.mainHeading}<img src={initialValues?.leafImage} alt='Leaf' loading='lazy' className={styles.leaf} />
                </h2>
            </div> 
            <div className={styles.coreContainer}>
                {initialValues?.card?.map((element, index) => (
                    <div className={styles.coreIdea} key={index}>
                        <div className={styles.imgContainer}>
                            <Icon name={coreImages[index]} className={styles.coreIcon} />
                        </div>
                        <h3 className={styles.heading}>{element?.heading}</h3>
                        <p className={styles.content}>{element?.content}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(CoreIdea) // Memoize the CoreIdea
