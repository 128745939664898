import React from "react";
import styles from "./Gallery.module.css";
import { useState, useEffect } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { showErrorToast } from "../../../Toster";

function ProductCrop() {
  const [gallery, setGallery] = useState([]);

  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("crop")
      .then((res) => {
        setLoaderCheck(false);
        setGallery(res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast(error.response.data?.message);
        }
      });
  }, []);

  return (
    <div>
      <div className={styles.galleryContainer}>
        {gallery.filter((res)=>res.images.length >0).map((category)=>(
            <div>
        <div className={styles.outerContainer}>
            <h1 className={styles.mainHeading}>
              {category?.name}
              <img
                src="../assets/images/home/leaf-1-1 1.webp"
                alt="Leaf"
                loading="lazy"
                className={styles.leaf}
              />
            </h1>
            <p style={{fontFamily:'var(--alice)',textAlign:"justify"}}>{category?.description}</p>
            <div className={`${styles.imageContainer} row`}>
              {category.images?.map((image, index) => {
                let mediaType = image.media.split(".");
                if (
                  mediaType[1] == "jpg" ||
                  mediaType[1] == "webp" ||
                  mediaType[1] == "png"
                ) {
                  return (
                    <div
                      key={index}
                      className={`${styles.image} col-lg-4 col-sm-6 col-12 my-2`}
                    >
                      <img
                        src={
                          process.env.REACT_APP_COMMON_FILE_URL +
                          "product-gallery/" +
                          image.media
                        }
                        alt={image.alt}
                        loading="lazy"
                        className={styles.galleryContent}
                      />
                    </div>
                  );
                }
              })}
            </div>
        </div>
        </div>
        ))}
         {/* <div className={styles.videoContainer}>
            <img
              src="../assets/images/home/Group 244.webp"
              className={styles.background}
              alt="background"
              loading="lazy"
            />
            <h2 className={`${styles.subHeading}`}>
              Harmful Chemical Free Healthy Fresh Vegetables !
            </h2>
            <div className={`${styles.imageContainer} row`}>
              {gallery
                ?.filter((gallery) => gallery.media_for == "Crop")
                .map((image, index) => {
                  let mediaType = image.media.split(".");
                  if (mediaType[1] == "mp4") {
                    return (
                      <div
                        key={index}
                        className={`${styles.image} col-lg-4 col-sm-6 col-12 my-2`}
                      >
                        <video controls className={styles.galleryContent}>
                          <source
                            src={
                              process.env.REACT_APP_COMMON_FILE_URL +
                              "product-gallery/" +
                              image.media
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    );
                  }
                })}
            </div>
          </div> */}
      </div>
    </div>
  );
}

export default React.memo(ProductCrop); // Memoize the ProductCrop;
