import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "../../../../Toster";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { useContext } from "react";

const CareerView = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const { setLoaderCheck } = useContext(LoaderContext);

  //   carrer get by id
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate.get(`career/${id}`).then((response) => {
      setLoaderCheck(false);
      if (response.data.code === 200) {
        setGetData(response.data?.data);
      }
    }).catch((erro) => {
      setLoaderCheck(false);
    });
  }, []);

  return (
    <div className="section-body ">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">View Career</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>

            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/career">Career</Link>
            </li>

            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">View Career</li>
          </ol>
        </div>

        <div className="tab-content ">
          <form>
            <div
              className="tab-pane fade show active"
              id="carrer-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong> Career</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    {/* Name*/}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Name:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="name"
                          className="form-control"
                          placeholder="Name "
                          value={getData.name}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* Mobile Number */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Mobile Number:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="mobile"
                          name="mobile"
                          type="number"
                          className="form-control"
                          placeholder="Mobile NUmber "
                          value={getData.mobile}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* Email */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Email:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email "
                          value={getData.email}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* Position Applying For				 */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Position Applying for:
                        <span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="applied_for"
                          name="applied_for"
                          type="text"
                          className="form-control"
                          placeholder="Positing Applaying for "
                          value={getData.applied_for}
                          readOnly
                        />
                      </div>
                    </div>

                    {/*    remark */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Remark:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="remark"
													name="remark"
													type="text"
													placeholder="Remark "
													className="form-control"
													value={getData.remark}
													readOnly
												/>
											</div>
										</div> */}

                    {/*     Resume */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Resume:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="resume"
                          name="resume"
                          type="text"
                          className="form-control"
                          placeholder="Resume "
                          value={getData.resume}
                          readOnly
                        />
                      </div>
                    </div>

                    {/* button */}
                    <div className="col-12 submit-btn text-right">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button
                        type="button"
                        id="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/career")}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerView;
