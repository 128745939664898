import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const TopNavBar = (props) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('');
    useEffect(() => {
        setCurrentPage(location.pathname);
    }, []);

    return (
        <div className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${currentPage == props.links.list ? 'active' : ''}`}
                                id="user-tab"
                                to={props.links.list}
                            >
                                List
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${currentPage == props.links.add ? 'active' : ''}`} id="user-tab" to={props.links.add}>
                                Add New
                            </Link>
                        </li>
                    </ul>
                    <div className="header-action"></div>
                </div>
            </div>
        </div>
    )
}

export default TopNavBar