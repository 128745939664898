import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../DataTable2";
import { FaGreaterThan } from 'react-icons/fa';
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { GrView } from 'react-icons/gr';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';

import TopNavBar from '../../layouts/TopNavBar';


import { showErrorToast } from "../../../../Toster";


function BlogComments() {
	const { authPermissions } = useContext(AuthContext);
	const [enquiryState, setEnquiryState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);
    const { alertMessage, setAlertMessage } = useContext(AlertContext);

	
	const [userState, setUserState] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);

	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		// { name: 'MOBILE No', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'Status', field: 'status', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	const searchItems = ['name', 'email'];

	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate
        
			.get('comment', options)
			.then((res) => {
				setLoaderCheck(false);
				const commentdata= res?.data?.data?.map((value, index) => {
                    
					let buttons = [];
					if (authPermissions.includes('Enquiry-read'))
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/blogcomments/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<i className="fa fa-edit"></i>
							</Link>
						);
                        if (authPermissions.includes('User-delete'))
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => userDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<i className="fa fa-trash-o text-danger"></i>
							</button>
						);
    
					value['action'] = buttons.length > 0 ? buttons : '-';
					
					value["sr-no"]=index+1
					return value;
				});
					setEnquiryState(commentdata);
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
            const userDeleteHandler = async (id) => {
                setLoaderCheck(true);
                axiosPrivate
                    .delete(`comment/${id}`)
                    .then(async (response) => {
                        setLoaderCheck(false);
                        if (response.data.code == 200) {
                            setAlertMessage({ message: 'users deleted successfully' });
                            setRefreshTable(true);
                            await refreshTable();
                        }
                    })
                    .catch((error) => {
                        showErrorToast(error.message);
                        setLoaderCheck(false);
                        if (error.response) {
                            //response status is an error code
                            console.log(error.response.status);
                        } else if (error.request) {
                            //response not received though the request was sent
                            console.log(error.request);
                        } else {
                            //an error occurred when setting up the request
                            console.log(error.message);
                        }
                    });
            };
        
	}, [])

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Blog-Comments</strong>
						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Blog-Comments</li>
						</ol>
					</div>

					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="enquiry-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h1
										className="card-title"
										style={{ fontWeight: '700', fontSize: '18px' }}
									>
										Enquiry
									</h1> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={enquiryState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BlogComments;