import React, { useEffect, useState, useRef } from "react";
import styles from "./Checkout.module.css";
import { BiSolidLock } from "react-icons/bi";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate } from "react-router";
import truckImg from "../../../assets/images/iconoir_delivery-truck.svg";
import { Link } from "react-router-dom";
import UserAddressForm from "../userDashboard/manageAddresses/userAddressesForm";
import { Dialog } from "primereact/dialog";
import { errorToast, successToast } from "../../backend/common/Toaster";

import { useContext } from "react";
import { showErrorToast } from "../../../Toster";
import CheckoutOtp from "../Cart/checkout-otp";

import { FaAngleRight, FaAngleDown } from "react-icons/fa6";
import ProductHistory from "./productHistory";
import LoaderContext from "../../../context/Loader/LoaderContext";
function Checkout({ product }) {
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);
  const [checked, setChecked] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [checkoutProduct, setCheckoutProduct] = useState([]);
  const [checkoutTotal, setCheckoutTotal] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [couponList, setCouponList] = useState([]);
  const [showPaymentMethod, setPaymentMethod] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [shipping_addresses, setShippingAddresses] = useState([]);
  const [differentAddress, setDifferentAddress] = useState(false);
  const [userLogin, setUserLogin] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const [valid, setValid] = useState(false);

  const [action, setAction] = useState(localStorage.getItem("action"));

  const [firstOrder, setFirstOrder] = useState(
    localStorage.getItem("first_order")
  );

  const [visible, setVisible] = useState(false);
  const [sendEditData, setSendEditData] = useState(null);
  const [shippingDelivery, setShippingDelivery] = useState({});
  const [userData, setUserData] = useState({});
  const [couponValue, setCouponValue] = useState(null);
  const [inputCouponValue, setInputCouponValue] = useState(null);
  const [couponName, setCouponName] = useState("");
  const [seletedCouponId, setSelectedCouponId] = useState("");
  const [discountValue, setDiscountValue] = useState(null);
  const [removeCoupon, setRemoveCoupon] = useState(false);

  const [addressType, setAddressType] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [btnBackground, setBtnBackground] = useState(false);
  const buttonRef = useRef(null);
  const buttonRef1 = useRef(null);
  const [newAddress, setNewAddress] = useState(false);
  const [orderHistory, setOrderHistory] = useState(false);

  const [addressId, setAddressId] = useState(null);
  // let userLogin = localStorage.getItem("userData");

  const billingForm = {
    name: "",
    email: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
  };

  const shippingForm = {
    name: "",
    email: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
  };

  const billingFormSchema = Yup.object({
    name: Yup.string().required(" Name is Required"),
    email: Yup.string().email().required("Email is Required"),
    mobile: Yup.string()
      .test(
        "starts-not-with-0-to-5",
        "Please enter a valid mobile number",
        function (value) {
          // Check if the mobile number does not start from 0 to 5
          if (!value) return true; // If no value, let Yup.string().required handle it
          return !/^[0-5]/.test(value);
        }
      )
      .max(10)
      .required("Mobile is Required"),
    address: Yup.string().required("Address is Required"),
    state: Yup.string().required("State is Required"),
    city: Yup.string().required("City is Required"),
    pincode: Yup.string().required("Pincode is Required"),
  });
  const shippingFormSchema = Yup.object({
    name: Yup.string().required(" Name is Required"),
    email: Yup.string().email().required("Email is Required"),
    mobile: Yup.string()
      .test(
        "starts-not-with-0-to-5",
        "Please enter a valid mobile number",
        function (value) {
          // Check if the mobile number does not start from 0 to 5
          if (!value) return true; // If no value, let Yup.string().required handle it
          return !/^[0-5]/.test(value);
        }
      )
      .max(13)
      .required("Mobile is Required"),
    address: Yup.string().required("Address is Required"),
    state: Yup.string().required("State is Required"),
    city: Yup.string().required("City is Required"),
    pincode: Yup.string().required("Pincode is Required"),
  });

  const shippingFormSchema2 = Yup.object({});

  useEffect(() => {
    const div_section = document.querySelector(".contact-us");
    const first_section = document.querySelector(".first-section");
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    if (userLogin) {
      setLoaderCheck(true);
      axiosPrivate
        .get("coupons")
        .then((res) => {
          setLoaderCheck(false);
          setCouponList(res?.data?.data);
        })
        .catch((err) => {
          setLoaderCheck(false);
          showErrorToast(
            err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
          );
        });
    } else {
      // User is not logged in, fetch global active coupons
      setLoaderCheck(true);
      axiosPrivate
        .get("coupons", {
          params: {
            coupon_type: "Global",
            is_active: "Active",
          },
        })
        .then((res) => {
          setLoaderCheck(false);
          const activeGlobalCoupons = res?.data?.data?.filter(
            (coupon) =>
              coupon.is_active === "Active" && coupon.coupon_type === "Global"
          );
          setCouponList(activeGlobalCoupons);
        })
        .catch((err) => {
          setLoaderCheck(false);
          showErrorToast(
            err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
          );
        });
    }
  }, [userLogin]);

  // form1 submit
  const formSubmit = (values) => {
    buttonRef1.current.click();
  };
  //form2 submit
  const formSubmit1 = (values) => {
    if (showPaymentMethod) {
      setErrorMsg("");
      proceedToPay();
    } else {
      errorToast("Please Select a Payment Method!");
      setErrorMsg("*Please Select a Payment Method!");
    }
  };

  const proceedToPay = async () => {
    if (action === "login") {
    } else {
      // if (!differentAddress) {
      //   setValues2(form1Values);
      // }
      let selectedProperties = checkoutProduct?.map(
        ({ product_id, variant_id, quantity }) => ({
          product_id,
          variant_id,
          quantity,
        })
      );
      let guestObject = {
        billing_address: form1Values,
        shipping_address: form2Values,
        cart: selectedProperties,
        payment_mode: showPaymentMethod,
        address: form1Values?.address,
        city: form1Values?.city,
        state: form1Values?.state,
        pincode: form1Values?.pincode,

        // coupon_id: ""
      };
      let registerObject = {
        name: form1Values?.name,
        email: form1Values?.email,
        mobile: form1Values?.mobile,
        password: form1Values?.mobile,
        role: "client",
      };

      if (localStorage.getItem("userData")) {
        setLoaderCheck(true);
        await axiosPrivate
          .post("add-details", guestObject)
          .then((res) => {
            setLoaderCheck(false);

            localStorage.removeItem("cart_items");
            localStorage.setItem("action", "login");
            localStorage.removeItem("first_order");
            successToast("Order Placed Successfully!");
            navigate("/thank-you-page/" + res?.data?.data?.order_id);
          })
          .catch((err) => {
            setLoaderCheck(false);
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          });
      }
    }
  };

  const form1HandleChangeCustom = (event) => {
    if (!differentAddress) {
      setValues2(form1Values);
      form1HandleChange(event);
    } else {
      form2HandleChange(event);
    }
  };

  //form
  const {
    values: form1Values,
    handleSubmit: form1HandleSubmit,
    handleBlur: form1HandleBlur,
    handleChange: form1HandleChange,
    validateForm: form1Validate,
    touched: form1Touched,
    errors: form1Errors,
    setFieldValue: setFiledValue1,
    setValues: setValues1,
  } = useFormik({
    initialValues: billingForm,
    validationSchema: billingFormSchema,
    onSubmit: formSubmit,
  });

  //form1
  const {
    values: form2Values,
    handleSubmit: form2HandleSubmit,
    handleBlur: form2HandleBlur,
    handleChange: form2HandleChange,
    validateForm: form2Validate,
    touched: form2Touched,
    errors: form2Errors,
    setFieldValue: setFiledValue2,
    setValues: setValues2,
  } = useFormik({
    initialValues: shippingForm,
    validationSchema: differentAddress
      ? shippingFormSchema
      : shippingFormSchema2,
    onSubmit: formSubmit1,
  });

  const data = JSON.parse(localStorage.getItem("cart_items"));

  const submitForm = () => {
    // debugger;
    // if (!userLogin) {
    if (action === "register") {
      buttonRef?.current?.click();
    } else {
      const onFirstOrder = async () => {
        await data?.map((item, index) => {
          let object = {
            product_id: item?.product_id,
            variant_id: item?.variant_id,
            quantity: item?.quantity,
          };
          axiosPrivate
            .post("/carts/create", object)
            .then((res) => {
              if (index === data?.length - 1) {
                if (Object.keys(shippingDelivery)?.length > 0) {
                  if (showPaymentMethod) {
                    let orderObject = {
                      payment_mode: showPaymentMethod,
                      bill_address_id: billingAddress?.id,
                      bill_name: billingAddress?.name,
                      bill_mobile: billingAddress?.mobile,
                      bill_address: billingAddress?.address,
                      bill_pincode: billingAddress?.pincode,
                      bill_state: billingAddress?.state,
                      bill_city: billingAddress?.city,
                      ship_address_id: shippingDelivery?.id,
                      ship_name: shippingDelivery?.name,
                      ship_mobile: shippingDelivery?.mobile,
                      ship_address: shippingDelivery?.address,
                      ship_pincode: shippingDelivery?.pincode,
                      ship_state: shippingDelivery?.state,
                      ship_city: shippingDelivery?.city,
                      order_status: "pending",
                      coupon_id: inputCouponValue,
                      gst_no: "test123test",
                      shipping_amount: shippingCharge,
                    };

                    if (seletedCouponId) {
                      orderObject.coupon_id = seletedCouponId;
                    }
                    setLoaderCheck(true);
                    axiosPrivate
                      .post("orders/create", orderObject)
                      .then((res) => {
                        localStorage.removeItem("first_order");
                        localStorage.removeItem("cart_items");

                        navigate("/thank-you-page/" + res.data?.data?.order_id);
                        setLoaderCheck(false);
                        successToast("Order Placed Successfully!");
                      })
                      .catch((err) => {
                        setLoaderCheck(false);
                        showErrorToast(
                          err?.response?.data?.message ||
                            err?.response?.data?.errors[0]?.msg ||
                            err?.response?.data?.errors?.msg
                        );
                      });
                  } else {
                    errorToast("*Please Select Payment Method!");
                    setErrorMsg("*Please Select Payment Method!");
                  }
                } else {
                  setErrorMsg("*Please Select Shipping and Billing Address!");
                  errorToast("Please Select Shipping and Billing Address!");
                }
              }
            })
            .catch((error) => {});
        });
      };
      if (firstOrder) {
        onFirstOrder();
      } else {
        if (Object.keys(shippingDelivery)?.length > 0) {
          if (showPaymentMethod) {
            let orderObject = {
              payment_mode: showPaymentMethod,
              bill_address_id: billingAddress?.id,
              bill_name: billingAddress?.name,
              bill_mobile: billingAddress?.mobile,
              bill_address: billingAddress?.address,
              bill_pincode: billingAddress?.pincode,
              bill_state: billingAddress?.state,
              bill_city: billingAddress?.city,
              ship_address_id: shippingDelivery?.id,
              ship_name: shippingDelivery?.name,
              ship_mobile: shippingDelivery?.mobile,
              ship_address: shippingDelivery?.address,
              ship_pincode: shippingDelivery?.pincode,
              ship_state: shippingDelivery?.state,
              ship_city: shippingDelivery?.city,
              order_status: "pending",
              coupon_id: inputCouponValue,
              gst_no: "test123test",
              shipping_amount: shippingCharge,
            };

            if (seletedCouponId) {
              orderObject.coupon_id = seletedCouponId;
            }
            setLoaderCheck(true);
            axiosPrivate
              .post("orders/create", orderObject)
              .then((res) => {
                localStorage.removeItem("first_order");
                localStorage.removeItem("cart_items");

                navigate("/thank-you-page/" + res.data?.data?.order_id);
                setLoaderCheck(false);
                successToast("Order Placed Successfully!");
              })
              .catch((err) => {
                setLoaderCheck(false);
                showErrorToast(
                  err?.response?.data?.message ||
                    err?.response?.data?.errors[0]?.msg ||
                    err?.response?.data?.errors?.msg
                );
              });
          } else {
            errorToast("*Please Select Payment Method!");
            setErrorMsg("*Please Select Payment Method!");
          }
        } else {
          setErrorMsg("*Please Select Shipping and Billing Address!");
          errorToast("Please Select Shipping and Billing Address!");
        }
      }
    }
  };

  // const onBoxChange = (event) => {
  //   setChecked(event.target.checked);
  //   if (event.target.checked) {
  //     setValues2(form1Values);
  //   } else {
  //     setFiledValue2("name", "");
  //     setFiledValue2("city", "");
  //     setFiledValue2("address", "");
  //     setFiledValue2("pincode", "");
  //     setFiledValue2("email", "");
  //     setFiledValue2("mobile", "");
  //   }
  // };

  useEffect(() => {
    if (userLogin && action === "login" && !firstOrder) {
      setLoaderCheck(true);
      axiosPrivate
        .get("carts/get")
        .then((res) => {
          setLoaderCheck(false);
          setCheckoutProduct(res?.data?.data);
          getUserAddresses();
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error?.response?.data?.data?.length == 0) {
            navigate("/cart");
          }
          showErrorToast(
            error?.response?.data?.message ||
              error?.response?.data?.errors[0]?.msg
          );
        });
    } else {
      // debugger;
      if (action === "login") {
        getUserAddresses();
      }
      let cart_items = JSON.parse(localStorage.getItem("cart_items"));
      setCheckoutProduct(cart_items);
    }
  }, []);

  const refreshCart = () => {
    if (userLogin && action === "login") {
      setLoaderCheck(true);
      axiosPrivate
        .get("carts/get")
        .then((res) => {
          setLoaderCheck(false);
          setCheckoutProduct(res?.data?.data);
          getUserAddresses();
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error?.response?.data?.data?.length == 0) {
            navigate("/cart");
          }
          showErrorToast(
            error?.response?.data?.message ||
              error?.response?.data?.errors[0]?.msg
          );
        });
    } else {
      // debugger;
      let cart_items = JSON.parse(localStorage.getItem("cart_items"));
      setCheckoutProduct(cart_items);
    }
  };

  const getUserAddresses = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get("user-addresses/get")
      .then((res) => {
        setLoaderCheck(false);
        setAddresses(res.data.data);
      })
      .catch((err) => {
        setLoaderCheck(false);
        showErrorToast(
          err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
        );
        // navigate('/admin/about-template/add')
      });
  };

  useEffect(() => {
    if (checkoutProduct?.length > 0) {
      let total = 0;
      checkoutProduct?.forEach((response, index) => {
        total +=
          (response?.regular_price ?? response?.rate) * response?.quantity;
        if (index == checkoutProduct?.length - 1) {
          total = total + shippingCharge;
        }
      });
      setCheckoutTotal(total.toFixed(2));
    }
  }, [checkoutProduct]);

  const openAddressFormForEdit = () => {};

  function selectShippingAddress(res) {
    setShippingDelivery(res);
  }

  function selectBillingAddress(res) {
    setBillingAddress(res);
  }

  const addressEditHandler = (e, res, string) => {
    setVisible(true);
    setAddressType(string);
    setUserData(res);
  };

  const addressAddHandler = (e, string) => {
    setVisible(true);
    setUserData({});
    setAddressType(string);
  };

  const closeDiv = (data) => {
    setNewAddress(false);
    setVisible(false);
    getUserAddresses();
  };

  const applyCoupons = (coupon) => {
    if (!userLogin) {
      showErrorToast("Please sign up or log in to apply the coupon.");
    } else {
      const code = coupon?.coupon_code;
      setCouponValue(code);
      setCouponName(coupon?.name);
    }
  };

  useEffect(() => {
    calculateCouponValue();
  }, [couponValue]);

  const calculateCouponValue = () => {
    if (couponValue) {
      setInputCouponValue(couponValue);
    }
    if (couponValue || inputCouponValue) {
      setLoaderCheck(true);
      axiosPrivate
        .get(`checkout/${userLogin?.userId}/${couponValue ?? inputCouponValue}`)
        .then((res) => {
          setLoaderCheck(false);
          // if (res?.data?.data?.cart_min_amount <= subTotal) {
          successToast("Coupon Applied Successfully!");
          let discount =
            res?.data?.data?.total_bill_amount -
            res?.data?.data?.total_pay_amount;
          setDiscountValue(discount.toFixed(2));
          let totalvalue =
            res?.data?.data?.total_bill_amount - discount + shippingCharge;
          setCheckoutTotal(totalvalue.toFixed(2));
          setSelectedCouponId(res?.data?.data?.coupon_id);
          setRemoveCoupon(true);
        })
        .catch((err) => {
          setCouponName("");
          setLoaderCheck(false);
          setSelectedCouponId("");
          setCouponValue(null);
          setInputCouponValue("");
          showErrorToast(
            err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
          );
        });
    }
  };

  const onRemoveCoupon = () => {
    setRemoveCoupon(false);
    setSelectedCouponId("");
    setCouponName("");
    setCouponValue(null);
    setInputCouponValue("");
    let removedValue = Number(checkoutTotal) + Number(discountValue);
    setCheckoutTotal(removedValue);
    setDiscountValue(null);
    successToast("Coupon Removed Successfully!");
  };

  const numberFilter1 = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFiledValue1(event.target.name, numericValue);
  };

  useEffect(() => {
    if (!differentAddress) setValues2(form1Values);
  }, [form1Values?.mobile, form1Values?.pincode]);

  const numberFilter2 = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFiledValue2(event.target.name, numericValue);
  };

  const nameFilter1 = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFiledValue1(event.target.name, inputValue);
      form1HandleChangeCustom(event);
    }
  };

  const nameFilter2 = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFiledValue2(event.target.name, inputValue);
    }

    form1HandleChangeCustom(event);
  };
  return (
    <>
      <>
        <Dialog
          header="Address Form"
          visible={visible}
          className={styles.addressForm}
          onHide={() => setVisible(false)}
        >
          <UserAddressForm getData={userData} sendData={closeDiv} />
        </Dialog>
      </>
      <div className={styles.checkout}>
        {userLogin && action === "login" ? (
          <>
            <>
              <div className={styles.checkoutContainer}>
                <h2 style={{ fontSize: "25px" }}>Select Billing Address</h2>
                <div className={styles.addressContainer}>
                  {addresses
                    .filter((res) => res.bill_type === "billing")
                    .map((res) => (
                      <>
                        <label className={styles.addressLabel}>
                          <input
                            type="radio"
                            name="value1"
                            style={{ marginTop: "7px" }}
                            onChange={() => {
                              selectBillingAddress(res);
                              setAddressId(res?.id);
                            }}
                          />
                          <div>
                            <h4 style={{ fontSize: "18px" }}>{res?.name}</h4>
                            {/* <span className={styles.phone_number}>{res?.mobile}</span> */}
                            <p className={styles.addressContent}>
                              {res?.address} {res?.landmark} {res?.city}{" "}
                              {res?.state} {res?.pincode}{" "}
                            </p>
                          </div>
                        </label>
                      </>
                    ))}
                </div>

                {/* <h3 style={{fontSize:'18px', fontWeight:'600'}}>Deliver Here</h3> */}
                <h2 style={{ fontSize: "25px" }}>Select Shipping Address</h2>
                <div className={styles.addressContainer}>
                  {addresses
                    .filter((res) => res.bill_type === "shipping")
                    .map((res) => (
                      <>
                        <label className={styles.addressLabel}>
                          <input
                            type="radio"
                            name="value2"
                            style={{ marginTop: "7px" }}
                            onChange={() => {
                              selectShippingAddress(res);
                            }}
                          />
                          <div>
                            <h4 style={{ fontSize: "18px" }}>{res?.name}</h4>
                            {/* <span className={styles.phone_number}>{res?.mobile}</span> */}
                            <p className={styles.addressContent}>
                              {res?.address} {res?.landmark} {res?.city}{" "}
                              {res?.state} {res?.pincode}{" "}
                            </p>
                          </div>
                        </label>
                      </>
                    ))}
                </div>
                <div>
                  <div className={styles.newAddress}>
                    <div
                      className={styles.addressToggle}
                      onClick={() => setNewAddress(!newAddress)}
                    >
                      <h2 style={{ fontSize: "25px", marginBottom: "0px" }}>
                        Add New Address
                      </h2>
                      {newAddress ? <FaAngleDown /> : <FaAngleRight />}
                    </div>

                    {newAddress && (
                      <div style={{ padding: "10px 0px" }}>
                        <UserAddressForm
                          getData={userData}
                          sendData={closeDiv}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.orderHistory}>
                    <div
                      className={styles.orderHistoryToggle}
                      onClick={() => setOrderHistory(!orderHistory)}
                    >
                      <h2 style={{ fontSize: "25px", marginBottom: "0px" }}>
                        View Order History
                      </h2>
                      {orderHistory ? <FaAngleDown /> : <FaAngleRight />}
                    </div>
                    {orderHistory && addressId !== null && (
                      <ProductHistory id={addressId} refresh={refreshCart} />
                    )}
                    {/* <ProductHistory id = {addressId} /> */}
                  </div>
                </div>
              </div>
            </>
          </>
        ) : (
          <div className={styles.addressSection}>
            <div className={styles.billingSection}>
              <form onSubmit={form1HandleSubmit}>
                <h2>
                  Billing Details
                  <img
                    src="../assets/images/home/leaf-1-1 1.webp"
                    alt="leaf"
                    loading="lazy"
                  />
                </h2>
                {/* <div className={styles.sameAsBill}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    name="auto-fill"
                    id="auto-fill"
                    color="green"
                    onClick={(event) => {
                      setDifferentAddress(!differentAddress);
                   
                      if(event){
                        setValues2({})
                      }else{
                        setValues2(form1Values)
                      }
                    }}
                  />
                  <p> Ship to Different Address</p>
                </div> */}

                <label className={styles.container}>
                  Ship to Different Address
                  <input
                    // className={styles.checkbox}
                    type="checkbox"
                    name="auto-fill"
                    id="auto-fill"
                    color="green"
                    onClick={(event) => {
                      setDifferentAddress(!differentAddress);
                      if (event.target.checked) {
                        setValues2({});
                      } else {
                        setValues2(form1Values);
                      }
                    }}
                  />
                  <span className={styles.checkmark}></span>
                </label>
                <div className={styles.name}>
                  <input
                    type="text"
                    className={`${styles.name} ${styles.countryWidth}`}
                    name="name"
                    placeholder="Name"
                    onChange={(event) => {
                      nameFilter1(event);
                    }}
                    onBlur={form1HandleBlur}
                    value={form1Values.name}
                  />
                  {form1Touched.name && form1Errors.name ? (
                    <div className={styles.validationerror}>
                      {form1Errors.name}
                    </div>
                  ) : null}
                </div>
                <div className={styles.name}>
                  <input
                    type="text"
                    className={`${styles.town}  ${styles.countryWidth}`}
                    name="city"
                    placeholder="Town/City"
                    onChange={(event) => {
                      nameFilter1(event);
                    }}
                    onBlur={form1HandleBlur}
                    value={form1Values.city}
                  />
                  {form1Touched.city && form1Errors.city ? (
                    <div className={styles.validationerror}>
                      {form1Errors.city}
                    </div>
                  ) : null}
                </div>
                <div className={styles.name}>
                  <input
                    type="text"
                    className={`${styles.town}  ${styles.countryWidth}`}
                    name="address"
                    placeholder="Address"
                    onChange={(event) => form1HandleChangeCustom(event)}
                    onBlur={form1HandleBlur}
                    value={form1Values.address}
                  />
                  {form1Touched.address && form1Errors.address ? (
                    <div className={styles.validationerror}>
                      {form1Errors.address}
                    </div>
                  ) : null}
                </div>
                <div className={styles.stateDetails}>
                  <input
                    type="text"
                    className={`${styles.state} ${styles.width}`}
                    name="state"
                    placeholder="State"
                    onChange={(event) => {
                      nameFilter1(event);
                    }}
                    onBlur={form1HandleBlur}
                    value={form1Values.state}
                  />
                  {form1Touched.state && form1Errors.state ? (
                    <div className={styles.validationerror}>
                      {form1Errors.state}
                    </div>
                  ) : null}
                  <input
                    type="text"
                    className={`${styles.zip} ${styles.width}`}
                    name="pincode"
                    placeholder="Pin Code"
                    onChange={(event) => {
                      numberFilter1(event);
                    }}
                    onBlur={form1HandleBlur}
                    value={form1Values.pincode}
                    maxLength={6}
                  />
                  {form1Touched.pincode && form1Errors.pincode ? (
                    <div className={styles.validationerror1}>
                      {form1Errors.pincode}
                    </div>
                  ) : null}
                </div>
                <div className={styles.contactDetails}>
                  <input
                    type="email"
                    className={`${styles.email} ${styles.width}`}
                    name="email"
                    placeholder="Email Address"
                    onChange={(event) => form1HandleChangeCustom(event)}
                    onBlur={form1HandleBlur}
                    value={form1Values.email}
                  />
                  {form1Touched.email && form1Errors.email ? (
                    <div className={styles.validationerror}>
                      {form1Errors.email}
                    </div>
                  ) : null}
                  <input
                    type="text"
                    className={`${styles.phone} ${styles.width}`}
                    name="mobile"
                    placeholder="Phone"
                    onChange={(event) => {
                      numberFilter1(event);
                    }}
                    onBlur={form1HandleBlur}
                    value={form1Values.mobile}
                    maxLength={10}
                  />
                  {form1Touched.mobile && form1Errors.mobile ? (
                    <div className={styles.validationerror1}>
                      {form1Errors.mobile}
                    </div>
                  ) : null}
                </div>
                <button type="submit" hidden ref={buttonRef}>
                  submit
                </button>
              </form>
            </div>

            <div className={styles.shippingSection}>
              <form onSubmit={form2HandleSubmit}>
                {differentAddress && (
                  <>
                    <h2>
                      Ship to a Different Address
                      <img
                        src="../assets/images/home/leaf-1-1 1.webp"
                        alt="leaf"
                        loading="lazy"
                      />
                    </h2>

                    <div className={styles.name}>
                      <input
                        type="text"
                        className={`${styles.fname} ${styles.countryWidth}`}
                        name="name"
                        placeholder="Name"
                        onChange={(event) => {
                          nameFilter2(event);
                        }}
                        onBlur={form2HandleBlur}
                        value={form2Values.name}
                        readOnly={checked}
                      />
                      {form2Touched.name && form2Errors.name ? (
                        <div className={styles.validationerror}>
                          {form2Errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.name}>
                      <input
                        type="text"
                        className={`${styles.town}  ${styles.countryWidth}`}
                        name="city"
                        placeholder="Town/City"
                        onChange={(event) => {
                          nameFilter2(event);
                        }}
                        onBlur={form2HandleBlur}
                        value={form2Values.city}
                        readOnly={checked}
                      />
                      {form2Touched.city && form2Errors.city ? (
                        <div className={styles.validationerror}>
                          {form2Errors.city}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.name}>
                      <input
                        type="text"
                        className={`${styles.town}  ${styles.countryWidth}`}
                        name="address"
                        placeholder="Address"
                        onChange={form2HandleChange}
                        onBlur={form2HandleBlur}
                        value={form2Values.address}
                        readOnly={checked}
                      />
                      {form2Touched.address && form2Errors.address ? (
                        <div className={styles.validationerror}>
                          {form2Errors.address}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.stateDetails}>
                      <input
                        type="text"
                        className={`${styles.state} ${styles.width}`}
                        name="state"
                        placeholder="State"
                        onChange={(event) => {
                          nameFilter2(event);
                        }}
                        onBlur={form2HandleBlur}
                        value={form2Values.state}
                        readOnly={checked}
                      />
                      {form2Touched.state && form2Errors.state ? (
                        <div className={styles.validationerror}>
                          {form2Errors.state}
                        </div>
                      ) : null}
                      <input
                        type="text"
                        className={`${styles.zip} ${styles.width}`}
                        name="pincode"
                        placeholder="Pin Code"
                        onChange={(e) => {
                          numberFilter2(e);
                        }}
                        onBlur={form2HandleBlur}
                        value={form2Values.pincode}
                        readOnly={checked}
                        maxLength={6}
                      />
                      {form2Touched.pincode && form2Errors.pincode ? (
                        <div className={styles.validationerror1}>
                          {form2Errors.pincode}
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.contactDetails}>
                      <input
                        type="email"
                        className={`${styles.email} ${styles.width}`}
                        name="email"
                        placeholder="Email Address"
                        onChange={form2HandleChange}
                        onBlur={form2HandleBlur}
                        value={form2Values.email}
                        readOnly={checked}
                      />
                      {form2Touched.email && form2Errors.email ? (
                        <div className={styles.validationerror}>
                          {form2Errors.email}
                        </div>
                      ) : null}

                      <input
                        type="text"
                        className={`${styles.phone} ${styles.width}`}
                        name="mobile"
                        placeholder="Phone"
                        onChange={(e) => {
                          numberFilter2(e);
                        }}
                        onBlur={form2HandleBlur}
                        value={form2Values.mobile}
                        readOnly={checked}
                        maxLength={10}
                      />
                      {form2Touched.mobile && form2Errors.mobile ? (
                        <div className={styles.validationerror1}>
                          {form2Errors.mobile}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                <button type="submit" hidden ref={buttonRef1}>
                  submit
                </button>
              </form>
            </div>
          </div>
        )}
        <div className={styles.orderDetails}>
          <h2>Your Order</h2>
          <table className={styles.productDetails}>
            <thead>
              <tr>
                <th className={"col-4"}>Product</th>
                <th className={"col-4 text-center"}>Quantity</th>
                <th className={"col-4"}>Price</th>
              </tr>
            </thead>
            <tbody>
              {checkoutProduct?.length > 0 &&
                checkoutProduct?.map((res) => (
                  <tr>
                    <td className={"col-4"}>
                      {res?.name ?? res?.product_name}
                    </td>
                    <td className={"col-4 text-center"}>{res?.quantity}</td>
                    <td className={"col-4"}>
                      <div className={styles.productPrice}>
                        <img
                          src="/checkout/currency.png"
                          alt="currency"
                          loading="lazy"
                        />
                        <span className={styles.priceValue}>
                          {res?.quantity * (res?.regular_price ?? res?.rate)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}

              <tr>
                <td className={"col-4"}>Shipping:</td>
                <td className={"col-4 text-center"}></td>
                <td className={"col-4"}>
                  <div className={styles.productPrice}>
                    <span className={styles.priceValue}>free</span>
                  </div>
                </td>
              </tr>
              {discountValue ? (
                <tr>
                  <td className={"col-4"}>Discount:</td>
                  <td className={"col-4 text-center"}></td>
                  <td className={"col-4"}>
                    <div className={styles.productPrice}>
                      <img
                        src="/checkout/currency.png"
                        alt="currency"
                        loading="lazy"
                      />
                      <span className={styles.priceValue}>{discountValue}</span>
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td className={"col-4"} style={{ fontWeight: "bold" }}>
                  Total:
                </td>
                <td className={"col-4 text-center"}></td>
                <td className={"col-4"}>
                  <div className={styles.productPrice}>
                    <img
                      src="/checkout/currency.png"
                      alt="currency"
                      loading="lazy"
                    />
                    <span
                      className={styles.priceValue}
                      style={{ fontWeight: "bold" }}
                    >
                      {checkoutTotal}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class={styles.couponsection}>
          <div class={styles.inputSection}>
            <div class={styles.input_div}>
              {/* <img src='img/edit-icon.svg' alt='edit-icon' loading='lazy' /> */}
              <input
                class={styles.addcoupon}
                name="coupon_id"
                type="text"
                placeholder="Add coupon or gift card"
                value={couponName}
                onChange={(e) => setInputCouponValue(e.target.value)}
              />
            </div>
            <button
              className={styles.couponbutton}
              onClick={(event) => calculateCouponValue()}
            >
              Apply
            </button>
          </div>
        </div>
        {removeCoupon ? (
          <div className={styles.remove_coupon}>
            <p onClick={() => onRemoveCoupon()}>Remove Coupon</p>
          </div>
        ) : (
          ""
        )}

        {couponList?.length > 0 ? (
          <>
            <div class={styles.couponListing}>
              {/* <p class={styles.coupon_list_p}>Coupons</p> */}
              <div class={styles.coupon_list}>
                {couponList
                  ?.filter((res) => res?.is_active == "Active")
                  ?.filter((res) => res?.used_coupon_count <= res?.coupon_usage)

                  ?.map((res) => (
                    <button onClick={(event) => applyCoupons(res)}>
                      {res.name}
                    </button>
                  ))}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className={styles.payment_methods_sections}>
          <h5 className="my-3 payment_heading">Choose Payment Method</h5>
          <div className="methods">
            <div className="d-flex">
              <input
                type="radio"
                onClick={() => setPaymentMethod("COD")}
                name="payment_type"
              />
              <p className="mb-0 mx-2 heading">COD (Cash on delivery)</p>
              <br></br>
            </div>
            {/* <p className="sub-content">
              Safe money transfer using your bank account, Visa, maestro,
              discover, american express.<br></br>
            </p> */}
            {/* <div className="d-flex">
              <input type="radio" onClick={() => setPaymentMethod('Online')} name="payment_type" /><p className="mb-0 mx-2 heading">Online</p><br></br>
            </div> */}
            {/* <p className="sub-content">
              Safe money transfer using your bank account, Visa, maestro,
              discover, american express.<br></br>
            </p> */}
          </div>
        </div>

        <div className={styles.detailsSection} style={{ width: "345px" }}>
          {/* <div className={styles.orderDetail}>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
          </div> */}
          <div className={styles.checkoutProceed}>
            <button type="submit" onClick={submitForm}>
              Confirm Order
            </button>
            {errorMsg ? <div className="invalidDataError">{errorMsg}</div> : ""}
            {/* <p>
              <BiSolidLock className={styles.secureIcon} /> Payments are
              processed securely.
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Checkout); // Memoize the Checkout;
