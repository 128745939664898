import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import moment from "moment";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import DataTable2 from "../../../DataTable2";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";

const AuditLogs = () => {
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [auditState, setAuditState] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  // header
  const headers = [
    // { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
    { name: "User Name", field: "user_name", classKey: "" },
    {
      name: "Module",
      field: "model",
      sortable: true,
      classKey: "",
    },
    { name: "Action", field: "action", sortable: true, classKey: "" },
    { name: "Action Date", field: "created_at", sortable: true, classKey: "" },
  ];

  // search item
  const searchItems = ["user_name", "model", "action"];

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("logs")
      .then((audit) => {
        setLoaderCheck(false);
        let values = audit.data.data.map((res,index) => {
          res["created_at"] = moment(res.created_at).format("DD/MM/YYYY");
          res["sr-no"]=index+1
          res["model"] = res["model"].charAt(0).toUpperCase() + res["model"].slice(1);
          res["action"] = res["action"].charAt(0).toUpperCase() + res["action"].slice(1);
          return res;
        });
        setAuditState(values);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        } else if (error.request) {
          //response not received though the request was sent
          showErrorToast("Internal server error");
        } else {
          //an error occurred when setting up the request
          showErrorToast("Internal server error");
        }
      });
  }, []);

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  return (
    <div>
      <div className="section-body pt-3">
        <div className="container-fluid">
          <div className="card-header">
            <strong className="top-left-heading">Audit Logs</strong>
            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Audit Logs</li>
            </ol>
          </div>
          <div className="tab-content pt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      <DataTable2
                        lists={auditState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditLogs;
