import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../../Toster';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const validationRules = {};

const EnquiryView = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});
	const { setLoaderCheck } = useContext(LoaderContext);

	//   enquairy get by id
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
		.get(`/contact-us/${id}`)
		.then((response) => {
				setLoaderCheck(false);
				//console.log(response.data,"check")
				if (response.data.code == 200) {
					setGetData(response.data.data);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				}
			});
	}, []);

	

	console.log(getData.name)
	return (
		<div className="section-body ">
			<div className="container-fluid">
				{/* hearder breadcrumb */}
				<div className="card-header">
					<strong className="top-left-heading">View Enquiry</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">
							<Link to="/admin/enquiry">Enquiry</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">View Enquiry</li>
					</ol>
				</div>

				<div className="tab-content ">
					<form>
						<div
							className="tab-pane fade show active"
							id="enquiry-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										{/* Name*/}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Name:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="name"
													name="name"
													type="name"
													className="form-control"
													placeholder="Name "
													value={getData.name}
													readOnly
												/>
											</div>
										</div>

										{/* Mobile Number */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Mobile Number:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="mobile"
													name="mobile"
													type="number"
													className="form-control"
													placeholder="Mobile "
													value={getData.mobile}
													readOnly
												/>
											</div>
										</div>

										{/* Email */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Email:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="email"
													name="email"
													type="email"
													className="form-control"
													placeholder="Email "
													value={getData.email}
													readOnly
												/>
											</div>
										</div>

										{/*    Comment */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Comment:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="message"
													name="message"
													type="text"
													className="form-control"
													placeholder="Message "
													value={getData.message}
													readOnly
												/>
											</div>
										</div>
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
											Category:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="Category"
													name="Category"
													type="text"
													className="form-control"
													placeholder="Category "
													value={getData.sell_platform}
													readOnly
												/>
											</div>
										</div>

										{/* close button */}
										<div className="col-12 submit-btn  text-right">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button
												type="button"
												id='button'
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/enquiry')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EnquiryView;
