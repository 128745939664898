import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './UserProfile.module.css';
import { showErrorToast } from '../../../../Toster';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .max(20, 'Password must not exceed 20 characters')
    .min(8, 'Password must exceed 8 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const Changepassword = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  });

  const [errors, setErrors] = useState({
    password: '',
    confirm_password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      return {};
    } catch (error) {
      const formErrors = {};
      error.inner.forEach((e) => {
        formErrors[e.path] = e.message;
      });
      return formErrors;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = await validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      const data = {
        password: formData.password,
        confirm_password: formData.confirm_password,
      };

      axiosPrivate
        .post(`users/change-password`, data)
        .then((response) => {
          toast('Password updated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });

          props.changePasswordClose();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                showErrorToast(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    }
  };

  const closeChangePassword = () => {
    props.changePasswordClose();
  };

  return (
    <>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="user-add" role="tabpanel">
          <div className="card">
            <div className="card-header" style={{ padding: '0 20px 10px 0px' }}></div>
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="row clearfix">
                  {/* password */}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>
                      New Password:<span className="required">*</span>
                    </label>
                    <div className="form-group">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className={`form-control ${errors.password ? classes.invalidDataError : ''}`}
                        placeholder="Password *"
                        onChange={handleInputChange}
                        value={formData.password}
                        onBlur={handleSubmit}
                      />
                      {errors.password && <div className={classes.invalidDataError}>{errors.password}</div>}
                    </div>
                  </div>

                  {/* confirm password */}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label>
                      Confirm Password:<span className="required">*</span>
                    </label>
                    <div className="form-group">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        className={`form-control ${errors.confirm_password ? classes.invalidDataError : ''}`}
                        placeholder="Confirm your password *"
                        onChange={handleInputChange}
                        value={formData.confirm_password}
                        onBlur={handleSubmit}
                      />
                      {errors.confirm_password && (
                        <div className={classes.invalidDataError}>{errors.confirm_password}</div>
                      )}
                    </div>
                  </div>

                  {/* close and update button */}
                  <div className="col-12">
                    <label>
                      <span className="required"></span>
                    </label>
                    <div className="form-group mt-2 submit-btn text-right">
                      <button
                        type="button"
                        id="button_1"
                        className="btn btn-secondary mx-1"
                        data-dismiss="modal"
                        onClick={() => closeChangePassword()}
                      >
                        Close
                      </button>
                      <button type="submit" id="button_2" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
