import { useFormik } from "formik";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Tax.module.css";
import { FaGreaterThan } from "react-icons/fa";
import { showErrorToast } from "../../../../Toster";
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";
import TopNavBar from "../../layouts/TopNavBar";


// validation
const validationRules = {
	name: Yup.string()
		.max(120, "Name must not exceed 120 characters")
		.required("Tax name is required"),
	tax_amount: Yup.string().required("Tax Amount is required"),
};

const AddTax = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const { authPermissions } = useContext(AuthContext);
	const navigate = useNavigate();

	// formik(add)
	const formik = useFormik({
		initialValues: {
			name: "",
			tax_amount: "",
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			setLoaderCheck(true);
			axiosPrivate
				.post("tax/create", values)
				.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: "New  Tax Created Successfully" });
					navigate("/admin/tax");
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (typeof error.response.data.errors == "array") {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});
	const addLink = authPermissions.includes('Tax-create') === true ? '/admin/tax/add' : '';

	return (
		<div>
			 <TopNavBar links={{ list: '/admin/tax', add: addLink }} />
			<div className="section-body pt-1 ">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row clearfix">
											{/*  Tax Name */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Name:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Tax name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>
											{/*  Tax  Amount */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Tax Amount:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="tax_amount"
														name="tax_amount"
														type="number"
														className="form-control"
														placeholder="Tax Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.tax_amount}
													/>
													{formik.touched.tax_amount &&
														formik.errors.tax_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.tax_amount}
														</div>
													) : null}
												</div>
											</div>

											{/* close and submit button */}
											<div className="col-12 submit-btn  text-right">
												<button
													type="button"
													id="button_1"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate("/admin/tax")}
												>
													Close
												</button>
												{authPermissions.includes('Tax-create') && (
													<button type="submit" id="button_2" className="btn btn-primary ml-2">
														Submit
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddTax;
