import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/Auth/AuthContext';
import axiosApiSigninInstance from '../../../hooks/axiosLogin';
import AlertContext from '../../../context/Alert/AlertContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Auth.css';
import { showErrorToast } from '../../../Toster';
import CheckoutContext from '../../../context/Checkout/CheckoutContext';
const CheckoutOtp = () => {
  const { user, setUser } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [validation, setValidation] = useState(true)
  const navigate = useNavigate();
  const {setShowCheckout} = useContext(CheckoutContext)
  const loginSchma = Yup.object({
    mobile: Yup.string().required('Mobile Number is Required'),
    otp: Yup.string().required(' OTP is Required'),
  });

  const loginForm = {
    mobile: '',
    otp: '',
  };

  const otpSchma = Yup.object({
    mobile: Yup.string().required('Mobile Number is Required'),
  });

  const otpForm = {
    mobile: '',
  };

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue ,errors, touched } =
    useFormik({
      initialValues: validation ? otpForm : loginForm,
      validationSchema: validation ? otpSchma : loginSchma,
      onSubmit: (data, values) => {
        validation ? 

        axiosApiSigninInstance
          .post('generate-otp', data)
          .then((res) => {
            setValidation(false)
          //   axiosApiSigninInstance
          // .post('get-otp', data)
          // .then((res) => {
            
          // })
          })

          .catch((err) => {
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          })

          : 
          
          axiosApiSigninInstance
          .post('login', data)
          .then((res) => {
            localStorage.setItem('userData', JSON.stringify(res?.data));
            localStorage.setItem('action', res?.data?.action);
            localStorage.setItem('first_order', true);
            setUser(res?.data);
            if (res?.data?.role == 'client') {
              setShowCheckout(false)
              navigate('/checkout');
              window.location.reload();
            } else {
              navigate('/admin');
              window.location.reload();
            }
          })

          .catch((err) => {
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          });
      },
    });

  

  

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast.success(alertMessage.message, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const numberFilter = (event) => {
    const inputValue = event.target.value;
    const numericValue = parseInt(inputValue.replace(/\D/g, ''), 10);
    setFieldValue(event.target.name, numericValue) 
  };


  return (
    <Fragment>
      <div className='authLeft'>
        <div className='card'>
          {/* <div className='text-center mb-2'>
            <Link className='header-brand' to='/'>
              <img
                src='./../assets/images/home/logo.webp'
                alt='login'
                loading='lazy'
              />
            </Link>
          </div> */}
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='card-title' style={{ fontWeight:'600', color:'#495057', textTransform:'none'}}>Please enter your mobile number</div>

              <div className='form-group'>
                <label className='form-label' style={{color:'#495057'}}>
                  Mobile Number<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='mobile'
                  id='mobile'
                  className='form-control'
                  value={values.mobile}
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  placeholder='Mobile Number'
                  maxLength={10}
                />
                {touched.mobile && errors.mobile ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.mobile}
                  </div>
                ) : null}
              </div>
              {
                 !validation && (
                  <div className='form-group'>
                <label className='form-label' style={{color:'#495057'}}>
                  OTP <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='otp'
                  id='otp'
                  className='form-control'
                  value={values.otp}
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  placeholder='OTP'
                  maxLength={6}
                />
                {touched.otp && errors.otp ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.otp}
                  </div>
                ) : null}
              </div>
                 )
              }
              
              {
                 validation ? (
                  <div className='form-footer'>
                <input
                  type='submit'
                  className='btn  btn-block submit-button'
                  value='Submit'
                />
              </div>
                 )

                 :

                 (
                  <div className='form-footer'>
                <input
                  type='submit'
                  className='btn  btn-block submit-button'
                  value='Verify OTP'
                />
              </div>
                 )
              }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutOtp;
