import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../../Toster';
import AlertContext from '../../../../context/Alert/AlertContext';
import DataTable2 from '../../../../components/DataTable2';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import TopNavBar from '../../layouts/TopNavBar';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const Gallery = () => {
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);
  const [galleryState, setGalleryState] = useState([]);
  const [refreshState, setRefreshState] = useState(false);

  // header
  const headers = [
    // { name: 'Sr No.', field: 'sr-no', sortable: true, classKey: '' },
    { name: 'Media', field: 'image', sortable: true, classKey: 'image-column' },
    { name: 'Media For', field: 'media_for', sortable: true, classKey: '' },

    { name: 'TITLE', field: 'title', sortable: true, classKey: '' },
    {
      name: 'ALT',
      field: 'alt',
      sortable: true,
      classKey: '',
    },

    { name: 'ACTION', field: 'action', classKey: '' },
  ];

  // search item
  const searchItems = ['title'];

  //  gallery data list
  useEffect(() => {
    const options = {
      headers: { 'content-type': 'application/json' },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get('product-gallery', options)
      .then((gallery) => {
        setLoaderCheck(false);
        const galleryData = gallery.data.data.map((value, index) => {
          let buttons = [];
          if (authPermissions.includes('Gallery-update'))
            buttons.push(
              <Link
                key='editButton##1'
                type='button'
                to={`/admin/gallery/edit/${value.id}`}
                className='btn btn-icon'
                title='Edit'
              >
                <BiEdit style={{ color: 'green' }} size={18} />
              </Link>
            );
          if (authPermissions.includes('Gallery-delete'))
            buttons.push(
              <button
                key='deleteButton##1'
                type='button'
                data-id={value.id}
                onClick={() => galleryDeleteHandler(value.id)}
                className='btn btn-icon js-sweetalert'
                title='Delete'
              >
                <RiDeleteBinLine style={{ color: 'red' }} size={17} />
              </button>
            );
          let mediaType = value.media.split('.');
          if (mediaType[1] == 'mp4') {
            value['image'] = (
              <video controls style={{ width: '40%' }}>
                <source
                  src={
                    process.env.REACT_APP_COMMON_FILE_URL +
                    'product-gallery/' +
                    value.media
                  }
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            );
          } else {
            value['image'] = (
              <img
                src={
                  process.env.REACT_APP_COMMON_FILE_URL +
                  'product-gallery/' +
                  value.media
                }
                alt='gallery'
                width='25%'
                loading='lazy'
              />
            );
          }
          value['action'] = buttons.length > 0 ? buttons : '-';

          value['sr-no'] = index + 1;
          return value;
        });

        if (authPermissions.includes('Gallery-read')) {
          setGalleryState(galleryData);
        } else {
          setGalleryState([]);
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        showErrorToast('Internal Server Error');
      });
  }, [refreshState, authPermissions]);

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  // delete function
  const galleryDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`product-gallery/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setRefreshState(true);
          setAlertMessage({ message: ' Gallery Deleted Successfully!' });
        }
      })
      .catch((error) => {
        if (typeof error.response.data.errors == 'array') {
          showErrorToast(error.response.data.errors[0].msg);
        } else {
          showErrorToast(error.response.data.errors.msg);
        }
      });
  };

  const addLink =
    authPermissions.includes('Gallery-create') === true
      ? '/admin/gallery/add'
      : '';

  return (
    <>
      <TopNavBar links={{ list: '/admin/gallery', add: addLink }} />
      <div className='section-body '>
        <div className='container-fluid'>
          <div className='tab-content '>
            <div
              className='tab-pane fade show active'
              id=' gallery-list'
              role='tabpanel'
            >
              <div className='card'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    {
                      <DataTable2
                        lists={galleryState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
