import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addProductStyle.module.css';
import { errorToast, successToast } from '../../common/Toaster';
import EditVariant from './EditVariant';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const validationRules = {
    variant: Yup.string()
        .required('Variant is required'),
    sequence: Yup.number()
        .required('Sequence is required'),
    regular_price: Yup.number()
        .required('Regular Price is required'),
    sale_price: Yup.number()
        .required('Sale Price is required'),
    // gst: Yup.number()
    //     .required('GST is required'),
    // quantity: Yup.number()
    //     .required('Stock is required'),
    // sku: Yup.string()
    //     .required('SKU is required'),
    // image: Yup.mixed()
    //     .required("Image is required"),
    // alt_title: Yup.string()
    //     .required("Image Alt is required"),
};

const ProductVariants = () => {
    const navigate = useNavigate();
    let { product_id } = useParams();
    const [productVariants, setProductVariants] = useState();
    const [regularPrice, setRegularPrice] = useState(0);
    const [gst, setGst] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [refreshtable, setRefreshtable] = useState(false);
    const [variantId, setVariantId] = useState();
    const [modalShow, setModalShow] = useState(false);
    const { setLoaderCheck } = useContext(LoaderContext);
    const [tableData, setTableData] = useState([
        {
            image: '',
            alt_title: '',
            img_sequence:'',
        },
    ]);

    //Function to calculate sale price
    useEffect(() => {
        let price = regularPrice;
        if (gst > 0) {
            price = parseFloat(price) + parseFloat((price * gst) / 100);
        }
        if (discount > 0) {
            price = parseFloat(price) - parseFloat((price * discount) / 100);
        }
        formik.setFieldValue('sale_price', (Math.round(price * 100) / 100).toFixed(2));
    }, [regularPrice, gst, discount]);

    //Get product variants
    useEffect(() => {
        setLoaderCheck(true);
        axiosPrivate
        .get(`product-variants/get/${product_id}`)
        .then((variants) => {
                setLoaderCheck(false);
                setProductVariants(variants?.data?.data);
                setRefreshtable(false);
                // console.log(item.img_sequence);
            })
            .catch((error) => {
                setLoaderCheck(false);
                // errorToast(error.message);
            });
    }, [product_id, refreshtable , modalShow]);

    //Function to store product variant
    const formik = useFormik({
        initialValues: {
            product_id: product_id,
            variant: '',
            sequence: '',
            regular_price: '',
            sale_price: '',
            gst: '0',
            image: [],
            alt_title: [],
            img_sequence: [],
            warranty: '0',
            guarantee: '0',
            quantity: '',
            sku: '',
            discount : '0'
        },
        validationSchema: Yup.object(validationRules),
        onSubmit: async (values) => {
            var formData = new FormData();
            tableData.map((value, index) => {
                formData.append("image", value.image);
                formData.append("alt_title", value.alt_title);
                formData.append("img_sequence", value.img_sequence);
                return true;
            });
            formData.append("product_id", values.product_id);
            formData.append("variant", values.variant);
            formData.append("sequence", values.sequence);
            formData.append("regular_price", values.regular_price);
            formData.append("sale_price", values.sale_price);
            formData.append("gst", values.gst);
            formData.append("warranty", values.warranty);
            formData.append("guarantee", values.guarantee);
            formData.append("quantity", values.quantity);
            formData.append("sku", values.sku);
            formData.append("discount", values.discount);
            setLoaderCheck(true);
            axiosPrivate
            .post('product-variants/create', formData)
            .then((response) => {
                    setLoaderCheck(false);
                    successToast('Product variant created successfully');
                    formik.resetForm();
                    setRefreshtable(true);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    if (error.response) {
                        if (error.response.status === 422) {
                            if (error.response.data.errors.length === undefined) {
                                errorToast(error.response.data.errors.msg);
                            } else {
                                for (let i = 0; i < error.response.data.errors.length; i++) {
                                    errorToast(error.response.data.errors[i].msg);
                                }
                            }
                        }
                    }
                });
        },
    });

    //Function to delete product variant
    const variantDeleteHandler = async (id) => {
        setLoaderCheck(true);
        axiosPrivate
        .post(`product-variants/${id}?_method=DELETE`)
        .then(async (response) => {
                setLoaderCheck(false);
                if (response.data.code === 200) {
                    successToast('Product variant deleted successfully');
                    setRefreshtable(!refreshtable);
                }
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error.message);
            });
    };

    //Function to open product variant modal
    const openModal = async (id) => {
        setVariantId(id);
        setModalShow(true);
    }

    //Function to add new product image row
    const addNewRow = (eve) => {
        eve.preventDefault();
        setTableData([
            ...tableData,
            {
                image: '',
                alt_title: '',
                img_sequence: '',
            },
        ]);
    };

    //Function to remove product image row
    const removeExpensesTask = (index, eve) => {
        eve.preventDefault();
        if (tableData.length > 1) {
            setTableData(tableData.filter((_, i) => i !== index));
        }
        return true;
    };

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div
                                className="tab-pane fade show active"
                                id="product-variant-add"
                                role="tabpanel"
                            >
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Add Product Variant</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Variant <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="variant"
                                                        name="variant"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Variant Name *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.variant || ''}
                                                    />
                                                    {formik.touched.variant && formik.errors.variant ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.variant}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Sequence <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="sequence"
                                                        name="sequence"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Sequence *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.sequence || ''}
                                                    />
                                                    {formik.touched.sequence && formik.errors.sequence ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.sequence}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Variant Image <span style={{ color: "red" }}>*</span></label>
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            name="image"
                                                            accept='image/*'
                                                            onChange={(e) => {
                                                                setFileName(e.currentTarget.files[0].name);
                                                                formik.setFieldValue('image', e.currentTarget.files[0]);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">{fileName}</label>
                                                        {formik.touched.image && formik.errors.image ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.image}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Alt Title <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="alt_title"
                                                        name="alt_title"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Image Alt *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.alt_title || ''}
                                                    />
                                                    {formik.touched.alt_title && formik.errors.alt_title ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.alt_title}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Quantity <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="quantity"
                                                        name="quantity"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Variant Stock *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.quantity || ''}
                                                    />
                                                    {formik.touched.quantity && formik.errors.quantity ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.quantity}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">SKU</label>
                                                    <input
                                                        id="sku"
                                                        name="sku"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Variant SKU *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.sku || ''}
                                                    />
                                                    {formik.touched.sku && formik.errors.sku ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.sku}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Regular Price <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="regular_price"
                                                        name="regular_price"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Regular Price *"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            setRegularPrice(e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.regular_price || ''}
                                                    />
                                                    {formik.touched.regular_price && formik.errors.regular_price ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.regular_price}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">GST(%)</label>
                                                    <input
                                                        id="gst"
                                                        name="gst"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="GST(%)"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            setGst(e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.gst || ''}
                                                    />
                                                    {/* {formik.touched.gst && formik.errors.gst ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.gst}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    {console.log(formik.values.discount)}
                                                    <label className="form-label">Discount </label>
                                                    <input
                                                        id="discount"
                                                        name="discount"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Discount(%)"
                                                        onChange={(e) => { 
                                                            formik.handleChange(e);
                                                            setDiscount(e.target.value)
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.discount || ''}
                                                    />
                                                    {/* {formik.touched.discount && formik.errors.discount ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.discount}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Sale Price <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        id="sale_price"
                                                        name="sale_price"
                                                        type="number"
                                                        readOnly
                                                        className="form-control"
                                                        placeholder="Sale Price *"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.sale_price || ''}
                                                    />
                                                    {formik.touched.sale_price && formik.errors.sale_price ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.sale_price}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Warranty</label>
                                                    <input
                                                        id="warranty"
                                                        name="warranty"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Warranty"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.warranty || 0}
                                                    />
                                                    {formik.touched.warranty && formik.errors.warranty ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.warranty}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="form-label">Guarantee</label>
                                                    <input
                                                        id="guarantee"
                                                        name="guarantee"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Guarantee"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.guarantee || 0}
                                                    />
                                                    {formik.touched.guarantee && formik.errors.guarantee ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.guarantee}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                {/* table */}
                                                <div className={`card-body ${classes.tableWrapper}`}>
                                                    <table
                                                        className="table table-hover"
                                                        style={{ width: '100%' }}
                                                    >
                                                        <thead>
                                                            {/* table heading */}
                                                            <tr>
                                                                <th>
                                                                    <label>
                                                                        Variant Image
                                                                    </label>
                                                                </th>
                                                                <th>
                                                                    <label>
                                                                        Image Alt
                                                                    </label>
                                                                </th>
                                                                <th>
                                                                    <label>
                                                                        Image Seq
                                                                    </label>
                                                                </th>
                                                                <th>
                                                                    <label>
                                                                        {/* Action:<span className="required">*</span> */}
                                                                    </label>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableData.map((item, index) => (
                                                                <tr key={index}>
                                                                    {/* Product Image */}
                                                                    <td>
                                                                        <input
                                                                            id={`image[${index}]`}
                                                                            type="file"
                                                                            className="form-control"
                                                                            name={`tableData[${index}].image`}
                                                                            onChange={(event) => {
                                                                                setTableData((prevData) =>
                                                                                    prevData.map((data, i) =>
                                                                                        i === index
                                                                                            ? { ...data, image: event.target.files[0] }
                                                                                            : data
                                                                                    )
                                                                                );
                                                                                formik.handleChange(event); // Call handleChange with the event
                                                                            }}
                                                                        />
                                                                        {formik.touched.image && formik.errors.image ? (
                                                                            <div className={classes.invalidDataError}>
                                                                                {formik.errors.image}
                                                                            </div>
                                                                        ) : null}
                                                                    </td>
                                                                    {/* alt_title */}
                                                                    <td>
                                                                        <input
                                                                            id={`alt_title[${index}]`}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={`tableData[${index}].alt_title`}
                                                                            value={item.alt_title}
                                                                            onChange={(event) => {
                                                                                setTableData((prevData) =>
                                                                                    prevData.map((data, i) =>
                                                                                        i === index
                                                                                            ? { ...data, alt_title: event.target.value }
                                                                                            : data
                                                                                    )
                                                                                );
                                                                                formik.handleChange(event);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                     {/* img_sequence */}
                                                                     <td>
                                                                        <input
                                                                            id={`img_sequence[${index}]`}
                                                                            type="number"
                                                                            className="form-control"
                                                                            name={`tableData[${index}].img_sequence`}
                                                                            value={item.img_sequence}
                                                                            onChange={(event) => {
                                                                                setTableData((prevData) =>
                                                                                    prevData.map((data, i) =>
                                                                                        i === index
                                                                                            ? { ...data, img_sequence: event.target.value }
                                                                                            : data
                                                                                    )
                                                                                );
                                                                                formik.handleChange(event);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div className={classes.actionBtns}>
                                                                            <button
                                                                                className={`btn ${classes.addBtn}`}
                                                                                onClick={addNewRow}
                                                                            >
                                                                                <GrAddCircle />
                                                                            </button>
                                                                            {tableData.length > 1 && (
                                                                                <button
                                                                                    className={`btn ${classes.removeBtn}`}
                                                                                    onClick={(event) =>
                                                                                        removeExpensesTask(index, event)
                                                                                    }
                                                                                >
                                                                                    <GrSubtractCircle />
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <hr className="mt-4" />
                                                <button
                                                    type="button"
                                                    id='button_1'
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => navigate('/admin/products')}
                                                >
                                                    CLOSE
                                                </button>
                                                <button type="submit" id='button_2' className="btn btn-primary float-right">
                                                    SUBMIT
                                                </button>
                                            </div>
                                            <div className="col-12 table-div" style={{ overflowX: "scroll" }}>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Variant</th>
                                                            <th scope="col">Sequence</th>
                                                            <th scope="col">Stock</th>
                                                            <th scope="col">SKU</th>
                                                            <th scope="col">Regular Price</th>
                                                            <th scope="col">GST</th>
                                                            <th scope="col">Discount</th>
                                                            <th scope="col">Sale Price</th>
                                                            <th scope="col">Warranty</th>
                                                            <th scope="col">Guarantee</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productVariants?.map((value, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{value.variant}</td>
                                                                    <td>{value.sequence}</td>
                                                                    <td>{value.quantity}</td>
                                                                    <td>{value.sku}</td>
                                                                    <td>{value.regular_price}</td>
                                                                    <td>{value.gst}</td>
                                                                    <td>{value.discount}</td>
                                                                    <td>{value.sale_price}</td>
                                                                    <td>{value.warranty}</td>
                                                                    <td>{value.guarantee}</td>
                                                                    <td>
                                                                        <button
                                                                           id={key}
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={() => openModal(value.id)}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </button>
                                                                        <button
                                                                            key="deleteButton##1"
                                                                            id="deleteButton##1"
                                                                            type="button"
                                                                            data-id={value.id}
                                                                            onClick={() => variantDeleteHandler(value.id)}
                                                                            className="btn btn-icon js-sweetalert"
                                                                            title="Delete"
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/*Product variant modal component*/}
            <EditVariant variantId={variantId} isOpen={modalShow} setModalShow={setModalShow} />
        </>
    )
}

export default ProductVariants