import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './ThankYou.module.css';
import { TiTick } from 'react-icons/ti';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { showErrorToast } from '../../../Toster';
import leafImg from '../../../assets/images/gallery/leaf-1-1 1.png';
import LoaderContext from '../../../context/Loader/LoaderContext';
import "./thankyou.css"
function Thankyou() {

  // window.location.reload();


  const [userOrders, setUserOrders] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const userOrderDetails = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`orders/${id}`)
      .then((res) => {
        setLoaderCheck(false);
        setUserOrders(res?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (typeof error.response.data.errors == 'array') {
          showErrorToast(error.response.data.errors[0]?.msg);
        } else {
          showErrorToast(error.response.data.errors?.msg);
        }
      });
  };
  useEffect(() => {
    window.scroll({
      top : 0,
      behavior : "smooth"
    })
    userOrderDetails();
  }, []);

  useEffect(() => {
    const div_section = document.querySelector('.contact-us');
    const first_section = document.querySelector('.first-section');
  });

  
  return (
    <>
      <div className={styles.thankYouPage}>
        <div className={styles.thankYou}>
          <Link>
            <div className={styles.vector}>
              <TiTick className={styles.tick} />
            </div>
          </Link>
        </div>
        <div className={styles.thankYouDescription}>
          <h1>
            Thank You
            <img
              className={styles.leaf}
              src={leafImg}
              alt='leaf'
              loading='lazy'
            />
          </h1>
          <p>
            We're excited to let you know that your order is confirmed! Thank
            you for shopping with us. Your purchase (
            <span className={styles.idBold}>
              {' '}
              Order ID: {userOrders.order_id}{' '}
            </span>
            ) is now being processed and prepared for shipment.
          </p>
        </div>
        <div className={styles.orderDetails}>
          <table className={styles.productDetails}>
            <thead>
              <tr>
                <th>Order detail</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {userOrders?.order_items?.map((res) => (
                <tr>
                  <td>{res?.product_name}</td>
                  <td className={styles.quantity}>{res?.quantity}</td>
                  <td>
                    <img
                      src='/checkout/currency.png'
                      alt='currency'
                      loading='lazy'
                    />
                    {res?.price}
                  </td>
                </tr>
              ))}
              <tr>
                <td>Shipping</td>
                <td></td>
                <td>
                  <img
                    src='/checkout/currency.png'
                    alt='currency'
                    loading='lazy'
                  />
                  {/* {userOrders.shipping_amount} */}
                  Free
                </td>
              </tr>
              <tr>
                <td>Discount</td>
                <td></td>
                <td>
                  <img
                    src='/checkout/currency.png'
                    alt='currency'
                    loading='lazy'
                  />
                  {/* {userOrders.shipping_amount} */}
                  {userOrders?.discount_amount}
                </td>
              </tr>

              <tr>
                <td style={{ fontWeight: 'bold' }}>Total</td>
                <td></td>
                <td style={{ fontWeight: 'bold' }}>
                  <img
                    src='/checkout/currency.png'
                    alt='currency'
                    loading='lazy'
                  />
                  {userOrders?.order_amount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.address}>
          <div className={styles.billingAddress}>
            {
               userOrders?.billingAddress && (
                <>
                <h2>Billing Address</h2>
            <div>
              <span>{userOrders?.bill_name} </span>
              <span>{userOrders?.bill_mobile}</span>
            </div>
            <div>
              <span>
                {' '}
                {userOrders?.bill_address} <br />
              </span>
              <span>{userOrders?.bill_landmark}</span>
            </div>
            <div>
              <span>{userOrders?.bill_city} </span>
              <span>{userOrders?.bill_state} </span>
              <span>{userOrders?.bill_pincode}</span>
            </div>
                </>
               )
            }
          </div>
          <div className={styles.shippingAddress}>
            <h2>Shipping Address</h2>
            <div>
              <span>{userOrders?.ship_name} </span>
              <span>{userOrders?.ship_mobile}</span>
            </div>
            <div>
              <span>
                {' '}
                {userOrders?.ship_address} <br />
              </span>
              <span>{userOrders?.ship_landmark}</span>
            </div>
            <div>
              <span>{userOrders?.ship_city} </span>
              <span>{userOrders?.ship_state} </span>
              <span>{userOrders?.ship_pincode}</span>
            </div>
          </div>
        </div>
        <div className={styles.proceedButton}>
          <a href='/Home'>
            <button className={styles.proceed}>Continue shopping</button>
          </a>
        </div>
      </div>
    </>
  );
}

export default React.memo(Thankyou); // Memoize the Thankyou;
