import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './BlogType.module.css';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import TopNavBar from '../../layouts/TopNavBar';

// validation
const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Blog Type Name Is Required'),
};

const EditBlogType = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);

	//    blog type get by id
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
		.get(`blog-type/${id}`)
		.then((response) => {
				setLoaderCheck(false);
				if (response.data.code == 200) {
					setGetData({ ...response.data.data });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	// formik (send data)

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			setLoaderCheck(true);
			axiosPrivate
			.put(`blog-type/update/${id}`, values)
			.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: 'Blog Category Updated Successfully' });
					navigate('/admin/blogtype');
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (typeof error.response.data.errors === 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	return (
		<>
			<TopNavBar links={{ list: "/admin/blogtype", add: "/admin/blogtype/add" }} />
		<div className="section-body pt-1">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Blog Type</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										{/*  Blog ype Name */}
										<div className="col-lg-12 col-md-12 col-sm-12">
											<label>
												Blog Type Name:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Blog type name"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>

										{/* update and close button */}
										<div className="col-12 submit-btn  text-right">
											<hr className="mt-4" />

											<button
												type="button"
												id='button_1'
												className="btn btn-secondary "
												data-dismiss="modal"
												onClick={() => navigate('/admin/blogtype')}
											>
												Close
											</button>
											{authPermissions.includes('Blog-update') && (
												<button type="submit" id='button_2' className="btn btn-primary ml-2">
													Update
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		</>
	);
};

export default EditBlogType;
