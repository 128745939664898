
import React, { useState, useEffect, useContext } from 'react';
import './Testimonial.css';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useFormik } from 'formik';
import LoaderContext from '../../../context/Loader/LoaderContext';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import { FcApproval } from 'react-icons/fc';
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const validationRules = {
  name: Yup.string()
    .max(120, 'Name must not exceed 120 characters')
    .required('Name is required'),
  location: Yup.string().required('Location is required'),
  remark: Yup.string().required('Remarks is required'),
  product_id: Yup.string().required('Product name is required'),
};

const Testimonial = () => {
  const [productState, setProductState] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('testimonials')
      .then((res) => {
        setLoaderCheck(false);
        setTestimonials(res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        console.log(error?.response?.data?.message);
      });
  }, []);

  const data = testimonials.filter((res) => res.status === 'Published');

  const itemTemplate = (testimonials) => {
    return (
      <div className='data'>
        <>
          <p style={{ textAlign: 'justify', padding: '6% 5% 5% 5%' }}>
            <div>
              <BiSolidQuoteAltLeft className='quote1' color='var(--mustard)' />
            </div>
            &nbsp;
            <span style={{ fontFamily: 'Inter', paddingTop: '5%' }}>
              {testimonials.remark}
            </span>
            &nbsp;
            <div style={{ paddingBottom: '2%', display: 'inline' }}>
              <BiSolidQuoteAltRight className='quote2' color='var(--mustard)' />
            </div>
          </p>
          <div className='d-flex kissan'>
            <div style={{ fontFamily: 'Inter' }}>
              <h2>
                {testimonials.name} <FcApproval />
              </h2>
              <p>{testimonials.location}</p>
            </div>
          </div>
        </>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      remark: '',
      product_id: '',
      status: 'Pending',
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values) => {
      setLoaderCheck(true);
      axiosPrivate
        .post('testimonials', values)
        .then((response) => {
          setLoaderCheck(false);
          showSuccessToast('Comment Added');
          formik.resetForm();
        })
        .catch((error) => {
          setLoaderCheck(false);
        });
    },
  });

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('products')
      .then((products) => {
        setLoaderCheck(false);
        setProductState(products.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        console.log(error?.response?.data?.message);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div style={{ marginTop: '3%', fontFamily: 'Inter' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Farmers Testimonial</h1>
        <img
          src='../assets/images/home/leaf-1-1 1.webp'
          alt='Leaf'
          style={{ position: 'relative', bottom: '54px', left: '86px' }}
        />
      </div>
      <div className='parentClass'>
        <div className='carousel'>
          <Slider {...settings} className='custom-carousel'>
            {data.map((item, index) => (
              <div key={index}>{itemTemplate(item)}</div>
            ))}
          </Slider>
        </div>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div
            className='tab-pane fade show active'
            id='product-add'
            role='tabpanel'
          >
            <div className='card'>
              <div className='card-body'>
                <h2 style={{ color: 'black', paddingBottom: '4%' }}>
                  Add Comment
                </h2>
                <div className='row clearfix'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>
                        Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        className='form-control'
                        placeholder='Name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        style={{ backgroundColor: 'var(--light-3)' }}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: 'red' }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>
                        Location <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id='location'
                        name='location'
                        type='text'
                        className='form-control'
                        placeholder='Location'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                        style={{ backgroundColor: 'var(--light-3)' }}
                      />
                      {formik.touched.location && formik.errors.location ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.location}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>
                        Product
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <select
                        className='form-control'
                        id='product_id'
                        name='product_id'
                        onChange={(e) => {
                          // const productName = e.target.value
                          // const selectedProductId = productMap[productName];
                          formik.setFieldValue('product_id', e.target.value);
                          // setProduct_Name(e.target.value)
                          // productId(e.target.value)
                        }}
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.product_id}
                        style={{ backgroundColor: 'var(--light-3)' }}
                      >
                        <option>Select Product</option>
                        {productState.map((value, key) => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.product_id && formik.errors.product_id ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.product_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='form-group'>
                      <label className='form-label'>
                        Remarks
                        <span style={{ color: 'red' }}> *</span>
                      </label>
                      <textarea
                        id='remark'
                        name='remark'
                        type='text'
                        className='form-control'
                        placeholder='Remarks *'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.remark}
                        style={{ backgroundColor: 'var(--light-3)' }}
                        // cols="50"
                        // rows='30'
                      />
                      {formik.touched.remark && formik.errors.remark ? (
                        <div style={{ color: 'red' }}>
                          {formik.errors.remark}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-12  text-center text-sm-right'>
                    <button type='submit' id='button_2' className='mt-4 button'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Testimonial);
