import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import styles from './Navbar.module.css';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { useContext } from 'react';

function Navbar() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [hideSearchIcon, setHideSearchIcon] = useState(false);
  const [menus, setMenus] = useState([]);
  const [openSubMenuId, setOpenSubMenuId] = useState(null); 
  const [show, setShow] = useState(false);
  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get('menus')
      .then((res) => {
        setLoaderCheck(false);

        const menu = res?.data?.data;
        const sortedData = [...menu].sort((a, b) => {
          if (a.sequence === null || b.sequence === null) {
            // Handle cases where one or both sequence values are null
            return 0; // No change in order
          }
          return a.sequence - b.sequence;
        });
        setMenus(sortedData);
      })
      .catch((err) => {
        setLoaderCheck(false);

        setMenus([]);
      });
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
    setHideSearchIcon(true);
    if (e.target.value.length === 0) {
      setHideSearchIcon(true);
    }
  };

  function showSubMenus(e, res) {
    if (openSubMenuId === res.id) {
      // If the clicked submenu is already open, close it
      setOpenSubMenuId(null);
      setShow(false); // Hide the submenus
    } else {
      // Otherwise, open the clicked submenu
      setOpenSubMenuId(res.id);
      setShow(true); // Show the submenus
    }
  }

  function hideShow(e, res) {

    if (res?.is_active == 1) {
      navigate('/comming-soon');
    } else if (res?.submenus?.is_active == 1) {
      navigate('/comming-soon');
    } else {
      navigate('/' + res?.slug);
    }
    setOpenSubMenuId(null);
  }

  useEffect(() => {
    if (openSubMenuId !== null) {
      // Log the open submenu's id
    }
  }, [openSubMenuId]);

  return (
    <div className={styles.desktopNavbar}>
      <div className={`${styles.navbar}`}>
        <ul className={styles.navList}>
          {menus.map((res) => (
            <li
              className={`${styles.navItem} ${styles.farmersMenu}`}
              key={res.id}
            >
              {res?.submenus?.length > 0 ? (
                <span style={{ cursor: 'pointer' }}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      showSubMenus(e, res);
                    }}
                    className={`${
                      openSubMenuId === res.id ? styles.menuIcon : ''
                    }`}
                  >
                    {res.title}
                  </span>
                </span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => hideShow(e, res)}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      showSubMenus(e, res);
                    }}
                    className={`${
                      openSubMenuId === res.id ? styles.menuIcon : ''
                    }`}
                  >
                    {res.title}
                  </span>
                </span>
              )}
              {res?.submenus?.length > 0 ? (
                <MdKeyboardArrowDown
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    showSubMenus(e, res);
                  }}
                  className={`${
                    openSubMenuId === res.id ? styles.menuIcon : ''
                  }`}
                />
              ) : (
                ''
              )}

              {openSubMenuId === res.id ? (
                <ul
                  className={`${styles.farmersLink} ${
                    show ? styles.showMenu : styles.hideMenu
                  }`}
                >
                  {res.submenus.map((resp, index) => (
                    <span style={{ cursor: 'pointer' }} key={index}>
                      <li
                        className={`${styles.subMenu} mx-2`}
                        onClick={(e) => hideShow(e, resp)}
                      >
                        {resp.title}
                      </li>
                    </span>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </li>
          ))}
          {/* ... */}
        </ul>
        <div className={styles.social}>
          <div className={styles.FreeConsultation}>
            <Link
              to='/contact-us'
              style={{ backgroundColor: 'white' }}
              className={styles.FreeConst}
            >
              Free Consultation
            </Link>
          </div>

          {/* <Link to="/">
            <div className={styles.socialLink}>
              <AiFillInstagram />
            </div>
          </Link>

          <Link to="/">
            <div className={styles.socialLink}>
              <FaTwitter />
            </div>
          </Link>

          <Link to="/">
            <div className={styles.socialLink}>
              <IoLogoLinkedin />
            </div>
          </Link>

          <Link to="/">
            <div className={styles.socialLink}>
              <TiSocialYoutube />
            </div>
          </Link> */}
        </div>
        <div className={`${styles.searchContainer} ${styles.searchSection}`}>
          <input
            type='search'
            name='search'
            id='search'
            placeholder='Search...'
            value={search}
            className={`${styles.search}`}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <BsSearch
            className={`${styles.searchIcon} ${styles.responsiveSearch} ${
              hideSearchIcon ? styles.hide : styles.show
            }`}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(Navbar); // Memoize the Navbar;
