import React from 'react'
import styles from './ProductQuality.module.css'

function ProductQuality() {
    return (
        <div className={styles.qualityContainer}>
            <div className={styles.desktopBackground}>
                <img className={styles.background} src="../../assets/images/home/Group 244.webp" alt="background" loading='lazy' />
            </div>

            <div className={styles.productQuality}>
                <div className={styles.mobileBackground}>
                    <img className={styles.qualityBackground} src="../../assets/images/home/Group 244.webp" alt="background" loading='lazy' />
                </div>
                <div className={styles.image}>
                    <img src="../../assets/images/home/image 66.webp" alt="" loading='lazy' />
                </div>
                <div className={styles.quality}>
                    <p>Improves physical, chemical
                        & biological properties by reducing
                        salts present in the soil.</p>
                </div>
            </div>
            <div className={styles.productQuality}>
                <div className={styles.mobileBackground}>
                    <img className={styles.qualityBackground} src="../../assets/images/home/Group 244.webp" alt="background" loading='lazy' />
                </div>
                <div className={styles.image}>
                    <img src="../../assets/images/home/image 67.webp" alt="" loading='lazy' />
                </div>
                <div className={styles.quality}>
                    <p>Improves the quality of all type of crops,
                        vegetables, and helps increase soil
                        porosity & stimulates nutrient absorption.</p>
                </div>
            </div>
            <div className={styles.productQuality}>
                <div className={styles.mobileBackground}>
                    <img className={styles.qualityBackground} src="../../assets/images/home/Group 244.webp" alt="background" loading='lazy' />
                </div>
                <div className={styles.image}>
                    <img src="../../assets/images/home/image 68.webp" alt="" loading='lazy' />
                </div>
                <div className={styles.quality}>
                    <p>Easy to apply as it is available
                        in 10 mm size granules.</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ProductQuality) // Memoize the ProductQuality
