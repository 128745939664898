import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';
import DataTable2 from '../../../DataTable2';
import TopNavBar from '../../layouts/TopNavBar';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import AuthContext from '../../../../context/Auth/AuthContext';
import { errorToast } from '../../common/Toaster';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const SubCategories = (props) => {
    const { alertMessage, setAlertMessage } = useContext(AlertContext);
    const { authPermissions } = useContext(AuthContext);
    const [subCategoryState, setSubCategoryState] = useState([]);
    const { setLoaderCheck } = useContext(LoaderContext);

    const headers = [
        // { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
        { name: 'NAME', field: 'name', sortable: true, classKey: '' },
        { name: 'PARENT CATEGORY', field: 'parent_name', sortable: true, classKey: '' },
        { name: 'ACTION', field: 'action', classKey: '' },
    ];

    const searchItems = ['name'];

    useEffect(() => {
        const options = {
            headers: { 'content-type': 'application/json' },
        };
        setLoaderCheck(true);
        axiosPrivate
        .get('sub-categories', options)
        .then((subCategories) => {
                setLoaderCheck(false);
                const subCategoriesData = subCategories.data.data.map((value,index) => {
                    let buttons = [];
                    if (authPermissions.includes('Sub Category-update'))
                        buttons.push(
                            <Link
                                key="editButton##1"
                                type="button"
                                to={`/admin/sub-categories/edit/${value.id}`}
                                className="btn btn-icon"
                                title="Edit"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                        );
                    if (authPermissions.includes('Sub Category-delete'))
                        buttons.push(
                            <button
                                key="deleteButton##1"
                                type="button"
                                data-id={value.id}
                                onClick={() => subCategoryDeleteHandler(value.id)}
                                className="btn btn-icon js-sweetalert"
                                title="Delete"
                            >
                                <i className="fa fa-trash-o text-danger"></i>
                            </button>
                        );
                    value['action'] = buttons.length > 0 ? buttons : '-';
                    value["sr-no"]=index+1
                    return value;
                });

                if (authPermissions.includes('Sub Category-read')) {
                    setSubCategoryState(subCategoriesData);
                } else {
                    setSubCategoryState([]);
                }
            })
            .catch((error) => {
                // errorToast(error.message);
                setLoaderCheck(false);
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }, []);

    useEffect(() => {
        if (alertMessage != null) {
            toast(alertMessage.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setAlertMessage(null);
        }
    }, [alertMessage]);

    const subCategoryDeleteHandler = async (id) => {
        setLoaderCheck(true);
        axiosPrivate
        .delete(`categories/${id}`)
        .then(async (response) => {
            setLoaderCheck(false);
            if (response.data.code == 200) {
                setSubCategoryState([]);
                setAlertMessage({ message: 'Sub Category deleted successfully' });
                await refreshTable();
            }
        })
        .catch((error) => {
                setLoaderCheck(false);
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    };

    const refreshTable = () => {
        return new Promise((resolve, reject) => {
            const options = {
                headers: { 'content-type': 'application/json' },
            };
            setLoaderCheck(true);
            axiosPrivate
            .get('sub-categories', options)
            .then((subCategories) => {
                    setLoaderCheck(false);
                    const subCategoriesData = subCategories.data.data.map((value, index) => {
                        let buttons = [];
                        if (authPermissions.includes('Sub Category-update'))
                            buttons.push(
                                <button
                                    key="editButton##1"
                                    type="button"
                                    className="btn btn-icon"
                                    title="Edit"
                                >
                                    <i className="fa fa-edit"></i>
                                </button>
                            );
                        if (authPermissions.includes('Sub Category-delete'))
                            buttons.push(
                                <button
                                    key="deleteButton##1"
                                    type="button"
                                    data-id={value.id}
                                    onClick={() => subCategoryDeleteHandler(value.id)}
                                    className="btn btn-icon js-sweetalert"
                                    title="Delete"
                                >
                                    <i className="fa fa-trash-o text-danger"></i>
                                </button>
                            );
                            value["sr-no"]=index+1
                        value['action'] = buttons.length > 0 ? buttons : '-';
                        return value;
                    });

                    if (authPermissions.includes('Sub Category-read')) {
                        setSubCategoryState(subCategoriesData);
                    } else {
                        setSubCategoryState([]);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    reject(error);
                    // errorToast(error.message);
                });
        });
    };

    const addLink = authPermissions.includes('Sub Category-create') === true ? '/admin/sub-categories/add' : '';

    return (
        <div>
            <TopNavBar links={{ list: '/admin/sub-categories', add: addLink }} />
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="category-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-header">
                                    {/* <h3 className="card-title">SUB CATEGORY LIST</h3> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={subCategoryState}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubCategories;
