import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import classes from "../Blog/Blog.module.css"; // Make sure to specify the correct CSS file path

const EditComments = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const { setLoaderCheck } = useContext(LoaderContext);


  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`comment/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        const commentData = response.data?.data;
        // Set values in formik for each input field
        formik.setFieldValue("name", commentData[0].name);
        formik.setFieldValue("mobile", commentData[0].mobile);
        formik.setFieldValue("email", commentData[0].email);
        formik.setFieldValue("message", commentData[0].message);
        formik.setFieldValue("status", commentData[0].status);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          console.log(error.response.status);
        }
      });
  }, [id]);

  const initialValues = {
    status: "",
    name: "",
    email: "",
    message: "",
    mobile: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setLoaderCheck(true);
      axiosPrivate
        .put(`comment/${id}`, values)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: "Status updated" });
          navigate("/admin/blogcomments");
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            if (error.response.status !== 200) {
              for (let i = 0; i < error.response?.data?.errors?.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  // enquairy get by id
  

  return (
    <div className="section-body">
      <div className="container-fluid">
        {/* header breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit Blog Comments</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>

            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/enquiry">Edit Blog Comments</Link>
            </li>
          </ol>
        </div>

        <div className="tab-content">
          <form onSubmit={formik.handleSubmit}>
            <div
             
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    {/* Name */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Name:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name *"
                          value={formik.values.name}
                          readOnly
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Mobile Number */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Mobile Number:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="mobile"
                          name="mobile"
                          type="number"
                          className="form-control"
                          placeholder="Mobile No"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                          readOnly
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Email */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Email:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Email ID *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          readOnly
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Comment */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Comment:<span className="required"></span>
                      </label>
                      <div className="form-group">
                        <textarea
                          id="message"
                          name="message"
                          type="text"
                          className="form-control"
                          placeholder="Comment"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                          readOnly
                        />
                        {formik.touched.message && formik.errors.message ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Status:<span className="required"></span>
                        </label>
                        <select
                          className="form-control show-tick"
                          id="status"
                          name="status"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.status}
                        >
                          <option>Select Status</option>
                          <option value="Pending">Pending</option>
                          <option value="Published">Published</option>
                          <option value="Rejected">Reject</option>
                        </select>
                        {formik.touched.status && formik.errors.status ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.status}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Close button */}
                    <div className="col-12 text-right">
                      <button
                        type="button"
                        id="button_1"
                        className="btn btn-secondary mx-1"
                        onClick={() => navigate("/admin/blogcomments")}
                      >
                        CLOSE
                      </button>

                      <button
                        type="submit"
                        id="button_2"
                        className="btn btn-primary float-right"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditComments;
