import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../layouts/TopNavBar';
import AlertContext from '../../../../context/Alert/AlertContext';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './addSubCategoryStyle.module.css';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SUPPORTED_FORMATS = ["image / jpg", "image / png", "image / jpeg", "image / gif"];

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	parent_id: Yup.string()
		.required('Parent Category is required'),
	slug: Yup.string()
		.max(120, 'Slug must not exceed 120 characters')
		.required('Slug is required'),
	description: Yup.string()
		.required('Description is required'),
	image: Yup.mixed()
		.required("Image is required"),
	alt_title: Yup.string()
		.required("Image Alt is required"),
	meta_title: Yup.string()
		.required("Meta Title is required"),
	meta_keywords: Yup.string()
		.required("IMeta Keywords is required"),
	meta_description: Yup.string()
		.required("Meta Description is required"),
	structured_data: Yup.string()
		.required("Schema is required"),
};

const AddSubCategory = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const navigate = useNavigate();
	const [parentCategories, setParentCategories] = useState([]);

	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
		.get('categories')
		.then((parentCat) => {
				setLoaderCheck(false);
				setParentCategories(parentCat?.data?.data);
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	const formik = useFormik({
		initialValues: {
			name: '',
			parent_id: '',
			slug: '',
			description: '',
			file: '',
			alt_title: '',
			meta_title: '',
			meta_keywords: '',
			meta_description: '',
			structured_data: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var formData = new FormData();
			formData.append("name", values.name);
			formData.append("parent_id", values.parent_id);
			formData.append("slug", values.slug);
			formData.append("description", values.description);
			formData.append("image", values.image);
			formData.append("alt_title", values.alt_title);
			formData.append("meta_title", values.meta_title);
			formData.append("meta_keywords", values.meta_keywords);
			formData.append("meta_description", values.meta_description);
			formData.append("structured_data", values.structured_data);
			setLoaderCheck(true);
			axiosPrivate
			.post('categories/create', formData)
			.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: 'New sub category created successfully' });
					navigate('/admin/sub-categories');
					console.log(response);
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (error.response) {
						if (error.response.status === 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div>
			<TopNavBar links={{ list: '/admin/sub-categories', add: '/admin/sub-categories/add' }} />
			<div className="section-body pt-1">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="sub-category-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Sub Category</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="slug"
														name="slug"
														type="text"
														className="form-control"
														placeholder="Slug *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.slug}
													/>
													{formik.touched.slug && formik.errors.slug ? (
														<div className={classes.invalidDataError}>
															{formik.errors.slug}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<select
														className="form-control"
														id="parent_id"
														name="parent_id"
														onChange={(e) =>
															formik.setFieldValue('parent_id', e.target.value)
														}
														onBlur={formik.handleBlur}
														value={formik.values.parent_id}
													>
														<option>Select Parent Category</option>
														{parentCategories.map((category, key) => (
															<option key={key + category.name} value={category.id}>
																{category.name}
															</option>
														))}
													</select>
													{formik.touched.parent_id && formik.errors.parent_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.parent_id}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<textarea
														id="description"
														name="description"
														type="text"
														className="form-control"
														placeholder="Description *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.description}
													/>
													{formik.touched.description && formik.errors.description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="imageSubCategory"
														name="image"
														type="file"
														// accept='image/*'
														className="form-control"
														placeholder="Image *"
														onChange={(e) =>
															formik.setFieldValue('image', e.currentTarget.files[0])
														}
														onBlur={formik.handleBlur}
													/>

													{formik.touched.image && formik.errors.image ? (
														<div className={classes.invalidDataError}>
															{formik.errors.image}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="alt_title"
														name="alt_title"
														type="text"
														className="form-control"
														placeholder="Image Alt *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.alt_title}
													/>
													{formik.touched.alt_title && formik.errors.alt_title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.alt_title}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="meta_title"
														name="meta_title"
														type="text"
														className="form-control"
														placeholder="Meta Title *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_title}
													/>
													{formik.touched.meta_title && formik.errors.meta_title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_title}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="meta_keywords"
														name="meta_keywords"
														type="text"
														className="form-control"
														placeholder="Meta Keywords *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_keywords}
													/>
													{formik.touched.meta_keywords && formik.errors.meta_keywords ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_keywords}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<textarea
														id="meta_description"
														name="meta_description"
														type="text"
														className="form-control"
														placeholder="Meta Description *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_description}
													/>
													{formik.touched.meta_description && formik.errors.meta_description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.meta_description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12">
												<div className="form-group">
													<textarea
														id="structured_data"
														name="structured_data"
														type="text"
														className="form-control"
														placeholder="Schema *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.structured_data}
													/>
													{formik.touched.structured_data && formik.errors.structured_data ? (
														<div className={classes.invalidDataError}>
															{formik.errors.structured_data}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-12  text-right">
												<hr className="mt-4" />
												<button
													type="button"
													id='button_1'
													className="btn btn-secondary mx-1"
													data-dismiss="modal"
													onClick={() => navigate('/admin/sub-categories')}
												>
													CLOSE
												</button>
												{authPermissions.includes('Sub Category-create') && (
													<button type="submit" id='button_2' className="btn btn-primary">
														SUBMIT
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSubCategory;
