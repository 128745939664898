import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TbGardenCart } from "react-icons/tb";
import { FaBookmark } from "react-icons/fa";
import styles from "./ProductDetail.module.css";
import { BsCurrencyRupee } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
// import { Carousel } from 'primereact/carousel';
import axiosPrivate from "./../../../hooks/axiosPrivate";
import CartCountContext from "../../../context/CartCount/CartCountContext";
import { errorToast, successToast } from "../../backend/common/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { Helmet } from "react-helmet";
import { TiTick } from "react-icons/ti";
function ProductDetail({ product }) {
  const slug = useParams();
  const navigate = useNavigate();
  const imgRef = useRef();

  const [selectedVarient, setSelectedVarient] = useState(null);
  const [product_variant, setProduct_variant] = useState({});
  const { setLoaderCheck } = useContext(LoaderContext);
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);
  const [imageChangeByVariant, setImageChange] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [wishList, setWishList] = useState(false);

  let action = localStorage.getItem("action");

  const [firstOrder, setFirstOrder] = useState(
    localStorage.getItem("first_order")
  );

  const handleMouseMove = (e) => {
    const { left, top, width, height } = imgRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    imgRef.current.style.transformOrigin = `${x}% ${y}%`;
  };

  useEffect(() => {
    if (Object.keys(product)?.length > 0) {
      if (slug.id) {
        onVariantChange(slug?.id);
      } else {
        onVariantChange(product?.product_variants[0]?.id);
      }
    }
  }, [product]);

  const onVariantChange = (id) => {
    setSelectedVarient(id);
    let product_variant = product?.product_variants?.filter(
      (res) => res?.id == id
    );

    if (product_variant.length > 0) {
      setImageChange(
        process.env.REACT_APP_COMMON_FILE_URL +
          "product-variants/" +
          product_variant[0].varinat_iamges[0].image
      );
    }

    if (product_variant?.length > 0) {
      let obj = product_variant[0];
      setProduct_variant(obj);
    }
  };

  const addToCart = (string) => {
    let userLogin = localStorage.getItem("userData");
    let object = {
      product_id: product.id,
      variant_id: selectedVarient,
      quantity: 1,
    };
    if (userLogin && action === "login") {
      setLoaderCheck(true);
      axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
          setLoaderCheck(false);
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
          setCartCountCheck(!cartCountCheck);
          // debugger;
          successToast("Item Added in Cart!");
          // setAlertMessage({ message: "Item Added in Cart!" });
          // if (string == 'buynow') {
          navigate("/cart");
          // }
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast("Something Went Wrong!");
          // setAlertMessage({ message: "Something Went Wrong!" });
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        });
    } else {
      object["product_name"] = product?.name;
      object["rate"] = product_variant?.regular_price;
      //  object["base_unit"] = productDetail.product_variants[0].base_unit;
      object["variant"] = product_variant?.variant;
      object["short_description"] = product?.short_description;
      if (product?.length > 0 || Object.keys(product)?.length > 0) {
        object["image"] = product_variant?.varinat_iamges[0]?.image;
      }
      let cart_items = localStorage.getItem("cart_items");
      let cart_array = [];
      cart_array.push(object);
      if (!cart_items) {
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      } else {
        let cart_array = JSON.parse(cart_items);
        let boolean_cart = false;
        cart_array?.forEach((element) => {
          if (object.variant_id == element.variant_id) {
            boolean_cart = true;
            element.quantity = element.quantity + 1;
          }
        });

        if (!boolean_cart) {
          cart_array.push(object);
        }
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      }
      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      successToast("Item Added in Cart!");
      setCartCountCheck(!cartCountCheck);
      if (string == "buynow") {
        navigate("/cart");
      }
    }
  };

  const getWishList = () =>{
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("wishlist/get", options)
      .then((res) => {
        res?.data?.data?.map((item) =>{
          
          if(item?.product_id === product?.id){
            setWishList(true)
          }
        })
      })
      .catch((error) => {
        
      });
  }
  useEffect(() => {
    getWishList()
  },[product?.id]);

  if (localStorage.getItem("userData")) {
    var { userId } = JSON.parse(localStorage.getItem("userData"));
  }

  const productSaveHandler = (e, res) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if(!userData){
      errorToast("First, you need to login, to add a product to your wishlist.");
    }
    const object = {
      user_id: userId,
      variant_id: res?.product_variants[0]?.id,
      product_id: res?.id,
      ...res,
    };
    axiosPrivate
      .post("wishlist/create", object)
      .then((res) => {
        if (res.data?.code == 201) {
          successToast("Product added to Wishlist");
          getWishList();
        }else{
          errorToast("First you have to login!");
        }
      })
      .catch((error) => {
        errorToast("First you have to login!");
        errorToast(error?.response?.data?.message);
      });
  };

  const handleImageClick = (image) => {
    setSelectedImage(
      process.env.REACT_APP_COMMON_FILE_URL + "product-variants/" + image
    );
  };

  useEffect(() => {
    if (selectedImage) {
      setShowImage(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (imageChangeByVariant) {
      setShowImage(imageChangeByVariant);
    }
  }, [imageChangeByVariant]);

  const liquidVariant = product?.product_variants?.filter((res) => {
    return (
      res?.variant?.toLowerCase().includes("ltr") ||
      res?.variant?.toLowerCase().includes("ml")
    );
  });

  const kgVariant = product?.product_variants?.filter((res) => {
    return (
      res?.variant?.toLowerCase().includes("kg") ||
      res?.variant?.toLowerCase().includes("grams")
    );
  });

  return (
    <>
      <Helmet>
        <title>{product?.meta_title}</title>
        <meta name="description" content={product?.meta_description} />
        <meta name="keywords" content={product?.meta_keywords} />
        <meta name="tags" content={product?.tags} />
        <meta property="og:title" content={product?.og_title} />
        <meta property="og:description" content={product?.og_description} />
        <meta property="og:image" content={product?.og_image} />
        <meta property="og:url" content={product?.og_image} />
        <meta name="twitter:title" content={product?.meta_title} />
        <meta name="twitter:description" content={product?.meta_description} />
        <meta name="twitter:image" content={product?.og_image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className={`${styles.productDetailContainer} productDetailPage `}>
        <div className={styles.productDetail}>
          <div className={styles.productImages}>
            <div className={styles.mainImage}>
              <div className={styles.imgDiv} onMouseMove={handleMouseMove}>
                {showImage && (
                  <img
                    src={showImage}
                    ref={imgRef}
                    className={styles.hoverZoom}
                    alt=""
                    loading="lazy"
                  />
                )}
              </div>
            </div>

            <div className={styles.productMoreImages}>
              {/* <div className={styles.moreImage}> */}
              {/* <Carousel value={products} numScroll={1} numVisible={3} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} /> */}

              {/* </div> */}
              {product?.product_variants?.length > 0 &&
                product_variant?.varinat_iamges?.map((res, index) => (
                  <div className={styles.moreImage}>
                    <img
                      src={
                        process.env.REACT_APP_COMMON_FILE_URL +
                        "product-variants/" +
                        res?.image
                      }
                      alt="varintImage"
                      loading="lazy"
                      onClick={() => handleImageClick(res?.image)}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className={styles.detail}>
            {/* heading */}
            <div className={styles.aboutProduct}>
              <div className={styles.description}>
                <h1 className={styles.productName}>{product?.name}</h1>
                <p>{product.forms}</p>
              </div>
              <div className={styles.productVarient}>
                <button onClick={() => addToCart()}>
                  <TbGardenCart className={styles.cart} />
                </button>
                <button className={!wishList ? styles.bookmark1 : styles.bookmark2} onClick={(e) => productSaveHandler(e, product)}>
                  <FaBookmark className={styles.save} />
                </button>
              </div>
            </div>
            {/* varient */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                marginLeft: "-10px",
              }}
            >
              <div className={styles.varientCardContainer}>
                {kgVariant?.map((res) => (
                  <button
                    key={res?.id}
                    className={
                      selectedVarient == res?.id
                        ? styles?.varientCardButtonClicked
                        : styles?.varientCardButton
                    }
                    onClick={() => onVariantChange(res?.id)}
                  >
                    {/* {
                    selectedVarient == res?.id ? <TiTick color="white" style={{backgroundColor:'var(--green)', borderRadius:'100%', outline:'2px solid white', position:'absolute', left:'-9', top:'15'}} /> : null
                  } */}
                    {res?.variant}
                  </button>
                ))}
              </div>

              <div className={styles.varientCardContainer}>
                {liquidVariant?.map((res) => (
                  <button
                    key={res?.id}
                    className={
                      selectedVarient == res?.id
                        ? styles?.varientCardButtonClicked
                        : styles?.varientCardButton
                    }
                    onClick={() => onVariantChange(res?.id)}
                  >
                    {/* {
                    selectedVarient == res?.id ? <TiTick color="white" style={{backgroundColor:'var(--green)', borderRadius:'100%', outline:'3px solid white', position:'absolute', left:'-8'}} /> : null
                  } */}
                    {res?.variant}
                  </button>
                ))}
              </div>
            </div>

            {/* <div className={styles.varientContainer}>
              <div className={`${styles.productVarient} productVarient`}>
                <div className='card flex justify-content-center'>
                <Dropdown
                  value={selectedVarient}
                  optionValue='id'
                  onChange={(e) => onVariantChange(e.value)}
                  options={product?.product_variants}
                  optionLabel='variant'
                  placeholder='Varient'
                  className='w-full md:w-14rem'
                />

                </div>
              </div>
              

              
            </div> */}

            <div>
              {/* price */}
              <div className={`${styles.productPrice} ${styles.productName}`}>
                <BsCurrencyRupee />
                {/* {Object.keys(product_variant)?.length > 0 ? */}
                <p>{product_variant?.regular_price}</p>
                {/* : ''}  */}
              </div>
              {/* product description */}
              <div className={styles.description}>
                <p>
                  <strong>Category{"  "}:</strong> {product.category_name}
                </p>

                {/* <p><strong>Suitable:</strong>{product.short_description}</p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: product?.short_description,
                  }}
                />
              </div>
              {/* stock */}
              <div className={styles.stock}>
                <p>In stock</p>
              </div>
              {/* buy now */}
              <div>
                <button
                  onClick={() => addToCart("buynow")}
                  className={styles.buyNow}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProductDetail); // Memoize the ProductDetail;
