import React from 'react';
import img1 from '../../../assets/images/DisImg1.svg';
import img2 from '../../../assets/images/Group 1.svg';
import dealersInfoImg from '../../../assets/images/dealers-info.svg';
import './DisSection1.css';

const DisSection1 = (pageData) => {
  return (
    <div className='row '>
      <div className='col-sm mb-3 mb-sm-0'>
        <img src={img1} alt='logoImg' className='img-fluid' loading='lazy' />
        <div className='dealers-infoImg'>
          <p className='paragraph'>{pageData?.pageData?.maincontent}</p>
          <img
            style={{ display: 'none' }}
            src={dealersInfoImg}
            alt='dealers and factories info'
            className='img-fluid'
            loading='lazy'
          />
        </div>
      </div>
      <div className='col-sm'>
        <img
          src={pageData?.pageData?.mainImage}
          alt='natural-img'
          className='img-fluid'
          loading='lazy'
        />
      </div>
    </div>
  );
};

export default DisSection1;
