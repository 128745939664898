import React from "react";
import styles from "./WhatsappLogo.module.css";
import { BsWhatsapp } from "react-icons/bs";
import { useState } from "react";

const WhatsappLogo = () => {
  const createWhatsAppURL = (phoneNumber) => {
    const phoneWithoutSpecialChars = phoneNumber.replace(/[^\d]/g, "");
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneWithoutSpecialChars}`;
    return whatsappURL;
  };

  const phoneNumber = "9429198147";

  return (
    <div>
      <a
        href={createWhatsAppURL(phoneNumber)}
        className={styles.float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className={styles["prevent-color-change"]} />
      </a>
    </div>
  );
};

export default React.memo(WhatsappLogo); // Memoize the WhatsappLogo;
