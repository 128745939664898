import React from 'react'
import styles from './Instruction.module.css'

function Instruction() {
    return (
        <div className={styles.instructionContainer}>
            <div className={styles.instruction}>
                <h2 className={styles.query}>What does it do?</h2>
                <p className={styles.description}>Minfert | BLK is a 100% natural product to enhance plant growth. BLK is useful to improve the physical properties of soil. It enhances the development of white root system. Minfert | BLK also increases the soil microbes and resistance power of the plant, which allows it to fight against pests and diseases. It is vital for the overall development of crops.</p>
            </div>

            <div className={styles.instruction}>
                <h2 className={styles.query}>How to use</h2>
                <p className={styles.description}>FARM USE: Mix 5 kg Minfert-BLK with any manure/fertilizer for 1 bigha land.
                    For Home Gardens: BLK is a micronutrient that is incredibly useful and specially modified for your home garden needs. Crafted in its noodle structure, Minfert | BLK is created so that your plant can take care of itself. To get the best results, add 3-4 tablespoons of Minfert | BLK in 1 planter, mix well in soil. Then, unlike other fertilizers, it dissolves slowly and acts as ‘food’ for the plant, providing all the right nutrients day after day, leading to miraculous results.</p>
            </div>

            <div className={styles.instruction}>
                <h2 className={styles.query}>Where to use</h2>
                <p className={styles.description}>Minfert-BLK Practically can be used on any soil and crop. It gives best results in Cotton, Tobacco, Sugarcane, Soybean all type of Oilseeds, and all vegetables like Brinjal, Tomatoes, Potatoes, Cabbage, Chillies, cucumber, Onion, etc.</p>
            </div>
        </div>
    )
}

export default React.memo(Instruction) // Memoize the Instruction
