import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from '../../../../hooks/axiosPrivate';
import classes from './BlogType.module.css';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import TopNavBar from '../../layouts/TopNavBar';

// valodation
const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Blog type name is required'),
};

const AddBlogType = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);

	// formik(add)
	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			setLoaderCheck(true);
			axiosPrivate
				.post('blog-type/create', values)
				.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: 'New Blog Category Created Successfully' });
					navigate('/admin/blogtype');
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (typeof error.response.data.errors === 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	return (
		<div>
			<TopNavBar links={{ list: "/admin/blogtype", add: "/admin/blogtype/add" }} />
			<div className="section-body pt-1">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row clearfix">
											{/*   blog type Name */}
											<div className="col-lg-12 col-md-12 col-sm-12">
												<label>
													Name:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Blog Type Name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>

											{/* button close and submit */}
											<div className="col-12 submit-btn  text-right">
												<button
													type="button"
													id='button_1'
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/blogtype')}
												>
													Close
												</button>
												{authPermissions.includes('Blog Category-create') && (
													<button type="submit" id='button_2' className="btn btn-primary ml-2">
														Submit
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddBlogType;
