import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from 'react';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import { showErrorToast } from '../../../../Toster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { useContext } from 'react';
import "./submit.css"

function UserAddressForm({ getData, sendData }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showCompany, setShowCompany] = useState('')
  const { setLoaderCheck } = useContext(LoaderContext);
  const initialValues = {
    name: '',
    mobile: '',
    email: '',
    address: '',
    landmark: '',
    city: '',
    state: '',
    pincode: '',
    bill_type: '',
    company: '',
  };

  const addressSchema = Yup.object({
    name: Yup.string().required("Please enter your name").min(4),
  mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid mobile number', function(value) {
    // Check if the mobile number does not start from 0 to 5
    if (!value) return true; // If no value, let Yup.string().required handle it
    return !/^[0-5]/.test(value);
  }).required("Please enter your mobile number"),
  email: Yup.string()
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid Email!"
    )
    .required("Please enter your email address"),
  city: Yup.string().required("Please enter City name"),
  address: Yup.string().required("Please enter Address"),
  // city: Yup.string().required('Please enter your city'),
  pincode: Yup.string().required("Please enter your pincode")
    .matches(/^[0-9]{6}$/, "Pincode is not valid")
    ,
  state: Yup.string().required("Please select your state"),
  // district: Yup.string().required('Please enter your district'),
  });

  useEffect(() => {
    if (getData !== null) {
      setValues(getData);
    }
  }, []);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addressSchema,
    onSubmit: (values) => {
      let formData = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        address: values.address,
        landmark: values.landmark,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        bill_type: values.bill_type,
        company: values.company
      };

      if (Object.keys(getData).length > 0) {
        setLoaderCheck(true);
        axiosPrivate
          .put(`/user-addresses/update/${getData.id}`, formData)
          .then((res) => {
            setLoaderCheck(false);
            sendData('close modal');
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == 'array') {
              showErrorToast(error.response.data.errors[0]?.msg);
            } else {
              showErrorToast(error.response.data.errors?.msg);
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post(`user-addresses/create`, formData)
          .then((response) => {
            setLoaderCheck(false);
            sendData('close modal');
          })
          .catch((error) => {
            setLoaderCheck(false);
            showErrorToast("Address already exists")
            if (typeof error.response.data.errors == 'array') {
              showErrorToast(error.response.data.errors[0]?.msg);
            } else {
              showErrorToast(error.response.data.errors?.msg);
            }
          });
      }
    },
  });

  const states = [
    {
      id: 1,
      name: 'Rajasthan',
    },
    {
      id: 2,
      name: 'Gujarat',
    },
    {
      id: 3,
      name: 'Maharashtra',
    },
    {
      id: 4,
      name: 'Kerala',
    },
    {
      id: 5,
      name: 'Madhya Pradesh',
    },
    {
      id: 6,
      name: 'Karnataka',
    },
    {
      id: 7,
      name: 'Jharkhandh',
    },
    {
      id: 8,
      name: 'Uttar Pradesh',
    },
    {
      id: 9,
      name: 'Himachal Pradesh',
    },
    {
      id: 10,
      name: 'Hariyana',
    },
    {
      id: 11,
      name: 'Goa',
    },
  ];

  const handleState = (id) => {
    let data = states.filter((dataItem) => dataItem.id === id);
    setSearchQuery(data[0].name);
  };


  const companyVisible = (event) =>{
    setShowCompany(event.target.value)
  }


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };
  return (
    <div className='address-form-container'>
      <div className='modal-content'>
        <div className='modal-body'>
          <form className='container' onSubmit={handleSubmit}>
            {/* name */}
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='name'>
                  Name<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='name'
                  value={values?.name}
                  id='name'
                  className='form-control'
                  placeholder='Enter Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.name}
                  </p>
                ) : null}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='mobile'>
                  Mobile Number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='mobile'
                  value={values?.mobile}
                  id='mobile'
                  className='form-control'
                  placeholder='Mobile'
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  maxLength={10}
                />
                {errors.mobile && touched.mobile ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.mobile}
                  </p>
                ) : null}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='email1'>
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='email'
                  name='email'
                  value={values?.email}
                  id='email1'
                  className='form-control'
                  placeholder='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.email}
                  </p>
                ) : null}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='address1'>
                  Address <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='address'
                  id='address'
                  value={values?.address}
                  className='form-control'
                  placeholder='Address'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.address && touched.address ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.address}
                  </p>
                ) : null}
              </div>
              {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='landmark'>
                  Landmark <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='landmark'
                  value={values?.landmark}
                  id='landmark'
                  className='form-control'
                  placeholder='Enter Landmark'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.landmark && touched.landmark ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.state}
                  </p>
                ) : null}
              </div> */}

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='shipping_state'>
                  State <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  placeholder='State'
                  name='state'
                  value={values?.state}
                  className='form-control'
                  onChange={handleChange}
                />
                {errors.state && touched.state ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.state}
                  </p>
                ) : null}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='shipping_city'>
                  City <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='city'
                  id='city'
                  value={values?.city}
                  className='form-control'
                  placeholder='City Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.city && touched.city ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.city}
                  </p>
                ) : null}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label htmlFor='shipping_zip'>
                  Pincode <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='pincode'
                  id='pincode'
                  value={values?.pincode}
                  className='form-control'
                  placeholder='Enter Pincode'
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  maxLength={6}
                />
                {errors.pincode && touched.pincode ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.pincode}
                  </p>
                ) : null}
              </div>


              <div className='col-lg-6 col-md-6 col-sm-12'>
                <label>
                  Address Type:
                  <span className='required' style={{ color: 'red' }}>
                    *
                  </span>
                </label>

                <div className='form-group'>
                  <select
                    className='form-control '
                    id='bill_type'
                    name='bill_type'
                    onChange={(event) => {
                      handleChange(event);
                      companyVisible(event)
                    }}
                    onBlur={handleBlur}
                    value={values.bill_type}
                  >
                    <option disabled selected value="">Select Address Type</option>
                    <option value={"shipping"}>Shipping Address</option>
                    <option value={"billing"}>Billing Address</option>
                  </select>
                  {errors.bill_type && touched.bill_type ? (
                    <p style={{ color: 'red', fontSize: 'smaller' }}>
                      {errors.bill_type}
                    </p>
                  ) : null}
                </div>
              </div>
              {
                 showCompany === "shipping" && (
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                <label htmlFor='shipping_zip'>
                  Company Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='company'
                  id='company'
                  value={values?.company}
                  className='form-control'
                  placeholder='Enter Company Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.company && touched.company ? (
                  <p style={{ color: 'red', fontSize: 'smaller' }}>
                    {errors.company}
                  </p>
                ) : null}
              </div>
                 )
              }

              {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="shipping_zip">Pincode <span style={{color:'red'}}>*</span></label>
                <input
                  type="text"
                  name="pincode"
                  id="pincode"
                  value={values?.bill_type}
                  className="form-control"
                  placeholder="select bill type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.pincode && touched.pincode ? (
                  <p style={{ color: "red", fontSize: "smaller" }}>
                    {errors.pincode}
                  </p>
                ) : null}
              </div> */}

              <div className='col-lg-12 col-md-12 col-sm-12 text-right'>
                <button
                  type='submit'
                  className='btn btn-primary mt-0 submit-button submitButton'
                  style={{ backgroundColor: '#7fb401' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UserAddressForm) || UserAddressForm;
