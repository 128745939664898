import React from "react";
import styles from "./RecomendedProduct.module.css";
import { Link } from "react-router-dom";

function RecommendedProduct({ Details }) {
  const initialValues = {
    mainHeading: "Recommended Products",
  };
  return (
    <div className={styles.productContainer}>
      <h2 className={styles.mainHeading}>{initialValues.mainHeading}</h2>
      <div className={styles.recomendedProducts}>
        {Details !== undefined &&
          Details.length !== 0 &&
          Details.related_products.slice(0, 6).map((product, index) => {
            return (
              <Link
                key={product.id} // Make sure to include a unique key
                to={{
                  pathname: `/product-detail/${product.slug}`,
                }}
              >
                <div className={styles.card}>
                  <div className={styles.image}>
                    <img
                      src={
                        process.env.REACT_APP_COMMON_FILE_URL +
                        `products/${product?.product_image[0]?.image}`
                      }
                      alt={product.product_image?.alt_text || "Product Image"}
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h3 className={styles.productName}>{product.name}</h3>
                  </div>
                  <div
                    className={`${styles.productPrice} ${styles.productName}`}
                  >
                    {/* <BsCurrencyRupee />
                    <p>{Details.product_variants[0].regular_price}</p> */}
                  </div>
                  {/* <div className={styles.icons}>
                   <button className="btn btn-success details-button">View Details</button>
                  </div> */}
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}

export default React.memo(RecommendedProduct) || RecommendedProduct;
