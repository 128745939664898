import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import styles from './manageAddresses.module.css';
import truckImg from '../../../../assets/images/iconoir_delivery-truck.svg';
import { Dialog } from 'primereact/dialog';
import UserAddressForm from './userAddressesForm';
import { showErrorToast } from '../../../../Toster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import { useContext } from 'react';

const UserAddresses = () => {
  const [addresses, setAddresses] = useState([]);
  const [userData, setUserData] = useState({});
  const [visible, setVisible] = useState(false);
  const { setLoaderCheck } = useContext(LoaderContext);



  const getUserAddresses = () => {
    setLoaderCheck(true);
    axiosPrivate
      .get('user-addresses/get')
      .then((response) => {
        setLoaderCheck(false);
        setAddresses(response.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (typeof error.response.data.errors == 'array') {
          showErrorToast(error.response.data.errors[0]?.msg);
        } else {
          showErrorToast(error.response.data.errors?.msg);
        }
      });
  };
    const closeDiv = (data) => {
      getUserAddresses();
      setVisible(false);
    };

  useEffect(() => {
    getUserAddresses();
  }, []);

  const addressAddHandler = (e) => {
    setVisible(true);
    setUserData({});
  };
  const addressEditHandler = (e, res) => {
    setVisible(true);
    setUserData(res);
  };

  return (
    <div>
      <>
        <Dialog
          header='Address Form'
          visible={visible}
          style={{ width: '50vw' }}
          onHide={() => setVisible(false)}
        >
          <UserAddressForm getData={userData} sendData={closeDiv} />
        </Dialog>
      </>
      <div className={styles.addresses}>
        <h2>Manage Addresses</h2>
        <div className={styles.address_section}>
          <div
            className={`${styles.card_1} ${styles['shipping-card']}`}
            onClick={(e) => addressAddHandler(e)}
          >
            <div className={styles.add_address}>
              <span className={styles['plus-icon']}>+</span>
              <br />
              <span className={styles.new_address_icon}>Add New Address</span>
              <br />
              <img src={truckImg} alt='Delivery Truck' loading='lazy' />
            </div>
          </div>

          {addresses
            // .filter((res) => res.bill_type == "shipping")
            .map((res) => (
              
                 res?.name && 
                 (
                  <div
                className={`${styles.card_2} ${styles['shipping-card']}`}
                key={res.id}
              >
                <div className={styles.deliver_div}>
                  {/* <Link to='/'>
                    <button className={styles.deliver_btn}>Deliver Here</button>
                  </Link> */}
                </div>
                <h4>
                  {res?.bill_type.charAt(0).toUpperCase() +
                    res?.bill_type.slice(1)}{' '}
                  Address
                </h4>
                <p>{res?.name}</p>
                <span className={styles.phone_number}>{res?.mobile}</span>
                <p className='mt-1 order_address'>
                  {res?.address} {res?.landmark} {res?.city} {res?.state}{' '}
                  {res?.pincode}{' '}
                </p>
                <div
                  className={styles.deliver_div}
                  onClick={(e) => {
                    addressEditHandler(e, res);
                  }}
                >
                  <button className={`${styles.edit_button} mt-1`}>EDIT</button>
                </div>
              </div>
                 )
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserAddresses) || UserAddresses;
