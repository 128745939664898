import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import Home from "./home/Home";
import AboutUsPage from "./aboutus/AboutUs";
import Blog from "./pages/Blog";
import DistributorPage from "./DistributorPage/DistributorPage";
import { useContext } from "react";
import LoaderContext from './../../context/Loader/LoaderContext'
import ProductDinamicPage from "./MarketplaceProducts/ProductDinamicPage";
import { Helmet } from 'react-helmet';

const CommonTemplate = () => {
  const {slug} = useParams();
  const [mode , setMode] = useState(slug);
  const [pageData, setPageData] = useState({});
  const [dynamicTagName, setDynamicTagName] = useState("");
  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {

    window.scroll({
      top : 0,
      behavior : "smooth"
    })

    const fetchData = async () => {
      // Fetch your page data here or use it from the state or props
     
      if(slug){
        var mode_local = slug.replace("%20" , '-').replace(" " , "-");
        setMode(mode_local);
        
      }

      else{
        mode_local = 'home';
        setMode(mode_local);
      }

      const getPageBySlug = (mode_local) => {
        setLoaderCheck(true);
        axiosPrivate
          .get("pages/slug/" + mode_local)
          .then((res) => {
            setLoaderCheck(false);
            
            const fetchedPageData = res.data.data;
            setPageData(JSON.parse(fetchedPageData.page_json));
            setDynamicTagName(fetchedPageData.template_type);
          })
          .catch((error) => {
            setLoaderCheck(false);
            
          });
      };
      getPageBySlug(mode_local);
    };
    fetchData();
  }, [slug]);

  const renderDynamicComponent = () => {
    switch (dynamicTagName) {
      case "product":
        return <ProductDinamicPage initialValues={pageData} />;
      case "about":
        return <AboutUsPage initialValues={pageData} />;
      case "home":
        return <Home pageData={pageData} />;
      case "blog":
        return <Blog pageData={pageData} />;
      case "distributor":
        return <DistributorPage pageData={pageData} />;
      // Add additional cases for other dynamic components
      default:
        return <Home pageData={pageData} />;
    }
    
    
  };

  // return renderDynamicComponent();


  return (
    <>
      
      <Helmet>
          <title>{pageData?.meta_title}</title>
          <meta name="description" content={pageData?.meta_description} />
          <meta name="keywords" content={pageData?.keywords?.map(keyword => keyword?.text).join(',')} />
          <meta name="tags" content={pageData?.tags?.map(tag => tag?.text).join(',')} />
          <meta property="og:title" content={pageData?.og_title} />
          <meta
            property="og:description"
            content={pageData?.og_description}
          />
          <meta property="og:image" content={pageData?.og_image} />
          <meta property="og:url" content={pageData?.og_image} />
          <meta name="twitter:title" content={pageData?.meta_title} />
          <meta
            name="twitter:description"
            content={pageData?.meta_description}
          />
          <meta name="twitter:image" content={pageData?.og_image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      {renderDynamicComponent()}
    </>
  );
};

export default React.memo(CommonTemplate); // Memoize the CommonTemplate;
