import { useState, useEffect, useContext } from 'react';
import { errorToast, successToast } from './Toaster';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { GrTrash } from 'react-icons/gr';
import ImagePopup from './ImagePopup';
import LoaderContext from '../../../context/Loader/LoaderContext';

const SiteImage = ({ id, baseUrl }) => {
    const [siteImages, setSiteImages] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const [imageAlt, setImageAlt] = useState();
    const [imageModalShow, setImageModalShow] = useState(false);
    const { setLoaderCheck } = useContext(LoaderContext);

    //Function to open modal
    const openModal = async (url, alt) => {
        setImageUrl(url);
        setImageAlt(alt);
        setImageModalShow(true);
    }

    //Function to fetch site images
    useEffect(() => {
        setLoaderCheck(true);
        axiosPrivate
        .get(`site_images/${id}`)
        .then((images) => {
                setLoaderCheck(false);
                setSiteImages(images?.data?.data);
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error.message);
            });
    }, [id]);

    //Function to refresh data after every action
    const refreshImageTable = () => {
        return new Promise((resolve, reject) => {
            setLoaderCheck(true);
            axiosPrivate
            .get(`site_images/${id}`)
            .then((images) => {
                setLoaderCheck(false);
                setSiteImages(images?.data?.data);
                resolve(true);
            })
            .catch((error) => {
                    setLoaderCheck(false);
                    reject(error);
                    errorToast(error.message);
                });
        });
    };

    //Function to delete image
    const deleteImage = async (e, id) => {
        e.preventDefault();
        setLoaderCheck(true);
        axiosPrivate
        .post(`site_images/${id}?_method=DELETE`)
        .then(async (response) => {
                setLoaderCheck(false);
                successToast('Image deleted successfully');
                await refreshImageTable();
            })
            .catch((error) => {
                setLoaderCheck(false);
                if (error.response) {
                    if (error.response.status === 422) {
                        if (error.response.data.errors.length === undefined) {
                            errorToast(error.response.data.errors.msg);
                        } else {
                            for (let i = 0; i < error.response.data.errors.length; i++) {
                                errorToast(error.response.data.errors[i].msg);
                            }
                        }
                    }
                }
            });
    }

    return (
        <>
            {siteImages.map((item, index) => (
                <tr key={index}>
                    {/* Product Image */}
                    <td className="text-center">
                        <img src={`${baseUrl}/${item.image}`} alt={item.alt} height="50px" width="50px" onClick={() => openModal(`${baseUrl}/${item.image}`, item.alt)} />
                    </td>
                    {/* alt_title */}
                    <td>{item.alt}</td>
                    <td>{item.order_img}</td>
                    <td>
                        <div>
                            <button
                                className={`btn`}
                                onClick={(e) => deleteImage(e, item.id)}
                            >
                                <GrTrash />
                            </button>
                        </div>
                    </td>
                </tr>
            ))}
            <ImagePopup imageUrl={imageUrl} imageAlt={imageAlt} isImageModalOpen={imageModalShow} setImageModalShow={setImageModalShow} />
        </>
    )
}

export default SiteImage