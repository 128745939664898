import React, { useState, useContext, useEffect } from "react";
import styles from "./History.module.css";
import { Link } from "react-router-dom";
import CartCountContext from "../../../context/CartCount/CartCountContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { errorToast, successToast } from "../../backend/common/Toaster";
import { TbGardenCart } from "react-icons/tb";
import { FaBookmark } from "react-icons/fa";
import { GoBookmark } from "react-icons/go";
import { IoMdCart } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
function ProductHistory({ id, refresh }) {
//   const [selectedVarient, setSelectedVarient] = useState(null);
  const [product_variant, setProduct_variant] = useState({});
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [productHistoryData, setProductHistoryData] = useState([]);
  const [iconColor, setIconColor] = useState("");
  const initialValues = {
    mainHeading: "Recommended Products",
  };

  const [userLogin, setUserLogin] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const addToCart = (product) => {
    let userLogin = localStorage.getItem("userData");
    let object = {
      product_id: product?.product_id,
      variant_id: product?.variant_id,
      quantity: product?.quantity,
    };
    if (userLogin) {
      setLoaderCheck(true);
      axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
          setLoaderCheck(false);
          // window.scroll({
          //   top: 0,
          //   behavior: "smooth",
          // });
          setCartCountCheck(!cartCountCheck);
          // debugger;
          successToast("Item Added in Cart!");
          refresh()
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast("Something Went Wrong!");
          // setAlertMessage({ message: "Something Went Wrong!" });
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        });
    } else {
      object["product_name"] = product?.name;
      object["rate"] = product?.price;
      //  object["base_unit"] = productDetail.product_variants[0].base_unit;
      object["variant"] = product?.variant;
      object["short_description"] = product?.short_description;
      if (
        product?.length > 0 ||
        Object.keys(product)?.length > 0
      ) {
        object["image"] = product?.product_image;
      }
      let cart_items = localStorage.getItem("cart_items");
      let cart_array = [];
      cart_array.push(object);
      if (!cart_items) {
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      } else {
        let cart_array = JSON.parse(cart_items);
        let boolean_cart = false;
        cart_array?.forEach((element) => {
          if (object.variant_id == element.variant_id) {
            boolean_cart = true;
            element.quantity = element.quantity + 1;
          }
        });

        if (!boolean_cart) {
          cart_array.push(object);
        }
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      }
    //   window.scroll({
    //     top: 0,
    //     behavior: "smooth",
    //   });

      successToast("Item Added in Cart!");
      setCartCountCheck(!cartCountCheck);
      
    }
  };

  const productSaveHandler = (e, res) => {
    const object = {
      // user_id: userId,
      user_id: res?.user_id,
      variant_id: res?.variant_id,
      product_id: res?.product_id,
      ...res,
    };
    axiosPrivate
      .post("wishlist/create", object)
      .then((res) => {
        if (res.data?.code == 201) {
          successToast("Product added to Wishlist");
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`orders/address/${id}`, options)
      .then((product) => {
        const productData = product?.data?.data

        productData?.map((item) =>{
          item?.product_variants?.map((variant) =>{
            if(item?.variant_id === variant?.id){
              item["image"] = variant?.varinat_iamges[0]?.image
            }
          })
          return item
        })
        
        setProductHistoryData(productData);

      })
      .catch((error) => {});

    axiosPrivate
      .get("products", options)
      .then((product) => {
        const finalData = product?.data?.data?.map((item) => {
          const image_path = item?.product_images[0]?.image?.split("/");
          item["main_image"] = image_path[image_path?.length - 1];
          return item;
          
        });
        // setProductHistoryData(finalData);
      })
      .catch((error) => {});
  }, []);
  return (
    <div className={styles.productContainer}>
      {/* <h2 className={styles.mainHeading}>{initialValues?.mainHeading}</h2> */}
      <div className={styles.recomendedProducts}>
        {productHistoryData?.map((product, index) => {
          return (
            <div className={styles.card}>
              <div className={styles.image}>
                <img
                  src={
                    process.env.REACT_APP_COMMON_FILE_URL +
                    `product-variants/${product?.image}`
                  }
                  alt={product?.product_image?.alt_text || "Product Image"}
                  loading="lazy"
                />
              </div>
              <div>
                <h3 className={styles.productName}>{product?.name}</h3>
              </div>
              <div className={`${styles.productPrice} ${styles.productName}`}>
                {/* <BsCurrencyRupee /> */}
                {/* <p>{productHistoryData?.product_variants[0]?.regular_price}</p> */}
                <p>
                  {
                    product?.price
                  }
                </p>
              </div>
              <div className={styles.icons}>
                {/* className="btn btn-success details-button" */}
                <button onClick={(e) => {
                  productSaveHandler(e, product)
                  setIconColor("Bookmark"+index)
                }} className={styles.buttonBorder}>
                  {
                     iconColor === "Bookmark"+index ? <FaBookmark color="#7fb401"/> : <GoBookmark color="#7fb401"/>
                  }
                  
                </button>

                <button onClick={() =>{
                  addToCart(product)
                  setIconColor("Cart"+index)
                }} className={styles.buttonBorder}>
                  {
                     iconColor === "Cart"+index ? <IoMdCart color="#7fb401"/> : <TbGardenCart color="#7fb401" />
                  }
                  
                </button>

                <Link
                  key={product?.id} // Make sure to include a unique key
                  to={{
                    pathname: `/product-detail/${product?.slug}`,
                  }}
                >
                  <button className={styles.buttonBorder}>
                    <IoEyeOutline color="#7fb401"/>
                  </button>
                </Link>
              </div>
            </div>
            //   <Link
            //     key={product?.id} // Make sure to include a unique key
            //     to={{
            //       pathname: `/product-detail/${product?.slug}`,
            //     }}
            //   >

            //   </Link>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(ProductHistory) || ProductHistory;
