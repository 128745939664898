import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../DataTable2";
import { FaGreaterThan } from 'react-icons/fa';
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { GrView } from 'react-icons/gr';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";
import { showErrorToast } from "../../../../Toster";

function DistributorEnquiry() {
	const { authPermissions } = useContext(AuthContext);
	const [distributorState, setDistributorState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'MOBILE No', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'SUBJECT', field: 'message', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	const searchItems = ['name', 'email', 'mobile'];

	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get(`/distributor`, options)
			.then((res) => {
				setLoaderCheck(false);
				const distributorenquirydata = res.data.data.filter(
					(res) => res.form_type == "distributor"
				);

				const distributordata = distributorenquirydata.map((value,index) => {
					let buttons = [];

					if (authPermissions.includes('Distributor Enquiry-read'))
						buttons.push(
							<Link
								key={`viewButton##${index}`}
								type="button"
								to={`/admin/distributorenquiry/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);
						if (value && typeof value.message === 'string') {
							value["message"] = value.message.slice(0, 10)+"...";
						  }
					value['action'] = buttons.length > 0 ? buttons : '-';
					value['mobile'] = String(value?.mobile);
					value["sr-no"]=index+1
					return value;
				});
				if (authPermissions.includes('Distributor Enquiry-read')) {
					setDistributorState(distributordata);
				} else {
					setDistributorState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					showErrorToast(error.response?.data?.message)
				}
			});
	}, [])

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Distributor Enquiry</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Distributor Enquiry</li>
						</ol>
					</div>

					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="enquiry-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h1
										className="card-title"
										style={{ fontWeight: '700', fontSize: '18px' }}
									>
										Distributor	Enquiry
									</h1> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={distributorState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default DistributorEnquiry;