import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Coupon.module.css";
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";
import TopNavBar from "../../layouts/TopNavBar";

// validation
const validationRules = {
	name: Yup.string()
		.max(120, "Name must not exceed 100 characters")
		.required("Coupon Name is required"),
	start_date: Yup.string().required("Coupon Start Date is required"),
	coupon_validity: Yup.string().required("Coupon  End Date is required"),
	per_user_count: Yup.string().required("Coupon  Count is required"),

	cart_min_amount: Yup.string().required("Minimum Amount is required"),
	offer_max_amount: Yup.string().required("Maximum Amount is required"),
	coupon_usage: Yup.string().required("Coupon Limite is required"),
	coupon_type: Yup.string().required("Coupon Type  is required"),
	discount: Yup.string().required("Discount is required"),
};

const EditCoupon = () => {
	let { id } = useParams();

	const { setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const navigate = useNavigate();
	const [categoryMultiSelectOption, setCategoryMultiSelectOption] = useState(
		[]
	);
	const [productMultiSelectOption, setProductMultiSelectOption] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [userMultiSelectOption, setUserMultiSelectOption] = useState([]);
	const [productList, setProductList] = useState([]);
	const [categoryDropDown, setCategoryDropDown] = useState(false);
	const [productDropDown, setproductDropDown] = useState(false);
	const [couponTypeValue, setCouponTypeValue] = useState();
	const [selectedValue, setSelectedValue] = useState([]);
	const [getData, setGetData] = useState({});
	const [typeArray, setTypeArray] = useState([]);
	const [userList, setUserList] = useState([]);
	const [userDropDown, setUserDropDown] = useState(false);

	//    coupon get by id
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate.get(`coupons/${id}`).then((response) => {
			setLoaderCheck(false);
			
			if (response.data.code == 200) {
				setGetData({ ...response.data.data });
				setTypeArray(response.data.data.type_id);
				
				if (response.data.data.coupon_type === "Product") {
					setproductDropDown(true);
				} else if (response.data.data.coupon_type === "Category") {
					setCategoryDropDown(true);
				} else if (response.data.data.coupon_type === "User") {
					setUserDropDown(true);
				}
			}
		}).catch((error) => {
			setLoaderCheck(false);
		});
	}, []);
	useEffect(() => {
		if (getData != "") {
			formik.setValues({ ...getData });
		}
	
	}, [getData]);


	
	//  category list
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate.get("categories/get").then((category) => {
			setLoaderCheck(false);
			setCategoryList(category?.data?.data);
		}).catch((error) => {
			setLoaderCheck(false);
		});
	}, []);

	//  Product list
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate.get("products").then((products) => {
			setLoaderCheck(false);
			setProductList(products?.data?.data);
		}).catch((error) => {
			setLoaderCheck(false);
		});
	}, []);

	//   User list
	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate.post("users").then((users) => {
			setLoaderCheck(false);
			setUserList(users?.data?.data);
		}).catch((error) => {
			setLoaderCheck(false);
		});
	}, []);

	// coupon type onChange function
	const coupaonTypeChange = (event) => {
		
		setCouponTypeValue(event.target.value);
		formik.setFieldValue("type_id", []);
		setSelectedValue([]);

		if (event.target.value === "Category") {
			setUserDropDown(false);
			setproductDropDown(false);
			setCategoryDropDown(true);
		} else if (event.target.value === "Product") {
			setUserDropDown(false);
			setCategoryDropDown(false);
			setproductDropDown(true);
		} else if (event.target.value === "User") {
			setCategoryDropDown(false);
			setproductDropDown(false);
			setUserDropDown(true);
		} else {
			setCategoryDropDown(false);
			setproductDropDown(false);
			setUserDropDown(false);
		}
	};

	// value set in array format category and product multiselect
	useEffect(() => {
		const categoryData = async () => {
			const empty = [];
			const selectedType = [];
			for (let i = 0; i < categoryList.length; i++) {
				if (typeArray.includes(categoryList[i].id)) {
					selectedType.push({
						label: categoryList[i].name,
						value: categoryList[i].id,
					});
				}
				empty.push({ label: categoryList[i].name, value: categoryList[i].id });
			}
			if (getData.coupon_type === "Category") {
			
				setSelectedValue(selectedType);
			}
			setCategoryMultiSelectOption(empty);
		};
	
		const productdata = async () => {
			const empty = [];
			const selectedType = [];
			
			for (let i = 0; i < productList.length; i++) {
				if (typeArray.includes(productList[i].id)) {
					selectedType.push({
						label: productList[i].name,
						value: productList[i].id,
					});
				}
				empty.push({
					label: productList[i].name,
					value: productList[i].id,
				});
			}
			if (getData.coupon_type === "Product") {
			
				setSelectedValue(selectedType);
			}
			setProductMultiSelectOption(empty);
		};

		const userdata = async () => {
			const empty = [];
			const selectedType = [];
			for (let i = 0; i < userList.length; i++) {
				if (typeArray.includes(userList[i].id)) {
					selectedType.push({
						label: userList[i].name,
						value: userList[i].id,
					});
				}
				empty.push({
					label: userList[i].name,
					value: userList[i].id,
				});
			}
			if (getData.coupon_type === "User") {
				setSelectedValue(selectedType);
			}
			setUserMultiSelectOption(empty);
		};

		Promise.all([categoryData(), userdata(), productdata()]);
	}, [categoryList, productList, userList, typeArray]);

	// muliselect onchange function
	const selectOnChange = (e) => {
		setSelectedValue(e);
		let idsArray = [];
		for (let i = 0; i < e.length; i++) {
			idsArray.push(e[i].value);
		}
		formik.setValues({ ...formik.values, type_id: idsArray });
	};

	//   start data and end date condition
	useEffect(() => {
		const start_date = document.getElementById("start_date");
		const coupon_validity = document.getElementById("coupon_validity");
		const today = new Date().toISOString().split("T")[0];

		start_date.setAttribute("min", today);

		coupon_validity.setAttribute("min", today);
	});
	// 	// add data to api (after check validation)
	const formik = useFormik({
		initialValues: {
			name: "",
			start_date: "",
			coupon_validity: "",
			per_user_count: "",
			cart_min_amount: "",
			offer_max_amount: "",
			coupon_usage: "",
			discount: "",
			coupon_type: "",
			type_id: "",
			is_active: "",
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			if (values.is_active === "Inactive") {
				var status = 1;
			} else {
				var status = 0;
			}

			const data = {
				name: values.name,
				start_date: values.start_date,
				coupon_validity: values.coupon_validity,
				cart_min_amount: values.cart_min_amount,
				offer_max_amount: values.offer_max_amount,
				coupon_usage: values.coupon_usage,
				discount: values.discount,
				per_user_count: values.per_user_count,
				coupon_type: values.coupon_type,
				type_id: values.type_id || null,
				is_active: status,
			};
			setLoaderCheck(true);
			axiosPrivate
				.post(`coupons/update/${id}?_method=PUT`, data)
				.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: "Coupon  Update Successfully" });
					navigate("/admin/coupon");
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (typeof error.response.data.errors == "array") {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	const addLink =
    authPermissions.includes("Coupon-create") === true
      ? "/admin/coupon/add"
      : "";


	return (
		<>
		  <TopNavBar links={{ list: "/admin/coupon", add: addLink }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong style={{ fontWeight: "700", fontSize: "18px" }}>
											Edit Coupon
										</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											{/*   coupon  Name */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Name:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Please Enter Coupon  Name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>

											{/* Form date */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Start Date:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="start_date"
														name="start_date"
														type="date"
														className="form-control"
														placeholder="Please Enter Coupon Start Date"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.start_date}
													/>
													{formik.touched.start_date &&
														formik.errors.start_date ? (
														<div className={classes.invalidDataError}>
															{formik.errors.start_date}
														</div>
													) : null}
												</div>
											</div>

											{/* To date */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon End Date:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="coupon_validity"
														name="coupon_validity"
														type="date"
														className="form-control"
														placeholder="Please Enter Coupon End Date"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.coupon_validity}
													/>
													{formik.touched.coupon_validity &&
														formik.errors.coupon_validity ? (
														<div className={classes.invalidDataError}>
															{formik.errors.coupon_validity}
														</div>
													) : null}
												</div>
											</div>

											{/*   Min Order Amount */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Min Order Amount:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="cart_min_amount"
														name="cart_min_amount"
														type="number"
														className="form-control"
														placeholder="Please Enter Min order Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.cart_min_amount}
													/>
													{formik.touched.cart_min_amount &&
														formik.errors.cart_min_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.cart_min_amount}
														</div>
													) : null}
												</div>
											</div>

											{/*   Max Order Amount */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Max Offer Amount:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="offer_max_amount"
														name="offer_max_amount"
														type="number"
														className="form-control"
														placeholder="Please Enter Max Order Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.offer_max_amount}
													/>
													{formik.touched.offer_max_amount &&
														formik.errors.offer_max_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.offer_max_amount}
														</div>
													) : null}
												</div>
											</div>

											{/*   Coupon limit */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Limit:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="coupon_usage"
														name="coupon_usage"
														type="text"
														className="form-control"
														placeholder="limit"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.coupon_usage}
													/>
													{formik.touched.coupon_usage &&
														formik.errors.coupon_usage ? (
														<div className={classes.invalidDataError}>
															{formik.errors.coupon_usage}
														</div>
													) : null}
												</div>
											</div>

											{/*   Per User Count */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Per User Coupon Count :
													<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="per_user_count"
														name="per_user_count"
														type="number"
														className="form-control"
														placeholder="Please Enter Coupon Count"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.per_user_count}
													/>
													{formik.touched.per_user_count &&
														formik.errors.per_user_count ? (
														<div className={classes.invalidDataError}>
															{formik.errors.per_user_count}
														</div>
													) : null}
												</div>
											</div>

											{/*   coupon   count */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Used Coupons :<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="used_coupon_count"
														name="used_coupon_count"
														type="text"
														className="form-control"
														placeholder=" Coupon Count"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.used_coupon_count}
														readOnly
													/>
												</div>
											</div>

											{/*  discount */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Discount (%):<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="discount"
														name="discount"
														type="number"
														className="form-control"
														placeholder="Discount "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.discount}
													/>
													{formik.touched.discount && formik.errors.discount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.discount}
														</div>
													) : null}
												</div>
											</div>

											{/* coupon type */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Type:<span className="required">*</span>
												</label>

												<div className="form-group">
													<select
														className="form-control show-tick"
														id="coupon_type"
														name="coupon_type"
														onChange={(event) => {
															formik.handleChange(event);
															coupaonTypeChange(event);
														}}
														onBlur={formik.handleBlur}
														value={formik.values.coupon_type}
													>
														<option>Select Coupon Type</option>
														<option>Category</option>
														<option>Product</option>
														<option>User</option>
														<option>Global</option>
													</select>
													{formik.touched.coupon_type &&
														formik.errors.coupon_type ? (
														<div className={classes.invalidDataError}>
															{formik.errors.coupon_type}
														</div>
													) : null}
												</div>
											</div>

											{/* coupon status */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Status:<span className="required">*</span>
												</label>

												<div className="form-group">
													<select
														className="form-control show-tick"
														id="is_active"
														name="is_active"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.is_active}
													>
														<option>Select Coupon Status</option>
														<option>Active</option>
														<option>Inactive</option>
													</select>
												</div>
											</div>

											{/* category list dropdown */}

											{categoryDropDown ? (
												<div className="col-md-6 col-sm-12">
													<label>
														Select Category:<span className="required">*</span>
													</label>

													<div className="form-group">
														<MultiSelect
															options={categoryMultiSelectOption}
															value={selectedValue}
															onChange={(e) => selectOnChange(e)}
															labelledBy="Select"
															name="type_id"
															onBlur={formik.handleBlur}
															hasSelectAll={false}
														/>

														{formik.touched.type_id &&
															formik.errors.type_id &&
															selectedValue.length == 0 ? (
															<div className={classes.invalidDataError}>
																{formik.errors.type_id}
															</div>
														) : null}
													</div>
												</div>
											) : (
												""
											)}

											{/* product list dropdown */}

											{productDropDown ? (
												<div className="col-md-6 col-sm-12">
													<label>
														Select Product:<span className="required">*</span>
													</label>

													<div className="form-group">
														<MultiSelect
															options={productMultiSelectOption}
															value={selectedValue}
															onChange={(e) => selectOnChange(e)}
															labelledBy="Select"
															name="type_id"
															onBlur={formik.handleBlur}
															hasSelectAll={false}
														/>

														{formik.touched.type_id &&
															formik.errors.type_id &&
															selectedValue.length == 0 ? (
															<div className={classes.invalidDataError}>
																{formik.errors.type_id}
															</div>
														) : null}
													</div>
												</div>
											) : (
												""
											)}

											{/* user DropDown */}
											{userDropDown ? (
												<div className="col-md-6 col-sm-12">
													<label>
														Select User:<span className="required">*</span>
													</label>

													<div className="form-group">
														<MultiSelect
															options={userMultiSelectOption}
															value={selectedValue}
															onChange={(e) => selectOnChange(e)}
															labelledBy="Select"
															name="type_id"
															onBlur={formik.handleBlur}
															hasSelectAll={false}
														/>

														{formik.touched.type_id &&
															formik.errors.type_id &&
															selectedValue.length == 0 ? (
															<div className={classes.invalidDataError}>
																{formik.errors.type_id}
															</div>
														) : null}
													</div>
												</div>
											) : (
												""
											)}

											{/* close and submit button */}

											<div className="col-12 submit-btn  text-right">
												<button
													type="button"
													id="button_1"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate("/admin/coupon")}
												>
													Close
												</button>
												{authPermissions.includes('Category-update') && (
													<button type="submit" id="button_2" className="btn btn-primary ml-2">
														Update
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditCoupon;
