import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Orders.module.css";
import LoaderContext from "../../../../context/Loader/LoaderContext";

const ViewOrders = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const [productDetail, setProductDetail] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [orderId, setOrderId] = useState("");

  //   Get order by id
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`orders/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        formik.setValues(response.data?.data);
        setOrderId(response.data.data.id);
        if (response?.data.code === 200) {
          setGetData({ ...response.data.data });
          setProductDetail(response.data?.data);
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          //response status is an error code
          showErrorToast(
            error?.response?.data?.message ||
              error?.response?.data?.errors[0]?.msg
          );
        }
      });
  }, []);

  useEffect(() => {
    if (getData != "") {
      formik.setValues({ ...getData });
    }
  }, [getData]);

  // formik (send data)
  const formik = useFormik({
    initialValues: {
      order_amount: "",
      shipping_amount: "",
      discount_amount: "",
      total_amount: "",
      payment_mode: "",
      payment_status: "",
      bill_name: "",
      bill_mobile: "",
      bill_address: "",
      bill_pincode: "",
      bill_state: "",
      bill_city: "",
      ship_name: "",
      ship_mobile: "",
      ship_address: "",
      ship_pincode: "",
      ship_state: "",
      ship_city: "",
      order_status: "",
      coupon_name: "",
      coupon_discount: "",
      gst_no: "",
    },
    enableReinitialize: true,

    validationSchema: Yup.object(),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setLoaderCheck(true);
      axiosPrivate
        .put(`orders/update/${orderId}`, values)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: "Orders Updated successfully" });
          navigate("/admin/orders");
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (error.response) {
            showErrorToast("Internal server error");

            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });
    
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading"> Order View</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/orders"> Order List </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Order View</li>
            </ol>
          </div>

          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row bordered-row">
                      {/* Order Id*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Order Id:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="order_id"
                            name="order_id"
                            type="order_id"
                            className="form-control"
                            placeholder="User Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.order_id}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* user Name*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          User Name:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_name"
                            name="bill_name"
                            type="text"
                            className="form-control"
                            placeholder="User Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bill_name}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/* order status */}
					<div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                        Order Status:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <select
                          id="order_status"
                          name="order_status"
                          type="text"
                          className="form-control"
                          placeholder="Order Status"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.order_status}
                        >
                          <option value="confirmed">Complete</option>
                          <option value="pending">Pending</option>
                          <option value="shipped">Shipped</option>
                          <option value="cancel">Rejected</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                        {formik.touched.order_status &&
                        formik.errors.order_status ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.order_status}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* payment status */}
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                        Payment Status:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="payment_status"
                          name="payment_status"
                          type="text"
                          className="form-control"
                          value={getData.payment_status}
                          placeholder="payment Status"
                        />
                      </div>
                    </div>
					</div>
                    {/* Product Section*/}
                    <div className="d-flex justify-content-between align-items-center ">
                      <div>
                        <p
                          // className="card-title"
                          style={{ fontWeight: "700", fontSize: "16px" }}
                        >
                          Product Details
                        </p>
                      </div>
                    </div>

                    {/* product listing detail map   */}
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Product Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productDetail?.order_items?.map((item, index) => (
                          <tr>
                            <td>
                              {item?.product_name} ({item?.product_variant})
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/*Billing Section*/}
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <p
                        className="my-3"
                        style={{ fontWeight: "700", fontSize: "16px" }}
                      >
                        Billing Address
                      </p>
                    </div>
                    {/* billing section fields */}
                    <div className="row bordered-row">
                      {/* Bill Name*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_name"
                            name="bill_name"
                            type="text"
                            className="form-control"
                            value={formik.values.bill_name}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Bill  Mobile*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Mobile:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_mobile"
                            name="bill_mobile"
                            type="number"
                            className="form-control"
                            value={formik.values.bill_mobile}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Bill  Address*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Address:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_address"
                            name="bill_address"
                            type="text"
                            className="form-control"
                            value={formik.values.bill_address}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Bill pincode*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Pincode:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_pincode"
                            name="bill_pincode"
                            type="number"
                            className="form-control"
                            placeholder="Bill  Pincode"
                            value={formik.values.bill_pincode}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* bill_state*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          State:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_state"
                            name="bill_state"
                            type="text"
                            className="form-control"
                            value={formik.values.bill_state}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  bill city*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          City:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="bill_city"
                            name="bill_city"
                            type="text"
                            className="form-control"
                            value={formik.values.bill_city}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/*Shiipping Section*/}
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <p
                        className="my-3"
                        style={{ fontWeight: "700", fontSize: "16px" }}
                      >
                        Shipping Address
                      </p>
                    </div>

                    {/* shipping section fields */}
                    <div className="row bordered-row">
                      {/*  shipping name*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_name"
                            name="ship_name"
                            type="text"
                            className="form-control"
                            value={formik.values.ship_name}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  shipping  mobile*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Mobile:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_mobile"
                            name="ship_mobile"
                            type="number"
                            className="form-control"
                            value={formik.values.ship_mobile}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  shipping address*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Address:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_address"
                            name="ship_address"
                            type="text"
                            className="form-control"
                            value={formik.values.ship_address}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  shipping  pincode*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Pincode:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_pincode"
                            name="ship_pincode"
                            type="number"
                            className="form-control"
                            value={formik.values.ship_pincode}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  shipping   State*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          State:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_state"
                            name="ship_state"
                            type="text"
                            className="form-control"
                            value={formik.values.ship_state}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  shipping    city*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          City:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="ship_city"
                            name="ship_city"
                            type="text"
                            className="form-control"
                            value={formik.values.ship_city}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* shipping amount*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                        Shipping Amount:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="shipping_amount"
                            name="shipping_amount"
                            type="number"
                            className="form-control"
                            value={formik.values.shipping_amount}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/* payment heading*/}
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <p
                        className="my-3"
                        style={{ fontWeight: "700", fontSize: "16px" }}
                      >
                        Payment Details
                      </p>
                    </div>

                    {/*payment fields */}
                    <div className="row ered-row">
                      {/*    Payment Mode*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Payment Mode:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="payment_mode"
                            name="payment_mode"
                            type="text"
                            className="form-control"
                            value={formik.values.payment_mode}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*    Payment  Status*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Payment Status:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="payment_status"
                            name="payment_status"
                            type="text"
                            className="form-control"
                            value={formik.values.payment_status}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Coupon id */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Coupon:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="coupon_name"
                            name="coupon_name"
                            type="text"
                            className="form-control"
                            value={formik.values.coupon_name}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* GST Number */}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          GST Number :<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="gst_no"
                            name="gst_no"
                            type="text"
                            className="form-control"
                            value={formik.values.gst_no}
                            readOnly
                          />
                        </div>
                      </div> */}

                      {/* order amount*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Order Amount:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="order_amount"
                            name="order_amount"
                            type="number"
                            className="form-control"
                            value={formik.values.total_amount}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* coupon_discount */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Coupon Discount(%) :<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="coupon_discount"
                            name="coupon_discount"
                            type="number"
                            className="form-control"
                            value={formik.values.coupon_discount}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*  Discount amount*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Total Discount Amount:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="discount_amount"
                            name="discount_amount"
                            type="number"
                            className="form-control"
                            value={formik.values.discount_amount}
                            readOnly
                          />
                        </div>
                      </div>

                      {/*   Total amount*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Total Amount:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="total_amount"
                            name="total_amount"
                            type="number"
                            className="form-control"
                            value={formik.values.order_amount}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    {/* update and close button */}
                    <div className="col-12 submit-btn  text-right">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button
                        type="button"
                        id="button_1"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/orders")}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        id="button_2"
                        className="btn btn-primary  ml-2"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrders;
