import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../DataTable2";
import { FaGreaterThan } from 'react-icons/fa';
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { GrView } from 'react-icons/gr';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from "../../../../context/Loader/LoaderContext";

function Enquiry() {
	const { authPermissions } = useContext(AuthContext);
	const [enquiryState, setEnquiryState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		// { name: 'S No', field: 'sr-no', sortable: true, classKey: '' },
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'MOBILE No', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'Category', field: 'sell_platform', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	const searchItems = ['name', 'email', 'category_sell', 'mobile'];

	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get('/contact-us', options)
			.then((res) => {
				setLoaderCheck(false);
				const enquirydata = res.data.data.filter(
					(res) => res.form_type == "contact"
				);
				const distributordata = enquirydata.map((value, index) => {
					let buttons = [];
					if (authPermissions.includes('Enquiry-read'))
						buttons.push(
							<Link
								key={`viewButton##${index}`}
								type="button"
								to={`/admin/enquiry/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);
    
					value['action'] = buttons.length > 0 ? buttons : '-';
					value['mobile'] = String(value?.mobile);
					value['category_sell'] = value?.sell_platform;
					value["sr-no"]=index+1
					return value;
				});
				if (authPermissions.includes('Enquiry-read')) {
					setEnquiryState(distributordata);
				} else {
					setEnquiryState([]);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [])

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Enquiry</strong>
						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Enquiry</li>
						</ol>
					</div>

					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="enquiry-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									{/* <h1
										className="card-title"
										style={{ fontWeight: '700', fontSize: '18px' }}
									>
										Enquiry
									</h1> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={enquiryState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Enquiry;