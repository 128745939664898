import React, { useEffect, useState } from 'react';
import styles from './AboutUs.module.css';
import { Link } from 'react-router-dom';
import AboutDescription from './AboutDescription';

function AboutUsPage({ initialValues }) {
  const [showContent, setShowContent] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [expandedContent, setExpandedContent] = useState(false);

  const showContentHandler = (e, index) => {
    e.preventDefault();

    // If the content is already expanded, reset to original position
    if (index === clickedIndex && showContent) {
      setClickedIndex(-1);
      setShowContent(false);
    } else {
      setClickedIndex(index);
      setShowContent(true);
    }
  };

  const getEndValue = () => {
    if (window.innerWidth <= 1366) {
      return 70;
    } else if (window.innerWidth <= 1366) {
      return 90;
    } else if (window.innerWidth <= 1440) {
      return 90;
    } else if (window.innerWidth <= 1920) {
      return 150;
    }
  };

  return (
    <>
      <div className={styles.aboutUs}>
        <div className={styles.firstSection}>
          <div className={styles.column1}>
            {initialValues !== undefined &&
              initialValues?.firstSection?.map((value, index) => {
                return (
                  <div className={styles.aboutUpper} key={index}>
                    <div
                      className={`${styles.aboutSection} ${
                        value?.mainImage
                          ? styles.aboutSection65
                          : styles.aboutSection100
                      }  ${styles.secondDesc}`}
                    >
                      <div className={styles.headingDiv}>
                        <h2>
                          {value?.heading}
                          <img
                            src='./assets/images/home/leaf-1-1 1.webp'
                            alt='Leaf'
                            loading='lazy'
                            className={styles.leaf1}
                          />
                        </h2>
                      </div>
                      <div
                        style={{ textAlign: 'justify' }}
                        dangerouslySetInnerHTML={{ __html: value?.content }}
                      />
                    </div>

                    {value?.mainImage ? (
                      <div className={styles.imgSection}>
                        <img src={value?.mainImage} alt='Minfert' />
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        {initialValues?.thirdSection[0].heading?.length > 0 && (
          <AboutDescription
            value={initialValues !== undefined && initialValues?.thirdSection}
          />
        )}

        {initialValues?.secondSection[0].heading?.length > 0 && (
          <>
            <h2 className={styles.secondHeading}>
              {initialValues?.secondSectionHeading}{' '}
              <img
                src='./assets/images/home/leaf-1-1 1.webp'
                alt='Leaf'
                loading='lazy'
                className={styles.leaf4}
              />
            </h2>
            <div className={styles.secondSection}>
              {initialValues?.secondSection !== '' &&
                initialValues?.secondSection?.map((value, index) => (
                  <div className={styles.column1} key={index}>
                    <div className={styles.aboutCardContainer}>
                      <img
                        className={styles.cardImage}
                        src={value?.image}
                        alt={value?.heading}
                        loading='lazy'
                      />
                      <div className={styles.aboutCard}>
                      {value.content !== '' && (
  <>
    <h2>{value?.heading}</h2>
    {index === clickedIndex ? (
      <p>{value.content}</p>
    ) : (
      <>
        <p>
          {value?.content
            .split('')
            .slice(0, getEndValue())
            .join('')}
          ...
        </p>
      </>
    )}
  </>
)}

                      </div>{' '}
                      {value.content !== '' && (
                <button onClick={(e) => showContentHandler(e, index)}>
                  {index === clickedIndex && showContent ? 'Read Less' : 'Read More'}
                </button>
              )}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(AboutUsPage);
