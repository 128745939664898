import React from 'react';
import './AboutDescription.css';

const AboutDescription = ({ value }) => {
  return (
    <div
      className='row m-0 text-center justify-content-center pt-5 pb-2'
      style={{ padding: '0% 7%' }}
    >
      {/* <div className="col-4"> */}
      {value !== undefined &&
        value.map((data, index) => (
          <div key={index} className='col-sm-12 col-md-4 col-lg-4 mt-2 '>
            <img src={data.image} className='img-fluid' />
            <p className='imgTitle'>
              {data.heading.split(' ').slice(0, 4).join(' ')}
              <br />
              {data.heading.split(' ').slice(4).join(' ')}
            </p>
          </div>
        ))}
    </div>
    // </div>
  );
};

export default React.memo(AboutDescription);
