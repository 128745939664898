import React, { useEffect, useState, useContext } from 'react';
import styles from './AboutPageTemplate.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import AuthContext from '../../../context/Auth/AuthContext';
import LoaderContext from '../../../context/Loader/LoaderContext';
import TopNavBar from '../../backend/layouts/TopNavBar';
// import { Editor } from 'primereact/editor';
import JoditEditor from 'jodit-react';
import { WithContext as ReactTags } from 'react-tag-input';

function AboutPageTemplate() {
  const { authPermissions } = useContext(AuthContext);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [submitButton, setSubmitButton] = useState('Submit');
  const [pageName, setPagename] = useState('Add About Template');
  const [keywords, setKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const { id } = useParams();
  const aboutTemplateValidation = Yup.object({
    pageName: Yup.string().required('Page Name is Required'),
    slug: Yup.string().required('Slug is Required'),
    // mainImage: Yup.mixed().required('Banner Image is Required'),
  });

  const initialValues = {
    pageName: '',
    slug: '',

    firstSection: [
      {
        heading: '',
        content: '',
        mainImage: '',
      },
    ],
    secondSectionHeading: '',
    secondSection: [
      {
        heading: '',
        content: '',
      },
    ],
    thirdSection: [
      {
        heading: '',
        image: '',
      },
    ],

    meta_title: '',
    meta_description: '',
    keywords: [],
    tags: [],
    og_title: '',
    og_description: '',
    og_image: '',
    og_alt: '',
    canonical_tags: '',
  };




  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const handleDeleteKeywords = (i) => {
    setKeywords(keywords.filter((tag, index) => index !== i));
  };

  const handleAdditionKeywords = (keyword) => {
    setKeywords([...keywords, keyword]);
  };

  const handleDragKeywords = (keyword, currPos, newPos) => {
    const newkeywords = keywords.slice();

    newkeywords.splice(currPos, 1);
    newkeywords.splice(newPos, 0, keyword);

    setKeywords(newkeywords);
  };

  const handleDeleteTags = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAdditionTags = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDragTags = (tags, currPos, newPos) => {
    const newtags = tags.slice();

    newtags.splice(currPos, 1);
    newtags.splice(newPos, 0, tags);

    setTags(newtags);
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];




  // page name onBlur method
  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name === 'pageName') {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
      setFieldValue('slug', slug);
    } else {
      handleBlur(event);
    }
  };
  const config = {
    controls: {
      font: {
        list: {
          'var(--alice)': 'Alice',
        }
      }
    }
  };
  //  Image upload method
  const onFileUpload = (event) => {
    //console.log(event.target.files[0], event.target.name);
    let formData = new FormData();
    formData.append('image', event.target.files[0]);
    setLoaderCheck(true);
    axiosPrivate
      .post('pages/file-upload', formData)
      .then((res) => {
        setLoaderCheck(false);
        console.log(res, event?.target?.name);
        setFieldValue(event?.target?.name, res?.data?.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        setFieldValue(event?.target?.name, '');
      });
    handleChange(event);
  };

  // add row in first section
  const onAddRow = (name, check) => {
    if (name === 'first section') {
      if (check) {
        let newObj = {
          heading: '',
          content: '',
          mainImage: '',
        };
        let rowsData = values.firstSection;
        if (rowsData?.length < 5) {
          rowsData.push(newObj);
          setFieldValue('firstSection', rowsData);
        }
      } else {
        let rowsData = values.firstSection;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue('firstSection', rowsData);
        }
      }
    }
    if (name === 'second section') {
      if (check) {
        let newObj = {
          heading: '',
          content: '',
          image: '',
        };
        let rowsData = values.secondSection;
        if (rowsData?.length < 4) {
          rowsData.push(newObj);
          setFieldValue('secondSection', rowsData);
        }
      } else {
        let rowsData = values.secondSection;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue('secondSection', rowsData);
        }
      }
    }
    if (name === 'third section') {
      if (check) {
        let newObj = {
          heading: '',
          image: '',
        };
        let rowsData = values.thirdSection;
        if (rowsData?.length < 10) {
          rowsData.push(newObj);
          setFieldValue('thirdSection', rowsData);
        }
      } else {
        let rowsData = values.thirdSection;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue('thirdSection', rowsData);
        }
      }
    }
  };

  const navigate = useNavigate();

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: aboutTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      values.keywords = keywords;
      values.tags = tags;
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: 'about',
      };
      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put('/pages/update/' + id, formData)
          .then((res) => {
            setLoaderCheck(false);
            showSuccessToast("Category Updated")
            navigate('/admin/about-template');
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == 'array') {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              showErrorToast(error.response.data.errors.msg);
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post('pages/create', formData)
          .then((res) => {
            setLoaderCheck(false);
            navigate('/admin/about-template');
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (typeof error.response.data.errors == 'array') {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              console.log(error.response.data.errors.msg, 'data');
              showErrorToast(error.response.data.errors.msg);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get('pages/' + id)
        .then((res) => {
          setLoaderCheck(false);
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });
          setKeywords(parsedValues?.keywords);
          setTags(parsedValues?.tags);
          setSubmitButton('Update');
          setPagename('Edit About Template');
          setLoaderCheck(false);
        })

        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error.response.data.errors == 'array') {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    }
  }, []);
  const addLink =
    authPermissions.includes('About Template-create') === true
      ? '/admin/about-template/add'
      : '';

  return (
    <div>
      <TopNavBar links={{ list: '/admin/about-template', add: addLink }} />
      <div className={styles.templateContainer}>
      <h1 className={styles.mainHeading}>{pageName}</h1>
        <form onSubmit={handleSubmit} className={styles.aboutForm}>
          <div className={styles.mainFormContainer}>
            <div className='row'>
              {/* Page name field */}
              <div className=' my-2 col-12 col-lg-4'>
                <label
                  htmlFor='pagename'
                  className={` ${styles.fieldName} form-label`}
                >
                  Page name<span className={styles.red}>*</span>
                </label>
                <input
                  name='pageName'
                  id='pageName'
                  type='text'
                  className='form-control'
                  aria-describedby='emailHelp'
                  value={values.pageName}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                />
                {touched.pageName && errors.pageName ? (
                  <div className={styles.red}>{errors.pageName}</div>
                ) : null}
              </div>

              {/* slug field */}
              <div className=' my-2 col-12 col-lg-4'>
                <label
                  htmlFor='slug'
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name='slug'
                  id='slug'
                  type='text'
                  className='form-control'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.slug}
                />
                {touched.slug && errors.slug ? (
                  <div className={styles.red}>{errors.slug}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* First section */}
          <h2 className={styles.subHeading}>First section</h2>
          <div
            className={`${styles.mainFormContainer} ${styles.sectionContainer}`}
          >
            <div className='row '>
              {values.firstSection?.map((res, index) => {
                return (
                  <>
                    <div className=' my-2 col-12 col-lg-12'>
                      <label
                        htmlFor='heading'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Heading<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`firstSection[${index}].heading`}
                        id={`heading` + index}
                        type='text'
                        className='form-control'
                        aria-describedby='emailHelp'
                        onChange={handleChange}
                        value={values.firstSection[index].heading}
                      />
                    </div>

                    <div className=' my-2 col-12 col-lg-12'>
                      <label
                        htmlFor='content'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Content<span className={styles.red}>*</span>
                      </label>
                      {/* <Editor
                        className={`form-control`}
                        name={`firstSection[${index}].content`}
                        id={`content` + index}
                        onTextChange={(e) =>
                          setFieldValue(
                            `firstSection[${index}].content`,
                            e.htmlValue
                          )
                        }
                        onBlur={handleBlur}
                        value={values.firstSection[index].content}
                        style={{ height: '200px' }}
                      /> */}

                      <JoditEditor config={config}
                        className={`form-control`}
                        name={`firstSection[${index}].content`}
                        id={`content` + index}
                        onChange={(newContent) =>
                          setFieldValue(
                            `firstSection[${index}].content`,
                            newContent
                          )
                        }
                        onBlur={handleBlur}
                        value={values.firstSection[index].content}
                        style={{ height: '200px' }}
                      />
                    </div>

                    {/* Main image field */}
                    <div className=' my-2 col-12 col-lg-4'>
                      <label
                        htmlFor='mainImage'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Image<span className={styles.red}>*</span>
                      </label>

                      <input
                        id={`firstSection[${index}].mainImage` + index}
                        name={`firstSection[${index}].mainImage`}
                        type='file'
                        className='form-control'
                        onChange={onFileUpload}
                        onBlur={handleBlur}
                      />
                      {typeof values.firstSection[index].mainImage ===
                        'string' &&
                      values.firstSection[index].mainImage !== '' ? (
                        <img
                          className={styles.mainImage}
                          src={values.firstSection[index].mainImage}
                          alt='AboutImg'
                          loading='lazy'
                        />
                      ) : (
                        ''
                      )}
                      {touched.mainImage && errors.mainImage ? (
                        <div className={styles.red}>{errors.mainImage}</div>
                      ) : null}
                    </div>
                  </>
                );
              })}

              <div className={`${styles.addDataButton} col-12 col-lg-1`}>
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('first section', true);
                  }}
                >
                  +
                </button>
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('first section', false);
                  }}
                >
                  -
                </button>
              </div>
            </div>
          </div>

          <h2 className={styles.subHeading}>Second section</h2>
          <div
            className={`${styles.mainFormContainer} ${styles.sectionContainer}`}
          >
            <div className='row '>
              <div className=' my-2 col-12 '>
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name='secondSectionHeading'
                  id='secondSectionHeading'
                  type='text'
                  className='form-control'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Enter Main Heading'
                  value={values?.secondSectionHeading}
                />
              </div>

             
              {values.secondSection?.map((res, index) => {
                return (
                  <>
                    <div className=' my-2 col-12 col-lg-6'>
                      <label
                        htmlFor='heading'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Heading<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`secondSection[${index}].heading`}
                        id={`heading` + index}
                        type='text'
                        className='form-control'
                        aria-describedby='emailHelp'
                        onChange={handleChange}
                        value={values.secondSection[index].heading}
                      />
                    </div>
                    <div className=' my-2 col-12 col-lg-6'>
                      <label
                        htmlFor={`image` + index}
                        className={` ${styles.fieldName} form-label`}
                      >
                        Image<span className={styles.red}>*</span>
                      </label>
                      <input
                        id={`image` + index}
                        name={`secondSection[${index}].image`}
                        type='file'
                        className='form-control'
                        onChange={onFileUpload}
                        onBlur={handleBlur}
                      />
                      {typeof res.image === 'string' && res.image !== '' ? (
                        <img
                          className={styles.mainImage}
                          src={res.image}
                          alt={`secondSection[${index}].heading`}
                          loading='lazy'
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className=' my-2 col-12'>
                      <label
                        htmlFor='content'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Content<span className={styles.red}>*</span>
                      </label>
                      <textarea
                        className={` form-control`}
                        name={`secondSection[${index}].content`}
                        id={`content` + index}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.secondSection[index].content}
                      />
                    </div>
                  </>
                );
              })}

              <div className={`${styles.addDataButton} col-12 col-lg-1`}>
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('second section', true);
                  }}
                >
                  +
                </button>
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('second section', false);
                  }}
                >
                  -
                </button>
              </div>
            </div>
          </div>

          <h2 className={styles.subHeading}>Third section</h2>
          <div
            className={`${styles.mainFormContainer} ${styles.sectionContainer}`}
          >
            <div className='row '>
              {values.thirdSection?.map((res, index) => {
                return (
                  <>
                    <div className=' my-2 col-12 col-lg-6'>
                      <label
                        htmlFor='heading'
                        className={` ${styles.fieldName} form-label`}
                      >
                        Heading<span className={styles.red}>*</span>
                      </label>
                      <input
                        name={`thirdSection[${index}].heading`}
                        id={`heading` + index}
                        type='text'
                        className='form-control'
                        aria-describedby='emailHelp'
                        onChange={handleChange}
                        value={values.thirdSection[index].heading}
                      />
                    </div>

                    <div className=' my-2 col-12 col-lg-6'>
                      <label
                        htmlFor={`image` + index}
                        className={` ${styles.fieldName} form-label`}
                      >
                        Image<span className={styles.red}>*</span>
                      </label>
                      <input
                        id={`image` + index}
                        name={`thirdSection[${index}].image`}
                        type='file'
                        className='form-control'
                        onChange={onFileUpload}
                        onBlur={handleBlur}
                      />
                      {typeof res.image === 'string' && res.image !== '' ? (
                        <img
                          className={styles.mainImage}
                          src={res?.image}
                          alt={`thirdSection[${index}].heading`}
                          loading='lazy'
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                );
              })}

              <div
                className={`${styles.addDataButton} col-12 col-lg-1 text-right`}
              >
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('third section', true);
                  }}
                >
                  +
                </button>
                <button
                  type='button'
                  onClick={() => {
                    onAddRow('third section', false);
                  }}
                >
                  -
                </button>
              </div>
            </div>
          </div>

          <h2 className={styles.subHeading}>Meta Fields:</h2>
          <div style={{paddingRight:'3%'}}
            className={`${styles.mainFormContainer} ${styles.sectionContainer}`}
          >
            <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_title'
                            name='meta_title'
                            type='text'
                            className='form-control'
                            placeholder='meta title *'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='meta_description'
                            name='meta_description'
                            type='text'
                            className='form-control'
                            placeholder='meta description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.meta_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div
                        className='col-md-12 col-sm-12'
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Keywords:</h5>
                        <ReactTags
                          tags={keywords}
                          id='keywords'
                          name='keywords'
                          delimiters={delimiters}
                          handleDelete={handleDeleteKeywords}
                          handleAddition={handleAdditionKeywords}
                          handleDrag={handleDragKeywords}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.keywords}
                          placeholder='Enter keywords'
                        />
                      </div>
                      <div
                        className='col-md-12 col-sm-12 '
                        style={{ marginLeft: '1%' }}
                      >
                        <h5 style={{ marginTop: '1%' }}>Tags:</h5>
                        <ReactTags
                          tags={tags}
                          id='tags'
                          name='tags'
                          delimiters={delimiters}
                          handleDelete={handleDeleteTags}
                          handleAddition={handleAdditionTags}
                          handleDrag={handleDragTags}
                          inputFieldPosition='bottom'
                          autocomplete
                          handleInputChange={handleChange}
                          handleInputBlur={handleBlur}
                          value={values?.tags}
                          placeholder='Enter tags'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_title'
                            name='og_title'
                            type='text'
                            className='form-control'
                            placeholder='Og title'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_title}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_description'
                            name='og_description'
                            type='text'
                            className='form-control'
                            placeholder='Og description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.og_description}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_image'
                            name='og_image'
                            type='file'
                            className='form-control'
                            placeholder='Og Image'
                            onChange={onFileUpload}
                            accept='image/*'
                            onBlur={handleBlur}
                            style={{ margin: '2%' }}
                          />
                          {typeof values?.og_image === 'string' && values?.og_image !== '' ? (
                            <img
                            className={styles.mainImage}
                            src={values?.og_image}
                            alt="Og Image Alt"
                            loading='lazy'
                          />
                          ) : (
                            ''
                          )}
                          
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='og_alt'
                            name='og_alt'
                            type='text'
                            className='form-control'
                            placeholder='Og alt'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.og_alt}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <div className=''>
                          <input
                            id='canonical_tags'
                            name='canonical_tags'
                            type='text'
                            className='form-control'
                            placeholder='Canonical'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.canonical_tags}
                            style={{ margin: '2%' }}
                          />
                        </div>
                      </div>
                    </div>

            
           
          </div>
          <div className='d-flex justify-content-end'>
            <button
              style={{ color: 'white' }}
              id='button_1'
              className={`${styles.closeButton} btn`}
              onClick={() => navigate('/admin/about-template')}
            >
              Close
            </button>
            {authPermissions.includes('About Template-create') && (
              <button
                style={{ color: 'white' }}
                type='submit'
                id='button_2'
                className={`${styles.submitButton} btn`}
              >
                {submitButton}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AboutPageTemplate;
