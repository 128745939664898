import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  // Redirect to the home page
  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null; // This component doesn't render anything
}
  